import React, { useCallback, useEffect } from 'react';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import {
  selectGpsList,
  selectGpsListCount,
  selectGpsQueryOptions,
} from 'src/store/selectors/gpsSelectors';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadTransmissionList, LOAD_GPS } from 'src/store/actions/gpsActions';
import GpsTable from './TransmissionGpsTable';
import Toolbar from './TransmissionGpsToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TransmissionGpsList = () => {
  const dispatch = useDispatch();
  const devices = useSelector(selectGpsList);
  const count = useSelector(selectGpsListCount);
  const { page } = useSelector(selectGpsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_GPS.action]));
  const success = useSelector(successSelector([LOAD_GPS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      console.log(value);
      dispatch(loadTransmissionList({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadTransmissionList({ sort: { property, order } }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      loadTransmissionList({
        deviceCatalogId: null,
        plateOrCarniv: null,
        page: 0,
      }),
    );
  }, []);

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado Dispositivos"
          description="Ningún dispositivo coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <GpsTable
      cars={devices}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

const TransmissionGpsListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('NAVIGATION.TRANSMISSION')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <TransmissionGpsList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default TransmissionGpsListView;
