import * as Yup from 'yup';

export default Yup.object().shape({
  plates: Yup.string()
    .length(7, 'SCHEMA_ERRORS.ENTER_SEVEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PLATE'),
  estado: Yup.string()
    .oneOf(['Monterrey', 'Saltillo', 'Torreón'], 'SCH_ERRORS.SELECT_VALUE_LIST')
    .required('SCH_ERRORS.SELECT_VALUE_LIST'),
  tarjeta: Yup.mixed(),
});
