import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import { useTranslation } from 'react-i18next';
import CollectionsRow from './CollectionsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const useStyles = makeStyles(() => ({
  tableRoot: {
    border: 'none',
  },
}));

const Tbody = ({ collection }) =>
  collection.map(col => <CollectionsRow key={col.id} {...col} />);

const CollectionsTable = ({
  className,
  collection,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell style={{ width: '5%' }}>{t('INCOME_VIEW.ID')}</TableCell>
        <TableCell style={{ width: '20%' }}>
          {t('INCOME_VIEW.CLIENT')}
        </TableCell>
        <TableCell style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.INITIAL_BALANCE')}
        </TableCell>
        <TableCell style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.CHARGES')}
        </TableCell>
        <TableCell style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.PAYMENTS')}
        </TableCell>
        <TableCell style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.FINAL_BALANCE')}
        </TableCell>
        <TableCell style={{ width: '10%' }}>
          {t('INCOME_VIEW.STATUS')}
        </TableCell>
        <TableCell style={{ width: '10%' }}>
          {t('INCOME_VIEW.LAST_PAYMENT')}
        </TableCell>
        <TableCell style={{ width: '10%' }}>
          {t('INCOME_VIEW.DAYS_SINCE')}
        </TableCell>
        <TableCell style={{ width: '10%' }}>
          {t('INCOME_VIEW.UNSUBSCRIBE')}
        </TableCell>
      </TableRow>
    ),
    [],
  );

  const tbody = useMemo(() => () => <Tbody collection={collection} />, [
    collection,
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, count, handleChangePage],
  );

  return (
    <Table
      {...rest}
      className={classes.tableRoot}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

CollectionsTable.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
};

CollectionsTable.defaultProps = {
  count: 0,
};

export default CollectionsTable;
