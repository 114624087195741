/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Box, Grid } from '@material-ui/core';
import MaintenanceTable from './MaintenancesTable/MaintenanceTable';

moment.updateLocale('es', moment.localeData('es'));

const MaintenanceView = ({ car }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaintenanceTable maintenances={car.maintenances} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MaintenanceView;
