import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import moment from 'moment';
import {
  formatNegativeAmount,
  formatPositiveAmount,
} from 'src/utils/formatAmount';
import useStyles from './styles';

const FleetMovementsRow = ({
  niv,
  equipment,
  purchaseDate,
  saleDate,
  monthsOfLife,
  depreciation,
  purchasePrice,
  commercialPrice,
  salePrice,
  balance,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableRow hover>
        <TableCell>{niv}</TableCell>
        <TableCell>{equipment}</TableCell>
        <TableCell className={classes.center}>
          {moment(purchaseDate).format('DD-MMM-YYYY')}
        </TableCell>
        <TableCell className={classes.center}>
          {moment(saleDate).format('DD-MMM-YYYY')}
        </TableCell>
        <TableCell className={classes.center}>{monthsOfLife}</TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(purchasePrice)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatNegativeAmount(depreciation)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(commercialPrice)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(salePrice)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(balance)}
        </TableCell>
      </TableRow>
    </>
  );
};

FleetMovementsRow.propTypes = {
  niv: PropTypes.string,
  equipment: PropTypes.string,
  purchaseDate: PropTypes.string,
  saleDate: PropTypes.string,
  monthsOfLife: PropTypes.number,
  purchasePrice: PropTypes.number,
  depreciation: PropTypes.number,
  commercialPrice: PropTypes.number,
  salePrice: PropTypes.number,
  balance: PropTypes.number,
};

export default FleetMovementsRow;
