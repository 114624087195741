import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { updateBasicDriverInfoAction } from 'src/store/actions/driverActions';
import DriverCalifitacion from './DriverCalification';
import useStyles from '../styles';
import DriverCalificationDialog from './DriverCalificationDialog';

const DriverCarDetail = ({ driver }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateBasicDriverInfoAction(driver.id, values));
    },
    [driver, dispatch],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('DRIVER_DETAIL_VIEW.CALIFICATION.TITLE')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <DriverCalifitacion
              creditRiskScore={driver.creditRiskScore}
              fraudScore={driver.fraudScore}
              phoneScore={driver.phoneScore}
              contactabilityScore={driver.contactabilityScore}
              driver={driver}
            />
          </TableBody>
        </Table>

        <DriverCalificationDialog
          open={open}
          driver={driver}
          handleClose={handleClose}
          submit={handleSubmit}
        />

        <Box alignItems="center" display="flex" className={classes.buttons}>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            className={clsx(classes.label, classes.buttons)}
            startIcon={<EditIcon />}
          >
            {t('COMMON.EDIT')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

DriverCarDetail.propTypes = {
  driver: propTypes.object.isRequired,
};

export default DriverCarDetail;
