import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UploadFile from 'src/components/UploadFile';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = car => {
  return {
    plates: car.plates,
    estado: car.state,
    tarjeta: car.circulationCard
      ? { name: 'Ver actual tarjeta', link: car.circulationCard, inDb: true }
      : '',
  };
};

const CarDocumentDialog = ({ open, car, handleClose, submit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(car), [car]);
  const resetRef = useRef();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      submit(values);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState],
  );

  const handleUploadChange = useCallback(
    setFieldValue => e => {
      if (e.target.files[0]) {
        setFieldValue('tarjeta', e.target.files[0]);
      }
    },
    [],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-driver"
      >
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CAR_DETAIL.VEHICLE_DOCUMENTS')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.plates && errors.plates)}
                  fullWidth
                  helperText={touched.plates && t(errors.plates)}
                  label={t('COMMON.PLATES')}
                  margin="normal"
                  name="plates"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.plates}
                  variant="outlined"
                />

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.estado && errors.estado)}
                >
                  <InputLabel id="estado-select-label">
                    {t('COMMON.PLAZA')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="estado-select-label"
                    label={t('COMMON.PLAZA')}
                    id="estado-select"
                    value={values.estado}
                    onChange={handleChange}
                    name="estado"
                  >
                    <MenuItem value="Monterrey">Monterrey</MenuItem>
                    <MenuItem value="Saltillo">Saltillo</MenuItem>
                    <MenuItem value="Torreón">Torreón</MenuItem>
                  </Select>
                  {Boolean(touched.estado && errors.estado) && (
                    <FormHelperText>{t(errors.estado)}</FormHelperText>
                  )}
                </FormControl>

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept="image/*"
                  valueLabel="tarjeta"
                  textLabel={t('COMMON.CIRCULATION_CARD')}
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.UPDATE')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CarDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  car: PropTypes.object,
};

export default CarDocumentDialog;
