/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { ExternalLink } from 'react-feather';
import DetailRow from 'src/components/DetailRow';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';

const DocumentLink = ({ link }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return link ? (
    <>
      <a href={link} className={classes.documentLink}>
        <Typography
          variant="body2"
          component="div"
          className={classes.documentText}
        >
          {t('COMMON.SEE_DOCUMENT')}
          <ExternalLink style={{ marginLeft: '8px' }} size={20} />
        </Typography>
      </a>
    </>
  ) : (
    <>{t('COMMON.NOT_AVAILABLE')}</>
  );
};

const CarInsuranceInfo = ({ insurance, lease }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow name={t('INSURANCE_VIEW.INSURER')} value={insurance.company} />
      <DetailRow name={t('INSURANCE_VIEW.AGENT')} value={insurance.agent} />
      <DetailRow
        name={t('INSURANCE_VIEW.POLICY_TYPE')}
        value={insurance.policyType}
      />

      <DetailRow
        name="PDF"
        value={<DocumentLink link={insurance.policyPDF} />}
      />

      <DetailRow name={t('INSURANCE_VIEW.COVERAGE')} value={insurance.cover} />

      <DetailRow
        name={t('INSURANCE_BALANCE_VIEW.COVERAGE_START_DATE')}
        value={moment(insurance.coverageStart).format('DD-MMM-YY')}
      />

      <DetailRow
        name={t('INSURANCE_VIEW.COVERAGE_END')}
        value={moment(insurance.coverageEnd).format('DD-MMM-YY')}
      />

      <DetailRow
        name={t('INSURANCE_VIEW.POLICY_NUMBER')}
        value={insurance.policyId}
      />

      <DetailRow
        name={t('COMMON.PRICE')}
        value={insurance.price || t('COMMON.NO_DATA')}
      />

      {lease && !!lease.costPerInsuranceTotal && (
        <DetailRow
          name={t('COMMON.TOTAL_COST')}
          value={lease.costPerInsuranceTotal}
        />
      )}
    </>
  );
};

export default CarInsuranceInfo;
