import moment from 'moment';
import { DateTypeFormat } from './constants';

const selectFormatDate = (date, format = DateTypeFormat.DAY) => {
  switch (format) {
    case DateTypeFormat.DAY:
      return moment(date).format('DD-MMM-YY');
    case DateTypeFormat.MONTH_AND_YEAR:
      return moment(date).format('MMM-YY');
    case DateTypeFormat.MONTH:
      return moment(date)
        .format('MMMM')
        .toUpperCase();
    case DateTypeFormat.QUARTER:
      return `${moment(date)
        .startOf('quarter')
        .format('MMMM YY')} - ${moment(date)
        .endOf('quarter')
        .format('MMMM YY')}`.toUpperCase();
    case DateTypeFormat.YEAR:
      return moment(date).format('YYYY');
    default:
      return moment(date).format('DD-MMM-YY');
  }
};

export default selectFormatDate;
