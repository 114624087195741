import React, { useCallback, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'src/components/DatePicker';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadBrands } from 'src/store/actions/catalogActions';
import {
  getBrandNameList,
  TRANSMISSION_TYPE,
} from 'src/views/fleet/CarDetail/utils';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import PropTypes from 'prop-types';
import DropdownMenu from 'src/components/DropdownMenu';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import useStyles from '../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const StepTwo = ({ data, handleBack, handleNext }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const brands = useSelector(selectCatalogList);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onNext = useCallback(
    values => {
      values.year = values.year.year();
      handleNext(values);
    },
    [handleNext],
  );

  useEffect(() => {
    dispatch(loadBrands());
  }, []);

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <DatePicker
            field={{
              name: 'purchaseDate',
              value: values.purchaseDate,
            }}
            form={{
              errors,
              setFieldValue,
              touched,
            }}
            label={t('DEPRECIATION_BALANCE_VIEW.PURCHASE_DATE')}
            disableFuture
          />

          <TextField
            error={Boolean(touched.rfc && t(errors.rfc))}
            fullWidth
            helperText={touched.rfc && t(errors.rfc)}
            label={t('CAR_DETAIL.OWNER_RFC')}
            margin="normal"
            name="rfc"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.rfc}
            variant="outlined"
          />

          <TextField
            error={Boolean(touched.motor && t(errors.motor))}
            fullWidth
            helperText={touched.motor && t(errors.motor)}
            label={t('CAR_DETAIL.ENGINE_NUMBER')}
            margin="normal"
            name="motor"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.motor}
            variant="outlined"
          />

          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.brand && t(errors.brand))}
          >
            <InputLabel id="brand-select-label">{t('COMMON.BRAND')}</InputLabel>
            <Select
              onBlur={handleBlur}
              labelId="brand-select-label"
              label={t('COMMON.BRAND')}
              id="brand-select"
              value={values.brand}
              onChange={handleChange}
              name="brand"
            >
              <MenuItem value="" disabled />
              {getBrandNameList(brands).map(brand => (
                <MenuItem key={brand} value={brand}>
                  {brand}
                </MenuItem>
              ))}
            </Select>
            {Boolean(touched.brand && errors.brand) && (
              <FormHelperText>{t(errors.brand)}</FormHelperText>
            )}
          </FormControl>

          <DatePicker
            field={{
              name: 'year',
              value: values.year,
            }}
            form={{
              errors,
              setFieldValue,
              touched,
            }}
            label={t('CAR_DETAIL.YEAR')}
            views={['year']}
            format="YYYY"
          />

          <TextField
            error={Boolean(touched.type && t(errors.type))}
            fullWidth
            helperText={touched.type && t(errors.type)}
            label={t('CAR_DETAIL.TYPE')}
            margin="normal"
            name="type"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.type}
            variant="outlined"
          />

          <TextField
            error={Boolean(touched.version && t(errors.version))}
            fullWidth
            helperText={touched.version && t(errors.version)}
            label={t('CAR_DETAIL.VERSION')}
            margin="normal"
            name="version"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.version}
            variant="outlined"
          />

          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.transmission && t(errors.transmission))}
          >
            <InputLabel id="transmission-select-label">
              {t('COMMON.TRANSMISSION')}
            </InputLabel>
            <Select
              onBlur={handleBlur}
              labelId="transmission-select-label"
              label={t('CAR_DETAIL.TRANSMISSION')}
              id="transmission-select"
              value={values.transmission}
              onChange={handleChange}
              name="transmission"
            >
              <MenuItem value="" disabled />
              <MenuItem value={TRANSMISSION_TYPE.MANUAL}>
                {t('CAR_DETAIL.MANUAL')}
              </MenuItem>
              <MenuItem value={TRANSMISSION_TYPE.AUTOMATIC}>
                {t('CAR_DETAIL.AUTOMATIC')}
              </MenuItem>
              <MenuItem value={TRANSMISSION_TYPE.SEMIMANUAL}>
                {t('CAR_DETAIL.SEMIMANUAL')}
              </MenuItem>
            </Select>
            {Boolean(touched.transmission && errors.transmission) && (
              <FormHelperText>{t(errors.transmission)}</FormHelperText>
            )}
          </FormControl>

          <TextField
            error={Boolean(touched.color && t(errors.color))}
            fullWidth
            helperText={touched.color && t(errors.color)}
            label={t('COMMON.COLOR')}
            margin="normal"
            name="color"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.color}
            variant="outlined"
          />

          <DropdownMenu
            list={data.carCategories.map(carCategory => {
              return {
                key: carCategory.name,
                name: `${t('COMMON.CATEGORY')} ${
                  carCategory.name
                } - ${formatPositiveAmount(carCategory.price)}`,
              };
            })}
            value={values.category}
            setValue={value => setFieldValue('category', value)}
            label={`${t('COMMON.CATEGORY')}(${t('COMMON.SYSTEM_PARAMATER')})`}
            error={Boolean(touched.category && errors.category)}
            errorText={touched.category && t(errors.category)}
          />

          <Box
            className={classes.box}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              className={classes.buttons}
              onClick={handleBack}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.BACK')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.buttonMain)}
              type="submit"
              color="primary"
              variant="outlined"
            >
              {t('COMMON.NEXT')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default StepTwo;
