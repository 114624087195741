import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getBillingParameters = serviceBase({
  request: options =>
    axiosClient.get('/billing/parameters', { params: options }),
  retry: 0,
});

export const test = serviceBase({
  request: data => axiosClient.post('/auth/login', data),
  transformResponse: res => res.data.data,
  retry: 0,
});
