import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import GeneralAval from './GeneralAval';
import AvalAddress from './AvalAddress';
import AvalDocuments from './AvalDocuments';

const AvalView = ({ driver }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <GeneralAval
            aval={driver.aval}
            driverId={driver.id}
            driverStatus={driver.status}
          />
        </Grid>

        {driver.aval && (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <AvalAddress
                aval={driver.aval}
                driverId={driver.id}
                driverStatus={driver.status}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <AvalDocuments
                aval={driver.aval}
                driverId={driver.id}
                driverStatus={driver.status}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

AvalView.propTypes = {
  driver: PropTypes.object,
};

export default AvalView;
