/* eslint-disable react/prop-types */
import React from 'react';
import PrivateRoute from 'src/components/PrivateRoute';
import RentResumeListView from 'src/views/income/RentResume';
import IncomeResumeView from './IncomeResume';
import RentFactorView from './RentFactor';
import CollectionsListView from './Collections';
import ExcedentMileage from './excedentMileageView';

const IncomeView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}/rent`} component={RentResumeListView} />
      <PrivateRoute
        path={`${match.url}/rentoverdue`}
        component={CollectionsListView}
      />
      <PrivateRoute
        path={`${match.url}/indicators`}
        component={RentFactorView}
      />
      <PrivateRoute
        path={`${match.url}/resumen`}
        component={IncomeResumeView}
      />
      <PrivateRoute
        path={`${match.url}/excedent-mileage`}
        component={ExcedentMileage}
      />
    </>
  );
};

export default IncomeView;
