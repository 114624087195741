import * as Yup from 'yup';

export default Yup.object().shape({
  socialReason: Yup.string().required('SCHEMA_ERRORS.ENTER_SOCIAL_REASON'),
  commercialName: Yup.string().required('SCHEMA_ERRORS.ENTER_COMMERCIAL_NAME'),
  activity: Yup.string().required('SCHEMA_ERRORS.ENTER_ACTIVITY'),
  address: Yup.string().required('SCHEMA_ERRORS.ENTER_ADDRESS'),
  contactName: Yup.string().required('SCHEMA_ERRORS.ENTER_CONTACT_NAME'),
  contactType: Yup.string()
    .oneOf(
      ['COMMERCIAL', 'COLLECTION', 'LEGAL'],
      'SCHEMA_ERRORS.SELECT_VALUE_LIST',
    )
    .required('SCHEMA_ERRORS.ENTER_CONTACT_TYPE'),
  contactPhonePersonal: Yup.string()
    .length(10, 'SCHEMA_ERRORS.ENTER_TEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PHONE_NUMBER'),
  contactMailAddress: Yup.string()
    .required()
    .email('SCHEMA_ERRORS.ENTER_EMAIL_VALID'),
});
