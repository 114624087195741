export const selectCollections = state => state.collections;
export const selectCollectionsList = state => selectCollections(state).items;
export const selectCollectionsCount = state => selectCollections(state).count;
export const selectCollectionsOptions = state => {
  const { options } = selectCollections(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
