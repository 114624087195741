import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    borderRadius: '4px',
    padding: theme.spacing(1),
    minWidth: '100%',
    margin: '0 auto',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: 11,
    },
  },
  outlined: {
    borderColor: '#FFFFFF',
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
  },
  outlinedDelete: {
    color: theme.palette.secondary.main,
    borderColor: '#FFFFFF',
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400,
    },
  },
  title: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%',
  },
  documentLink: {
    color: theme.palette.primary.main,
    marginLeft: '8px',
    cursor: 'pointer',
  },
  documentIcon: {
    marginRight: '8px',
    size: 20,
  },
  documentText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginTop: '-16px',
  },
  root: {
    minWidth: theme.spacing(58),
    overflow: 'hidden',
  },
  box: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px`,
  },
  confirmation: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2.5),
    padding: `${theme.spacing(2.5)}px`,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    float: 'right',
    width: '20px',
    height: '20px',
  },
  tooltip: {
    fontSize: 15,
  },
  datePicker: {
    border: '1px dashed rgba(0, 0, 0, 0.26)',
    borderRadius: 3,
    paddingLeft: '5px',
  },
  datePickerLabel: {
    marginTop: theme.spacing(),
    fontSize: '14px',
  },
}));
