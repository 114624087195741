import * as Yup from 'yup';

export default Yup.object().shape({
  insideHomeNumber: Yup.string().nullable(),
  externalHomeNumber: Yup.string().nullable(),
  street: Yup.string().nullable(),
  colonia: Yup.string().nullable(),
  municipality: Yup.string().nullable(),
  homeTown: Yup.string().nullable(),
  zipCode: Yup.string()
    .length(5, 'SCHEMA_ERRORS.INVALID_ZIP_CODE')
    .nullable(),
});
