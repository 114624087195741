import { push } from 'connected-react-router';
import { getCarByNiv } from 'src/services/carService';
import {
  getAvailableDrivers,
  getDrivers,
  getDriverById,
  updateDriverBasicInfo,
  assignDriver as assign,
  unassignDriver as unassign,
  changeDriverCar,
  createNewDriver,
  updateDriverDocuments,
  getUploadLink,
  uploadFile,
  createAndAssignAval,
  editAval,
  deleteAval,
  getDriverTransactionsById,
  createTransaction,
  enableDriverService,
  downloadeExportedTransactions,
  downloadExportedDrivers,
  activeDriverService,
  unactiveDriverService,
  deletePayment,
  getDriverIncidentsById,
  downloadExportedMovements,
  revokeLease,
  createLease,
  updateDriverStatusService,
  getKilometersTraveledById,
  cancelAssing,
  updateSigleFile,
  resendSignNotification,
} from 'src/services/driverService';
import { getDriverPendingMovementsById } from 'src/services/deferredChargeService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { UNASSIGN_TYPE } from 'src/utils/constants';
import { getDriverTransactionMovementsById } from 'src/services/movementService';
import { TransactionCategory } from 'src/utils/transactions';
import {
  selectDriversQueryOptions,
  selectTransactionsQueryOptions,
  selectMovementsQueryOptions,
  selectMetricsQueryOptions,
} from '../selectors/driverSelectors';
import { LOAD_DETAIL_CAR } from './carActions';
import { showMessage } from './snackBarActions';

export const LOAD_AVAILABLE_DRIVERS = asyncActionCreator(
  'LOAD_AVAILABLE_DRIVERS',
);

export const SAVE_DRIVER_OPTIONS = 'SAVE_DRIVER_OPTIONS';
export const LOAD_DRIVERS = asyncActionCreator('LOAD_DRIVERS');
export const CLEAR_DRIVERS = 'CLEAR_DRIVERS';
export const LOAD_DETAIL_DRIVER = asyncActionCreator('LOAD_DETAIL_DRIVER');
export const CLEAR_DETAIL_DRIVER = 'CLEAR_DETAIL_DRIVER';
export const CLEAR_AVAILABLE_DRIVERS = 'CLEAR_AVAILABLE_DRIVERS';
export const CLEAR_DRIVER_OPTIONS = 'CLEAR_DRIVER_OPTIONS';
export const UPDATE_DRIVER = asyncActionCreator('UPDATE_DRIVER');
export const CREATE_DRIVER = asyncActionCreator('CREATE_DRIVER');
export const SAVE_TRANSACTION_OPTIONS = 'SAVE_TRANSACTION_OPTIONS';
export const CLEAR_TRANSACTION_OPTIONS = 'CLEAR_TRANSACTION_OPTIONS';
export const LOAD_TRANSACTIONS = asyncActionCreator('LOAD_TRANSACTIONS');
export const CREATE_TRANSACTION = asyncActionCreator('CREATE_TRANSACTION');
export const DELETE_TRANSACTION = asyncActionCreator('DELETE_TRANSACTION');
export const LOAD_MOVEMENTS = asyncActionCreator('LOAD_MOVEMENTS');
export const SAVE_MOVEMENT_OPTIONS = 'SAVE_MOVEMENT_OPTIONS';
export const CLEAR_MOVEMENT_OPTIONS = 'CLEAR_MOVEMENT_OPTIONS';
export const LOAD_INCIDENTS = asyncActionCreator('LOAD_INCIDENTS');
export const LOAD_METRICS = asyncActionCreator('LOAD_METRICS');
export const LOAD_TRANSACTION_MOVEMENTS = asyncActionCreator(
  'LOAD_TRANSACTION_MOVEMENTS',
);
export const SAVE_METRICS_OPTIONS = 'SAVE_METRICS_OPTIONS';
export const CLEAR_METRICS_OPTIONS = 'CLEAR_METRICS_OPTIONS';
export const CHANGE_DRIVER_STATUS = asyncActionCreator('CHANGE_DRIVER_STATUS');
export const CANCEL_ASSIGNATION = asyncActionCreator('CANCEL_ASSIGNATION');
export const DRIVER_ASSIGNATION = asyncActionCreator('DRIVER_ASSIGNATION');
export const CHANGE_DRIVER_ASSIGNATION = asyncActionCreator(
  'CHANGE_DRIVER_ASSIGNATION',
);
export const UNASSIGN_DRIVER = asyncActionCreator('UNASSIGN_DRIVER');
export const UPDATE_PROMISSORY_DOCUMENT = asyncActionCreator(
  'UPDATE_PROMISSORY_DOCUMENT',
);
export const RESEND_SIGN_NOTIFICATION = asyncActionCreator(
  'RESEND_SIGN_NOTIFICATION',
);

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectDriversQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.isAssigned !== undefined) {
    reqOptions.isAssigned = queryOpts.isAssigned;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }
  if (queryOpts.carMode) {
    reqOptions.carMode = queryOpts.carMode;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

function getTransactionOptions(getState, sendLimit = true) {
  const queryOpts = selectTransactionsQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

function getMovementOptions(getState, sendLimit = true) {
  const queryOpts = selectMovementsQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

function getMetricsOptions(getState, sendLimit = true) {
  const queryOpts = selectMetricsQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearTransactionOptions = () => dispatch => {
  dispatch({ type: CLEAR_TRANSACTION_OPTIONS });
};

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_DRIVER_OPTIONS });
};

const uploadSingleFile = async file => {
  if (!file) return Promise.reject(new Error('No file'));
  const { type } = file;
  const extension = file.name.split('.').pop();
  if (!extension) {
    throw new Error('No extension :(');
  }
  const link = await getUploadLink({ format: extension, contentType: type });
  const url = new URL(link);
  const attachmentId = url.pathname.slice(1);
  await uploadFile({ targetURL: link, file });
  return `https://meteoros-files.s3.us-east-2.amazonaws.com/${attachmentId}`;
};

export const loadDrivers = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_DRIVERS.start });

    dispatch({
      type: SAVE_DRIVER_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getDrivers(reqOptions);

    return dispatch({
      type: LOAD_DRIVERS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DRIVERS.failure });
  }
};

export const loadAvailableDrivers = isBusinessCar => async dispatch => {
  try {
    dispatch({ type: LOAD_AVAILABLE_DRIVERS.start });

    const data = await getAvailableDrivers(isBusinessCar);

    return dispatch({
      type: LOAD_AVAILABLE_DRIVERS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_AVAILABLE_DRIVERS.failure });
  }
};

export const loadDriverDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_DETAIL_DRIVER.start });

    const data = await getDriverById(id);

    return dispatch({
      type: LOAD_DETAIL_DRIVER.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DETAIL_DRIVER.failure });
  }
};

export const clearDrivers = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_DRIVERS });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const clearAvailableDrivers = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_AVAILABLE_DRIVERS });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const assignDriver = values => async dispatch => {
  try {
    dispatch({ type: DRIVER_ASSIGNATION.start });
    // Upload all assets.

    await assign(values);

    dispatch({ type: DRIVER_ASSIGNATION.success });

    dispatch(
      showMessage({
        message: 'ASSIGN_SUCCESS',
        variant: 'success',
      }),
    );

    dispatch({ type: LOAD_DETAIL_CAR.start });
    const data = await getCarByNiv(values.niv);

    return dispatch({
      type: LOAD_DETAIL_CAR.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch(push(`/drivers/detail/${values.driverId}`));
    dispatch({ type: DRIVER_ASSIGNATION.failure });
    return dispatch({ type: LOAD_DETAIL_CAR.failure });
  }
};

export const unassignDriver = (
  updateAction = () => {},
) => values => async dispatch => {
  try {
    if (values.unassignType === UNASSIGN_TYPE.CAR_CHANGE) {
      dispatch({ type: CHANGE_DRIVER_ASSIGNATION.start });

      await changeDriverCar(values);

      dispatch({ type: CHANGE_DRIVER_ASSIGNATION.success });
    }

    if (values.unassignType === UNASSIGN_TYPE.DISCHARGE) {
      dispatch({ type: UNASSIGN_DRIVER.start });
      console.log(values, 'DISCARCHE');

      await unassign(values);

      dispatch({ type: UNASSIGN_DRIVER.success });
    }

    dispatch(
      showMessage({
        message: 'UNASSIGN_SUCCESS',
        variant: 'success',
      }),
    );

    dispatch({ type: LOAD_DETAIL_CAR.start });
    const data = await getCarByNiv(values.niv);
    if (updateAction) {
      updateAction();
    }

    return dispatch({
      type: LOAD_DETAIL_CAR.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    if (values.unassignType === UNASSIGN_TYPE.CAR_CHANGE) {
      dispatch({ type: CHANGE_DRIVER_ASSIGNATION.failure });
    }
    if (values.unassignType === UNASSIGN_TYPE.DISCHARGE) {
      dispatch({ type: UNASSIGN_DRIVER.failure });
    }
    return dispatch({ type: LOAD_DETAIL_CAR.failure });
  }
};

export const cancelAssignDriver = ({ driverId }) => async dispatch => {
  try {
    dispatch({ type: CANCEL_ASSIGNATION.start });

    await cancelAssing({ driverId });

    dispatch({ type: CANCEL_ASSIGNATION.success });

    dispatch(loadDriverDetail(driverId));

    return dispatch(
      showMessage({
        message: 'CANCEL_ASSIGNATION',
        variant: 'success',
      }),
    );
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CANCEL_ASSIGNATION.failure });
  }
};

const updateDriverAction = serviceCallback => (
  id,
  values,
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_DRIVER.start });

    const data = await serviceCallback({ id, values });

    dispatch(
      showMessage({
        message: 'Conductor actualizado correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadDriverDetail(id));
    return dispatch({
      type: UPDATE_DRIVER.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_DRIVER.failure });
  }
};

export const updateBasicDriverInfoAction = updateDriverAction(
  updateDriverBasicInfo,
);
export const updateDriverDocumentsAction = updateDriverAction(async data => {
  let {
    ifeFile,
    licenceFile,
    waterFile,
    pictureFile,
    ifeAval,
    licenceAval,
    waterAval,
  } = data.values;
  if (ifeFile && ifeFile.inDb) {
    ifeFile = '';
  }

  if (licenceFile && licenceFile.inDb) {
    licenceFile = '';
  }

  if (waterFile && waterFile.inDb) {
    waterFile = '';
  }

  if (pictureFile && pictureFile.inDb) {
    pictureFile = '';
  }

  if (ifeAval && ifeAval.inDb) {
    ifeAval = '';
  }

  if (licenceAval && licenceAval.inDb) {
    licenceAval = '';
  }

  if (waterAval && waterAval.inDb) {
    waterAval = '';
  }

  if (ifeFile) {
    ifeFile = await uploadSingleFile(ifeFile);
  }
  if (licenceFile) {
    licenceFile = await uploadSingleFile(licenceFile);
  }
  if (waterFile) {
    waterFile = await uploadSingleFile(waterFile);
  }
  if (pictureFile) {
    pictureFile = await uploadSingleFile(pictureFile);
  }
  if (ifeAval) {
    ifeAval = await uploadSingleFile(ifeAval);
  }
  if (licenceAval) {
    licenceAval = await uploadSingleFile(licenceAval);
  }
  if (waterAval) {
    waterAval = await uploadSingleFile(waterAval);
  }

  return updateDriverDocuments({
    id: data.id,
    values: {
      ...data.values,
      ifeFile,
      licenceFile,
      waterFile,
      pictureFile,
      ifeAval,
      licenceAval,
      waterAval,
    },
  });
});

export const enableDriver = updateDriverAction(enableDriverService);

export const activeDriver = updateDriverAction(activeDriverService);

export const unactiveDriver = updateDriverAction(unactiveDriverService);

export const assignAval = updateDriverAction(createAndAssignAval);

export const modifyAval = updateDriverAction(editAval);

export const unassignAval = updateDriverAction(deleteAval);

export const updateDriverStatusAction = (id, values) => async dispatch => {
  try {
    dispatch({ type: CHANGE_DRIVER_STATUS.start });

    await updateDriverStatusService({ id, values });
    dispatch(
      showMessage({
        message: 'UPDATE_DRIVER_STATUS',
        variant: 'success',
      }),
    );

    dispatch(loadDrivers());

    return dispatch({ type: CHANGE_DRIVER_STATUS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CHANGE_DRIVER_STATUS.failure });
  }
};

export const createDriver = values => async dispatch => {
  try {
    dispatch({ type: CREATE_DRIVER.start });

    await createNewDriver({ ...values, mobile: values.mobile.toString() });
    dispatch(
      showMessage({
        message: 'Conductor creado correctamente',
        variant: 'success',
      }),
    );

    dispatch(loadDrivers());

    return dispatch({ type: CREATE_DRIVER.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_DRIVER.failure });
  }
};

export const loadDriverTransactions = (id, options) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_TRANSACTIONS.start });

    dispatch({
      type: SAVE_TRANSACTION_OPTIONS,
      options,
    });
    const reqOptions = getTransactionOptions(getState);

    const data = await getDriverTransactionsById({ id, reqOptions });

    return dispatch({
      type: LOAD_TRANSACTIONS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_TRANSACTIONS.failure });
  }
};

export const loadDriverPendingMovements = (id, options) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_MOVEMENTS.start });

    dispatch({
      type: SAVE_MOVEMENT_OPTIONS,
      options,
    });
    const reqOptions = getMovementOptions(getState);

    const { charges, count } = await getDriverPendingMovementsById({
      id,
      reqOptions,
    });

    return dispatch({
      type: LOAD_MOVEMENTS.success,
      movements: charges,
      count,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_MOVEMENTS.failure });
  }
};

export const createDriverTransaction = (id, values) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: CREATE_TRANSACTION.start });

    const getComment = () => {
      if (values.category === TransactionCategory.PENDING_PAYMENT) {
        return `REF: ${values.reference}`;
      }
      return values.comment || null;
    };

    const body = {
      ...values,
      comment: getComment(),
    };

    await createTransaction({
      id,
      values: body,
    });

    dispatch(
      showMessage({
        message: 'TRANSACTION_CREATED',
        variant: 'success',
      }),
    );
    const reqOptions = getOptions(getState);

    dispatch(loadDriverTransactions(id, reqOptions));
    return dispatch({
      type: CREATE_TRANSACTION.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_TRANSACTION.failure });
  }
};

export const downloadExcelTransactions = id => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadeExportedTransactions({ id, options });
  } catch (error) {
    console.log(dispatch);
    console.error(error);
  }
};

export const downloadExcelDrivers = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadExportedDrivers(options);
  } catch (error) {
    console.log(dispatch);
    console.error(error);
  }
};

export const deleteDriverPayment = (transactionId, driverId) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: DELETE_TRANSACTION.start });
    await deletePayment({ id: transactionId });

    dispatch(
      showMessage({
        message: 'Transacción eliminada correctamente',
        variant: 'success',
      }),
    );
    const reqOptions = getOptions(getState);

    dispatch(loadDriverTransactions(driverId, reqOptions));
    return dispatch({
      type: DELETE_TRANSACTION.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_TRANSACTION.failure });
  }
};

export const loadDriverIncidents = id => async dispatch => {
  try {
    dispatch({ type: LOAD_INCIDENTS.start });

    const data = await getDriverIncidentsById(id);

    return dispatch({
      type: LOAD_INCIDENTS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INCIDENTS.failure });
  }
};

export const loadMileagesTraveled = (id, options) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_METRICS.start });
    dispatch({
      type: SAVE_METRICS_OPTIONS,
      options,
    });
    const reqOptions = getMetricsOptions(getState);

    const data = await getKilometersTraveledById({ id, reqOptions });

    return dispatch({
      type: LOAD_METRICS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_METRICS.failure });
  }
};

export const downloadExcelMovements = id => async (dispatch, getState) => {
  try {
    const options = getMovementOptions(getState);
    return downloadExportedMovements({ id, options });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const createLeaseAction = (values, id) => async dispatch => {
  try {
    // Upload all assets.
    let { hitchInvoiceXML, hitchInvoicePDF, contract, promissoryNote } = values;

    [contract, promissoryNote] = await Promise.all([
      uploadSingleFile(contract),
      uploadSingleFile(promissoryNote),
    ]);

    if (hitchInvoicePDF) {
      hitchInvoicePDF = await uploadSingleFile(hitchInvoicePDF);
    }

    if (hitchInvoiceXML) {
      hitchInvoiceXML = await uploadSingleFile(hitchInvoiceXML);
    }

    await createLease({
      ...values,
      ...(hitchInvoiceXML && { hitchInvoiceXML }),
      ...(hitchInvoicePDF && { hitchInvoicePDF }),
      contract,
      promissoryNote,
    });

    dispatch(
      showMessage({
        message: 'Contrato creado correctamente',
        variant: 'success',
      }),
    );
    return dispatch(loadDriverDetail(id));
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const revokeLeaseAction = (leaseId, driverId) => async dispatch => {
  try {
    await revokeLease(leaseId);
    dispatch(
      showMessage({
        message: 'Contrato revocado correctamente',
        variant: 'success',
      }),
    );
    return dispatch(loadDriverDetail(driverId));
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const updatePromissoryNote = ({
  values,
  fileName,
  driverId,
}) => async dispatch => {
  try {
    dispatch({ type: UPDATE_PROMISSORY_DOCUMENT.start });
    // Upload all assets.
    const { physicalSignedPdf } = values;

    if (physicalSignedPdf) {
      const pathName = fileName.split('/').pop();
      const formData = new FormData();

      formData.append('file', physicalSignedPdf);
      formData.append('fileName', pathName);

      await updateSigleFile(formData);

      dispatch({ type: UPDATE_PROMISSORY_DOCUMENT.success });
    }

    dispatch(
      showMessage({
        message: 'UPDATE_PROMISSORY_DOCUMENT',
        variant: 'success',
      }),
    );

    return dispatch(loadDriverDetail(driverId));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch(push(`/drivers/detail/${driverId}`));
    dispatch({ type: UPDATE_PROMISSORY_DOCUMENT.failure });
    return dispatch({ type: LOAD_DETAIL_CAR.failure });
  }
};

export const resendSignNotificationAction = contractId => async dispatch => {
  try {
    dispatch({
      type: RESEND_SIGN_NOTIFICATION.start,
    });
    await resendSignNotification(contractId);
    dispatch(
      showMessage({
        message: 'RESEND_SIGN_NOTIFICATION',
        variant: 'success',
      }),
    );
    return dispatch({
      type: RESEND_SIGN_NOTIFICATION.success,
    });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadTransactionMovements = id => async dispatch => {
  try {
    dispatch({ type: LOAD_TRANSACTION_MOVEMENTS.start });
    const data = await getDriverTransactionMovementsById(id);
    return dispatch({
      type: LOAD_TRANSACTION_MOVEMENTS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_TRANSACTION_MOVEMENTS.failure });
  }
};
