import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  makeStyles,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Page from 'src/components/Page';
import { login } from 'src/store/actions/authActions';
import { useDispatch } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import { Link } from 'react-router-dom';
import Footer from 'src/components/Footer';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: `${theme.spacing(4)}px ${theme.spacing(10)}px`,
  },
  login: {
    maxWidth: '75%',
    padding: 0,
    margin: 'auto',
    marginLeft: '20%',
  },
  button: {
    letterSpacing: 1.25,
    fontSize: theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(10)}px`,
    maxWidth: '85%',
  },
  text: {
    width: '85%',
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(7.5),
    fontWeight: '300',
    letterSpacing: -0.5,
    textAlign: 'left',
    marginBottom: theme.spacing(),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(2.3),
    fontWeight: '500',
    letterSpacing: 0.15,
    textAlign: 'left',
  },
  forgotPassText: {
    textAlign: 'center',
    width: '85%',
  },
}));

const makeIntiailValues = () => ({
  username: '',
  password: '',
  keepLogged: false,
});

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .max(255)
    .required('LOGIN_VIEW.USER_ERROR'),
  password: Yup.string()
    .max(255)
    .required('LOGIN_VIEW.PASS_ERROR'),
});

const LoginView = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValues = makeIntiailValues();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const timeoutRef = useRef(null);

  const handleLogin = useCallback(
    ({ username, password, keepLogged }, actions) => {
      timeoutRef.current = setTimeout(() => {
        actions.setSubmitting(false);
      }, [200]);
      dispatch(login({ username, password, keepLogged }));
    },
    [dispatch, timeoutRef],
  );

  useEffect(() => () => clearTimeout(timeoutRef.current), [timeoutRef]);

  const trimWhitespaces = useCallback(
    setFieldValue => e => {
      setFieldValue(e.target.name, e.target.value.trim());
    },
    [],
  );

  return (
    <MainLayout>
      <Page className={classes.root} title={t('LOGIN_VIEW.TITLE')}>
        <Box display="flex" flexDirection="column" height="100%">
          <div style={{ marginLeft: 'auto' }}>
            <I18nMenu />
          </div>
          <Container maxWidth="sm" className={classes.login}>
            <FadeIn transitionDuration={200}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values,
                  handleChange,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                      <Typography className={classes.title}>
                        {t('LOGIN_VIEW.WELCOME')}
                      </Typography>
                      <Typography className={classes.subtitle}>
                        {t('LOGIN_VIEW.WELCOME_SUBTITLE')}
                      </Typography>
                    </Box>
                    <TextField
                      className={classes.text}
                      error={Boolean(touched.username && errors.username)}
                      helperText={touched.username ? t(errors.username) : null}
                      label={t('LOGIN_VIEW.USERNAME')}
                      margin="normal"
                      name="username"
                      onBlur={handleBlur}
                      onChange={trimWhitespaces(setFieldValue)}
                      type="text"
                      value={values.username}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.text}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password ? t(errors.password) : null}
                      label={t('LOGIN_VIEW.PASSWORD')}
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={trimWhitespaces(setFieldValue)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      variant="outlined"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="keepLogged"
                          color="primary"
                          value={values.keepLogged}
                          checked={values.keepLogged}
                          onChange={handleChange}
                        />
                      }
                      label={t('LOGIN_VIEW.KEEP_ME_LOGGED')}
                    />
                    <Box my={3}>
                      <Button
                        className={classes.button}
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {t('LOGIN_VIEW.LOGIN')}
                      </Button>
                    </Box>
                    <Typography
                      variant="body1"
                      className={classes.forgotPassText}
                    >
                      <Link to="/recover-password">
                        {t('LOGIN_VIEW.FORGOT_PASS')}
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </FadeIn>
          </Container>
          <Footer />
        </Box>
      </Page>
    </MainLayout>
  );
};

export default LoginView;
