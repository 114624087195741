import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import useStyles from './styles';
import DepreciationBalanceRow from './DepreciationBalanceRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const Tbody = ({ balances }) =>
  balances?.map(balance => (
    <DepreciationBalanceRow key={balance.niv} {...balance} />
  ));

const DepreciationBalanceTable = ({
  className,
  balances,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell key="niv">NIV</TableCell>
        <TableCell key="model">{t('COMMON.MODEL')}</TableCell>
        <TableCell key="mode">{t('COMMON.MODE')}</TableCell>
        <TableCell key="price">{t('COMMON.PRICE')}</TableCell>
        <TableCell key="covarage">
          {t('DEPRECIATION_BALANCE_VIEW.PURCHASE_DATE')}
        </TableCell>
        <TableCell key="months">
          {t('INSURANCE_BALANCE_VIEW.MONTHS_OF_LIFE')}
        </TableCell>
        <TableCell key="initialBalance">
          {t('COMMON.INITIAL_BALANCE')}
        </TableCell>
        <TableCell key="amortization">
          {t('DEPRECIATION_BALANCE_VIEW.DEPRECIATION')}
        </TableCell>
        <TableCell key="unsubscription">{t('COMMON.UNSUBSCRIPTION')}</TableCell>
        <TableCell key="finalBalance">{t('COMMON.FINAL_BALANCE')}</TableCell>
      </TableRow>
    ),
    [classes, t],
  );

  const tbody = useMemo(() => () => <Tbody t={t} balances={balances} />, [
    balances,
    t,
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, balances],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

DepreciationBalanceTable.propTypes = {
  className: PropTypes.string,
  balances: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
  campaignType: PropTypes.string,
};

DepreciationBalanceTable.defaultProps = {
  count: 0,
};

export default DepreciationBalanceTable;
