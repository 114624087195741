import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import useStyles from '../../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditAddressDialog = ({ open, aval, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(
    () => makeInitialState(aval ? aval.address : null),
    [aval],
  );
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      submit(values);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-aval-driver"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DRIVER_DETAIL_VIEW.AVAL.EDIT_ADDRESS')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow:'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(
                    touched.insideHomeNumber && errors.insideHomeNumber,
                  )}
                  fullWidth
                  helperText={
                    touched.insideHomeNumber && t(errors.insideHomeNumber)
                  }
                  label={t('COMMON.INSIDE_NUMBER')}
                  margin="normal"
                  name="insideHomeNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.insideHomeNumber}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(
                    touched.externalHomeNumber && errors.externalHomeNumber,
                  )}
                  fullWidth
                  helperText={
                    touched.externalHomeNumber && t(errors.externalHomeNumber)
                  }
                  label={t('COMMON.EXTERNAL_NUMBER')}
                  margin="normal"
                  name="externalHomeNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.externalHomeNumber}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.street && errors.street)}
                  fullWidth
                  helperText={touched.street && t(errors.street)}
                  label={t('COMMON.STREET')}
                  margin="normal"
                  name="street"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.street}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.colonia && errors.colonia)}
                  fullWidth
                  helperText={touched.colonia && t(errors.colonia)}
                  label={t('COMMON.COLONIA')}
                  margin="normal"
                  name="colonia"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.colonia}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.municipality && errors.municipality)}
                  fullWidth
                  helperText={touched.municipality && t(errors.municipality)}
                  label={t('COMMON.MUNICIPALITY')}
                  margin="normal"
                  name="municipality"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.municipality}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.homeTown && errors.homeTown)}
                  fullWidth
                  helperText={touched.homeTown && t(errors.homeTown)}
                  label={t('COMMON.HOME_TOWN')}
                  margin="normal"
                  name="homeTown"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.homeTown}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  fullWidth
                  helperText={touched.zipCode && t(errors.zipCode)}
                  label={t('COMMON.ZIP_CODE')}
                  margin="normal"
                  name="zipCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.zipCode}
                  variant="outlined"
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('DRIVER_DETAIL_VIEW.AVAL.EDIT_ADDRESS')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditAddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  aval: PropTypes.object,
};

export default EditAddressDialog;
