import React, { useCallback, useEffect } from 'react';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import {
  selectGpsList,
  selectGpsListCount,
  selectGpsQueryOptions,
} from 'src/store/selectors/gpsSelectors';

import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  loadMileageExcedentList,
  LOAD_GPS,
} from 'src/store/actions/gpsActions';
import ExcedentMileageTable from './ExcedentMileageTable';
import ExcedentMileageToolbar from './ExcedentMileageToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ExcedentMileageList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mileageExcedentList = useSelector(selectGpsList);
  const count = useSelector(selectGpsListCount);
  const { page } = useSelector(selectGpsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_GPS.action]));
  const success = useSelector(successSelector([LOAD_GPS.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadMileageExcedentList({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadMileageExcedentList({ sort: { property, order } }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      loadMileageExcedentList({
        deviceCatalogId: null,
        plateOrCarniv: null,
        page: 0,
      }),
    );
  }, []);

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.MILEAGE_EXCEDENT_TITLE')}
          description={t(
            'NOT_FOUND_RECORDS_MESSAGE.MILEAGE_EXCEDENT_DESCRIPTION',
          )}
        />
      </Card>
    );
  }

  return (
    <ExcedentMileageTable
      cars={mileageExcedentList}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

const ExcedentMileageView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('NAVIGATION.EXCEDENT_MILEAGES')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <ExcedentMileageToolbar />
          <Box mt={3}>
            <ExcedentMileageList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default ExcedentMileageView;
