import axios from 'axios';
import store from 'src/store';
import { selectAuthToken } from 'src/store/selectors/authSelectors';

if (!process.env.REACT_APP_API_BASE) {
  console.error(
    'REACT_APP_API_BASE variable not loaded properly. Check your .env file please!',
  );
}

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: 30000,
});

client.interceptors.request.use(config => {
  const token = selectAuthToken(store.getState());
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default client;
