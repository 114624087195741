import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import * as Actions from 'src/store/actions/transactionsActions';

import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import {
  selectTransactionsList,
  selectTransactionsListCount,
  selectTransactionsQueryOptions,
} from 'src/store/selectors/transactionsSelectors';
import NotFoundRecords from 'src/components/NotFoundRecords';
import TransactionsTable from './TransactionsTable';

const TransactionsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const transactions = useSelector(selectTransactionsList);
  const count = useSelector(selectTransactionsListCount);
  const { page } = useSelector(selectTransactionsQueryOptions);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_TRANSACTIONS_TABLE.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_TRANSACTIONS_TABLE.action]),
  );

  const handleChangePage = useCallback(
    (_event, value) => {
      dispatch(Actions.loadTransactions({ page: value }));
    },
    [dispatch],
  );

  if (success && !loading && transactions?.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.TRANSACTIONS_LIST_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.TRANSACTIONS_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <TransactionsTable
      t={t}
      transactions={transactions}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default TransactionsList;
