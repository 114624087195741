/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import TransactionsListView from './TransactionsView';

const TransactionsView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}`} component={TransactionsListView} />
    </>
  );
};

export default TransactionsView;
