import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  Box,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Circle, Map, TileLayer } from 'react-leaflet';
import clsx from 'clsx';
import useStyles from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewGeofencingInMap = ({ open, handleClose, lat, lng, radius }) => {
  const classes = useStyles();
  const resetRef = useRef();

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  const getZoomDistance = rad => {
    switch (true) {
      case rad < 100:
        return 20;
      case rad < 500:
        return 16;
      case rad < 1000:
        return 15;
      default:
        return 12;
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent className={classes.dialogContainer}>
        <Box
          container
          direction="column"
          alignItems="stretch"
          alignContent="flex-end"
          position="absolute"
          top="25px"
          right="25px"
          zIndex="40001"
        >
          <IconButton
            aria-label="delete"
            size="large"
            classname={clsx(classes.label, classes.closeButton)}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Map
          center={[lat, lng]}
          zoom={getZoomDistance(radius)}
          scrollWheelZoom={false}
          style={{ height: '90vh', width: '100%' }}
        >
          <Circle center={[lat, lng]} radius={Number(radius)} />

          <TileLayer
            attribution="&copy; <a>Meteoros</a> "
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
        </Map>
      </DialogContent>
    </Dialog>
  );
};

ViewGeofencingInMap.propTypes = {
  open: PropTypes.bool.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,
  radius: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
};

export default ViewGeofencingInMap;
