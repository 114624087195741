import { createMuiTheme } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#110A5B',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#5F6162', // Neutral dark 2
      secondary: '#2B2D42',
      hint: '#000000',
      disabled: 'rgba(95,97,98,0.6)',
    },
    primary: {
      dark: '#2B2D42', // Dark
      main: '#110A5B', // Blue dark
      contrastText: '#FFFFFF', // Neutral white
    },
    secondary: {
      main: '#EE3552', // Pantone red
      edit: '#5C95FF',
    },
    succes: {
      main: '#7cb342',
    },
  },
  shadows,
  typography,
});

export default theme;
