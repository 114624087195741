import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import { DriverStatus } from 'src/utils/constants';
import DriverRow from './DriverRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Tbody = ({ drivers, handleOpenConfirmation }) =>
  drivers.map(driver => (
    <DriverRow
      key={driver.id}
      {...driver}
      handleOpenConfirmation={handleOpenConfirmation}
    />
  ));

const DriversTable = ({
  className,
  drivers,
  count,
  page,
  driverStatus,
  handleChangePage,
  handleSortChange,
  loading,
  handleOpenConfirmation,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('niv');
  const [order, setOrder] = useState('asc');

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSortChange(property, isAsc ? 'desc' : 'asc');
    },
    [order, orderBy, setOrder, setOrderBy, handleSortChange],
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell
        key="id"
        sortDirection={orderBy === 'id' ? order : false}
        style={{ width: '5%' }}
      >
        <TableSortLabel
          active={orderBy === 'id'}
          direction={orderBy === 'id' ? order : 'asc'}
          onClick={createSortHandler('id')}
        >
          ID
          {orderBy === 'id' ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>

      {driverStatus === DriverStatus.ACTIVE && (
        <>
          <TableCell
            variant="head"
            key="status"
            sortDirection={orderBy === 'status' ? order : false}
            style={{ width: '5%' }}
          >
            <TableSortLabel
              active={orderBy === 'status'}
              direction={orderBy === 'status' ? order : 'asc'}
              onClick={createSortHandler('status')}
            >
              {t('DRIVERS_VIEW.ASSIGNED')}
              {orderBy === 'status' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>

          <TableCell variant="head" key="MODE" style={{ width: '12%' }}>
            {t('FLEET_VIEW.MODE')}
          </TableCell>
        </>
      )}

      <TableCell variant="head" key="name" style={{ width: '12%' }}>
        {t('DRIVERS_VIEW.NAME')}
      </TableCell>

      <TableCell variant="head" key="paternalName" style={{ width: '12%' }}>
        {t('DRIVERS_VIEW.PATERNAL_NAME')}
      </TableCell>

      <TableCell variant="head" key="maternalName" style={{ width: '12%' }}>
        {t('DRIVERS_VIEW.MATERNAL_NAME')}
      </TableCell>

      {driverStatus === DriverStatus.REVIEW && (
        <>
          <TableCell variant="head" key="mobile" style={{ width: '10%' }}>
            {t('DRIVER_DETAIL_VIEW.CALIFICATION.CREDIT_RISK_SCORE')}
          </TableCell>
        </>
      )}
      {driverStatus === DriverStatus.REVIEW && (
        <>
          <TableCell variant="head" key="mobile" style={{ width: '10%' }}>
            {t('DRIVER_DETAIL_VIEW.CALIFICATION.FRAUD_SCORE')}
          </TableCell>
        </>
      )}
      {driverStatus === DriverStatus.REVIEW && (
        <>
          <TableCell variant="head" key="mobile" style={{ width: '10%' }}>
            {t('DRIVER_DETAIL_VIEW.CALIFICATION.PHONE_SCORE')}
          </TableCell>
        </>
      )}
      {driverStatus === DriverStatus.REVIEW && (
        <>
          <TableCell variant="head" key="mobile" style={{ width: '10%' }}>
            {t('DRIVER_DETAIL_VIEW.CALIFICATION.CONTACTABILITY_SCORE')}
          </TableCell>
        </>
      )}

      {driverStatus === DriverStatus.ACTIVE && (
        <>
          <TableCell variant="head" key="mobile" style={{ width: '10%' }}>
            {t('DRIVERS_VIEW.CELLPHONE')}
          </TableCell>
        </>
      )}

      {driverStatus !== DriverStatus.REVIEW && (
        <TableCell variant="head" key="region" style={{ width: '10%' }}>
          {t('DRIVERS_VIEW.REGION')}
        </TableCell>
      )}

      {(driverStatus === DriverStatus.ACTIVE ||
        driverStatus === DriverStatus.PIPELINE) && (
        <>
          <TableCell variant="head" key="audit" style={{ width: '10%' }}>
            {t('DRIVERS_VIEW.PENDING')}
          </TableCell>
        </>
      )}
      {driverStatus === DriverStatus.REVIEW && (
        <TableCell style={{ width: '10%', textAlign: 'right' }}>
          {t('COMMON.ACTIONS')}
        </TableCell>
      )}
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => (
      <Tbody
        drivers={drivers}
        handleOpenConfirmation={handleOpenConfirmation}
      />
    ),
    [drivers],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, drivers],
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

DriversTable.propTypes = {
  className: PropTypes.string,
  drivers: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  driverStatus: PropTypes.string,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
  handleOpenConfirmation: PropTypes.func,
};

DriversTable.defaultProps = {
  count: 0,
};

export default DriversTable;
