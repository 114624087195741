import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_CATALOG,
  CLEAR_CATALOG,
  loadCarCategories,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import PropTypes from 'prop-types';
import CarCategoryTable from './CarCategoryTable';

const CarCategoryList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadCarCategories());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency

  return (
    <CarCategoryTable
      categories={categories}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
    />
  );
};

CarCategoryList.propTypes = {
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default CarCategoryList;
