import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import {
  CLEAR_DRIVERS,
  loadDrivers,
  createDriver,
  downloadExcelDrivers,
} from 'src/store/actions/driverActions';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectDriversQueryOptions } from 'src/store/selectors/driverSelectors';
import { Search } from 'react-feather';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { DriverStatus } from 'src/utils/constants';
import ConfirmationMessage from 'src/components/ConfirmationMessage';
import DriverCreationForm from './DriverCreationForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '85vh',
    border: '1px solid #EEEEEE',
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 6,
      marginLeft: 6,
    },
  },
  icon: {
    fill: theme.palette.primary.main,
  },
}));

const filters = [
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'DRIVERS_VIEW.ASSIGNED',
        value: 'assigned',
      },
      {
        label: 'DRIVERS_VIEW.NOT_ASSIGNED',
        value: 'notAssigned',
      },
    ],
  },
];

function getFilterOptions(options) {
  const queryOptions = {};

  queryOptions.isAssigned = null;
  queryOptions.carMode = [];

  if (options.assigned || options.notAssigned) {
    if (options.assigned) {
      queryOptions.isAssigned = true;
    }

    if (options.notAssigned) {
      queryOptions.isAssigned = false;
    }
  }

  return queryOptions;
}

const Toolbar = ({
  className,
  driverStatus,
  handleCloseConfirmation,
  handleSubmitAction,
  openConfirmation,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectDriversQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria, setSearchCriteria] = useState('id');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    assigned: false,
    notAssigned: false,
  });

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const debounceSearch = useDebounce(
    (criteria, value) =>
      dispatch(
        loadDrivers({
          [criteria]: value,
        }),
      ),
    400,
  );

  useEffect(() => {
    dispatch(
      loadDrivers({
        status: driverStatus,
        ...queryOptions,
      }),
    );
    return () => dispatch({ type: CLEAR_DRIVERS });
  }, []);

  useEffect(() => {
    setFilterValues({
      assigned: false,
      notAssigned: false,
    });
  }, [driverStatus]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(searchCriteria, value);
    },
    [setSearchValue, dispatch, debounceSearch, queryOptions, driverStatus],
  );

  const handleSearchCriteria = useCallback(
    ev => {
      dispatch(
        loadDrivers({
          [searchCriteria]: '',
        }),
      );
      setSearchCriteria(ev.target.value);
      setSearchValue('');
    },
    [setSearchCriteria, dispatch, debounceSearch, queryOptions, driverStatus],
  );

  const handleSubmitFilter = options => {
    dispatch(loadDrivers(options));
  };

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      handleSubmitFilter({
        ...queryOptions,
        ...getFilterOptions(event),
        page: 0,
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions,
    ],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreateDriver = useCallback(
    values => {
      dispatch(createDriver(values));
      handleClose();
    },
    [dispatch, handleClose],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelDrivers());
  }, [dispatch]);

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.DRIVERS']}
          current={t(`DRIVERS_VIEW.${driverStatus.toUpperCase()}`)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400', textTransform: 'capitalize' }}
            color="primary"
            variant="h1"
          >
            {t(`DRIVERS_VIEW.${driverStatus.toUpperCase()}`)}
          </Typography>
          <div className={classes.actionsContainer}>
            <Paper
              elevation={0}
              className={classes.search}
              style={{ marginLeft: 0 }}
            >
              <>
                <Input
                  classes={{ input: classes.input }}
                  startAdornment={Addornment}
                  placeholder={
                    searchCriteria === 'id'
                      ? t('DRIVERS_VIEW.SEARCH_BY_ID')
                      : t('DRIVERS_VIEW.SEARCH_BY_NAME')
                  }
                  className="flex mx-8"
                  disableUnderline
                  value={searchValue}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  type={searchCriteria === 'id' ? 'number' : 'string'}
                  onChange={handleSearch}
                />
                <Select
                  id="criteria-selection"
                  style={{ width: '20%' }}
                  defaultValue="id"
                  onChange={handleSearchCriteria}
                >
                  <MenuItem value="id">
                    {t('DRIVERS_VIEW.SEARCH_BY_ID')}
                  </MenuItem>
                  <MenuItem value="name">
                    {t('DRIVERS_VIEW.SEARCH_BY_NAME')}
                  </MenuItem>
                </Select>
              </>
            </Paper>

            {driverStatus === DriverStatus.ACTIVE && (
              <>
                <Filter
                  anchorEl={anchorEl}
                  filterValues={filterValues}
                  handleClose={handleCloseFilter}
                  handleChange={handleFilterChange}
                  filters={filters}
                />
                <Button
                  className={clsx(
                    classes.buttons,
                    classes.outlined,
                    classes.horizontalPadding,
                  )}
                  color="primary"
                  onClick={handleClickFilter}
                >
                  <FilterIcon
                    className={classes.icon}
                    style={{ marginRight: 8 }}
                  />
                  {t('COMMON.FILTER')}
                </Button>
              </>
            )}

            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>
            <Button
              className={clsx(classes.buttons, classes.horizontalPadding)}
              onClick={handleClickOpen}
              color="primary"
              variant="contained"
            >
              <PlusIcon style={{ marginRight: 8 }} fill="white" />
              {t('DRIVERS_VIEW.ADD_DRIVER')}
            </Button>
          </div>
        </div>
      </div>

      <ConfirmationMessage
        open={Boolean(openConfirmation)}
        handleClose={handleCloseConfirmation}
        handleAccept={handleSubmitAction}
        text={t(`DRIVERS_VIEW.CONFIRMATION_MESSAGE`).replace(
          '{param}',
          t(`COMMON.${openConfirmation}`).toUpperCase(),
        )}
      />

      <DriverCreationForm
        open={open}
        handleCreateDriver={handleCreateDriver}
        handleClose={handleClose}
      />
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  driverStatus: PropTypes.string,
  openConfirmation: PropTypes.bool,
  handleCloseConfirmation: PropTypes.func,
  handleSubmitAction: PropTypes.func,
};

export default Toolbar;
