import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, TableRow, Tooltip } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import {
  DriverStatus,
  DriverStatusAction,
  IconStatusAndColor,
} from 'src/utils/constants';
import StatusIcon from 'src/components/StatusIcon';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const getStatusIcon = isAssigned => {
  return isAssigned ? (
    <StatusIcon
      description="FLEET_VIEW.ASSIGNED"
      type={IconStatusAndColor.OK.status}
    />
  ) : (
    <StatusIcon
      description="FLEET_VIEW.AVAILABLE"
      type={IconStatusAndColor.WARNING.status}
    />
  );
};

const DriverRow = ({
  id,
  audit,
  isAssigned,
  category,
  name,
  paternalName,
  maternalName,
  mobile,
  region,
  status,
  handleOpenConfirmation,
  creditRiskScore,
  fraudScore,
  phoneScore,
  contactabilityScore,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const statusIcon = useMemo(() => getStatusIcon(isAssigned), [isAssigned]);

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/drivers/detail/${id}`}>{id}</Link>
      </TableCell>
      {status === DriverStatus.ACTIVE && (
        <>
          <TableCell>{statusIcon}</TableCell>
          <TableCell>{category ? t(`FLEET_VIEW.${category}`) : '-'}</TableCell>
        </>
      )}
      <TableCell>{name}</TableCell>
      <TableCell>{paternalName}</TableCell>
      <TableCell>{maternalName}</TableCell>

      {status === DriverStatus.REVIEW && (
        <TableCell>{creditRiskScore || t('COMMON.NO_DATA')}</TableCell>
      )}
      {status === DriverStatus.REVIEW && (
        <TableCell>{fraudScore || t('COMMON.NO_DATA')}</TableCell>
      )}
      {status === DriverStatus.REVIEW && (
        <TableCell>{phoneScore || t('COMMON.NO_DATA')}</TableCell>
      )}
      {status === DriverStatus.REVIEW && (
        <TableCell>{contactabilityScore || t('COMMON.NO_DATA')}</TableCell>
      )}
      {status === DriverStatus.ACTIVE && (
        <>
          <TableCell>{mobile}</TableCell>
        </>
      )}
      {status !== DriverStatus.REVIEW && <TableCell>{region}</TableCell>}
      {(status === DriverStatus.ACTIVE || status === DriverStatus.PIPELINE) && (
        <>
          <TableCell>{audit === 0 || !audit ? '--' : audit}</TableCell>
        </>
      )}
      {status === DriverStatus.REVIEW && (
        <TableCell style={{ textAlign: 'right' }}>
          <Tooltip title={t('DRIVERS_VIEW.ACTIVATE_DRIVER')}>
            <IconButton
              style={{ padding: '0 12px' }}
              onClick={() =>
                handleOpenConfirmation(DriverStatusAction.ACTIVATE_DRIVER, id)
              }
              key="edit"
              aria-label="Edit"
              color="inherit"
            >
              <CheckIcon className={classes.checkIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('DRIVERS_VIEW.REJECT_DRIVER')}>
            <IconButton
              style={{ padding: '0 12px' }}
              onClick={() =>
                handleOpenConfirmation(DriverStatusAction.REJECT_DRIVER, id)
              }
              key="delete"
              aria-label="Delete"
              color="inherit"
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

DriverRow.propTypes = {
  id: PropTypes.string,
  audit: PropTypes.number,
  isAssigned: PropTypes.bool,
  category: PropTypes.string,
  name: PropTypes.string,
  paternalName: PropTypes.string,
  maternalName: PropTypes.string,
  mobile: PropTypes.string,
  region: PropTypes.string,
  status: PropTypes.string,
  creditRiskScore: PropTypes.number,
  fraudScore: PropTypes.number,
  phoneScore: PropTypes.number,
  contactabilityScore: PropTypes.number,
  handleOpenConfirmation: PropTypes.func,
};

export default DriverRow;
