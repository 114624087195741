import * as Yup from 'yup';

export default Yup.object().shape({
  contactName: Yup.string().required('SCHEMA_ERRORS.ENTER_CONTACT_NAME'),
  contactType: Yup.string()
    .oneOf(
      ['COMMERCIAL', 'COLLECTION', 'LEGAL'],
      'SCHEMA_ERRORS.SELECT_VALUE_LIST',
    )
    .required('SCHEMA_ERRORS.ENTER_CONTACT_TYPE'),
  contactPhoneOffice: Yup.string().nullable(),
  contactPhonePersonal: Yup.string()
    .max(15, 'SCHEMA_ERRORS.ENTER_FIFTEEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PHONE_NUMBER'),
  contactMailAddress: Yup.string()
    .required()
    .email('SCHEMA_ERRORS.ENTER_EMAIL_VALID'),
});
