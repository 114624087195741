import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCarList,
  selectCarListCount,
  selectCarQueryOptions,
} from 'src/store/selectors/carSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { Card } from '@material-ui/core';
import { loadSoldCars, LOAD_SOLD_CARS } from 'src/store/actions/carActions';
import SoldCarsTable from './SoldCarsTable';

const SoldCarsList = () => {
  const dispatch = useDispatch();
  const cars = useSelector(selectCarList);
  const count = useSelector(selectCarListCount);
  const { page } = useSelector(selectCarQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_SOLD_CARS.action]));
  const success = useSelector(successSelector([LOAD_SOLD_CARS.action]));

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadSoldCars({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadSoldCars({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado coches"
          description="Ningún coche coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <SoldCarsTable
      cars={cars}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

export default SoldCarsList;
