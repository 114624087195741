import {
  LOAD_CARS,
  SAVE_OPTIONS,
  CLEAR_OPTIONS,
  CLEAR_CARS,
  LOAD_DETAIL_CAR,
  LOAD_AVAILABLE_CARS,
  CLEAR_DETAIL_CAR,
  UPDATE_CAR,
  UPLOAD_CAR_XML,
  CLEAR_UPLOAD_CAR_XML,
  LOAD_SOLD_CARS,
  LOAD_CAR_AUDIT_LIST,
  SAVE_CAR_AUDIT_OPTIONS,
  LOAD_CAR_LOCATION_LIST,
  SAVE_CAR_LOCATION_OPTIONS,
} from '../actions/carActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    sort: { property: 'niv', order: 'asc' },
  },
  items: [],
  availableCars: [],
  count: 0,
  detail: null,
  form: {
    alreadyExists: false,
    niv: '',
    serie: '',
    purchaseDate: '',
    rfc: '',
    vat: 0,
    total: 0,
    catalog: [],
  },
  carAudit: {
    options: {
      limit: +process.env.REACT_APP_TABLE_SIZE,
      page: 0,
    },
    items: [],
    count: 0,
  },
  carLocation: {
    options: {
      limit: +process.env.REACT_APP_TABLE_SIZE,
      page: 0,
    },
    items: [],
    count: 0,
  },
};

const carReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CARS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_OPTIONS: {
      return {
        ...state,
        options: {
          ...initialState.options,
          mode: state.options.mode,
          status: state.options.status,
        },
      };
    }
    case LOAD_DETAIL_CAR.success: {
      return {
        ...state,
        detail: action.data,
      };
    }
    case LOAD_AVAILABLE_CARS.success: {
      return {
        ...state,
        availableCars: action.data.items,
      };
    }
    case LOAD_SOLD_CARS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case UPDATE_CAR.success: {
      return {
        ...state,
        detail: { ...state.detail, ...action.data },
      };
    }
    case UPLOAD_CAR_XML.success: {
      return {
        ...state,
        form: {
          niv: action.niv,
          alreadyExists: action.alreadyExists,
          serie: action.serie,
          purchaseDate: action.purchaseDate,
          rfc: action.rfc,
          vat: action.vat,
          total: action.total,
          catalog: action.catalog,
        },
      };
    }
    case CLEAR_DETAIL_CAR: {
      return {
        ...state,
        detail: null,
      };
    }
    case CLEAR_UPLOAD_CAR_XML: {
      return {
        ...state,
        form: initialState.form,
      };
    }
    case CLEAR_CARS: {
      return initialState;
    }
    case LOAD_CAR_AUDIT_LIST.success: {
      return {
        ...state,
        carAudit: {
          ...state.carAudit,
          items: action.items,
          count: action.count,
        },
      };
    }
    case SAVE_CAR_AUDIT_OPTIONS: {
      return {
        ...state,
        carAudit: {
          ...state.carAudit,
          options: {
            ...state.carAudit.options,
            ...action.options,
          },
        },
      };
    }
    case LOAD_CAR_LOCATION_LIST.success: {
      return {
        ...state,
        carLocation: {
          ...state.carLocation,
          items: action.items,
          count: action.count,
        },
      };
    }
    case SAVE_CAR_LOCATION_OPTIONS: {
      return {
        ...state,
        carLocation: {
          ...state.carLocation,
          options: {
            ...state.carLocation.options,
            ...action.options,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default carReducer;
