import { ReactComponent as FleetIcon } from 'src/assets/icons/cars.svg';
import { ReactComponent as FleetListIcon } from 'src/assets/icons/car.svg';
import { ReactComponent as InactiveIcon } from 'src/assets/icons/car_disable.svg';
import { ReactComponent as InsuranceIcon } from 'src/assets/icons/security.svg';
import { ReactComponent as DriversIcon } from 'src/assets/icons/drivers.svg';
import { ReactComponent as RentIcon } from 'src/assets/icons/income.svg';
import { ReactComponent as CatalogIcon } from 'src/assets/icons/list.svg';
import { ReactComponent as ParametersIcon } from 'src/assets/icons/settings.svg';
/* import { ReactComponent as CandidateDriversIcon } from 'src/assets/icons/person_add.svg'; */
import { ReactComponent as LeftDriversIcon } from 'src/assets/icons/pending_actions.svg';
import { ReactComponent as RentResumeIcon } from 'src/assets/icons/request_quote.svg';
import { ReactComponent as Gps } from 'src/assets/icons/Gps.svg';
import { ReactComponent as notes } from 'src/assets/icons/notes.svg';
import TodayIcon from '@material-ui/icons/Today';
import PolicyIcon from '@material-ui/icons/Policy';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import SpeedIcon from '@material-ui/icons/Speed';
import PodcastsIcon from '@material-ui/icons/SignalWifi3Bar';
import BlockIcon from '@material-ui/icons/Block';
import EngineeringIcon from '@material-ui/icons/Settings';
import ListAltIcon from '@material-ui/icons/ListAlt';

export default [
  {
    title: 'NAVIGATION.OPERATION',
    children: [
      {
        href: '/fleet',
        icon: FleetIcon,
        title: 'NAVIGATION.FLEET',
        children: [
          {
            href: '/fleet/cars',
            icon: FleetListIcon,
            title: 'NAVIGATION.CARS',
          },
          {
            href: '/fleet/inactive-cars',
            icon: InactiveIcon,
            title: 'NAVIGATION.INACTIVE_CARS',
          },
          {
            href: '/fleet/sold',
            icon: MonetizationOnIcon,
            title: 'NAVIGATION.SOLD_CARS',
          },
          {
            href: '/fleet/insurance',
            icon: InsuranceIcon,
            title: 'NAVIGATION.INSURANCE',
          },
          {
            href: '/fleet/licences',
            icon: notes,
            title: 'NAVIGATION.LICENCE',
          },
        ],
      },
      {
        href: '/drivers/list',
        icon: DriversIcon,
        title: 'NAVIGATION.DRIVERS',

        /*
       TODO: the code is commented to be implemented again in the future
        children: [
          {
            href: '/drivers/list',
            icon: CandidateDriversIcon,
            title: 'NAVIGATION.PEOPLE',
          },
          {
            href: '/drivers/businesses',
            icon: CandidateDriversIcon,
            title: 'NAVIGATION.BUSINESS',
          },
        ], */
      },
      {
        icon: RentIcon,
        title: 'NAVIGATION.INCOME',
        children: [
          {
            href: '/income/rent',
            icon: RentResumeIcon,
            title: 'NAVIGATION.RENT',
          },
          {
            href: '/income/resumen',
            icon: RentResumeIcon,
            title: 'NAVIGATION.RENT_RESUME',
          },
          {
            href: '/income/rentoverdue',
            icon: RentResumeIcon,
            title: 'NAVIGATION.RENT_OVERDUE',
          },
          {
            href: '/income/indicators',
            icon: RentResumeIcon,
            title: 'NAVIGATION.RENT_FACTOR',
          },

          {
            href: '/income/excedent-mileage',
            icon: SpeedIcon,
            title: 'NAVIGATION.EXCEDENT_MILEAGES',
          },
        ],
      },
      {
        icon: LeftDriversIcon,
        title: 'NAVIGATION.FINES',
        children: [
          {
            href: '/fines/list',
            icon: ListAltIcon,
            title: 'NAVIGATION.REGISTERED_FINES',
          },
          {
            href: '/fines/reports',
            icon: AssessmentIcon,
            title: 'NAVIGATION.SCRAPER_REPORTS',
          },
        ],
      },

      {
        href: '/expenses',
        icon: RentResumeIcon,
        title: 'NAVIGATION.EXPENSE',
      },
    ],
  },
  {
    title: 'GPS',
    children: [
      {
        href: '/gps',
        icon: Gps,
        title: 'GPS',
        children: [
          {
            href: '/gps/list',
            icon: Gps,
            title: 'NAVIGATION.GPS_DEVICES',
          },
          {
            href: '/gps/transmission',
            icon: PodcastsIcon,
            title: 'NAVIGATION.TRANSMISSION',
          },
          {
            href: '/gps/rent-lockout',
            icon: BlockIcon,
            title: 'NAVIGATION.RENT_LOCKOUT',
            disabled: true,
          },
          {
            href: '/gps/mmto-lockout',
            icon: EngineeringIcon,
            title: 'NAVIGATION.MMTO_LOCKOUT',
            disabled: true,
          },
          {
            href: '/gps/geofencing',
            icon: TripOriginIcon,
            title: 'NAVIGATION.GEOFENCING',
          },
        ],
      },
    ],
  },
  {
    title: 'NAVIGATION.ADMINISTRATION',
    children: [
      {
        href: '/campaigns',
        icon: TodayIcon,
        title: 'NAVIGATION.CAMPAIGNS',
      },
      {
        icon: CatalogIcon,
        title: 'NAVIGATION.CATALOGS',
        href: '/catalogs',
      },
      {
        href: '/parameters',
        icon: ParametersIcon,
        title: 'NAVIGATION.PARAMETERS',
      },
    ],
  },
  {
    title: 'NAVIGATION.ACCOUNTING',
    children: [
      {
        icon: FleetIcon,
        title: 'NAVIGATION.FLEET_PROFILE',
        children: [
          {
            href: '/accounting/depreciation/balance',
            icon: AccountBalanceIcon,
            title: 'NAVIGATION.BALANCE',
          },
          {
            href: '/accounting/depreciation/resume',
            icon: AssessmentIcon,
            title: 'NAVIGATION.RESUME',
          },
          {
            href: '/accounting/fleet-movements',
            icon: DirectionsCarIcon,
            title: 'NAVIGATION.FLEET_MOVEMENTS',
          },
        ],
      },
      {
        icon: PolicyIcon,
        title: 'NAVIGATION.POLICY_PROFILE',
        children: [
          {
            href: '/accounting/amortization/balance',
            icon: AccountBalanceIcon,
            title: 'NAVIGATION.BALANCE',
          },
          {
            href: '/accounting/amortization/resume',
            icon: AssessmentIcon,
            title: 'NAVIGATION.RESUME',
          },
        ],
      },
      {
        href: '/billing',
        icon: RentResumeIcon,
        title: 'NAVIGATION.BILLING',
      },
      {
        href: '/transactions',
        icon: RentResumeIcon,
        title: 'NAVIGATION.TRANSACTION',
      },
    ],
  },
];

export const CATALOG_ROUTES = [
  {
    href: '/catalogs/agents',
    title: 'NAVIGATION.CATALOG_LIST.AGENT',
  },
  {
    href: '/catalogs/insurer',
    title: 'NAVIGATION.CATALOG_LIST.INSURER',
  },
  {
    href: '/catalogs/companies',
    title: 'NAVIGATION.CATALOG_LIST.COMPANIES',
  },
  {
    href: '/catalogs/brands',
    title: 'NAVIGATION.CATALOG_LIST.BRANDS',
  },
  {
    href: '/catalogs/services',
    title: 'NAVIGATION.CATALOG_LIST.SERVICES',
  },
  {
    href: '/catalogs/incidents',
    title: 'NAVIGATION.CATALOG_LIST.INCIDENTS',
  },
  {
    href: '/catalogs/location',
    title: 'NAVIGATION.CATALOG_LIST.LOCATION',
  },
  {
    href: '/catalogs/expenses',
    title: 'NAVIGATION.EXPENSE',
  },
  {
    href: '/catalogs/providers',
    title: 'NAVIGATION.CATALOG_LIST.PROVIDERS',
  },
  {
    href: '/catalogs/devices',
    title: 'NAVIGATION.CATALOG_LIST.DEVICES',
  },
  {
    href: '/catalogs/car-categories',
    title: 'NAVIGATION.CAR_CATEGORIES',
  },
  {
    href: '/catalogs/operational-emails',
    title: 'NAVIGATION.OPERATIONAL_EMAILS',
  },
  {
    href: '/catalogs/geofencing-category',
    title: 'NAVIGATION.CATALOG_LIST.GEOFENCING_CATEGORY',
  },
  {
    href: '/catalogs/geofencing-zones',
    title: 'NAVIGATION.CATALOG_LIST.GEOFENCING_ZONES',
  },
  {
    href: '/catalogs/unassign-reasons',
    title: 'NAVIGATION.CATALOG_LIST.UNASSIGN_REASONS',
  },
];
