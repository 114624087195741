import React from 'react';
import { TableRow, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TableCell from 'src/components/Table/TableCell';
import { ExternalLink } from 'react-feather';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';

const DocumentLink = ({ link }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return link ? (
    <>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={classes.documentLink}
      >
        <Typography
          variant="body2"
          component="div"
          className={classes.documentText}
        >
          {t('COMMON.DOCUMENT_VIEW')}
          <ExternalLink style={{ marginLeft: '8px' }} size={20} />
        </Typography>
      </a>
    </>
  ) : (
    <>{t('COMMON.NOT_AVAILABLE')}</>
  );
};
DocumentLink.propTypes = {
  link: PropTypes.string,
};

const AuditRow = ({
  driverId,
  frontSide,
  backSide,
  leftSide,
  rightSide,
  createdAt,
  driver,
}) => {
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/conductores/detalle/${driverId}`}>
          {`${driver.name} ${driver.paternalName} ${driver.maternalName}`}
        </Link>
      </TableCell>
      <TableCell>
        <DocumentLink link={frontSide} />
      </TableCell>
      <TableCell>
        <DocumentLink link={backSide} />
      </TableCell>
      <TableCell>
        <DocumentLink link={leftSide} />
      </TableCell>
      <TableCell>
        <DocumentLink link={rightSide} />
      </TableCell>
      <TableCell>
        <Typography>{moment(createdAt).format('DD-MMM-YY')}</Typography>
      </TableCell>
    </TableRow>
  );
};

AuditRow.propTypes = {
  driverId: PropTypes.string,
  frontSide: PropTypes.string,
  backSide: PropTypes.string,
  leftSide: PropTypes.string,
  rightSide: PropTypes.string,
  createdAt: PropTypes.string,
  driver: PropTypes.object,
};

export default AuditRow;
