import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const InsuranceBalanceRow = ({
  niv,
  model,
  mode,
  price,
  coverageStart,
  monthsOfLife,
  initialBalance,
  amortization,
  unsubscription,
  finalBalance,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Link to={`/fleet/car/${niv}`}>{niv}</Link>
        </TableCell>
        <TableCell>{model}</TableCell>
        <TableCell>{t(`FLEET_VIEW.${mode}`)}</TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(price)}
        </TableCell>
        <TableCell className={classes.center}>
          {moment(coverageStart).format('DD-MMM-YYYY')}
        </TableCell>
        <TableCell className={classes.center}>{monthsOfLife}</TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(initialBalance)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(amortization)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(unsubscription)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(finalBalance)}
        </TableCell>
      </TableRow>
    </>
  );
};

InsuranceBalanceRow.propTypes = {
  niv: PropTypes.string,
  model: PropTypes.string,
  mode: PropTypes.string,
  price: PropTypes.number,
  coverageStart: PropTypes.string,
  monthsOfLife: PropTypes.number,
  initialBalance: PropTypes.number,
  amortization: PropTypes.number,
  unsubscription: PropTypes.number,
  finalBalance: PropTypes.number,
};

export default InsuranceBalanceRow;
