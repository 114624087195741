import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import CloseIcon from '@material-ui/icons/Close';
import Loading from 'src/components/Loading';
import {
  LOAD_RESIDUAL_TRANSACTIONS,
  loadResidualTransactions,
} from 'src/store/actions/transactionsActions';
import selectFormatDate from 'src/utils/selectDateFormat';
import { DateTypeFormat } from 'src/utils/constants';
import { selectDriverDetail } from 'src/store/selectors/driverSelectors';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DriverResidualListDialog = ({ openDialog, onCloseModal }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const { actualDriverAssignationNiv: carniv, id: driverId } = useSelector(
    selectDriverDetail,
  );

  const [residualList, setResidualList] = useState([]);
  const loading = useSelector(
    isLoadingSelector([LOAD_RESIDUAL_TRANSACTIONS.action]),
  );
  const success = useSelector(
    successSelector([LOAD_RESIDUAL_TRANSACTIONS.action]),
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, openDialog]);

  useEffect(() => {
    if (openDialog) {
      dispatch(loadResidualTransactions({ carniv, driverId }, setResidualList));
    }
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      className={classes.dialogContainer}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseModal}
    >
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography variant="h4" className={classes.title}>
            {t('DRIVER_DETAIL_VIEW.RESIDUAL_PAYMENT')}
          </Typography>
          <IconButton
            aria-label="delete"
            size="large"
            className={classes.closeButtom}
            onClick={onCloseModal}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogBodyContainer}>
        {success && !loading && residualList.length > 0 ? (
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow component="tr">
                  <TableCell variant="head" align="left">
                    {t('COMMON.DATE')}
                  </TableCell>
                  <TableCell variant="head" align="center">
                    {t('COMMON.AMOUNT')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {residualList.map((residual, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={`driver-residual-list-item-${index}`}>
                    <TableCell align="left">
                      {selectFormatDate(residual.createdAt, DateTypeFormat.DAY)}
                    </TableCell>
                    <TableCell align="center">{residual.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box className={classes.loadingBox}>
            <Loading />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

DriverResidualListDialog.propTypes = {
  openDialog: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

export default DriverResidualListDialog;
