const DateRange = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY'
};

export default DateRange;
