import * as Yup from 'yup';
import { CAR_MODES } from '../utils';

export default Yup.object().shape({
  startDate: Yup.date().required('SCHEMA_ERRORS.ENTER_INITIAL_DATE'),
  driverId: Yup.string().required('SCHEMA_ERRORS.ENTER_DRIVER'),
  contractEndDate: Yup.date().required('SCHEMA_ERRORS.ENTER_FINAL_DATE'),

  hitch: Yup.number().when('carMode', {
    is: CAR_MODES.LEASE,
    then: Yup.number()
      .positive()
      .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
      .required('SCHEMA_ERRORS.ENTER_HITCH'),
  }),
  rentCost: Yup.number().when('carMode', {
    is: CAR_MODES.LEASE,
    then: Yup.number()
      .positive()
      .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
      .required('SCHEMA_ERRORS.ENTER_RENT_COST'),
  }),
  insuranceCost: Yup.number().when('carMode', {
    is: CAR_MODES.LEASE,
    then: Yup.number()
      .positive()
      .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
      .required('SCHEMA_ERRORS.ENTER_INSURANCE_COST'),
  }),
  duration: Yup.number().when('carMode', {
    is: CAR_MODES.LEASE,
    then: Yup.number()
      .positive()
      .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
      .required('SCHEMA_ERRORS.DURATION_IN_WEEKS'),
  }),
  costPerWeek: Yup.number().when('carMode', {
    is: CAR_MODES.LEASE,
    then: Yup.number()
      .positive()
      .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
      .required('SCHEMA_ERRORS.ENTER_COST_PER_WEEK'),
  }),
});
