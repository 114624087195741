/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Grid, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'src/components/DropdownMenu';
import { CarSaleReason } from 'src/utils/constants';
import UploadFile from 'src/components/UploadFile';
import DatePicker from 'src/components/DatePicker';
import moment from 'moment';
import useStyles from './styles';
import validationSchema from './schema';

const makeInitialState = data => {
  return {
    niv: data ? data.niv : '',
    salePrice: data ? data.salePrice : '',
    saleReason: '',
    saleInvoicePdfFile: '',
    saleDate: moment().toDate(),
  };
};

const SALE_REASON_LIST = [
  {
    name: 'FLEET_MOVEMENTS.SALE_REASON.TURN_OVER',
    key: CarSaleReason.TURN_OVER,
  },
  { name: 'FLEET_MOVEMENTS.SALE_REASON.LEASE', key: CarSaleReason.LEASE },
  {
    name: 'FLEET_MOVEMENTS.SALE_REASON.ACCIDENT_INSURANCE_CARRIER',
    key: CarSaleReason.ACCIDENT_INSURANCE_CARRIER,
  },
  {
    name: 'FLEET_MOVEMENTS.SALE_REASON.ACCIDENT_DIRECT',
    key: CarSaleReason.ACCIDENT_DIRECT,
  },
];

const RemoveCarForm = ({ resetRef, handleClose, data, handleSubmitForm }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleUploadChange = useCallback(setFieldValue => e => {
    if (e.target.files[0]) {
      setFieldValue(e.target.name, e.target.files[0]);
    }
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        resetForm,
        setFieldValue,
      }) => {
        resetRef.current = () => resetForm(initialState);
        return (
          <form onSubmit={handleSubmit}>
            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.salePrice && errors.salePrice)}
            >
              <UploadFile
                values={values}
                errors={errors}
                touched={touched}
                handleUploadChange={handleUploadChange}
                setFieldValue={setFieldValue}
                accept="pdf/*"
                valueLabel="saleInvoicePdfFile"
                textLabel={t('FLEET_VIEW.SALE_INVOICE')}
              />

              <DatePicker
                field={{ name: 'saleDate', value: values.saleDate }}
                form={{
                  errors,
                  setFieldValue,
                  touched,
                }}
                label={t('FLEET_MOVEMENTS.SALE_DATE')}
                disableFuture
                disablePast={false}
              />

              <TextField
                className={classes.field}
                error={Boolean(touched.niv && errors.niv)}
                fullWidth
                helperText={touched.niv && t(errors.niv)}
                label="NIV"
                margin="none"
                name="niv"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.niv}
                variant="outlined"
              />

              <TextField
                className={classes.field}
                error={Boolean(touched.salePrice && errors.salePrice)}
                fullWidth
                helperText={touched.salePrice && t(errors.salePrice)}
                label={t('FLEET_MOVEMENTS.SALE_PRICE')}
                margin="none"
                name="salePrice"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.salePrice}
                variant="outlined"
              />

              <DropdownMenu
                list={SALE_REASON_LIST}
                value={values.saleReason}
                setValue={value => setFieldValue('saleReason', value)}
                label={t('FLEET_MOVEMENTS.SALE_REASON_TITLE')}
                error={Boolean(touched.saleReason && errors.saleReason)}
                errorText={touched.saleReason && t(errors.saleReason)}
              />
            </FormControl>

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Grid container direction="column" alignItems="stretch">
                <Grid item>
                  <Button
                    className={clsx(classes.label, classes.buttons)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.UNSUBSCRIBE')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={clsx(classes.outlined, classes.buttons)}
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.CANCEL')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default RemoveCarForm;
