import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GeofencingCreationStepper from './GeofencingCreationStepper';
import CurrentStep from './CurrentStep';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`,
    },
  },
  root: {
    minWidth: theme.spacing(80),
  },
  closeIcon: {
    float: 'right',
    marginTop: '-25px',
    width: '20px',
    height: '20px',
  },
}));

const makeInitialState = () => {
  return {
    name: '',
    categoryId: '',
    zoneId: '',
    alert: false,
    center: '',
    radius: '',
  };
};

const GeofencingForm = ({ open, handleClose, handleCreateGeofencing }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = makeInitialState();

  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, [open]); // DO NOT ADD DEPENDENCY - the intended behavior is achieved like this

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data],
  );

  const handleBackOrClose = useCallback(() => {
    if (step === 0) {
      handleClose();
    } else {
      setStep(step - 1);
    }
  }, [dispatch, step, setStep, handleClose]);

  const handleSubmit = useCallback(
    value => {
      handleCreateGeofencing({ ...data, ...value });
      setData(initialState);
    },
    [data],
  );

  return (
    <div>
      <Dialog
        aria-labelledby="form-new-gefencing-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-new-gefencing-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
              setData({});
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('GPS_VIEW.ADD_NEW_GEOFENCING')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <DialogContent>
          <GeofencingCreationStepper step={step} />
          <CurrentStep
            activeStep={step}
            data={data}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
GeofencingForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateGeofencing: PropTypes.func.isRequired,
};

export default GeofencingForm;
