/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import HistoryTable from './HistoryTable';
import CarDriver from './CarDriver';

const useStyles = makeStyles(theme => ({
  table: {
    width: '70%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
  },
}));

const DriversHistory = ({ car, t }) => {
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7} lg={3}>
          <CarDriver
            t={t}
            actualDriverName={car.actualDriverName}
            actualDriverId={car.actualDriverId}
            actualDriverAssignation={car.actualDriverAssignation}
            actualDriverAssignationId={car.actualDriverAssignationId}
            niv={car.niv}
            carMode={car.mode}
            carLease={car.lease}
            car={car}
            costPerWeek={car.costPerWeek}
            rent={car.rent}
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <HistoryTable className={classes.table} history={car.history} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DriversHistory;
