import getAllCampaigns, {
  addNewCampaign,
  removeCampaign,
  updateCampaign,
} from 'src/services/campaignsService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectCampaignsQueryOptions } from '../selectors/campaignsSelector';
import { showMessage } from './snackBarActions';

export const SAVE_CAMPAIGNS_OPTIONS = 'SAVE_CAMPAIGNS_OPTIONS';
export const CLEAR_CAMPAIGNS_OPTIONS = 'CLEAR_CAMPAIGNS_OPTIONS';
export const LOAD_CAMPAIGNS = asyncActionCreator('LOAD_CAMPAIGNS');
export const UPDATE_CAMPAIGNS = asyncActionCreator('UPDATE_CAMPAIGNS');
export const CREATE_CAMPAIGNS = asyncActionCreator('CREATE_CAMPAIGNS');
export const DELETE_CAMPAIGNS = asyncActionCreator('DELETE_CAMPAIGNS');
export const CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectCampaignsQueryOptions(getState());
  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.type) {
    reqOptions.type = queryOpts.type;
  }
  if (queryOpts.recurrent && queryOpts.notRecurrent) {
    reqOptions.recurrent = '';
  } else {
    if (queryOpts.recurrent) {
      reqOptions.recurrent = 'true';
    }
    if (queryOpts.notRecurrent) {
      reqOptions.recurrent = 'false';
    }
  }

  if (queryOpts.active && queryOpts.inactive) {
    reqOptions.active = '';
  } else {
    if (queryOpts.active) {
      reqOptions.active = 'true';
    }
    if (queryOpts.inactive) {
      reqOptions.active = 'false';
    }
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_CAMPAIGNS_OPTIONS });
};

export const loadCampaignListAction = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CAMPAIGNS.start });

    dispatch({
      type: SAVE_CAMPAIGNS_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getAllCampaigns(reqOptions);

    return dispatch({
      type: LOAD_CAMPAIGNS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CAMPAIGNS.failure });
  }
};

export const updateCampaignAction = values => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CAMPAIGNS.start });
    await updateCampaign(values);

    dispatch(
      showMessage({
        message: 'UPDATE_CAMPAIGN',
        variant: 'success',
      }),
    );

    const reqOptions = getOptions(getState);
    dispatch(loadCampaignListAction(reqOptions));

    return dispatch({
      type: UPDATE_CAMPAIGNS.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_CAMPAIGNS.failure });
  }
};

export const createCampaign = (newCampaign, resetForm, handleClose) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: CREATE_CAMPAIGNS.start,
    });

    await addNewCampaign(newCampaign);

    resetForm();

    handleClose();

    dispatch(
      showMessage({
        message: 'CREATE_CAMPAIGN',
        variant: 'success',
      }),
    );

    const reqOptions = getOptions(getState);
    dispatch(loadCampaignListAction(reqOptions));

    return dispatch({
      type: CREATE_CAMPAIGNS.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_CAMPAIGNS.failure });
  }
};

export const deleteCampaign = campaignId => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_CAMPAIGNS.start });

    await removeCampaign(campaignId);

    dispatch(
      showMessage({
        message: 'UPDATE_CAMPAIGN',
        variant: 'success',
      }),
    );

    const reqOptions = getOptions(getState);
    dispatch(loadCampaignListAction(reqOptions));

    return dispatch({
      type: DELETE_CAMPAIGNS.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_CAMPAIGNS.failure });
  }
};
