import * as Yup from 'yup';
import { UNASSIGN_TYPE } from 'src/utils/constants';

export default Yup.object().shape({
  unassignType: Yup.string()
    .oneOf(Object.values(UNASSIGN_TYPE), 'SCHEMA_ERRORS.SELECT_VALUE_LIST')
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  contractEndDate: Yup.date().required('SCHEMA_ERRORS.ENTER_END_DATE'),
  comment: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  newNiv: Yup.string()
    .max(255)
    .when('unassignType', {
      is: UNASSIGN_TYPE.CAR_CHANGE,
      then: Yup.string()
        .max(255)
        .required('SCHEMA_ERRORS.ENTER_CAR'),
    }),
  newEndDate: Yup.date().when('unassignType', {
    is: UNASSIGN_TYPE.CAR_CHANGE,
    then: Yup.date().required('SCHEMA_ERRORS.ENTER_END_DATE'),
  }),
});
