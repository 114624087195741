import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from 'src/components/Table/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from '../styles';

const ServiceRow = ({
  name,
  price,
  priority,
  serviceId,
  openEdit,
  handleDelete,
}) => {
  const classes = useStyles();

  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{priority}</TableCell>
      <TableCell style={{ width: 130 }}>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={openEdit}
          key="edit"
          aria-label="Edit"
          color="inherit"
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={() => handleDelete(serviceId)}
          key="delete"
          aria-label="Delete"
          color="inherit"
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

ServiceRow.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  priority: PropTypes.number,
  serviceId: PropTypes.number,
  openEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ServiceRow;
