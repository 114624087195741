/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Slide,
  Select,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { loadExpenseTypesList } from 'src/store/actions/catalogActions';
import { useTranslation } from 'react-i18next';
import { selectExpenseTypesList } from 'src/store/selectors/catalogSelectors';
import useStyles from '../../styles';
import validationSchema from './schema';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  id: data ? data.id : '',
  name: data ? data.name : '',
  expenseType: data ? data.expenseType : '',
});

const ProviderForm = ({
  open,
  data,
  handleClose,
  handleCreate,
  handleEdit,
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const expenseTypes = useSelector(selectExpenseTypesList);

  const handleOuterSubmit = useMemo(() => {
    if (data) {
      return handleEdit;
    }
    return handleCreate;
  }, [data, handleEdit, handleCreate]);

  useEffect(() => {
    if (open) {
      dispatch(loadExpenseTypesList());
    }

    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-test-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data ? 'Actualizar registro' : 'Ingresar nuevo registro'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                  >
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && t(errors.name)}
                      label="Nombre"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.expenseType && errors.expenseType)}
                  >
                    <InputLabel id="expenseType-select-label">
                      Tipo de gasto
                    </InputLabel>
                    <Select
                      onBlur={handleBlur}
                      labelId="expenseType-select-label"
                      label="Tipo de gasto"
                      id="expenseType-select"
                      value={values.expenseType}
                      onChange={handleChange}
                      name="expenseType"
                    >
                      {expenseTypes &&
                        expenseTypes.map(expenseType => (
                          <MenuItem
                            value={expenseType.name}
                            key={expenseType.name}
                          >
                            {expenseType.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {Boolean(touched.expenseType && errors.expenseType) && (
                      <FormHelperText>{t(errors.expenseType)}</FormHelperText>
                    )}
                  </FormControl>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? 'Actualizar' : 'Añadir'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProviderForm;
