import React, { useCallback } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import SpeedIcon from '@material-ui/icons/Speed';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMetricsListCount,
  selectMetricsQueryOptions,
} from 'src/store/selectors/driverSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  loadMileagesTraveled,
  LOAD_METRICS,
} from 'src/store/actions/driverActions';
import DriverMetricsTable from './DriverMetricsTable';

const useStyles = makeStyles(theme => ({
  table: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  mileageContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  mileage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc((100% - 24px) / 2)',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(),
    padding: theme.spacing(4),
  },
  mileageIcon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    background: 'rgba(40,167,69,0.1)',
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DriverMetrics = ({ mileageList, id }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const count = useSelector(selectMetricsListCount);
  const { page } = useSelector(selectMetricsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_METRICS.action]));

  const handleMovementsChangePage = useCallback(
    (_, value) => {
      dispatch(loadMileagesTraveled(id, { page: value }));
    },
    [dispatch, id],
  );

  console.log(page, count);
  return (
    <Box mt={3}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7} lg={8}>
          <Grid container className={classes.mileageContainer}>
            <Grid item className={classes.mileage}>
              <Box className={classes.mileageIcon}>
                <SpeedIcon fill="#28A745" />
              </Box>
              <Grid item>
                <Typography
                  style={{ marginBottom: 8 }}
                  color="textPrimary"
                  component="p"
                  variant="body2"
                >
                  {t('DRIVER_DETAIL_VIEW.METRICS.MILEAGE_TRAVELED_THIS_WEEK')}
                </Typography>
                <Typography color="primary" component="h2" variant="h2">
                  {mileageList.mileageTraveled}
                </Typography>
              </Grid>
            </Grid>

            <Box />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={10}>
          <DriverMetricsTable
            className={classes.table}
            count={count}
            page={page}
            loading={loading}
            handleChangePage={handleMovementsChangePage}
            mileages={mileageList.items}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

DriverMetrics.propTypes = {
  mileageList: PropTypes.object,
  id: PropTypes.string,
};

export default DriverMetrics;
