import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import OperationalEmailsRow from './OperationalEmailsRow';

const OperationalEmailsTable = ({
  className,
  operationalEmails,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.EMAIL')}</TableCell>
      <TableCell>{t('COMMON.ACTIONS')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return operationalEmails?.map(email => (
        <OperationalEmailsRow
          {...email}
          key={email.id}
          handleDelete={handleDelete}
        />
      ));
    },
    [operationalEmails],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

OperationalEmailsTable.propTypes = {
  className: PropTypes.string,
  operationalEmails: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default OperationalEmailsTable;
