import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import NotFoundIcon from 'src/icons/NotFoundIcon';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    minHeight: '75vh',
  },
  image: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(40),
    height: theme.spacing(40),
  },
}));

function NotFoundRecords({ title, description }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NotFoundIcon className={classes.image} />

      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {description}
      </Typography>
    </div>
  );
}

NotFoundRecords.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default NotFoundRecords;
