import {
  CLEAR_BUSINESS,
  LOAD_BUSINESS,
  SAVE_BUSINESS_OPTIONS,
  CLEAR_BUSINESS_OPTIONS,
  LOAD_DETAIL_BUSINESS,
  CLEAR_DETAIL_BUSINESS,
  LOAD_TRANSACTIONS_BUSINESS,
  CLEAR_TRANSACTIONS_BUSINESS,
  SAVE_TRANSACTION_BUSINESS_OPTIONS,
  CLEAR_TRANSACTION_BUSINESS_OPTIONS,
} from '../actions/businessActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  count: 0,
  detail: null,
  transactionItems: [],
  balance: {
    debts: 0,
    asset: 0,
  },
  transactionsCount: 0,
  transactionOptions: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUSINESS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case CLEAR_BUSINESS: {
      return initialState;
    }
    case SAVE_BUSINESS_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_BUSINESS_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    case LOAD_DETAIL_BUSINESS.success: {
      return {
        ...state,
        detail: action.data,
      };
    }
    case CLEAR_DETAIL_BUSINESS: {
      return {
        ...state,
        detail: null,
      };
    }
    case LOAD_TRANSACTIONS_BUSINESS.success: {
      return {
        ...state,
        transactionItems: action.transactions,
        transactionsCount: action.count,
        balance: action.balance,
      };
    }
    case CLEAR_TRANSACTIONS_BUSINESS: {
      return {
        ...state,
        transactionItems: initialState.transactionItems,
        transactionsCount: initialState.count,
        balance: initialState.balance,
      };
    }
    case SAVE_TRANSACTION_BUSINESS_OPTIONS: {
      return {
        ...state,
        transactionOptions: {
          ...state.transactionOptions,
          ...(action.options || {}),
        },
      };
    }
    case CLEAR_TRANSACTION_BUSINESS_OPTIONS: {
      return {
        ...state,
        transactionOptions: initialState.transactionOptions,
      };
    }
    default: {
      return state;
    }
  }
};

export default businessReducer;
