/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation } from '@material-ui/icons';
import * as Actions from 'src/store/actions/carActions';
import { selectAvailableCarList } from 'src/store/selectors/carSelectors';
import { UNASSIGN_TYPE } from 'src/utils/constants';
import { loadUnassignReasonsList } from 'src/store/actions/catalogActions';
import useStyles from '../styles';

const DatePickerField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      disablePast
      className={classes.field}
      inputVariant="outlined"
      fullWidth
      name={field.name}
      value={field.value}
      format="DD/MM/YYYY"
      variant="inline"
      label={other.label}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={error => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={date => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...other}
    />
  );
};

const UnassignForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleClose,
  handleSubmit,
  setFieldValues,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const availableCars = useSelector(selectAvailableCarList);
  const [unassignComments, setUnassignComments] = React.useState([]);

  useEffect(() => {
    dispatch(loadUnassignReasonsList(setUnassignComments));
  }, []);

  useEffect(() => {
    return dispatch(Actions.loadAvailableCars());
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        className={classes.field}
        variant="outlined"
        fullWidth
        error={Boolean(touched.unassignType && errors.unassignType)}
      >
        <InputLabel id="unassign-type-label">
          {t('ASSIGN_DRIVER_DIALOG.UNASSIGN_OPERATION_LABEL')}
        </InputLabel>
        <Select
          onBlur={handleBlur}
          labelId="unassign-type-label"
          label={t('ASSIGN_DRIVER_DIALOG.UNASSIGN_OPERATION_LABEL')}
          id="unassign-type-select"
          value={values.unassignType}
          onChange={handleChange}
          name="unassignType"
        >
          {Object.keys(UNASSIGN_TYPE).map(type => (
            <MenuItem key={type} value={type}>
              {t(`ASSIGN_DRIVER_DIALOG.${type}`)}
            </MenuItem>
          ))}
        </Select>
        {Boolean(touched.unassignType && errors.unassignType) && (
          <FormHelperText>{t(errors.unassignType)}</FormHelperText>
        )}
      </FormControl>

      {values.unassignType &&
      values.unassignType === UNASSIGN_TYPE.CAR_CHANGE ? (
        <>
          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.newNiv && errors.newNiv)}
          >
            <Autocomplete
              value={availableCars.find(c => c.niv === values.newNiv)}
              options={availableCars}
              getOptionLabel={option =>
                `${option.niv} - ${option.type} ${option.year}`
              }
              clearOnBlur={false}
              onOpen={handleBlur}
              onChange={(_e, value) => {
                setFieldValues('newNiv', value?.niv || '');
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('ASSIGN_DRIVER_DIALOG.CAR_TO_ASSIGN')}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            {Boolean(touched.newNiv && errors.newNiv) && (
              <FormHelperText>{t(errors.newNiv)}</FormHelperText>
            )}
          </FormControl>
          <Field
            name="newEndDate"
            component={DatePickerField}
            label={t('ASSIGN_DRIVER_DIALOG.NEW_END_DATE')}
          />
        </>
      ) : null}

      {values.unassignType ? (
        <>
          <Field
            name="contractEndDate"
            label={t('ASSIGN_DRIVER_DIALOG.END_DATE')}
            component={DatePickerField}
            disabled
          />

          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.comment && errors.comment)}
          >
            <Autocomplete
              value={unassignComments.find(
                comment => comment.reason === values.reason,
              )}
              options={unassignComments}
              getOptionLabel={option => ` ${option.reason}`}
              clearOnBlur={false}
              onOpen={handleBlur}
              onChange={(_e, value) => {
                setFieldValues('comment', value?.reason || '');
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('ASSIGN_DRIVER_DIALOG.COMMENT')}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            {Boolean(touched.comment && errors.comment) && (
              <FormHelperText>{t(errors.comment)}</FormHelperText>
            )}
          </FormControl>
        </>
      ) : null}

      <Grid container direction="column" alignItems="stretch">
        <Grid item>
          <Button
            className={clsx(classes.label, classes.buttons)}
            type="submit"
            color="primary"
            variant="outlined"
          >
            {t('ASSIGN_DRIVER_DIALOG.UNASSIGN')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={clsx(classes.outlined, classes.buttons)}
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            {t('ASSIGN_DRIVER_DIALOG.CANCEL')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

UnassignForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldValues: PropTypes.func.isRequired,
};

export default UnassignForm;
