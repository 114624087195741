import React, { useCallback } from 'react';
import { TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import InactiveCarsRowMenu from './InactiveCarsRowMenu';
import { CAR_STATUS } from '../CarDetail/utils';

const InactiveCarRow = ({
  niv,
  plates,
  details,
  incident,
  location,
  daysAfterUnassign,
  departure,
  status,
  comment,
  openEdit,
  salePrice,
  handleOpenConfirmationModal,
  handleOpenSellModal,
  handleOpenEditModal,
}) => {
  const setColorForDepartureText = useCallback(() => {
    const today = moment();
    if (moment(departure).isBefore(today)) {
      return '#EE3552';
    }
    return '';
  }, [departure]);

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/fleet/car/${niv}`}>{niv}</Link>
      </TableCell>
      <TableCell>{plates || ''}</TableCell>
      <TableCell>{details}</TableCell>
      {status === CAR_STATUS.AVAILABLE && (
        <TableCell>{incident || ''}</TableCell>
      )}
      <TableCell>{location || ''}</TableCell>
      <TableCell>{daysAfterUnassign || ''}</TableCell>
      <TableCell style={{ color: setColorForDepartureText() }}>
        {departure ? moment(departure).format('DD-MMM-YY') : null}
      </TableCell>
      {status === CAR_STATUS.ON_SALE && (
        <TableCell>{formatPositiveAmount(salePrice) || ''}</TableCell>
      )}
      {status === CAR_STATUS.ON_SALE && (
        <TableCell /> // Utility: Empty for now
      )}
      <TableCell>{comment || ''}</TableCell>
      <TableCell>
        <InactiveCarsRowMenu
          niv={niv}
          openEdit={openEdit}
          status={status}
          salePrice={salePrice}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleOpenSellModal={handleOpenSellModal}
          handleOpenEditModal={handleOpenEditModal}
        />
      </TableCell>
    </TableRow>
  );
};

InactiveCarRow.propTypes = {
  niv: PropTypes.string,
  plates: PropTypes.string,
  details: PropTypes.string,
  incident: PropTypes.string,
  location: PropTypes.string,
  daysAfterUnassign: PropTypes.number,
  departure: PropTypes.instanceOf(Date),
  comment: PropTypes.string,
  openEdit: PropTypes.func,
  status: PropTypes.string,
  salePrice: PropTypes.number,
  handleOpenConfirmationModal: PropTypes.func,
  handleOpenSellModal: PropTypes.func,
  handleOpenEditModal: PropTypes.func,
};

export default InactiveCarRow;
