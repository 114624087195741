import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, TableRow } from '@material-ui/core';
import { LensRounded } from '@material-ui/icons';
import TableCell from 'src/components/Table/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import ConfirmationMessage from 'src/components/ConfirmationMessage';
import { useDispatch } from 'react-redux';
import { deleteCampaign } from 'src/store/actions/campaignsActions';
import { CAMPAIGN_TYPE } from 'src/utils/constants';
import moment from 'moment';
import CreateOrEditCampaignDialog from './CreateOrEditCampaignDialog';

const CampaignsRow = ({
  id,
  recurrent,
  discountDays,
  active,
  minDriverLevel,
  description,
  discountDate,
  type,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleClickOpenConfirmation = useCallback(() => {
    setOpenConfirmation(true);
  }, [setOpen]);

  const handleCloseConfirmation = useCallback(() => {
    setOpenConfirmation(false);
  }, [setOpen]);

  const handleDeleteCampaign = useCallback(() => {
    dispatch(deleteCampaign(id));
  }, [setOpen]);

  return (
    <>
      <TableRow hover>
        <TableCell>{description}</TableCell>
        <TableCell>
          {recurrent
            ? t('CAMPAIGNS_VIEW.RECURRENT')
            : t('CAMPAIGNS_VIEW.NOT_RECURRENT')}
        </TableCell>
        <TableCell align="center">
          <LensRounded style={{ color: minDriverLevel.toLowerCase() }} />
        </TableCell>
        <TableCell align="center">{discountDays}</TableCell>
        <TableCell align="center">
          {active ? t('COMMON.ACTIVE') : t('COMMON.INACTIVE')}
        </TableCell>
        {type === CAMPAIGN_TYPE.GENERAL && (
          <TableCell>{moment(discountDate).format('DD/MM') || '--'}</TableCell>
        )}
        <TableCell align="center">
          <IconButton onClick={handleClickOpen} key="edit" aria-label="Edit">
            <EditIcon color="primary" />
          </IconButton>
          <IconButton
            onClick={handleClickOpenConfirmation}
            key="delete"
            aria-label="Delete"
          >
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
      <CreateOrEditCampaignDialog
        open={open}
        campaign={{
          id,
          active,
          discountDays,
          discountDate,
          recurrent,
          minDriverLevel,
          description,
          type,
        }}
        handleClose={handleClose}
      />
      <ConfirmationMessage
        open={openConfirmation}
        handleClose={handleCloseConfirmation}
        text={t('CAMPAIGNS_VIEW.DELETE_CONFIRMATION')}
        handleAccept={handleDeleteCampaign}
      />
    </>
  );
};

CampaignsRow.propTypes = {
  id: PropTypes.number,
  recurrent: PropTypes.bool,
  discountDays: PropTypes.number,
  active: PropTypes.bool,
  minDriverLevel: PropTypes.string,
  description: PropTypes.string,
  discountDate: PropTypes.string,
  type: PropTypes.string,
};

export default CampaignsRow;
