export const selectBusiness = state => state.business;
export const selectBusinessDetail = state => selectBusiness(state).detail;
export const selectBusinesssList = state => selectBusiness(state).items;
export const selectBusinesssListCount = state => selectBusiness(state).count;
export const selectBusinesssQueryOptions = state => {
  const { options } = selectBusiness(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};

export const selectTransactionBalance = state => selectBusiness(state).balance;
export const selectTransactionsList = state =>
  selectBusiness(state).transactionItems;

export const selectTransactionsListCount = state =>
  selectBusiness(state).transactionsCount;

export const selectTransactionsQueryOptions = state => {
  const { transactionOptions } = selectBusiness(state);
  Object.keys(transactionOptions).forEach(key => {
    if (transactionOptions[key] === '') {
      delete transactionOptions[key];
    }
  });

  return transactionOptions;
};
