import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import { CAMPAIGN_TYPE } from 'src/utils/constants';
import CampaignsRow from './CampaignsRow';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const Tbody = ({ campaigns }) =>
  campaigns?.map(campaign => <CampaignsRow key={campaign.id} {...campaign} />);

const CampaignsTable = ({
  className,
  campaigns,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  campaignType,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell key="description" style={{ width: '7.5%' }}>
          {t('COMMON.DESCRIPTION')}
        </TableCell>
        <TableCell key="recurrent" style={{ width: '5%' }}>
          {t('CAMPAIGNS_VIEW.RECURRENT')}
        </TableCell>
        <TableCell key="semaphore" style={{ width: '5%' }} align="center">
          {t('COMMON.SEMAPHORE')}
        </TableCell>
        <TableCell key="discountDays" style={{ width: '7.5%' }} align="center">
          {t('CAMPAIGNS_VIEW.DISCOUNT_DAYS')}
        </TableCell>
        <TableCell key="state" style={{ width: '7.5%' }} align="center">
          {t('COMMON.STATUS')}
        </TableCell>
        {campaignType === CAMPAIGN_TYPE.GENERAL && (
          <TableCell key="discount_date" style={{ width: '7.5%' }}>
            {t('CAMPAIGNS_VIEW.DISCOUNT_DATE')}
          </TableCell>
        )}
        <TableCell key="actions" style={{ width: '7.5%' }} align="center">
          {t('COMMON.ACTIONS')}
        </TableCell>
      </TableRow>
    ),
    [classes, t],
  );

  const tbody = useMemo(() => () => <Tbody t={t} campaigns={campaigns} />, [
    campaigns,
    t,
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, campaigns],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

CampaignsTable.propTypes = {
  className: PropTypes.string,
  campaigns: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
  campaignType: PropTypes.string,
};

CampaignsTable.defaultProps = {
  count: 0,
};

export default CampaignsTable;
