import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectRentFactorList,
  selectRentFactorListCount,
  selectRentFactorQueryList,
} from 'src/store/selectors/rentFactorSelectors';
import * as Actions from 'src/store/actions/rentFactorActions';
import PropTypes from 'prop-types';
import RentFactorTable from './RentFactorTable';

const RentFactorList = ({ tabValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const factors = useSelector(selectRentFactorList);
  const count = useSelector(selectRentFactorListCount);
  const { page } = useSelector(selectRentFactorQueryList);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_RENT_FACTOR.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_RENT_FACTOR.action]),
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(Actions.loadRentFactor({ page: value }));
    },
    [dispatch],
  );

  if (success && !loading && factors.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.COMMON_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.COMMON_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <RentFactorTable
      factors={factors}
      loading={loading}
      tabValue={tabValue}
      handleChangePage={handleChangePage}
      count={count}
      page={page}
    />
  );
};

RentFactorList.propTypes = {
  tabValue: PropTypes.number,
};

export default RentFactorList;
