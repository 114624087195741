import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import useDebounce from 'src/hooks/useDebounce';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { selectFineQueryOptions } from 'src/store/selectors/fineSelectors';
import {
  CLEAR_FINES,
  downloadExcelFines,
  loadFines,
} from 'src/store/actions/fineActions';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '70vh',
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    width: '100%',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const TimePeriodEnum = {
  LAST_30_DAYS: 'last30Days',
  THIS_MONTH: 'thisMonth',
  THIS_YEAR: 'thisYear',
  ALL: 'all',
};

const filters = [
  {
    title: 'FINE_VIEW.BY_DATE',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'byDate',
    items: [
      {
        label: 'COMMON.ALL',
        value: 'all',
      },
      {
        label: 'FINE_VIEW.LAST_30_DAYS',
        value: 'last30Days',
      },
      {
        label: 'FINE_VIEW.THIS_MONTH',
        value: 'thisMonth',
      },
      {
        label: 'FINE_VIEW.THIS_YEAR',
        value: 'thisYear',
      },
    ],
  },
  {
    title: 'FINE_VIEW.BY_DISCOUNT',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'FINE_VIEW.WITH_DISCOUNT',
        value: 'withDiscount',
      },
    ],
  },
];

function getFilterOptions(options) {
  const filterOptions = {};

  if (options.byDate === TimePeriodEnum.LAST_30_DAYS) {
    filterOptions.startDate = moment()
      .subtract(30, 'days')
      .toDate();
    filterOptions.endDate = moment().toDate();
  }

  if (options.byDate === TimePeriodEnum.THIS_MONTH) {
    filterOptions.startDate = moment()
      .startOf('month')
      .toDate();
    filterOptions.endDate = moment().toDate();
  }

  if (options.byDate === TimePeriodEnum.THIS_YEAR) {
    filterOptions.startDate = moment()
      .startOf('year')
      .toDate();
    filterOptions.endDate = moment().toDate();
  }

  if (options.byDate === TimePeriodEnum.ALL) {
    filterOptions.startDate = null;
    filterOptions.endDate = null;
  }

  filterOptions.withDiscount = options.withDiscount;

  return filterOptions;
}

// eslint-disable-next-line react/prop-types
const Toolbar = ({ ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryOptions = useSelector(selectFineQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    byDate: 'all',
    withDiscount: false,
  });

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const debounceSearch = useDebounce((value, queryOpts) => {
    dispatch(loadFines({ ...queryOpts, plateOrInfraction: value }));
    handleCloseFilter();
  }, 400);

  useEffect(() => {
    dispatch(loadFines());
    return () => dispatch({ type: CLEAR_FINES });
  }, []);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelFines());
  }, [dispatch]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [searchValue, debounceSearch, queryOptions, getFilterOptions],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <div className={classes.root} {...rest}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.FINES']}
        current="NAVIGATION.REGISTERED_FINES"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontWeight: '400', textTransform: 'capitalize' }}
          color="primary"
          variant="h1"
        >
          {t('NAVIGATION.REGISTERED_FINES')}
        </Typography>

        <div className={classes.actionsContainer}>
          <Paper
            elevation={0}
            className={classes.search}
            style={{ marginLeft: 0 }}
          >
            <Input
              className={classes.input}
              startAdornment={Addornment}
              placeholder={t('FINE_VIEW.SEARCH_BY_PLATE_OR_INFRACTION')}
              disableUnderline
              value={searchValue}
              inputProps={{
                'aria-label': 'Search',
              }}
              type="string"
              onChange={handleSearch}
            />
          </Paper>
          <Button
            className={clsx(
              classes.buttons,
              classes.outlined,
              classes.horizontalPadding,
            )}
            color="primary"
            onClick={handleOpenFilter}
          >
            <FilterIcon className={classes.icon} style={{ marginRight: 8 }} />
            {t('COMMON.FILTER')}
          </Button>
          <Filter
            anchorEl={anchorEl}
            filterValues={filterValues}
            handleClose={handleCloseFilter}
            handleChange={handleFilterChange}
            filters={filters}
          />
          <Button
            className={clsx(classes.buttons, classes.outlined)}
            onClick={handleExcelExport}
            color="primary"
          >
            <ExportIcon className={classes.icon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
