/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DateRange from 'src/utils/DateRangeType';
import { useDispatch, useSelector } from 'react-redux';
import { selectIncomeResumeOptions } from 'src/store/selectors/incomeResumeSelectors';
import { loadIncomeResume } from 'src/store/actions/incomeResumeActions';
import { useTranslation } from 'react-i18next';
import IncomeResumeList from './IncomeResumeList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `driver-detail-tab-${index}`,
    'aria-controls': `driver-detail-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

function selectDateRange(value) {
  switch (Number(value)) {
    case 0:
      return DateRange.WEEKLY;
    case 1:
      return DateRange.MONTHLY;
    case 2:
      return DateRange.QUARTERLY;
    case 3:
      return DateRange.YEARLY;
    default:
      return '';
  }
}

export default function IncomeResumeTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const queryOpts = useSelector(selectIncomeResumeOptions);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      dispatch(
        loadIncomeResume({
          page: 0,
          rangeType: selectDateRange(newValue),
        }),
      );
    },
    [setValue, queryOpts],
  );

  return (
    <div className={classes.root}>
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="tabs for driver detail"
        >
          <Tab label={t('COMMON.WEEKLY')} {...a11yProps(0)} />
          <Tab label={t('COMMON.MONTHLY')} {...a11yProps(1)} />
          <Tab label={t('COMMON.QUARTERLY')} {...a11yProps(2)} />
          <Tab label={t('COMMON.YEARLY')} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <IncomeResumeList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IncomeResumeList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <IncomeResumeList />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <IncomeResumeList />
      </TabPanel>
    </div>
  );
}
