import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import PutOnSaleIcon from '@material-ui/icons/Loyalty';
import SellIcon from '@material-ui/icons/AttachMoneyRounded';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { CAR_STATUS } from '../CarDetail/utils';

const InactiveCarsRowMenu = ({
  niv,
  openEdit,
  status,
  salePrice,
  handleOpenConfirmationModal,
  handleOpenSellModal,
  handleOpenEditModal,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openConfirmationModal = () => {
    handleOpenConfirmationModal({ niv, salePrice });
    handleCloseMenu();
  };

  const openSellModal = () => {
    handleOpenSellModal({ niv, salePrice: salePrice || 0 });
    handleCloseMenu();
  };

  return (
    <Box component="div">
      <IconButton
        aria-label="documentsMenu"
        id="documentsMenu"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={niv}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            width: '27ch',
            bgcolor: 'background.paper',
            color: 'text.primary',
            boxShadow: 1,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            if (status === CAR_STATUS.AVAILABLE) {
              openEdit();
            } else {
              handleOpenEditModal({ niv, salePrice: salePrice || 0 });
            }
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t('COMMON.EDIT')}</Typography>
        </MenuItem>
        {status === CAR_STATUS.AVAILABLE && (
          <MenuItem onClick={openConfirmationModal}>
            <ListItemIcon>
              <PutOnSaleIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t('COMMON.PUT_ON_SALE')}</Typography>
          </MenuItem>
        )}
        {status === CAR_STATUS.ON_SALE && (
          <MenuItem onClick={openSellModal}>
            <ListItemIcon>
              <SellIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t('COMMON.SELL')}</Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

InactiveCarsRowMenu.propTypes = {
  niv: PropTypes.string.isRequired,
  openEdit: PropTypes.func,
  status: PropTypes.string,
  salePrice: PropTypes.number,
  handleOpenConfirmationModal: PropTypes.func,
  handleOpenSellModal: PropTypes.func,
  handleOpenEditModal: PropTypes.func,
};

export default InactiveCarsRowMenu;
