import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Box } from '@material-ui/core';
import { Restore } from '@material-ui/icons';
import distanceInMeters from 'src/utils/getDistance';
import { Circle, Map, Polyline, TileLayer } from 'react-leaflet';
import clsx from 'clsx';
import useStyles from './style';

const GeofencingMap = ({
  open,
  handleRadiusChange,
  handleCenterChange,
  data,
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const [clickCount, setClickCount] = React.useState(0);
  const [radius, setRadius] = React.useState(0);
  const [locations, setLocations] = React.useState([]);
  const [circleView, setCircleView] = React.useState(false);

  const changeLocation = e => {
    const { lat, lng } = e.latlng;
    setLocations([...locations, { lat, lng }]);
    setClickCount(clickCount + 1);
  };

  const resetCircle = () => {
    setLocations([]);
    setClickCount(0);
    setRadius(0);
    setCircleView(false);
  };

  useEffect(() => {
    if (data.center?.lat) {
      setLocations([data.center]);
      setRadius(data.radius);
      setCircleView(true);
    }
  }, []);

  useEffect(() => {
    if (clickCount === 2) {
      setRadius(
        distanceInMeters(
          locations[0].lat,
          locations[0].lng,
          locations[1].lat,
          locations[1].lng,
        ),
      );
      handleCenterChange(locations[0]);
      handleRadiusChange(
        distanceInMeters(
          locations[0].lat,
          locations[0].lng,
          locations[1].lat,
          locations[1].lng,
        ),
      );
      setCircleView(true);
    }
  }, [clickCount]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Box style={{ whidth: '100%', height: '100%' }}>
      <Box
        container
        direction="column"
        alignItems="stretch"
        alignContent="flex-end"
        position="absolute"
        right="25px"
        zIndex="40001"
      >
        <IconButton
          aria-label="delete"
          size="large"
          classname={clsx(classes.label, classes.closeButton)}
          onClick={() => resetCircle()}
        >
          <Restore />
        </IconButton>
      </Box>
      <Map
        center={['25.6708891', '-100.3789822']}
        zoom={10}
        scrollWheelZoom={false}
        className={clsx(classes.map)}
        onclick={e => {
          if (clickCount < 2) {
            changeLocation(e);
          }
        }}
      >
        {circleView && <Circle center={locations[0]} radius={radius} />}
        {clickCount === 2 && (
          <Polyline
            positions={[
              [locations[0].lat, locations[0].lng],
              [locations[1].lat, locations[1].lng],
            ]}
          />
        )}
        <TileLayer
          attribution="&copy; <a>Meteoros</a> "
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
      </Map>
    </Box>
  );
};

GeofencingMap.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleRadiusChange: PropTypes.func.isRequired,
  handleCenterChange: PropTypes.func.isRequired,
};

export default GeofencingMap;
