import React, { useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { Periods } from 'src/utils/constants';
import moment from 'moment';
import 'moment/locale/es';

moment.updateLocale('es', moment.localeData('es'));

const selectPeriodToRender = (timePeriod, period) => {
  switch (timePeriod) {
    case Periods.WEEKLY:
      return moment(period).format('DD-MM-YYYY');
    case Periods.MONTHLY:
      return moment(period)
        .locale('ES')
        .format('MMMM');
    case Periods.QUARTERLY:
      return `${moment(period)
        .startOf('quarter')
        .format('MMMM-YYYY')} - ${moment(period)
        .endOf('quarter')
        .format('MMMM-YYYY')}`;
    case Periods.YEARLY:
      return moment(period).format('YYYY');
    default:
      return '';
  }
};

const RentFactorRow = ({
  period,
  rent,
  occupation,
  operational,
  discounts,
  vacancy,
  charged,
  potentialIncome,
  timePeriod,
  tabValue,
}) => {
  const fieldsToRender = useMemo(() => {
    if (tabValue === 1) {
      return [discounts, vacancy, charged, potentialIncome];
    }

    return [rent, occupation, operational];
  }, [tabValue]);

  return (
    <TableRow hover>
      <TableCell>{selectPeriodToRender(timePeriod, period)}</TableCell>
      {fieldsToRender.map(field => (
        <TableCell key={field}>{`${field.toFixed(1)}%`}</TableCell>
      ))}
    </TableRow>
  );
};

RentFactorRow.propTypes = {
  period: PropTypes.string,
  rent: PropTypes.number,
  occupation: PropTypes.number,
  operational: PropTypes.number,
  discounts: PropTypes.number,
  vacancy: PropTypes.number,
  charged: PropTypes.number,
  potentialIncome: PropTypes.number,
  timePeriod: PropTypes.oneOf(Object.keys(Periods)),
  tabValue: PropTypes.string,
};

export default RentFactorRow;
