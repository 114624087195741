import { Card } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { LOAD_DEPRECIATION_RESUME } from 'src/store/actions/accountingActions';
import { selectDepreciationResume } from 'src/store/selectors/accountingSelector';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import DepreciationResumeTable from './DepreciationResumeTable';

const DepreciationResumeList = () => {
  const { t } = useTranslation();
  const depreciationResume = useSelector(selectDepreciationResume);

  const loading = useSelector(
    isLoadingSelector([LOAD_DEPRECIATION_RESUME.action]),
  );
  const success = useSelector(
    successSelector([LOAD_DEPRECIATION_RESUME.action]),
  );

  if (success && !loading && Object.keys(depreciationResume).lenght === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.AMORTIZATION_BALANCE_TITLE')}
          description={t(
            'NOT_FOUND_RECORDS_MESSAGE.AMORTIZATION_BALANCE_DESCRIPTION',
          )}
        />
      </Card>
    );
  }

  return (
    <DepreciationResumeTable
      resume={depreciationResume}
      count={1}
      page={1}
      loading={loading}
    />
  );
};

export default DepreciationResumeList;
