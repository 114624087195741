import * as Yup from 'yup';

export const paymentComment = [
  'Pagos UBER',
  'Descuentos',
  'Pago a Cuenta Bancaria',
];

export const paymentSchema = Yup.object().shape({
  comment: Yup.string()
    .oneOf(paymentComment, 'SCHEMA_ERRORS.SELECT_VALUE_LIST')
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  amount: Yup.number()
    .positive('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER'),
});

export const chargeSchema = Yup.object().shape({
  amount: Yup.number()
    .positive('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER'),
});
