/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  Grid,
  TableBody,
  Typography,
} from '@material-ui/core';
import { UserPlus } from 'react-feather';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import {
  assignAval,
  modifyAval,
  unassignAval,
} from 'src/store/actions/driverActions';
import { useTranslation } from 'react-i18next';
import { DriverStatus } from 'src/utils/constants';
import AvalInfo from './AvalInfo';
import CreateAvalDialog from './CreateAvalDialog';
import EditAvalDialog from './EditAvalDialog';
import DeleteAvalDialog from './DeleteAvalDialog';
import useStyles from '../styles';

const GeneralAval = ({ driverId, aval, driverStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(assignAval(driverId, values));
    },
    [aval, dispatch],
  );

  const handleClickOpenEdit = useCallback(() => {
    setOpenEdit(true);
  }, [setOpenEdit]);

  const handleCloseEdit = useCallback(() => {
    setOpenEdit(false);
  }, [setOpenEdit]);

  const handleSubmitEdit = useCallback(
    values => {
      dispatch(modifyAval(driverId, values));
    },
    [aval, dispatch],
  );

  const handleClickOpenDelete = useCallback(() => {
    setOpenDelete(true);
  }, [setOpenDelete]);

  const handleCloseDelete = useCallback(() => {
    setOpenDelete(false);
  }, [setOpenDelete]);

  const handleSubmitDelete = useCallback(() => {
    dispatch(unassignAval(driverId));
    handleCloseDelete();
  }, [handleCloseDelete]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('DRIVER_DETAIL_VIEW.AVAL_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      {aval ? (
        <CardContent style={{ padding: 0, marginBottom: '16px' }}>
          <Table>
            <TableBody>
              <AvalInfo aval={aval} />
            </TableBody>
          </Table>
          <Grid container direction="column" alignItems="stretch">
            <Grid item className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.label, classes.buttons)}
                startIcon={<EditIcon />}
                onClick={handleClickOpenEdit}
                disabled={driverStatus === DriverStatus.LEFT}
              >
                {t('COMMON.EDIT')}
              </Button>
            </Grid>
            <Grid item className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.outlinedDelete, classes.buttons)}
                startIcon={<DeleteIcon />}
                onClick={handleClickOpenDelete}
                disabled={driverStatus === DriverStatus.LEFT}
              >
                {t('DRIVER_DETAIL_VIEW.DELETE_AVAL')}
              </Button>
            </Grid>
          </Grid>
          <EditAvalDialog
            open={openEdit}
            aval={aval}
            handleClose={handleCloseEdit}
            submit={handleSubmitEdit}
          />
          <DeleteAvalDialog
            open={openDelete}
            handleClose={handleCloseDelete}
            handleSubmit={handleSubmitDelete}
          />
        </CardContent>
      ) : (
        <CardContent style={{ padding: 0 }} alignItems="center">
          <Box alignItems="center" display="flex" className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<UserPlus />}
              onClick={handleClickOpen}
              className={clsx(classes.label, classes.buttons)}
              disabled={driverStatus === DriverStatus.LEFT}
            >
              {t('DRIVER_DETAIL_VIEW.ASSIGN_AVAL')}
            </Button>
            <CreateAvalDialog
              open={open}
              aval={aval}
              handleClose={handleClose}
              submit={handleSubmit}
            />
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default GeneralAval;
