import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import GeneralBusiness from './GeneralDriver';
import GeneralContact from './GeneralContact';
import BusinessDocuments from './BusinessDocuments';

const GeneralView = ({ business }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <GeneralBusiness business={business} />
        </Grid>
        <Grid item xs={4}>
          <BusinessDocuments business={business} />
        </Grid>
        <Grid item xs={4}>
          <GeneralContact business={business} />
        </Grid>
      </Grid>
    </Box>
  );
};

GeneralView.propTypes = {
  business: PropTypes.object,
};

export default GeneralView;
