const makeInitialState = address => {
  return {
    insideHomeNumber: address ? address.insideHomeNumber : '',
    externalHomeNumber: address ? address.externalHomeNumber : '',
    street: address ? address.street : '',
    colonia: address ? address.colonia : '',
    municipality: address ? address.municipality : '',
    homeTown: address ? address.homeTown : '',
    zipCode: address ? address.zipCode : '',
  };
};

export default makeInitialState;
