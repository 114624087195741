/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { ExternalLink } from 'react-feather';
import { ReactComponent as IfeIcon } from 'src/assets/icons/ife.svg';
import { ReactComponent as IfeAvalIcon } from 'src/assets/icons/ife_aval.svg';
import { ReactComponent as LicenceIcon } from 'src/assets/icons/licence.svg';
import { ReactComponent as LicenceAvalIcon } from 'src/assets/icons/licence_aval.svg';
import { ReactComponent as PlatformPictureIcon } from 'src/assets/icons/platform_picture.svg';
import { ReactComponent as WaterFileIcon } from 'src/assets/icons/water_file.svg';
import { ReactComponent as WaterFileAvalIcon } from 'src/assets/icons/water_file_aval.svg';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DetailRow from 'src/components/DetailRow';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import EditImageDialog from 'src/components/EditImageDialog/EditImageDialog';
import useStyles from '../styles';

function getIcon(iconType, classes) {
  switch (iconType) {
    case 'IFE':
      return <IfeIcon className={classes} />;
    case 'Licencia':
      return <LicenceIcon className={classes} />;
    case 'Boleta de Agua':
      return <WaterFileIcon className={classes} />;
    case 'Foto Plataforma':
      return <PlatformPictureIcon className={classes} />;
    case 'IFE Aval':
      return <IfeAvalIcon className={classes} />;
    case 'Licencia Aval':
      return <LicenceAvalIcon className={classes} />;
    case 'Boleta de Agua Aval':
      return <WaterFileAvalIcon className={classes} />;
    default:
      return <InsertDriveFileIcon className={classes} />;
  }
}

export const DocumentLink = ({ name, link }) => {
  const { t } = useTranslation();
  const [openTest, setOpenTest] = useState(false);

  const handleOpenTest = () => {
    setOpenTest(true);
  };

  const handleCloseTest = () => {
    setOpenTest(false);
  };

  const classes = useStyles();
  return link ? (
    <>
      <a className={classes.documentLink} onClick={handleOpenTest}>
        <Typography
          variant="body2"
          component="div"
          className={classes.documentText}
        >
          {getIcon(name, classes.documentIcon)}
          {t('COMMON.DOCUMENT_VIEW')}
          <ExternalLink style={{ marginLeft: '8px' }} size={20} />
        </Typography>
      </a>

      <EditImageDialog
        open={openTest}
        onClose={handleCloseTest}
        imageUrl={link}
      />
    </>
  ) : (
    <>{t('COMMON.NO_DATA')}</>
  );
};

const DocumentsInfo = ({ driver }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name="IFE"
        value={<DocumentLink name="IFE" link={driver.ifeFile} />}
      />

      <DetailRow
        name="Licencia"
        value={<DocumentLink name="Licencia" link={driver.licenceFile} />}
      />

      <DetailRow
        name={t('DRIVERS_VIEW.LICENCE_EXPIRATION')}
        value={
          driver.licenceExpirationAt
            ? moment(driver.licenceExpirationAt).format('DD-MMM-YY')
            : 'No disponible'
        }
      />

      <DetailRow
        name="Boleta de Agua"
        value={<DocumentLink name="Boleta de Agua" link={driver.waterFile} />}
      />

      <DetailRow
        name="Foto Plataforma"
        value={
          <DocumentLink name="Foto Plataforma" link={driver.pictureFile} />
        }
      />
    </>
  );
};

export default DocumentsInfo;
