/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { updateCarDocumentsAction } from 'src/store/actions/carActions';
import { useTranslation } from 'react-i18next';
import CarDocumentInfo from './CarDocumentInfo';
import CarDocumentDialog from './CarDocumentDialog';
import useStyles from '../styles';
import { CAR_STATUS } from '../utils';

const CarDocuments = ({ car }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateCarDocumentsAction(car.niv, values));
    },
    [car, dispatch],
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CAR_DETAIL.VEHICLE_DOCUMENTS')}
          </Typography>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <CarDocumentInfo car={car} openModal={handleClickOpen} />
          </TableBody>
        </Table>
        <CarDocumentDialog
          open={open}
          car={car}
          handleClose={handleClose}
          submit={handleSubmit}
        />
        <Box
          style={{ marginBottom: '32px' }}
          display="flex"
          justifyContent="flex"
          className={classes.buttons}
        >
          <Button
            disabled={car.status === CAR_STATUS.SOLD}
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            className={clsx(classes.label, classes.buttons)}
            startIcon={<EditIcon />}
          >
            {t('COMMON.EDIT')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CarDocuments;
