import React from 'react';
import moment from 'moment';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const GeneralDriverInfo = ({ driver }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow name={t('COMMON.FULLNAME')} value={driver.fullName} />

      <DetailRow name={t('COMMON.MOBILE')} value={driver.mobile} />

      <DetailRow name={t('COMMON.EMAIL')} value={driver.email} />

      <DetailRow
        name={t('COMMON.DATE_OF_BIRTH')}
        value={driver.age ? moment(driver.age).format('DD-MMM-YY') : ''}
      />

      <DetailRow
        name={t('COMMON.AGE')}
        value={moment().diff(driver.age, 'years') || ''}
      />

      <DetailRow name={t('COMMON.CIVIL_STATUS')} value={driver.civilStatus} />

      <DetailRow
        name={t('COMMON.ECONOMIC_DEPENDENTS')}
        value={driver.economicDependents}
      />

      <DetailRow name={t('COMMON.BIRTH_STATE')} value={driver.homeTown} />

      <DetailRow name={t('COMMON.HOME_STATUS')} value={driver.houseStatus} />

      <DetailRow
        name={t('COMMON.ZIP_CODE')}
        value={driver.zipCode || t('COMMON.NO_DATA')}
      />

      <DetailRow name="RFC" value={driver.rfc} />

      {driver.unassignReasonComment && (
        <DetailRow
          name={t('DRIVER_DETAIL_VIEW.UNASSIGN_REASON_COMMENT')}
          value={driver.unassignReasonComment}
        />
      )}
    </>
  );
};

GeneralDriverInfo.propTypes = {
  driver: PropTypes.object.isRequired,
};
export default GeneralDriverInfo;
