import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useStyles from '../styles';
import validationSchema from './schema';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  salePrice: data ? data.salePrice : 0,
});

const EditCarsOnSaleForm = ({ open, data, handleClose, handleEdit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = values => {
    handleEdit(values);
  };

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-test-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('COMMON.EDIT_CAR_ON_SALE')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            onSubmit={handleOuterSubmit}
            validationSchema={validationSchema}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.salePrice && errors.salePrice)}
                    fullWidth
                    helperText={touched.salePrice && t(errors.salePrice)}
                    label={t('COMMON.SALE_PRICE')}
                    margin="normal"
                    name="salePrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.salePrice}
                    variant="outlined"
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditCarsOnSaleForm.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    salePrice: PropTypes.number,
  }),
  handleClose: PropTypes.func,
  handleEdit: PropTypes.func,
};

export default EditCarsOnSaleForm;
