import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { history } from 'src/store';
import { ConnectedRouter } from 'connected-react-router';
import LoginView from 'src/views/auth/LoginView';
import PrivateRoute from 'src/components/PrivateRoute';
import DashboardViews from 'src/views';
import RecoverPassView from 'src/views/auth/RecoverPasswordView';

const AppRoutes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/login" component={LoginView} />
      <Route path="/recover-password" component={RecoverPassView} />
      <PrivateRoute path="/" component={DashboardViews} />
    </Switch>
  </ConnectedRouter>
);

export default AppRoutes;
