import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumb from 'src/components/Breadcrumb';
import { ParameterSection } from 'src/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadParameters,
  LOAD_PARAMETERS,
} from 'src/store/actions/parameterActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { selectParameterList } from 'src/store/selectors/parameterSelector';
import ParameterList from './ParameterList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

function ParameterTabs({ handleOpenEdit }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const parameters = useSelector(selectParameterList);
  const loading = useSelector(isLoadingSelector([LOAD_PARAMETERS.action]));

  const getParameterSection = useCallback(
    index => {
      switch (index) {
        case 0:
          return ParameterSection.GENERAL;
        case 1:
          return ParameterSection.PRICING;
        default:
          return ParameterSection.GENERAL;
      }
    },
    [value],
  );

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
      dispatch(loadParameters({ section: getParameterSection(newValue) }));
    },
    [setValue],
  );

  useEffect(() => {
    dispatch(loadParameters({ section: ParameterSection.GENERAL }));
  }, []);

  return (
    <div className={classes.root}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL']}
        current="NAVIGATION.PARAMETERS"
      />
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label="General" />
          <Tab label="Precios" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ParameterList
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ParameterList
          handleOpenEdit={handleOpenEdit}
          parameters={parameters}
          loading={loading}
        />
      </TabPanel>
    </div>
  );
}

ParameterTabs.propTypes = {
  handleOpenEdit: PropTypes.func,
};

export default ParameterTabs;
