import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectLicenceCount,
  selectLicenceList,
  selectLicenceQueryOptions,
} from 'src/store/selectors/licenceSelectors';
import {
  loadLicences,
  LOAD_DRIVERS_WITH_LICENCE,
} from 'src/store/actions/licenceActions';
import Toolbar from './Toolbar';
import LicencesTable from './LicencesTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LicencesList = () => {
  const dispatch = useDispatch();
  const licences = useSelector(selectLicenceList);
  const count = useSelector(selectLicenceCount);
  const { page } = useSelector(selectLicenceQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_DRIVERS_WITH_LICENCE.action]),
  );
  const success = useSelector(
    successSelector([LOAD_DRIVERS_WITH_LICENCE.action]),
  );

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadLicences({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadLicences({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado conductores con licencias"
          description="Ningúna licencia coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <LicencesTable
      licences={licences}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

const LicencesListView = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Page className={classes.root} title={t('LICENCE_VIEW.LICENCES')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <LicencesList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default LicencesListView;
