import React from 'react';
import PropTypes from 'prop-types';
import MuiTooltip from '@material-ui/core/Tooltip';

const Tooltip = React.forwardRef(function Tooltip(props, ref) {
  return props.condition ? (
    <MuiTooltip title={props.text} ref={ref} {...props}>
      <span>{props.children}</span>
    </MuiTooltip>
  ) : (
    props.children
  );
});

Tooltip.propTypes = {
  condition: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
};

export default Tooltip;
