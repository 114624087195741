import * as Yup from 'yup';

export const paymentSchema = Yup.object().shape({
  comment: Yup.string().required('SCHEMA_ERRORS.COMMENT_REQUIRED'),
  amount: Yup.number()
    .positive('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER'),
});

export const chargeSchema = Yup.object().shape({
  amount: Yup.number()
    .positive('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER'),
});

export const pendingPaymentSchema = Yup.object().shape({
  movement: Yup.string().required('SCHEMA_ERRORS.ENTER_VALUE'),
});
