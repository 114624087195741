import React from 'react';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Preloader from 'src/components/Preloader';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import MuiTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

const Table = ({
  className,
  theads: Theads,
  tbody: Tbody,
  pagination: Pagination,
  loading,
  ...rest
}) => {
  return (
    <Card className={className} {...rest}>
      <PerfectScrollbar>
        <Box>
          <FadeIn transitionDuration={200}>
            <TableContainer style={{ maxHeight: '68vh' }}>
              <MuiTable stickyHeader>
                <TableHead>
                  <Theads />
                </TableHead>
                {loading ? null : (
                  <TableBody>
                    <Tbody />
                  </TableBody>
                )}
              </MuiTable>
            </TableContainer>
            {loading ? <Preloader style={{ height: '50vh' }} /> : null}
            {Pagination ? <Pagination /> : null}
          </FadeIn>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  theads: PropTypes.func.isRequired,
  tbody: PropTypes.func.isRequired,
  pagination: PropTypes.func,
  loading: PropTypes.bool,
};

export default Table;
