import { getParameters, editParameter } from 'src/services/parameterService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectParameterQueryOptions } from '../selectors/parameterSelector';
import { showMessage } from './snackBarActions';

export const LOAD_PARAMETERS = asyncActionCreator('LOAD_PARAMETERS');
export const CLEAR_PARAMETERS = 'CLEAR_PARAMETERS';
export const SAVE_OPTIONS = 'SAVE_OPTIONS';

function getOptions(getState) {
  const queryOpts = selectParameterQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.section) {
    reqOptions.section = queryOpts.section;
  }

  return reqOptions;
}

export const loadParameters = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_PARAMETERS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });

    const reqOptions = getOptions(getState);

    const data = await getParameters(reqOptions);

    return dispatch({
      type: LOAD_PARAMETERS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_PARAMETERS.failure });
  }
};

export const clearParameters = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_PARAMETERS });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const editParameterAction = values => async dispatch => {
  try {
    await editParameter(values);
    dispatch(
      showMessage({
        message: 'UPDATE_PARAMETER',
        variant: 'success',
      }),
    );
    return dispatch(loadParameters());
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};
