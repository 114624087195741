import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, IconButton } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { Edit } from 'react-feather';
import { ParameterType } from 'src/utils/constants';
import { useTranslation } from 'react-i18next';

const selectValueForBooleanParam = value => {
  switch (value) {
    case 'true':
      return 'YES';
    case 'false':
      return 'NO';
    default:
      return 'NO';
  }
};

const ParameterRow = ({ name, value, type, openEdit }) => {
  const { t } = useTranslation();
  return (
    <TableRow hover>
      <TableCell>{t(`PARAMETER_VIEW.${name}`)}</TableCell>
      {type === ParameterType.BOOLEAN ? (
        <TableCell>
          {t(`COMMON.${selectValueForBooleanParam(value)}`).toUpperCase()}
        </TableCell>
      ) : (
        <TableCell>{value}</TableCell>
      )}
      <TableCell style={{ width: 130 }}>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={openEdit}
          key="edit"
          aria-label="Edit"
          color="inherit"
          disabled={name === 'INTERESTS'}
        >
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

ParameterRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  openEdit: PropTypes.func,
};

export default ParameterRow;
