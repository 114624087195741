/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  updateInsurance,
  createInsurance,
  unassignInsurance,
} from 'src/store/actions/insuranceActions';
import CarInsuranceInfo from './CarInsuranceInfo';
import CarInsuranceDialog from './CarInsuranceDialog';
import DeleteInsuranceDialog from './DeleteInsuranceDialog';

import useStyles from '../styles';

const CarInsurance = ({ car }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      if (!car.insurance) {
        dispatch(createInsurance(car.niv, values));
      } else {
        dispatch(updateInsurance(car.niv, car.insurance.id, values));
      }
    },
    [car, dispatch],
  );

  const handleClickOpenDelete = useCallback(() => {
    setOpenDelete(true);
  }, [setOpenDelete]);

  const handleCloseDelete = useCallback(() => {
    setOpenDelete(false);
  }, [setOpenDelete]);

  const handleSubmitDelete = useCallback(() => {
    dispatch(unassignInsurance(car.niv, car.insurance.id));
    handleCloseDelete();
  }, [handleCloseDelete]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('FLEET_VIEW.INSURANCE_DATA')}
          </Typography>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            {car.insurance ? (
              <CarInsuranceInfo insurance={car.insurance} lease={car.lease} />
            ) : null}
          </TableBody>
        </Table>
        <CarInsuranceDialog
          open={open}
          car={car}
          handleClose={handleClose}
          submit={handleSubmit}
        />
        <Box
          style={{ marginBottom: !car.insurance ? '32px' : '12px' }}
          display="flex"
          justifyContent="flex"
          className={classes.buttons}
        >
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            className={clsx(classes.label, classes.buttons)}
            startIcon={<EditIcon />}
          >
            {car.insurance ? t('COMMON.UPDATE') : t('FLEET_VIEW.ADD_INSURANCE')}
          </Button>
        </Box>
        {car.insurance && (
          <Box
            style={{ marginBottom: '32px' }}
            display="flex"
            justifyContent="flex"
            className={classes.buttons}
          >
            <DeleteInsuranceDialog
              open={openDelete}
              handleClose={handleCloseDelete}
              handleSubmit={handleSubmitDelete}
            />
            <Button
              onClick={handleClickOpenDelete}
              variant="contained"
              color="primary"
              className={clsx(classes.outlinedDelete, classes.buttons)}
              startIcon={<DeleteIcon />}
            >
              {t('FLEET_VIEW.UNASSIGN_INSURANCE')}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CarInsurance;
