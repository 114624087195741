import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const loginWithCredentials = serviceBase({
  request: data => axiosClient.post('/auth/login', data),
  transformResponse: res => res.data.data,
  retry: 0,
});

export const validateTokenWithUsername = serviceBase({
  request: data => axiosClient.post('/auth/validate-token', data),
  transformResponse: res => res.data.data,
  retry: 0,
});

export const recoverPass = serviceBase({
  request: data => axiosClient.post('/auth/recover-password', data),
  transformResponse: res => res.data.data,
  retry: 0,
});

export const changePass = serviceBase({
  request: data => axiosClient.post('/auth/change-password', data),
  transformResponse: res => res.data.data,
  retry: 0,
});

export default loginWithCredentials;
