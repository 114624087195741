import { Card } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  loadFleetMovements,
  LOAD_FLEET_MOVEMENTS,
} from 'src/store/actions/accountingActions';
import {
  selectDepreciationBalanceQueryOptions,
  selectFleetMovementsList,
  sselectFleetMovementsListCount,
} from 'src/store/selectors/accountingSelector';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import FleetMovementsTable from './FleetMovementsTable';

const FleetMovementsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const movements = useSelector(selectFleetMovementsList);
  const count = useSelector(sselectFleetMovementsListCount);
  const { page } = useSelector(selectDepreciationBalanceQueryOptions);
  const queryOptions = useSelector(selectDepreciationBalanceQueryOptions);

  const loading = useSelector(isLoadingSelector([LOAD_FLEET_MOVEMENTS.action]));
  const success = useSelector(successSelector([LOAD_FLEET_MOVEMENTS.action]));

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadFleetMovements({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(
        loadFleetMovements({
          ...queryOptions,
          sort: { property, order },
        }),
      );
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.FLEET_MOVEMENTS_TITLE')}
          description={t(
            'NOT_FOUND_RECORDS_MESSAGE.FLEET_MOVEMENTS_DESCRIPTION',
          )}
        />
      </Card>
    );
  }

  return (
    <FleetMovementsTable
      movements={movements}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

export default FleetMovementsList;
