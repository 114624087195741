import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CreateOrEditCampaignForm from './CreateOrEditCampaignForm';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateOrEditCampaignDialog = ({ open, handleClose, campaign }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      aria-labelledby="form-new-campaign-dialog"
      classes={{ paper: classes.rootDialog }}
      open={open}
      scroll="body"
      TransitionComponent={Transition}
      onClose={handleClose}
      keepMounted
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        className={classes.titleDialog}
        id="form-new-campaign-title"
        disableTypography
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeIconDialog}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <Typography
          className={classes.titleDialog}
          color="primary"
          variant="h2"
        >
          {campaign
            ? t('CAMPAIGNS_VIEW.EDIT_CAMPAIGN')
            : t('CAMPAIGNS_VIEW.ADD_CAMPAIGN')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CreateOrEditCampaignForm
          handleClose={handleClose}
          campaign={campaign}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateOrEditCampaignDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  campaign: PropTypes.object,
};

export default CreateOrEditCampaignDialog;
