import React from 'react';
import DetailRow from 'src/components/DetailRow';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DriverMinimumPaymentInfo = ({ minimumPaymentAdjustment }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('DRIVER_DETAIL_VIEW.MINIMUM_PAYMENT_VALUE')}
        value={minimumPaymentAdjustment}
      />
    </>
  );
};

DriverMinimumPaymentInfo.propTypes = {
  minimumPaymentAdjustment: PropTypes.number,
};

export default DriverMinimumPaymentInfo;
