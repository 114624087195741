import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation } from '@material-ui/icons';
import { selectAvailableDrivers } from 'src/store/selectors/driverSelectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  clearAvailableDrivers,
  loadAvailableDrivers,
} from 'src/store/actions/driverActions';
import useStyles from '../styles';
import { CAR_MODES } from '../utils';

const CalculatedEndDate = ({ values, setFieldValue }) => {
  useEffect(() => {
    setFieldValue(
      'contractEndDate',
      moment(values.startDate)
        .add(values.duration, 'weeks')
        .toDate(),
      true,
    );
  }, [values.startDate, values.duration]);

  return null;
};

const DatePickerField = ({ field, form, label, ...other }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      disablePast={false}
      className={classes.field}
      inputVariant="outlined"
      fullWidth
      name={field.name}
      value={field.value}
      format="DD/MM/YYYY"
      variant="inline"
      label={label}
      helperText={t(currentError)}
      error={Boolean(currentError)}
      onError={error => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={date => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...other}
    />
  );
};
DatePickerField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

const AssignForm = ({
  open,
  values,
  errors,
  touched,
  isBusinessCar,
  handleBlur,
  handleClose,
  handleReset,
  handleSubmit,
  setFieldValues,
  handleChange,
  carMode,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const availableDrivers = useSelector(selectAvailableDrivers).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const { t } = useTranslation();

  const handleInnerClose = useCallback(() => {
    handleReset();
    handleClose();
  }, [handleClose, handleReset]);

  useEffect(() => {
    if (open) {
      dispatch(loadAvailableDrivers(isBusinessCar));
    }

    return () => {
      dispatch(clearAvailableDrivers());
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="startDate"
        label={t('DRIVER_DETAIL_VIEW.LEASE.FORM.START_DATE')}
        component={DatePickerField}
      />

      {carMode !== CAR_MODES.LEASE && carMode !== CAR_MODES.ADMINISTRATIVE && (
        <Field
          name="contractEndDate"
          label={t('DRIVER_DETAIL_VIEW.LEASE.FORM.END_DATE')}
          component={DatePickerField}
          minDate={values.startDate}
        />
      )}

      <FormControl
        className={classes.field}
        variant="outlined"
        fullWidth
        error={Boolean(touched.driverId && errors.driverId)}
      >
        <Autocomplete
          value={availableDrivers.find(d => d.id === values.driverId)}
          options={availableDrivers}
          getOptionLabel={option => option.name}
          includeInputInList
          onOpen={handleBlur}
          onChange={(_e, value) => {
            setFieldValues('driverId', value?.id || '');
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Cliente"
              name="driverId"
              fullWidth
              variant="outlined"
            />
          )}
        />
        {Boolean(touched.driverId && errors.driverId) && (
          <FormHelperText>{t(errors.driverId)}</FormHelperText>
        )}
      </FormControl>
      {carMode === CAR_MODES.LEASE && (
        <>
          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.hitch && errors.hitch)}
          >
            <TextField
              error={Boolean(touched.hitch && errors.hitch)}
              fullWidth
              helperText={touched.hitch && t(errors.hitch)}
              label={t('DRIVER_DETAIL_VIEW.LEASE.FORM.INITIAL_PAYMENT')}
              margin="none"
              name="hitch"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.hitch}
              variant="outlined"
            />
          </FormControl>

          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.duration && errors.wedurationeks)}
          >
            <TextField
              error={Boolean(touched.duration && errors.duration)}
              fullWidth
              helperText={touched.duration && t(errors.weeks)}
              label={t('DRIVER_DETAIL_VIEW.LEASE.FORM.WEEKS_OF_CONTRACT')}
              margin="none"
              name="duration"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.duration}
              variant="outlined"
            />
          </FormControl>

          <Field
            disabled
            value={values.contractEndDate}
            name="contractEndDate"
            component={DatePickerField}
          />

          <CalculatedEndDate values={values} setFieldValue={setFieldValues}>
            {' '}
          </CalculatedEndDate>

          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.rentCost && errors.rentCost)}
          >
            <TextField
              error={Boolean(touched.rentCost && errors.rentCost)}
              fullWidth
              helperText={touched.rentCost && t(errors.rentCost)}
              label={t('DRIVER_DETAIL_VIEW.LEASE.FORM.COST_PER_RENT')}
              margin="none"
              name="rentCost"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.rentCost}
              variant="outlined"
            />
          </FormControl>

          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.insuranceCost && errors.insuranceCost)}
          >
            <TextField
              error={Boolean(touched.insuranceCost && errors.insuranceCost)}
              fullWidth
              helperText={t(
                'DRIVER_DETAIL_VIEW.LEASE.FORM.COST_PER_INSURANCE_TEXT',
              )}
              label={t('DRIVER_DETAIL_VIEW.LEASE.FORM.COST_FOR_INSURANCE')}
              margin="none"
              name="insuranceCost"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.insuranceCost}
              variant="outlined"
            />
          </FormControl>

          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.costPerWeek && errors.costPerWeek)}
          >
            <TextField
              error={Boolean(touched.costPerWeek && errors.costPerWeek)}
              fullWidth
              disabled
              helperText={touched.costPerWeek && t(errors.costPerWeek)}
              label={t('DRIVER_DETAIL_VIEW.LEASE.FORM.TOTAL_BASE_COST')}
              margin="none"
              name="costPerWeek"
              type="number"
              value={values.rentCost + values.insuranceCost}
              variant="outlined"
            />
          </FormControl>
        </>
      )}

      <Grid container direction="column" alignItems="stretch">
        <Grid item>
          <Button
            className={clsx(classes.label, classes.buttons)}
            type="submit"
            color="primary"
            variant="outlined"
          >
            {t('COMMON.SAVE')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={clsx(classes.outlined, classes.buttons)}
            onClick={handleInnerClose}
            color="primary"
            variant="outlined"
          >
            {t('COMMON.CANCEL')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

AssignForm.propTypes = {
  isBusinessCar: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  carMode: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AssignForm;
