import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CLEAR_INSURANCES,
  downloadExcelInsurances,
  loadInsurances,
} from 'src/store/actions/insuranceActions';
import useDebounce from 'src/hooks/useDebounce';
import { selectInsuranceQueryOptions } from 'src/store/selectors/insuranceSelectors';
import { Search } from 'react-feather';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { InsuranceStatus } from 'src/utils/constants';
import InsuranceAutocomplete from './InsuranceAutocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const filters = [
  {
    title: 'INSURANCE_VIEW.BY_POLICY_TYPE',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'policyType',
    items: [
      {
        label: 'COMMON.ALL',
        value: 'ALL',
      },
      {
        label: 'COMMON.PLATFORM',
        value: 'Plataforma',
      },
      {
        label: 'COMMON.NORMAL',
        value: 'Normal',
      },
    ],
  },
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'COMMON.ACTIVE',
        value: 'activeInsurances',
      },
      {
        label: 'COMMON.INACTIVE',
        value: 'errorInsurances',
      },
    ],
  },
  {
    title: 'INSURANCE_VIEW.BY_INSURER',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'INSURANCE_VIEW.REMAINING_DAYS_FILTER',
        value: 'remainingDays',
      },
    ],
  },
];

function getFilterOptions(options) {
  let remainingDays = null;
  let policyType = null;
  let status = null;

  if (options.remainingDays) {
    remainingDays = '30';
  }

  if (options.policyType) {
    policyType = options.policyType;
  }

  if (options.policyType === 'ALL') {
    policyType = null;
  }

  if (options.activeInsurances) {
    status = InsuranceStatus.ACTIVE;
  }

  if (options.errorInsurances) {
    status = InsuranceStatus.ERROR;
  }

  if (options.activeInsurances && options.errorInsurances) {
    status = null;
  }

  return { policyType, remainingDays, status };
}

const options = [
  { text: 'INSURANCE_VIEW.SEARCH_BY_NIV', value: 'carniv' },
  { text: 'INSURANCE_VIEW.POLICY_TYPE', value: 'policyType' },
  { text: 'INSURANCE_VIEW.INSURER', value: 'remainingDays' },
];

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryOptions = useSelector(selectInsuranceQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria] = useState('carniv');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    policyType: 'ALL',
  });

  const debounceSearch = useDebounce(
    (criteria = searchCriteria, value, queryOpts) =>
      dispatch(loadInsurances({ ...queryOpts, page: 0, [criteria]: value })),
    400,
  );

  useEffect(() => {
    dispatch(loadInsurances());
    return () => dispatch({ type: CLEAR_INSURANCES });
  }, []);

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelInsurances());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(searchCriteria, value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions, filterValues],
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues({
        ...filterValues,
        ...event,
      });
      debounceSearch(searchCriteria, searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions,
    ],
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.FLEET']}
        current="NAVIGATION.INSURANCE"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontWeight: '400' }} color="primary" variant="h1">
          {t('NAVIGATION.INSURANCE')}
        </Typography>
        <div className={classes.actionsContainer}>
          <Paper
            elevation={0}
            className={classes.search}
            style={{ marginLeft: 0 }}
          >
            {searchCriteria === 'policyType' ||
            searchCriteria === 'remainingDays' ? (
              <InsuranceAutocomplete
                options={options}
                t={t}
                criteria={searchCriteria}
                handleSearch={handleSearch}
              />
            ) : (
              <Input
                classes={{ input: classes.input }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                }
                placeholder={t('INSURANCE_VIEW.SEARCH_BY_NIV')}
                className="flex mx-8"
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                type="number"
                onChange={handleSearch}
              />
            )}
          </Paper>
          <Button
            className={clsx(
              classes.buttons,
              classes.outlined,
              classes.horizontalPadding,
            )}
            color="primary"
            onClick={handleClickFilter}
          >
            <FilterIcon className={classes.icon} style={{ marginRight: 8 }} />
            {t('COMMON.FILTER')}
          </Button>
          <Filter
            anchorEl={anchorEl}
            filterValues={filterValues}
            handleClose={handleCloseFilter}
            handleChange={handleFilterChange}
            filters={filters}
          />
          <Button
            className={clsx(classes.buttons, classes.outlined)}
            onClick={handleExcelExport}
            color="primary"
          >
            <ExportIcon className={classes.icon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
