import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  getExpense,
  downloadeExportedExpense,
  getPlates,
  getDriversByPlate,
  createNewExpense,
} from 'src/services/expenseService';
import { getExpenseTypes, getProviders } from 'src/services/catalogService';
import { showMessage } from './snackBarActions';
import {
  selectExpenseAutcompletes,
  selectExpenseOptions,
} from '../selectors/expenseSelectors';

export const SAVE_EXPENSE_OPTIONS = 'SAVE_EXPENSE_OPTIONS';
export const CLEAR_EXPENSE_OPTIONS = 'CLEAR_EXPENSE_OPTIONS';
export const LOAD_EXPENSE = asyncActionCreator('LOAD_EXPENSE');
export const CLEAR_EXPENSE = 'CLEAR_EXPENSE';
export const CREATE_EXPENSE = asyncActionCreator('CREATE_EXPENSE');
export const LOAD_EXPENSE_AUTOCOMPLETES = asyncActionCreator(
  'LOAD_EXPENSE_AUTOCOMPLETES',
);
export const CLEAR_EXPENSE_AUTOCOMPLETES = 'CLEAR_EXPENSE_AUTOCOMPLETES';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectExpenseOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.driverName) {
    reqOptions.driverName = queryOpts.driverName;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadExpense = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_EXPENSE.start });

    dispatch({
      type: SAVE_EXPENSE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getExpense(reqOptions);

    return dispatch({
      type: LOAD_EXPENSE.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_EXPENSE.failure });
  }
};

export const clearExpense = () => dispatch => {
  dispatch({ type: CLEAR_EXPENSE });
};

export const clearExpenseOptions = () => dispatch => {
  dispatch({ type: CLEAR_EXPENSE_OPTIONS });
};

export const downloadExcelExpense = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    return downloadeExportedExpense(options);
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadSelections = () => async dispatch => {
  try {
    dispatch({ type: LOAD_EXPENSE_AUTOCOMPLETES.start });
    const providers = await getProviders();
    const expenses = await getExpenseTypes();
    const plates = await getPlates();

    return dispatch({
      type: LOAD_EXPENSE_AUTOCOMPLETES.success,
      data: {
        providers,
        expenses,
        plates,
      },
    });
  } catch (error) {
    dispatch({ type: LOAD_EXPENSE_AUTOCOMPLETES.failure });
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const clearSelections = () => dispatch => {
  dispatch({ type: CLEAR_EXPENSE_AUTOCOMPLETES });
};

export const loadDriversByPlate = plate => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_EXPENSE_AUTOCOMPLETES.start });
    const { providers, expenses, plates } = selectExpenseAutcompletes(
      getState(),
    );

    const {
      history,
      actualDriverId,
      actualDriverName,
      state,
    } = await getDriversByPlate(plate);

    const repeatedDrivers = new Set();

    const drivers = history.reduce((acc, curr) => {
      if (repeatedDrivers.has(curr.driverId)) {
        return acc;
      }

      repeatedDrivers.add(curr.driverId);
      return [...acc, { ...curr, region: state }];
    }, []);

    if (actualDriverName && actualDriverId) {
      drivers.push({
        driverName: actualDriverName,
        driverId: actualDriverId,
        region: state,
      });
    }

    return dispatch({
      type: LOAD_EXPENSE_AUTOCOMPLETES.success,
      data: {
        providers,
        expenses,
        plates,
        drivers,
      },
    });
  } catch (error) {
    dispatch({ type: LOAD_EXPENSE_AUTOCOMPLETES.failure });
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const createExpense = (values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: CREATE_EXPENSE.start });
    const expense = {
      ...values,
      driverId: Number(values.driverId),
      incidentDate: values.incidentDate.toDate(),
    };

    await createNewExpense(expense);
    closeCallback();
    dispatch(
      showMessage({
        message: 'Gasto creado correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadExpense());
    return dispatch({ type: CREATE_EXPENSE.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_EXPENSE.failure });
  }
};
