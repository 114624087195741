import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  Info,
  X,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from 'src/store/actions/snackBarActions';
import {
  selectSnackbarOptions,
  selectSnackbarState,
} from 'src/store/selectors/snackBarSelectors';

const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: '#FFFFFF',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: '#EEEEEE',
    color: '#000000aa',
  },
  warning: {
    backgroundColor: amber[600],
    color: '#FFFFFF',
  },
  message: {
    marginLeft: theme.spacing(),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  success: <CheckCircle />,
  warning: <AlertTriangle />,
  error: <AlertCircle />,
  info: <Info />,
};

function SnackbarMessage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector(selectSnackbarState);
  const options = useSelector(selectSnackbarOptions);

  options.variant = options.variant || 'info';

  const classes = useStyles();
  const handleOnClose = useCallback(() => {
    dispatch(hideMessage());
  }, [dispatch]);

  const messageComponent = useMemo(() => {
    return (
      <div className={classes.container}>
        {variantIcon[options.variant]}
        <Typography className={classes.message}>
          {options.variant === 'error' &&
            t(`ERRORS.${options.message}`, {
              defaultValue: options.message,
            })}
          {options.variant === 'success' &&
            t(`SUCCESS.${options.message}`, {
              defaultValue: options.message,
            })}
        </Typography>
      </div>
    );
  }, [classes, options]);

  return (
    <Snackbar
      {...options}
      open={state}
      onClose={handleOnClose}
      classes={{
        root: classes.root,
      }}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div',
        },
      }}
    >
      <SnackbarContent
        className={clsx(classes[options.variant])}
        message={messageComponent}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleOnClose}
          >
            <X />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export default React.memo(SnackbarMessage);
