import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import BusinessRow from './BusinessRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const useStyles = makeStyles(() => ({
  tableRoot: {
    border: 'none',
  },
}));

const Tbody = ({ business }) =>
  business.map(b => <BusinessRow key={b.id} {...b} />);

const BusinessTable = ({
  className,
  business,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell>{t('COMMON.ID')}</TableCell>
        <TableCell>{t('BUSINESS.COMMERCIAL_NAME')}</TableCell>
        <TableCell>{t('BUSINESS.TWIST')}</TableCell>
        <TableCell>{t('BUSINESS.RENTED_CAR')}</TableCell>
      </TableRow>
    ),
    [],
  );

  const tbody = useMemo(() => () => <Tbody business={business} />, [business]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, count, handleChangePage],
  );

  return (
    <Table
      {...rest}
      className={classes.tableRoot}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

BusinessTable.propTypes = {
  className: PropTypes.string,
  business: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
};

BusinessTable.defaultProps = {
  count: 0,
};

export default BusinessTable;
