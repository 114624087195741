import { CarSaleReason } from 'src/utils/constants';
import * as Yup from 'yup';

export default Yup.object().shape({
  niv: Yup.string().required('FLEET_VIEW.NIV_REQUIRED'),
  salePrice: Yup.number()
    .min(0, 'FLEET_VIEW.ABOVE_ZERO')
    .required('FLEET_VIEW.SALE_PRICE_REQUIRED'),
  saleReason: Yup.string()
    .oneOf(Object.keys(CarSaleReason), 'FLEET_VIEW.SALE_REASON_REQUIRED')
    .required('FLEET_VIEW.SELECT_IN_LIST'),
  saleInvoicePdfFile: Yup.mixed().required('FLEET_VIEW.SALE_INVOICE_REQUIRED'),
});
