export const selectCampaigns = state => state.campaigns;
export const selectCampaignsList = state => selectCampaigns(state).items;
export const selectCampaignsListCount = state => selectCampaigns(state).count;
export const selectCampaignsQueryOptions = state => {
  const { options } = selectCampaigns(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
