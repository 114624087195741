import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MoneyIcon } from 'src/assets/icons/attach_money.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import { selectTransactionBalance } from 'src/store/selectors/driverSelectors';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { TransactionType } from 'src/utils/constants';
import { TransactionCategory } from 'src/utils/transactions';
import { useTranslation } from 'react-i18next';
import { createDriverTransaction } from 'src/store/actions/driverActions';
import ManualTransactionDialog from './ManualTransactionDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    minWidth: theme.spacing(55),
    justifyContent: 'end',
  },
  balanceContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  balance: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc((100% - 24px) / 2)',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(),
    padding: theme.spacing(4),
  },
  balanceIcon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    background: 'rgba(40,167,69,0.1)',
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
}));

const Toolbar = ({ driver }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const balance = useSelector(selectTransactionBalance);
  const [openTransactionMenu, setOpenTransactionMenu] = useState(false);
  const [transactionType, setTransactionType] = useState('');
  const [transactionCategory, setTransactionCategory] = useState('');

  const handleOpenPayment = useCallback(() => {
    setTransactionType(TransactionType.PAYMENT);
    setOpenTransactionMenu(true);
  }, [setOpenTransactionMenu]);

  const handleOpenCharge = useCallback(() => {
    setTransactionType(TransactionType.CHARGE);
    setOpenTransactionMenu(true);
  }, [setOpenTransactionMenu]);

  const handleOpenPendingPayment = useCallback(() => {
    setTransactionType(TransactionType.PAYMENT);
    setTransactionCategory(TransactionCategory.PENDING_PAYMENT);
    setOpenTransactionMenu(true);
  }, [setOpenTransactionMenu]);

  const handleCloseTransaction = useCallback(() => {
    setTransactionType('');
    setTransactionCategory('');
    setOpenTransactionMenu(false);
  }, [setOpenTransactionMenu]);

  const handleSubmitTransaction = useCallback(
    values => {
      handleCloseTransaction();
      if (values.category === TransactionCategory.PENDING_PAYMENT) {
        const formattedValues = {
          category: values.category,
          reference: values.movement.reference,
          amount: values.movement.amount,
          type: TransactionType.PAYMENT,
        };
        dispatch(createDriverTransaction(driver.id, formattedValues));
      } else {
        dispatch(
          createDriverTransaction(driver.id, {
            type: transactionType,
            category: transactionCategory,
            ...values,
          }),
        );
      }
    },
    [handleCloseTransaction, dispatch, transactionType],
  );

  return (
    <Box className={classes.root}>
      <Grid container className={classes.actionsContainer}>
        <Grid item xs={12} sm={7}>
          <Typography color="primary" component="h4" variant="h4">
            {driver.fullName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.buttonsContainer}>
          <Button
            style={{ marginRight: 10, whiteSpace: 'nowrap' }}
            className={clsx(classes.buttons, classes.horizontalPadding)}
            color="primary"
            variant="contained"
            onClick={handleOpenCharge}
          >
            <PlusIcon style={{ marginRight: 8 }} fill="white" />
            {t('COMMON.MANUAL_CHARGE')}
          </Button>

          <Button
            className={clsx(classes.buttons, classes.horizontalPadding)}
            style={{ marginRight: 10, whiteSpace: 'nowrap' }}
            color="primary"
            variant="contained"
            onClick={handleOpenPayment}
          >
            <PlusIcon style={{ marginRight: 8 }} fill="white" />
            {t('COMMON.MANUAL_PAYMENT')}
          </Button>

          <Button
            style={{ marginRight: 10, whiteSpace: 'nowrap' }}
            className={clsx(classes.buttons, classes.horizontalPadding)}
            color="primary"
            variant="contained"
            onClick={handleOpenPendingPayment}
          >
            <PlusIcon style={{ marginRight: 8 }} fill="white" />
            {t('COMMON.COMPLETE_PAYMENT')}
          </Button>
        </Grid>
      </Grid>

      <Grid container className={classes.balanceContainer}>
        <Grid item className={classes.balance}>
          <Box className={classes.balanceIcon}>
            <MoneyIcon fill="#28A745" />
          </Box>
          <Grid item>
            <Typography
              style={{ marginBottom: 8 }}
              color="textPrimary"
              component="p"
              variant="body2"
            >
              Balance
            </Typography>
            <Typography color="primary" component="h2" variant="h2">
              {`$${formatPositiveAmount(balance.debts)}`}
            </Typography>
          </Grid>
        </Grid>

        <Box />
      </Grid>

      <ManualTransactionDialog
        debts={balance.debts}
        type={transactionType}
        category={transactionCategory}
        open={openTransactionMenu}
        handleClose={handleCloseTransaction}
        submit={handleSubmitTransaction}
      />
    </Box>
  );
};

Toolbar.propTypes = {
  driver: PropTypes.object,
};

export default Toolbar;
