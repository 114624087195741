import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Update from '@material-ui/icons/Update';
import Upload from '@material-ui/icons/CloudUpload';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updatePromissoryNote } from 'src/store/actions/driverActions';
import InvoiceLeaseDialog from './InvoiceLeaseDialog/InvoiceLeaseDialog';

const InvoiceSubMenu = ({ driver }) => {
  const { t } = useTranslation();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [anchorDialog, setAnchorDialog] = useState(null);
  const openDialog = Boolean(anchorDialog);
  const openMenu = Boolean(anchorMenu);
  const dispatch = useDispatch();

  const handleClick = event => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleCloseDialog = () => {
    setAnchorDialog(null);
  };

  const handleOpenDialog = () => {
    setAnchorDialog(true);
    handleClose();
  };

  const handleUpdate = values => {
    dispatch(
      updatePromissoryNote({
        values,
        fileName: driver.actualContract.promissoryNote.physicalSignedPdf,
        driverId: driver.id,
      }),
    );
    handleClose();
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={openMenu}
        anchorEl={anchorMenu}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem onClick={handleOpenDialog}>
          {driver.actualContract.promissoryNote ? (
            <>
              <Update style={{ marginRight: '5px' }} />
              {t('COMMON.UPDATE')}
            </>
          ) : (
            <>
              <Upload style={{ marginRight: '5px' }} />
              {t('COMMON.UPLOAD')}
            </>
          )}
        </MenuItem>
      </Menu>
      <InvoiceLeaseDialog
        handleClose={handleCloseDialog}
        open={openDialog}
        submit={handleUpdate}
      />
    </Box>
  );
};

InvoiceSubMenu.propTypes = {
  driver: PropTypes.object,
};

export default InvoiceSubMenu;
