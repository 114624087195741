import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectTransactionsList,
  selectTransactionsListCount,
  selectTransactionsQueryOptions,
} from 'src/store/selectors/businessSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import * as Actions from 'src/store/actions/businessActions';
import { useTranslation } from 'react-i18next';
import TransactionsTable from './TransactionsTable';
import Toolbar from './Toolbar';
import DeletePaymentConfirmation from './DeletePaymentConfirmation';

const useStyles = makeStyles(() => ({
  preloaderCard: {
    height: '82vh',
  },
}));

const BusinessBalance = ({ business }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactionsList);
  const [open, setOpen] = useState(false);
  const [trId, setTrId] = useState();
  const count = useSelector(selectTransactionsListCount);
  const { page } = useSelector(selectTransactionsQueryOptions);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_TRANSACTIONS_BUSINESS.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_TRANSACTIONS_BUSINESS.action]),
  );

  useEffect(() => {
    dispatch(Actions.loadBusinessTransactions(business.id, { page }));
  }, []); // not add dependency

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(() => {
    dispatch(Actions.deletePayment(trId, business.id));
    handleClose();
  }, [business, trId, dispatch, handleClose]);

  const handlePaymentDelete = useCallback(
    transactionId => {
      handleClickOpen();
      setTrId(transactionId);
    },
    [handleClickOpen, setTrId],
  );

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadBusinessTransactions(business.id, { page: value }));
    },
    [dispatch, business],
  );

  return (
    <Box>
      <Grid container direction="column" alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <Toolbar business={business} />
        </Grid>

        <Grid item>
          {success && !loading && count === 0 ? (
            <Card className={classes.preloaderCard}>
              <NotFoundRecords
                title="No se han encontrado transacciones"
                description="No existen transacciones para la empresa"
              />
            </Card>
          ) : (
            <TransactionsTable
              t={t}
              count={count}
              page={page}
              transactions={transactions}
              handleChangePage={handleChangePage}
              loading={loading}
              handlePaymentDelete={handlePaymentDelete}
            />
          )}
        </Grid>
      </Grid>

      <DeletePaymentConfirmation
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

BusinessBalance.propTypes = {
  business: PropTypes.object,
};

export default BusinessBalance;
