import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import useStyles from './styles';
import SoldCarsRow from './SoldCarsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const Tbody = ({ cars }) =>
  cars.map(car => <SoldCarsRow key={car.niv} {...car} />);

const SoldCarsTable = ({
  className,
  cars,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell>NIV</TableCell>
        <TableCell>{t('FLEET_VIEW.PLATES')}</TableCell>
        <TableCell>{t('COMMON.MODEL')}</TableCell>
        <TableCell>{t('FLEET_MOVEMENTS.SALE_DATE')}</TableCell>
        <TableCell>{t('FLEET_MOVEMENTS.SALE_REASON_TITLE')}</TableCell>
        <TableCell>{t('FLEET_MOVEMENTS.SALE_PRICE')}</TableCell>
      </TableRow>
    ),
    [classes, t],
  );

  const tbody = useMemo(() => () => <Tbody t={t} cars={cars} />, [cars, t]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, cars],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

SoldCarsTable.propTypes = {
  className: PropTypes.string,
  cars: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
};

SoldCarsTable.defaultProps = {
  count: 0,
};

export default SoldCarsTable;
