import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectRentResumeList,
  selectRentResumeCount,
  selectRentResumeOptions,
} from 'src/store/selectors/rentResumeSelectors';
import * as Actions from 'src/store/actions/rentResumeActions';
import RentResumeTable from './RentResumeTable';

const RentResumeList = () => {
  const dispatch = useDispatch();
  const incomes = useSelector(selectRentResumeList);
  const count = useSelector(selectRentResumeCount);
  const { page } = useSelector(selectRentResumeOptions);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_RENT_RESUME.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_RENT_RESUME.action]),
  );

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadRentResume({ page: value }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado registros"
          description="Ningún registro coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <RentResumeTable
      incomes={incomes}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default RentResumeList;
