import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { updateBasicDriverInfoAction } from 'src/store/actions/driverActions';
import useStyles from '../styles';
import DriverMinimumPaymentInfo from './DriverMinimumPayment';
import DriverMinimumPaymentDialog from './DriverMinimumPaymentDialog';

const DriverMinimumPayment = ({ driverId, minimumPaymentAdjustment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateBasicDriverInfoAction(driverId, values));
    },
    [driverId, dispatch],
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('DRIVER_DETAIL_VIEW.MINIMUM_PAYMENT_ADJUSTMENT')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <Box style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <DriverMinimumPaymentInfo
              minimumPaymentAdjustment={minimumPaymentAdjustment}
            />
          </TableBody>
        </Table>
        <Box alignItems="center" display="flex" className={classes.buttons}>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            className={clsx(classes.label, classes.buttons)}
            startIcon={<EditIcon />}
            disabled
          >
            {t('COMMON.EDIT')}
          </Button>
        </Box>
      </Box>
      <DriverMinimumPaymentDialog
        minimumPaymentAdjustment={minimumPaymentAdjustment}
        open={open}
        handleClose={handleClose}
        submit={handleSubmit}
      />
    </Card>
  );
};

DriverMinimumPayment.propTypes = {
  driverId: PropTypes.number,
  minimumPaymentAdjustment: PropTypes.number,
};

export default DriverMinimumPayment;
