import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import {
  selectCarList,
  selectCarListCount,
  selectCarQueryOptions,
} from 'src/store/selectors/carSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadInactiveCars, LOAD_CARS } from 'src/store/actions/carActions';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InactiveCarsTable from './InactiveCarsTable';

const InactiveCarsList = ({
  handleOpenEdit,
  status,
  handleOpenConfirmationModal,
  handleOpenSellModal,
  handleOpenEditModal,
}) => {
  const dispatch = useDispatch();
  const cars = useSelector(selectCarList);
  const count = useSelector(selectCarListCount);
  const { page } = useSelector(selectCarQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_CARS.action]));
  const success = useSelector(successSelector([LOAD_CARS.action]));
  const { t } = useTranslation();

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadInactiveCars({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadInactiveCars({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && cars.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.CAR_LIST_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.CAR_LIST_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <InactiveCarsTable
      cars={cars}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      handleOpenEdit={handleOpenEdit}
      loading={loading}
      status={status}
      handleOpenConfirmationModal={handleOpenConfirmationModal}
      handleOpenSellModal={handleOpenSellModal}
      handleOpenEditModal={handleOpenEditModal}
    />
  );
};

InactiveCarsList.propTypes = {
  handleOpenEdit: PropTypes.func,
  status: PropTypes.string,
  handleOpenConfirmationModal: PropTypes.func,
  handleOpenSellModal: PropTypes.func,
  handleOpenEditModal: PropTypes.func,
};

export default InactiveCarsList;
