import { BillingType } from 'src/utils/constants';
import {
  CLEAR_BILLING,
  CLEAR_BILLING_OPTIONS,
  LOAD_BILLING_PARAMETERS,
  SAVE_BILLING_OPTIONS,
} from '../actions/billingActions';

const initialState = {
  parameters: [],
  options: {
    type: BillingType.CONCEPT,
  },
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BILLING_PARAMETERS.success: {
      return {
        ...state,
        parameters: action.data,
      };
    }
    case SAVE_BILLING_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options },
      };
    }
    case CLEAR_BILLING: {
      return initialState;
    }
    case CLEAR_BILLING_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    default: {
      return state;
    }
  }
};

export default billingReducer;
