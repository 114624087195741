/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TableCell from 'src/components/Table/TableCell';
import { formatPositiveAmount } from 'src/utils/formatAmount';

const HistoryRow = ({
  driverId,
  driverName,
  rent,
  days,
  startDate,
  endDate,
}) => (
  <TableRow hover>
    <TableCell>
      <Link to={`/drivers/detail/${driverId}`}>
        {driverName}
      </Link>
    </TableCell>
    <TableCell>{moment(startDate).format('DD-MMM-YY')}</TableCell>
    <TableCell>{moment(endDate).format('DD-MMM-YY')}</TableCell>
    <TableCell>{days}</TableCell>
    <TableCell>{formatPositiveAmount(rent)}</TableCell>
  </TableRow>
);

export default HistoryRow;
