import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import ExcedentMileagaRow from './ExcedentMileageRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Tbody = ({ cars }) =>
  cars.map(car => <ExcedentMileagaRow key={car.id} {...car} />);

const ExcedentMileageTable = ({
  className,
  cars,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('niv');
  const [order, setOrder] = useState('asc');

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSortChange(property, isAsc ? 'desc' : 'asc');
    },
    [order, orderBy, setOrder, setOrderBy, handleSortChange],
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort],
  );

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell
          variant="head"
          key="niv"
          sortDirection={orderBy === 'niv' ? order : false}
          style={{ maxWidth: '50px' }}
        >
          {t('COMMON.NIV')}
        </TableCell>

        <TableCell
          variant="head"
          key="plates"
          sortDirection={orderBy === 'status' ? order : false}
          style={{ maxWidth: '80px' }}
        >
          {t('GPS_VIEW.PLATES')}
        </TableCell>

        <TableCell variant="head" key="client" style={{ maxWidth: '95px' }}>
          {t('GPS_VIEW.CLIENT')}
        </TableCell>

        <TableCell variant="head" key="device">
          {t('GPS_VIEW.DEVICE')}
        </TableCell>

        <TableCell variant="head" key="device">
          {t('COMMON.MILEAGE')}
        </TableCell>

        <TableCell
          variant="head"
          key="mileageWeek"
          style={{ maxWidth: '125px' }}
        >
          {t('GPS_VIEW.MILEAGE_WEEK')}
        </TableCell>

        <TableCell
          variant="head"
          key="mileageRemaining"
          style={{ maxWidth: '95px' }}
        >
          {t('GPS_VIEW.MILEAGE_REMAINING')}
        </TableCell>

        <TableCell
          variant="head"
          key="mileageExcedent"
          style={{ maxWidth: '95px' }}
        >
          {t('GPS_VIEW.MILEAGE_EXCEDENT')}
        </TableCell>

        <TableCell
          variant="head"
          key="extraCharge"
          style={{ maxWidth: '95px' }}
        >
          {t('GPS_VIEW.EXTRA_CHARGE')}
        </TableCell>
      </TableRow>
    ),
    [classes, order, orderBy, createSortHandler, t],
  );

  const tbody = useMemo(() => () => <Tbody t={t} cars={cars} />, [cars, t]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, cars],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

ExcedentMileageTable.propTypes = {
  className: PropTypes.string,
  cars: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
};

ExcedentMileageTable.defaultProps = {
  count: 0,
};

export default ExcedentMileageTable;
