import * as Yup from 'yup';

export default Yup.object().shape({
  expenseConcept: Yup.string().required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  plate: Yup.string().required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  driverId: Yup.string().required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  provider: Yup.string().required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  amount: Yup.number()
    .moreThan(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_AMOUNT'),
});
