const makeInitialState = aval => {
  return {
    ifeAval: aval.ifeFile
      ? { name: 'Ver actual IFE', link: aval.ifeFile, inDb: true }
      : null,
    waterAval: aval.waterFile
      ? {
          name: 'Ver actual boleta de agua',
          link: aval.waterFile,
          inDb: true,
        }
      : null,
  };
};
export default makeInitialState;
