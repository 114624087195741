/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Field, Formik } from 'formik';
import {
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { InsertInvitation } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import InputText from 'src/components/InputText/InputText';
import validationSchema from './editionSchema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = aval => {
  return {
    name: aval ? aval.name : '',
    paternalName: aval ? aval.paternalName : '',
    maternalName: aval ? aval.maternalName : '',
    relationShip: aval ? aval.relationShip : null,
    mobile: aval ? aval.mobile : null,
    civilStatus: aval ? aval.civilStatus : null,
    age: aval ? aval.age : null,
    email: aval ? aval.email : null,
    economicDependents: aval ? aval.economicDependents : null,
    homeTown: aval ? aval.homeTown : null,
    houseStatus: aval ? aval.houseStatus : null,
    homeYears: aval ? aval.homeYears : null,
    waterName: aval ? aval.waterName : null,
    ifeLicence: aval ? aval.ifeLicence : null,
  };
};

const DatePickerField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      disableFuture
      className={classes.field}
      inputVariant="outlined"
      fullWidth
      margin="normal"
      name={field.name}
      value={field.value}
      format="DD/MM/YYYY"
      variant="inline"
      label="Fecha de Nacimiento"
      helperText={currentError}
      error={Boolean(currentError)}
      onError={error => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={date => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...other}
    />
  );
};

const EditAvalDialog = ({ open, aval, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(aval), [aval]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleConfirmationClose();
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-aval-driver"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          Datos de aval
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    Al hacer click en&nbsp;
                    <b>Aceptar</b>, acepta que los datos actuales del aval
                    serán&nbsp;
                    <b>reemplazados.</b>
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.name && errors.name)}
                >
                  <InputText
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && t(errors.name)}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.paternalName && errors.paternalName)}
                >
                  <InputText
                    error={Boolean(touched.paternalName && errors.paternalName)}
                    fullWidth
                    helperText={touched.paternalName && t(errors.paternalName)}
                    label="Nombre Paterno"
                    name="paternalName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.paternalName}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.maternalName && errors.maternalName)}
                >
                  <InputText
                    error={Boolean(touched.maternalName && errors.maternalName)}
                    fullWidth
                    helperText={touched.maternalName && t(errors.maternalName)}
                    label="Nombre Materno"
                    name="maternalName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.maternalName}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.relationShip && errors.relationShip)}
                >
                  <InputLabel id="relationShip-select-label">
                    Relación
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="relationShip-select-label"
                    label="Relación"
                    id="relationShip"
                    value={values.relationShip}
                    onChange={handleChange}
                    name="relationShip"
                  >
                    <MenuItem value="Esposa">Esposa</MenuItem>
                    <MenuItem value="Hijo">Hijo</MenuItem>
                    <MenuItem value="Padre">Padre</MenuItem>
                    <MenuItem value="Madre">Madre</MenuItem>
                  </Select>
                  {Boolean(touched.relationShip && errors.relationShip) && (
                    <FormHelperText>{t(errors.relationShip)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.mobile && errors.mobile)}
                >
                  <InputText
                    error={Boolean(touched.mobile && errors.mobile)}
                    fullWidth
                    helperText={touched.mobile && t(errors.mobile)}
                    label="Celular"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.mobile}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                >
                  <InputText
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && t(errors.email)}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />
                </FormControl>

                <Field name="age" component={DatePickerField} />

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.homeTown && errors.homeTown)}
                >
                  <InputLabel id="homeTown-select-label">
                    Estado de nacimiento
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    margin="normal"
                    labelId="homeTown-label"
                    label="Estado de nacimiento"
                    id="homeTown"
                    value={values.homeTown}
                    onChange={handleChange}
                    name="homeTown"
                  >
                    <MenuItem value="Ciudad de México">México</MenuItem>
                    <MenuItem value="Nuevo León">Nuevo León</MenuItem>
                    <MenuItem value="Aguascalientes">Aguascalientes</MenuItem>
                  </Select>
                  {Boolean(touched.homeTown && errors.homeTown) && (
                    <FormHelperText>{t(errors.homeTown)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  variant="outlined"
                  margin='normal'
                  fullWidth
                  error={Boolean(touched.houseStatus && errors.houseStatus)}
                >
                  <InputLabel id="houseStatus-select-label">
                    Estado de casa
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="houseStatus-select-label"
                    label="Estado de casa"
                    id="houseStatus"
                    value={values.houseStatus}
                    onChange={handleChange}
                    name="houseStatus"
                  >
                    <MenuItem value="Propia">Propia</MenuItem>
                    <MenuItem value="Financiada">Financiada</MenuItem>
                    <MenuItem value="Rentada">Rentada</MenuItem>
                    <MenuItem value="De Familiar">De Familiar</MenuItem>
                  </Select>
                  {Boolean(touched.houseStatus && errors.houseStatus) && (
                    <FormHelperText>{t(errors.houseStatus)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.ifeLicence && errors.ifeLicence)}
                >
                  <InputText
                    error={Boolean(touched.ifeLicence && errors.ifeLicence)}
                    fullWidth
                    helperText={touched.ifeLicence && t(errors.ifeLicence)}
                    label="Direcciones registradas"
                    name="ifeLicence"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.ifeLicence}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    variant="outlined"
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      Editar Aval
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditAvalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  aval: PropTypes.object,
};

export default EditAvalDialog;
