import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationMessage = ({ open, handleClose, text, handleAccept }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      aria-labelledby="form-message-dialog"
      classes={{ paper: classes.root }}
      open={open}
      scroll="body"
      TransitionComponent={Transition}
      onClose={handleClose}
      keepMounted
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent className={classes.content}>
        <Typography variant="h2">{text}</Typography>
        <Box className={classes.buttonGroup}>
          <Button
            variant="contained"
            onClick={handleClose}
            className={clsx([classes.button, classes.cancelButton])}
          >
            {t('CONFIRMATION_MODAL.CANCEL')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAccept}
            className={clsx(classes.button)}
          >
            {t('CONFIRMATION_MODAL.ACCEPT')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationMessage.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  text: PropTypes.string,
  handleAccept: PropTypes.func,
};

export default ConfirmationMessage;
