import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectIncomeResumeList,
  selectIncomeResumeCount,
  selectIncomeResumeOptions,
} from 'src/store/selectors/incomeResumeSelectors';
import * as Actions from 'src/store/actions/incomeResumeActions';
import IncomeResumeTable from './IncomeResumeTable';

const IncomeResumeList = () => {
  const dispatch = useDispatch();
  const count = useSelector(selectIncomeResumeCount);
  const incomeResumes = useSelector(selectIncomeResumeList);
  const { page, rangeType } = useSelector(selectIncomeResumeOptions);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_INCOME_RESUME.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_INCOME_RESUME.action]),
  );

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadIncomeResume({ page: value, rangeType }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado registros"
          description="Un registro nuevo se generará al inicio de cada semana"
        />
      </Card>
    );
  }

  return (
    <IncomeResumeTable
      incomeResumes={incomeResumes}
      loading={loading}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      rangeType={rangeType}
    />
  );
};

export default IncomeResumeList;
