import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { ParameterType } from 'src/utils/constants';
import DropdownMenu from 'src/components/DropdownMenu';
import validationSchema from './schema';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    '& h2': {
      fontWeight: 500,
      fontSize: theme.spacing(2.5),
      lineHeight: `${theme.spacing(3)}px`,
    },
  },
  root: {
    minWidth: theme.spacing(68),
  },
  box: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`,
  },
  buttons: {
    width: '45%',
  },
}));

const makeInitialState = data => ({
  id: data ? data.id : '',
  name: data ? data.name : '',
  value: data ? data.value : '',
});

const ParameterForm = ({ open, data, handleClose, handleEdit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();

  const handleOuterSubmit = useMemo(() => handleEdit, [handleEdit]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-test-title">
          {t('PARAMETERS_VIEW.UPDATE_PARAMETER_TITLE')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue,
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t('PARAMETERS_VIEW.NAME')}
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                    disabled
                  />

                  {data &&
                    (data.type === ParameterType.TEXT ||
                      data.type === ParameterType.NUMBER) && (
                      <TextField
                        error={Boolean(touched.value && errors.value)}
                        fullWidth
                        helperText={touched.value && errors.value}
                        label={t('PARAMETERS_VIEW.VALUE')}
                        margin="normal"
                        name="value"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={data && data.type.toLowerCase()}
                        value={values.value}
                        variant="outlined"
                      />
                    )}

                  {data && data.type === ParameterType.BOOLEAN && (
                    <DropdownMenu
                      list={[
                        { key: 'true', name: t('COMMON.YES') },
                        { key: 'false', name: t('COMMON.NO') },
                      ]}
                      value={values.value}
                      setValue={value => setFieldValue('value', value)}
                      label={t('PARAMETERS_VIEW.VALUE')}
                      error={Boolean(touched.value && errors.value)}
                      errorText={touched.value && t(errors.value)}
                    />
                  )}

                  <Box
                    className={classes.box}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      className={classes.buttons}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('PARAMETERS_VIEW.CANCEL')}
                    </Button>
                    <Button
                      className={classes.buttons}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('PARAMETERS_VIEW.UPDATE')}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ParameterForm.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ParameterForm;
