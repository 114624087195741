import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  clearRentResume,
  downloadExcelRent,
  loadRentResume,
} from 'src/store/actions/rentResumeActions';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectRentResumeOptions } from 'src/store/selectors/rentResumeSelectors';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { useTranslation } from 'react-i18next';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import DateRange from 'src/utils/DateRangeType';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '70vh',
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    width: '100%',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  currentDate: {
    fontWeight: '400',
    display: 'inline',
    marginLeft: '5px',
  },
}));

const filters = [
  {
    title: 'INCOME_VIEW.BY_DEBT',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'INCOME_VIEW.DRIVERS_TO_PAY',
        value: 'filterByDebt',
      },
    ],
  },
  {
    title: 'COMMON.ASSIGNAMENT_STATUS',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'isAssigned',
    items: [
      {
        label: 'COMMON.ASSIGNED',
        value: 'true',
      },
      {
        label: 'COMMON.NOT_ASSIGNED',
        value: 'false',
      },
      {
        label: 'INCOME_VIEW.ALL',
        value: 'null',
      },
    ],
  },
];

const selectTimePeriodLabel = timePeriod => {
  switch (timePeriod) {
    case DateRange.WEEKLY:
      return moment()
        .startOf('isoWeek')
        .format('DD [de] MMMM');
    case DateRange.MONTHLY: {
      const date = moment().format('MMMM YYYY');
      return date[0].toUpperCase() + date.slice(1, date.length);
    }
    case DateRange.QUARTERLY: {
      const startQuarter = moment()
        .startOf('quarter')
        .format('MMMM YYYY');
      const endQuarter = moment()
        .endOf('quarter')
        .format('MMMM YYYY');
      return `${startQuarter[0].toUpperCase() +
        startQuarter.slice(
          1,
          startQuarter.length,
        )} - ${endQuarter[0].toUpperCase()}${endQuarter.slice(
        1,
        endQuarter.length,
      )}`;
    }
    case DateRange.YEARLY: {
      return moment().format('YYYY');
    }
    default:
      return '';
  }
};

const Toolbar = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectRentResumeOptions);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    filterByDebt: true,
    isAssigned: 'true',
  });

  const debounceSearch = useDebounce(
    (searchCriteria, queryOpts) =>
      dispatch(
        loadRentResume({
          ...queryOpts,
          searchCriteria,
          page: 0,
        }),
      ),
    400,
  );

  useEffect(() => {
    dispatch(
      loadRentResume({ filterByDebt: true, timePeriod: DateRange.WEEKLY }),
    );
    return () => dispatch(clearRentResume());
  }, []);

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelRent());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        filterByDebt: event.filterByDebt,
        isAssigned: event.isAssigned,
      });
      handleCloseFilter();
    },
    [setFilterValues, searchValue, debounceSearch, queryOptions],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <>
      <div className={classes.root} {...props}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.INCOME']}
          current="NAVIGATION.RENT"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {`${t('NAVIGATION.RENT')}`}
            <Typography
              className={classes.currentDate}
              color="primary"
              variant="h4"
            >
              - 
              {' '}
              {selectTimePeriodLabel(queryOptions.timePeriod)}
            </Typography>
          </Typography>

          <div className={classes.actionsContainer}>
            <Paper
              elevation={0}
              className={classes.search}
              style={{ marginLeft: 0 }}
            >
              <Input
                className={classes.input}
                startAdornment={Addornment}
                placeholder={t('DRIVERS_VIEW.SEARCH_CLIENT')}
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                type="text"
                onChange={handleSearch}
              />
            </Paper>

            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />

            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>

            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
