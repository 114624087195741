import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Map } from '@material-ui/icons';
import clsx from 'clsx';
import { ReactComponent as EngineCarOff } from 'src/assets/icons/engineCarOff.svg';
import { ReactComponent as EngineCar } from 'src/assets/icons/engineCar.svg';
import { useTranslation } from 'react-i18next';
import StatusIcon from 'src/components/StatusIcon';
import { IconStatusAndColor } from 'src/utils/constants';
import { formatTransmissionTime, GpsTransmissionStatus } from 'src/utils/gps';
import Tooltip from 'src/components/Tooltip';
import EngineControllerModal from './EngineControllerDialog';
import useStyles from './styles';
import DeviceMapLocation from './DeviceMapLocation';

const GpsDeviceDetail = ({ device, deviceNumber }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openEngineModal, setOpenEngineModal] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClickOpenEngineModal = useCallback(() => {
    setOpenEngineModal(true);
  }, [setOpenEngineModal]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCloseEngineModal = useCallback(() => {
    setOpenEngineModal(false);
  }, [setOpenEngineModal]);

  const getTransmissionStatus = transmissionStatus => {
    if (transmissionStatus === GpsTransmissionStatus.ONLINE) {
      return {
        description: 'COMMON.ONLINE',
        type: IconStatusAndColor.OK.status,
      };
    }

    if (transmissionStatus === GpsTransmissionStatus.OFFLINE) {
      return {
        description: 'COMMON.OFFLINE',
        type: IconStatusAndColor.ERROR.status,
      };
    }

    return {
      description: 'COMMON.NO_DATA',
      type: IconStatusAndColor.NONE.status,
    };
  };

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography component="h5" variant="h5">
            {`${t('CAR_DETAIL.GPS.DEVICE_GPS')} ${deviceNumber}`}
          </Typography>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('CAR_DETAIL.GPS.BRAND_AND_MODEL')}
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  {device.deviceModel}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('CAR_DETAIL.GPS.PURCHASE_DATE')}
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  {device.createdAt}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('COMMON.IMEI')}
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  {device.imei}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('COMMON.SIM')}
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  {device.simNumber}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('CAR_DETAIL.GPS.DEVICE_ID')}
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  {device.id}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('CAR_DETAIL.GPS.TIME_OF_THE_LAST_TRANSMISSION')}
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  {formatTransmissionTime(device.timeSinceLastTransmission)}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('CAR_DETAIL.GPS.TRANSMISSION_STATUS')}
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  <StatusIcon {...getTransmissionStatus(device.status)} />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: 500 }}>
                {t('CAR_DETAIL.GPS.CAR_ENGINE')}
              </TableCell>
              <TableCell>
                <Tooltip
                  condition
                  text={`${
                    device.isEngineOn
                      ? t('COMMON.BLOCK_ENGINE')
                      : t('COMMON.UNBLOCK_ENGINE')
                  }`}
                >
                  <Button
                    variant="contained"
                    endIcon={
                      device.isEngineOn ? <EngineCarOff /> : <EngineCar />
                    }
                    onClick={() => handleClickOpenEngineModal()}
                  >
                    {device.isEngineOn
                      ? t('COMMON.BLOCK_ENGINE')
                      : t('COMMON.UNBLOCK_ENGINE')}
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={clsx(classes.label, classes.buttons)}
          startIcon={<Map />}
          onClick={() => handleClickOpen()}
        >
          {t('GPS_VIEW.VEHICLE_LOCATION')}
        </Button>
      </CardContent>

      <EngineControllerModal
        open={openEngineModal}
        gps={device}
        handleClose={handleCloseEngineModal}
      />

      <DeviceMapLocation
        open={open}
        coor={[device.latitude || '', device.longitude || '']}
        handleClose={handleClose}
      />
    </Card>
  );
};

GpsDeviceDetail.propTypes = {
  device: PropTypes.object,
  deviceNumber: PropTypes.number.isRequired,
};

export default GpsDeviceDetail;
