import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IncidentRow from './IncidentRow';

const IncidentTable = ({
  className,
  incidents,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const openEdit = useCallback(
    incident => () => {
      handleOpenEdit(incident);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Nombre</TableCell>
      <TableCell>Acciones</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return incidents.map(incident => (
        <IncidentRow
          {...incident}
          openEdit={openEdit(incident)}
          handleDelete={handleDelete}
          key={incident.id}
        />
      ));
    },
    [incidents],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

IncidentTable.propTypes = {
  className: PropTypes.string,
  incidents: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default IncidentTable;
