import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch } from 'react-redux';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { useTranslation } from 'react-i18next';
import {
  clearRentFactor,
  downloadExcelFactor,
  loadRentFactor,
} from 'src/store/actions/rentFactorActions';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { CarModeQuery, Periods } from 'src/utils/constants';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const filters = [
  {
    title: 'COMMON.BY_PERIOD',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'timePeriod',
    items: [
      {
        label: 'COMMON.WEEKLY',
        value: Periods.WEEKLY,
      },
      {
        label: 'COMMON.MONTHLY',
        value: Periods.MONTHLY,
      },
      {
        label: 'COMMON.QUARTERLY',
        value: Periods.QUARTERLY,
      },
      {
        label: 'COMMON.YEARLY',
        value: Periods.YEARLY,
      },
    ],
  },
  {
    title: 'FLEET_VIEW.MODE',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'carMode',
    items: [
      {
        label: 'COMMON.ALL',
        value: CarModeQuery.ALL,
      },
      {
        label: 'FLEET_VIEW.LEASE',
        value: CarModeQuery.LEASE,
      },
      {
        label: 'FLEET_VIEW.PLATFORM',
        value: CarModeQuery.PLATFORM,
      },
    ],
  },
];

const getFilterOptions = options => {
  const queryOptions = {};

  if (options.timePeriod) {
    queryOptions.timePeriod = options.timePeriod;
  }
  if (options.carMode) {
    queryOptions.carMode = options.carMode;
  }

  return queryOptions;
};

const Toolbar = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filterValues, setFilterValues] = useState({
    timePeriod: Periods.WEEKLY,
    carMode: CarModeQuery.ALL,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(loadRentFactor());
    return () => dispatch(clearRentFactor());
  }, []);

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelFactor());
  }, [dispatch]);

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleClickOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleSubmitFilter = queryOpts => {
    dispatch(loadRentFactor(queryOpts));
    handleCloseFilter();
  };

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      handleSubmitFilter({
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [handleSubmitFilter],
  );

  return (
    <>
      <div className={classes.root} {...props}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.INCOME']}
          current="NAVIGATION.RENT_FACTOR"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.RENT_FACTOR')}
          </Typography>
          <Filter
            anchorEl={anchorEl}
            filterValues={filterValues}
            handleClose={handleCloseFilter}
            handleChange={handleFilterChange}
            filters={filters}
          />
          <div className={classes.actionsContainer}>
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleClickOpenFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
