export const selectParameter = state => state.parameter;
export const selectParameterList = state => selectParameter(state).items;
export const selectParameterQueryOptions = state => {
  const { options } = selectParameter(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
