// eslint-disable-next-line import/prefer-default-export
export const GpsTransmissionStatus = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  NO_DATA: 'NO_DATA',
};

export const formatTransmissionTime = minutesSinceLastTransmission => {
  if (minutesSinceLastTransmission === null) {
    return '-';
  }
  const hours = Math.floor(minutesSinceLastTransmission / 60);
  const minutes = minutesSinceLastTransmission % 60;
  if (hours === 0) {
    return `${minutes} min`;
  }
  return `${hours} h: ${minutes} min`;
};
