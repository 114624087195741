import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CLEAR_CARS, loadCars } from 'src/store/actions/carActions';
import { selectCarList } from 'src/store/selectors/carSelectors';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepTwo = ({ data, handleBack, handleNext }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nivList = useSelector(selectCarList);
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  useEffect(() => {
    dispatch(loadCars({}, false));
    return () => dispatch({ type: CLEAR_CARS });
  }, []);

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext],
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,

        touched,
        values,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.niv && errors.niv)}
            >
              <Autocomplete
                id="niv"
                options={nivList}
                loading={nivList.length === 0}
                getOptionLabel={option => option.niv}
                getOptionSelected={(option, value) => option.niv === value.niv}
                inputValue={values.niv || ''}
                onInputChange={(event, value) => {
                  if (event) {
                    setFieldValue('niv', value);
                  }
                }}
                clearOnBlur={false}
                onOpen={handleBlur}
                onChange={(_e, value) => {
                  if (value) {
                    setFieldValue('niv', value.niv);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('GPS_VIEW.NIV_ASIGNATION')}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              {Boolean(touched.niv && errors.niv) && (
                <FormHelperText>{t(errors.niv)}</FormHelperText>
              )}
            </FormControl>

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};
export default StepTwo;
