import React from 'react';
import {
  Grid,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  valueClass: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    marginRight: 1,
  },
}));

const DetailRow = ({ name, value, children }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item className={classes.title}>
            {t(`${name}`)}
          </Grid>
          <Grid item className={classes.gridItem}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.valueClass}
            >
              {value}
            </Typography>
            {children}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

DetailRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

export default DetailRow;
