import moment from 'moment';

const makeInitialValues = value => ({
  recurrent: value ? value.recurrent ?? '' : '',
  discountDays: value ? value.discountDays : '',
  minDriverLevel: value ? value.minDriverLevel : '',
  description: value ? value.description : '',
  discountDate: value ? moment(value.discountDate).toDate() : moment().toDate(),
  active: value ? value.active ?? null : null,
  type: value ? value.type : null,
  id: value ? value.id : null,
});

export default makeInitialValues;
