import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '40vw',
      height: '50vh',
      maxHeight: '70vh',
    },
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    fontWeight: 500,
  },
  loadingBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButtom: {
    float: 'right',
    width: '20px',
    height: '20px',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  tableContainer: {
    maxHeight: '90%',
  },
  dialogBodyContainer: {
    width: '100%',
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400,
    },
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%',
  },
}));
