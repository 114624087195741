import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { assignDriver, unassignDriver } from 'src/store/actions/driverActions';
import assignSchema from './assignSchema';
import unassignSchema from './unassignSchema';
import AssignForm from './AssignForm';
import UnassignForm from './UnassignForm';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = (niv, assigned, carMode) => {
  if (assigned) {
    return {
      contractEndDate: moment(),
      unassignType: '',
      newNiv: '',
      newEndDate: moment(),
      comment: '',
      endDate: moment(),
    };
  }

  return {
    niv,
    startDate: moment(),
    contractEndDate: moment(),
    driverId: '',
    // contrato de arrendamiento
    hitch: 0,
    optionToBuy: true,
    rentCost: 0,
    insuranceCost: 0,
    duration: '',
    costPerWeek: 0,
    carMode,
  };
};

// TODO:add if is business mode to fetch only business drivers
const AssignDriverDialog = ({
  open,
  assigned,
  niv,
  isLeaseMode,
  isBusinessCar,
  handleClose,
  submit,
  actionUpdate,
  carMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const driverOrBusiness = useMemo(
    () => (isBusinessCar ? 'empresa' : 'conductor'),
    [isBusinessCar],
  );
  const initialState = useMemo(() => makeInitialState(niv, assigned, carMode), [
    niv,
    assigned,
    carMode,
  ]);
  const currentSchema = useMemo(
    () => (assigned ? unassignSchema : assignSchema),
    [assigned],
  );
  const resetRef = useRef();

  const onSubmit = useMemo(() => {
    return submit(assigned ? unassignDriver(actionUpdate) : assignDriver);
  }, [assigned, submit]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const cleanValues = assigned
        ? { ...values, contractEndDate: values.contractEndDate.toDate() }
        : { ...values, startDate: values.startDate.toDate() };
      onSubmit(cleanValues);
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      keepMounted
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-assign-driver"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {assigned
            ? `${t('FLEET_VIEW.UNASSIGN')} ${driverOrBusiness}`
            : `${t('FLEET_VIEW.ASSIGN')} ${driverOrBusiness}`}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={currentSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            return assigned ? (
              <UnassignForm
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                setFieldValues={setFieldValue}
              />
            ) : (
              <AssignForm
                open={open}
                values={values}
                isLeaseMode={isLeaseMode}
                isBusinessCar={isBusinessCar}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                handleReset={() => resetForm(initialState)}
                setFieldValues={setFieldValue}
                carMode={carMode}
              />
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AssignDriverDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  assigned: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  niv: PropTypes.string.isRequired,
  isLeaseMode: PropTypes.bool,
  isBusinessCar: PropTypes.bool,
  actionUpdate: PropTypes.func,
  carMode: PropTypes.string,
};

export default AssignDriverDialog;
