import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  downloadExportedCollections,
  getCollectionsList,
  unSubscribeDriverForUnrecovarableDebt,
} from 'src/services/collectionsService';
import { showMessage } from './snackBarActions';
import { selectCollectionsOptions } from '../selectors/collectionsSelector';

export const LOAD_COLLECTIONS = asyncActionCreator('LOAD_COLLECTIONS');
export const CLEAR_COLLECTIONS = 'CLEAR_COLLECTIONS';
export const SAVE_COLLECTIONS_OPTIONS = 'SAVE_COLLECTIONS_OPTIONS';
export const CLEAR_COLLECTIONS_OPTIONS = 'CLEAR_COLLECTIONS_OPTIONS';
export const UNSUBSCRIBE_DRIVER = asyncActionCreator('UNSUBSCRIBE_DRIVER');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectCollectionsOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.driverName) {
    reqOptions.driverName = queryOpts.driverName;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }

  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }

  if (queryOpts.timePeriod) {
    reqOptions.timePeriod = queryOpts.timePeriod;
  }

  return reqOptions;
}

export const loadCollections = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_COLLECTIONS.start });

    dispatch({
      type: SAVE_COLLECTIONS_OPTIONS,
      options,
    });

    const reqOptions = getOptions(getState);
    const data = await getCollectionsList(reqOptions);

    return dispatch({
      type: LOAD_COLLECTIONS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_COLLECTIONS.failure });
  }
};

export const clearCollections = () => dispatch => {
  dispatch({ type: CLEAR_COLLECTIONS });
};

export const clearCollectionsOptions = () => dispatch => {
  dispatch({ type: CLEAR_COLLECTIONS_OPTIONS });
};

export const downloadExcelCollections = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    return downloadExportedCollections(options);
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const unrecovarableDebtAction = (driverId, handleClose) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: UNSUBSCRIBE_DRIVER.start });
    await unSubscribeDriverForUnrecovarableDebt(driverId);

    dispatch(
      showMessage({
        message: 'El conductor se dió de baja del sistema existosamente.',
        variant: 'success',
      }),
    );

    handleClose();

    const reqOptions = getOptions(getState);

    dispatch(loadCollections(reqOptions));
    return dispatch({
      type: UNSUBSCRIBE_DRIVER.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UNSUBSCRIBE_DRIVER.failure });
  }
};
