/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import InfoCard from 'src/components/InfoCard';
import IncidentHistoryTable from './IncidentHistoryTable';

const useStyles = makeStyles(theme => ({
  table: {
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
}));

const DriverIncidents = ({ driverName, incidents }) => {
  const classes = useStyles();
  const fields = [
    {
      name: 'DRIVER_DETAIL_VIEW.INCIDENTS.AMOUNT',
      value: incidents.amount,
    },
    {
      name: 'DRIVER_DETAIL_VIEW.INCIDENTS.YEARS',
      value: incidents.years,
    },
    {
      name: 'DRIVER_DETAIL_VIEW.INCIDENTS.AVERAGE_INCIDENTS',
      value: incidents.averageIncidentsYear,
    },
    {
      name: 'DRIVER_DETAIL_VIEW.INCIDENTS.LAST_NINETY_DAYS',
      value: incidents.incidentsNinety,
    },
  ];

  return (
    <Box mt={3}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7} lg={3}>
          <InfoCard title={driverName} fields={fields} />
        </Grid>
        <Grid item xs={12} lg={9}>
          <IncidentHistoryTable
            className={classes.table}
            history={incidents.items}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DriverIncidents;
