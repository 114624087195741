/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FadeIn from 'react-fade-in';
import Preloader from 'src/components/Preloader';
import clsx from 'clsx';
import useStyles from '../styles';
import ReviewExpense from './ReviewExpense';

const StepTwo = ({ data, handleSubmit, handleBack, loading }) => {
  const classes = useStyles();

  const content = useMemo(() => {
    return !loading ? <ReviewExpense values={data} /> : <Preloader />;
  }, [loading]);

  return (
    <FadeIn>
      <form onSubmit={handleSubmit}>
        {content}

        <Box
          className={classes.tableBox}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            className={classes.buttons}
            onClick={handleBack}
            color="primary"
            variant="outlined"
          >
            Volver
          </Button>
          <Button
            className={clsx(classes.buttons, classes.buttonMain)}
            type="submit"
            color="primary"
            variant="outlined"
          >
            Crear gasto
          </Button>
        </Box>
      </form>
    </FadeIn>
  );
};

export default StepTwo;
