import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpenseTypesRow from './ExpenseTypesRow';

const ExpenseTypesTable = ({
  className,
  expenseTypes,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const openEdit = useCallback(
    expenseType => () => {
      handleOpenEdit(expenseType);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Nombre</TableCell>
      <TableCell>Acciones</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return expenseTypes.map(expenseType => (
        <ExpenseTypesRow
          {...expenseType}
          openEdit={openEdit(expenseType)}
          handleDelete={handleDelete}
          key={expenseType.id}
        />
      ));
    },
    [expenseTypes],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

ExpenseTypesTable.propTypes = {
  className: PropTypes.string,
  expenseTypes: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ExpenseTypesTable;
