import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  Box,
} from '@material-ui/core';
import { Map, TileLayer, Popup, Marker } from 'react-leaflet';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceMapLocationDialog = ({ open, coor, handleClose }) => {
  const classes = useStyles();
  const resetRef = useRef();

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent style={{ marginBottom: '16px', width: '600px' }}>
        <Box
          container
          direction="column"
          alignItems="stretch"
          alignContent="flex-end"
          position="absolute"
          top="25px"
          right="25px"
          zIndex="40001"
        >
          <IconButton
            aria-label="delete"
            size="large"
            classname={clsx(classes.label, classes.closeButton)}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>

        <Map
          center={coor}
          zoom={22}
          scrollWheelZoom={false}
          className={clsx(classes.map)}
        >
          <TileLayer
            attribution="&copy; <a>Meteoros</a> "
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          <Marker position={coor}>
            <Popup>
              <span>Usted esta aquí</span>
            </Popup>
          </Marker>
        </Map>
      </DialogContent>
    </Dialog>
  );
};

DeviceMapLocationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  coor: PropTypes.object,
};

export default DeviceMapLocationDialog;
