import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const policyTypes = [
  { text: 'INSURANCE_VIEW.SHOW_ALL', value: '' },
  { text: 'INSURANCE_VIEW.CHOFER', value: 'Chofer' },
  { text: 'INSURANCE_VIEW.REGULAR', value: 'Regular' },
];

const remainingDays = [
  { text: 'INSURANCE_VIEW.SHOW_ALL', value: '' },
  { text: 'INSURANCE_VIEW.REMAINING_DAYS_FILTER', value: 30 },
];

const getOptionsForCriteria = criteria => {
  switch (criteria) {
    case 'policyType':
      return policyTypes;
    case 'remainingDays':
      return remainingDays;
    default:
      return [];
  }
};

const getLabelForOption = (options, option) => {
  const selectedOption = options.find(opt => opt.value === option);
  return selectedOption ? selectedOption.text : '';
};

// eslint-disable-next-line react/prop-types
const InsuranceAutocomplete = ({ t, criteria, handleSearch, options }) => {
  const autoOptions = useMemo(() => getOptionsForCriteria(criteria), [
    criteria,
  ]);
  const label = useMemo(() => getLabelForOption(options, criteria), [
    options,
    criteria,
  ]);

  return (
    <Autocomplete
      options={autoOptions}
      getOptionLabel={option => t(option.text)}
      style={{ width: '50%', marginRight: '8px' }}
      defaultValue={autoOptions[0]}
      disableClearable
      renderInput={params => (
        <TextField {...params} className="w-full my-auto" label={t(label)} />
      )}
      onChange={(ev, { value }) => handleSearch({ target: { value } })}
    />
  );
};

export default InsuranceAutocomplete;
