import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';

import selectFormatDate from 'src/utils/selectDateFormat';
import { DateTypeFormat } from 'src/utils/constants';
import { useDispatch } from 'react-redux';
import { downloadFinesReport } from 'src/store/actions/fineActions';
import { IconButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const FinesReportRow = ({
  date,
  duration,
  consultedMunicipalities,
  consultedPlates,
  newFines,
  paidFines,
  failedPlates,
  failedMunicipalities,
  id,
  objectKey,
}) => {
  const dispatch = useDispatch();

  const handleDownloadReport = useCallback(() => {
    dispatch(downloadFinesReport({ id, objectKey }));
  }, [dispatch]);

  return (
    <TableRow hover>
      <TableCell style={{ width: '8%' }}>
        {selectFormatDate(date, DateTypeFormat.DAY)}
      </TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>{consultedMunicipalities}</TableCell>
      <TableCell>{consultedPlates}</TableCell>
      <TableCell>{newFines}</TableCell>
      <TableCell>{paidFines}</TableCell>
      <TableCell>{failedPlates}</TableCell>
      <TableCell>{failedMunicipalities}</TableCell>
      <TableCell>
        <IconButton aria-label="download Report" onClick={handleDownloadReport}>
          <DownloadIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

FinesReportRow.propTypes = {
  date: PropTypes.string,
  duration: PropTypes.string,
  consultedMunicipalities: PropTypes.string,
  consultedPlates: PropTypes.string,
  newFines: PropTypes.string,
  paidFines: PropTypes.string,
  failedPlates: PropTypes.string,
  failedMunicipalities: PropTypes.string,
  id: PropTypes.number,
  objectKey: PropTypes.string,
};

export default FinesReportRow;
