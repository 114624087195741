import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DropdownMenu from 'src/components/DropdownMenu';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { loadDeviceList } from 'src/store/actions/catalogActions';
import useStyles from '../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const StepOne = ({ data, handleBack, handleNext }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const devices = useSelector(selectCatalogList);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDeviceList());
  }, []);

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext],
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DropdownMenu
              list={devices.map(device => ({
                name: `${device.brand} ${device.model} - ${device.type}`,
                key: device,
              }))}
              value={values.gpsCatalog}
              setValue={value => setFieldValue('gpsCatalog', value)}
              label={t('GPS_VIEW.DEVICE_AVAILABLE')}
              error={Boolean(touched.gpsCatalog && errors.gpsCatalog)}
              errorText={touched.gpsCatalog && t(errors.gpsCatalog)}
            />

            <TextField
              error={Boolean(touched.imei && errors.imei)}
              fullWidth
              helperText={touched.imei && t(errors.imei)}
              label={t('COMMON.IMEI')}
              margin="normal"
              name="imei"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.imei}
              variant="outlined"
            />

            <TextField
              error={Boolean(touched.simNumber && errors.simNumber)}
              fullWidth
              helperText={touched.simNumber && t(errors.simNumber)}
              label={t('COMMON.SIM_NUMBER')}
              margin="normal"
              name="simNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.simNumber}
              variant="outlined"
            />

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export default StepOne;
