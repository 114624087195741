/* eslint-disable react/prop-types */
import React from 'react';
import { IconButton, TableRow } from '@material-ui/core';

import TableCell from 'src/components/Table/TableCell';
import { useDispatch } from 'react-redux';
import { removeGpsDevice } from 'src/store/actions/gpsActions';
import { Link } from 'react-router-dom';
import { Delete } from '@material-ui/icons';

const GpsRow = ({
  id,
  gpsWoxId,
  name,
  imei,
  simNumber,
  model,
  createdAt,
  niv,
}) => {
  const dispatch = useDispatch();

  const handleDeleteGpsDevice = () => {
    dispatch(removeGpsDevice(id));
  };

  return (
    <TableRow hover>
      <TableCell>{gpsWoxId}</TableCell>
      <TableCell>
        <Link style={{ color: 'blue' }} to={`/fleet/car/${niv}`}>
          {niv}
        </Link>
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{model}</TableCell>
      <TableCell>{simNumber}</TableCell>
      <TableCell>{imei}</TableCell>
      <TableCell>{createdAt}</TableCell>
      <TableCell key="options">
        <IconButton
          aria-label="Delete"
          onClick={handleDeleteGpsDevice}
          style={{ padding: '0' }}
        >
          <Delete color="secondary" />
        </IconButton>
      </TableCell>
      {/* TODO: Edit gps device and unassign from car */}
      {/* <GpsDeviceUpdateDialog
        open={open}
        gps={{ name, model, imei, simNumber, deviceType, id, niv }}
        handleClose={handleClose}
        submit={handleUpdateSubmit}
      /> */}
    </TableRow>
  );
};

export default GpsRow;
