import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import CarCategoryRow from './CarCategoryRow';

const CarCategoryTable = ({
  className,
  categories,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('CATALOGS_VIEW.CAR_CATEGORY.CATEGORY_NAME')}</TableCell>
      <TableCell>{t('COMMON.DESCRIPTION')}</TableCell>
      <TableCell>{t('COMMON.PRICE')}</TableCell>
      <TableCell>{t('COMMON.ACTIONS')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return categories.map(category => (
        <CarCategoryRow
          {...category}
          key={category.id}
          openEdit={openEdit(category)}
          handleDelete={handleDelete}
        />
      ));
    },
    [categories],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

CarCategoryTable.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default CarCategoryTable;
