import React from 'react';
import PropTypes from 'prop-types';
import ParameterTable from './ParameterTable';

const ParameterList = ({ handleOpenEdit, parameters, loading }) => {
  return (
    <ParameterTable
      parameters={parameters}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
    />
  );
};

ParameterList.propTypes = {
  handleOpenEdit: PropTypes.func,
  parameters: PropTypes.array,
  loading: PropTypes.bool,
};

export default ParameterList;
