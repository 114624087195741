import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import StatusIcon from 'src/components/StatusIcon';
import { IconStatusAndColor, InsuranceStatus } from 'src/utils/constants';

const InsuranceRow = ({
  carniv,
  remainingDays,
  policyId,
  company,
  agent,
  policyType,
  cover,
  plates,
  mode,
  insuranceStatus,
}) => {
  const { t } = useTranslation();

  const getInsuranceStatus = () => {
    const { status, message } = insuranceStatus;

    const getInsuranceDescription = messageList => {
      let description = '';
      if (messageList.length > 1) {
        messageList.forEach(msg => {
          description += `• ${t(`INSURANCE_VIEW.MESSAGE.${msg}`)}. \n `;
        });
      } else {
        description = t(`INSURANCE_VIEW.MESSAGE.${messageList[0]}`);
      }
      return description;
    };

    switch (status) {
      case InsuranceStatus.ACTIVE:
        return {
          type: IconStatusAndColor.OK.status,
        };
      case InsuranceStatus.ERROR:
        return {
          description: getInsuranceDescription(message),
          type: IconStatusAndColor.ERROR.status,
        };
      default:
        return {
          description: t('COMMON.NO_INFORMATION'),
          type: IconStatusAndColor.NONE.status,
        };
    }
  };
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/fleet/car/${carniv}`}>{carniv}</Link>
      </TableCell>
      <TableCell>
        <StatusIcon {...getInsuranceStatus()} />
      </TableCell>
      <TableCell>{remainingDays || 0}</TableCell>
      <TableCell>{policyId || ''}</TableCell>
      <TableCell>{company || ''}</TableCell>
      <TableCell>{agent || ''}</TableCell>
      <TableCell>{policyType || ''}</TableCell>
      <TableCell>{cover || ''}</TableCell>
      <TableCell>{t(`FLEET_VIEW.${mode}`) || ''}</TableCell>
      <TableCell>{plates || ''}</TableCell>
    </TableRow>
  );
};
InsuranceRow.propTypes = {
  carniv: PropTypes.string.isRequired,
  remainingDays: PropTypes.number.isRequired,
  policyId: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  agent: PropTypes.string.isRequired,
  policyType: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  plates: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  insuranceStatus: PropTypes.instanceOf({
    status: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
export default InsuranceRow;
