export const selectLicence = state => state.licence;
export const selectLicenceList = state => selectLicence(state).items;
export const selectLicenceCount = state => selectLicence(state).count;
export const selectLicenceQueryOptions = state => {
  const { options } = selectLicence(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
