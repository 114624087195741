import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeviceRow from './DeviceRow';

const DeviceTable = ({
  className,
  devices,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    provider => () => {
      handleOpenEdit(provider);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('FLEET_VIEW.BRAND')}</TableCell>
      <TableCell>{t('FLEET_VIEW.MODEL')}</TableCell>
      <TableCell>{t('FLEET_VIEW.INSTALLATION_TYPE')}</TableCell>
      <TableCell>{t('PARAMETERS_VIEW.ACTIONS')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return devices?.map(device => (
        <DeviceRow
          {...device}
          key={device.id}
          openEdit={openEdit(device)}
          handleDelete={handleDelete}
        />
      ));
    },
    [devices],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

DeviceTable.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default DeviceTable;
