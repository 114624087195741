export const selectFine = state => state.fine;
export const selectFineList = state => selectFine(state).items;
export const selectFinesReport = state => selectFine(state).finesReport;
export const selectFineListCount = state => selectFine(state).count;
export const selectFineQueryOptions = state => {
  const { options } = selectFine(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
