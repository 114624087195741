import {
  CLEAR_DEVICE,
  CLEAR_OPTIONS,
  LOAD_ALL_GPS,
  LOAD_GPS,
  LOAD_GPS_DEVICES_LOCATION,
  SAVE_OPTIONS,
} from '../actions/gpsActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  allDevices: [],
  count: 0,
  form: {
    imei: '',
    simNumber: '',
    niv: '',
    gpsCatalog: '',
  },
  locations: [],
};

const GpsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GPS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case LOAD_ALL_GPS.success: {
      return {
        ...state,
        allDevices: action.items,
      };
    }
    case SAVE_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_OPTIONS: {
      return {
        ...state,
        options: {
          ...initialState.options,
          status: state.options.status,
        },
      };
    }
    case CLEAR_DEVICE: {
      return { ...initialState, allDevices: state.allDevices };
    }
    case LOAD_GPS_DEVICES_LOCATION.success: {
      return {
        ...state,
        locations: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default GpsReducer;
