import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BrandRow from './BrandRow';

const BrandTable = ({
  className,
  brands,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>Marca</TableCell>
      <TableCell>Tipo</TableCell>
      <TableCell>Versión</TableCell>
      <TableCell>Acciones</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return brands.map(brand => (
        <BrandRow
          {...brand}
          key={brand.id}
          openEdit={openEdit(brand)}
          handleDelete={handleDelete}
        />
      ));
    },
    [brands],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

BrandTable.propTypes = {
  className: PropTypes.string,
  brands: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default BrandTable;
