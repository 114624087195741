import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { updateDriverDocumentsAction } from 'src/store/actions/driverActions';
import { useTranslation } from 'react-i18next';
import { DriverStatus } from 'src/utils/constants';
import PropTypes from 'prop-types';
import AvalDocumentsInfo from './AvalDocumentsInfo';
import AvalDocumentDialog from './AvalDocumentDialog';
import useStyles from '../styles';

const AvalDocuments = ({ aval, driverId, driverStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateDriverDocumentsAction(driverId, values));
    },
    [driverId, dispatch],
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('COMMON.DOCUMENTS')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <AvalDocumentsInfo aval={aval} />
          </TableBody>
        </Table>
        <AvalDocumentDialog
          open={open}
          aval={aval}
          handleClose={handleClose}
          submit={handleSubmit}
        />
        <Box alignItems="center" display="flex" className={classes.buttons}>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            className={clsx(classes.label, classes.buttons)}
            startIcon={<EditIcon />}
            disabled={driverStatus === DriverStatus.LEFT}
          >
            {t('COMMON.EDIT')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

AvalDocuments.propTypes = {
  aval: PropTypes.object.isRequired,
  driverId: PropTypes.string.isRequired,
  driverStatus: PropTypes.string.isRequired,
};

export default AvalDocuments;
