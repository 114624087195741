import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import { TableSortLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FineRow from './FineRow';

const useStyles = makeStyles(() => ({
  tableRoot: {
    border: 'none',
  },
}));

const Tbody = ({ fines }) =>
  fines.map(fine => <FineRow {...fine} key={fine.id} />);

const FineTable = ({
  fines,
  count,
  page,
  handleChangePage,
  loading,
  handleSortChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSortChange(property, isAsc ? 'desc' : 'asc');
    },
    [order, orderBy, setOrder, setOrderBy, handleSortChange],
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>
        <TableSortLabel
          active={orderBy === 'date'}
          direction={orderBy === 'date' ? order : 'desc'}
          onClick={createSortHandler('date')}
        >
          {t('FINE_VIEW.DATE')}
        </TableSortLabel>
      </TableCell>
      <TableCell>{t('FINE_VIEW.DESCRIPTION')}</TableCell>
      <TableCell>{t('FINE_VIEW.INFRACTION_NUMBER')}</TableCell>
      <TableCell>{t('FINE_VIEW.LOCATION')}</TableCell>
      <TableCell>{t('FINE_VIEW.MUNICIPALITY')}</TableCell>
      <TableCell>{t('FINE_VIEW.PLATES')}</TableCell>
      <TableCell>
        <TableSortLabel
          active={orderBy === 'amount'}
          direction={orderBy === 'amount' ? order : 'desc'}
          onClick={createSortHandler('amount')}
        >
          {t('FINE_VIEW.AMOUNT')}
        </TableSortLabel>
      </TableCell>
      <TableCell>
        <TableSortLabel
          active={orderBy === 'discount'}
          direction={orderBy === 'discount' ? order : 'desc'}
          onClick={createSortHandler('discount')}
        >
          {t('FINE_VIEW.DISCOUNT')}
        </TableSortLabel>
      </TableCell>
      <TableCell>
        <TableSortLabel
          active={orderBy === 'total_amount'}
          direction={orderBy === 'total_amount' ? order : 'desc'}
          onClick={createSortHandler('total_amount')}
        >
          {t('FINE_VIEW.TOTAL')}
        </TableSortLabel>
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody fines={fines} />, [fines]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, count, handleChangePage],
  );

  return (
    <Table
      className={classes.tableRoot}
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

FineTable.propTypes = {
  fines: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  handleSortChange: PropTypes.func,
};

export default FineTable;
