import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, ListSubheader, makeStyles } from '@material-ui/core';
import NavItem from './NavItem';
import SubMenu from './SubMenu';

const useStyles = makeStyles(theme => ({
  list: {
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.contrastText,
    letterSpacing: 0.1,
    opacity: 0.4,
    textTransform: 'uppercase',
  },
}));

const Menu = ({ item, openDrawer }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getSubHeader = title => (
    <ListSubheader
      disableGutters
      classes={{ root: classes.heading }}
      component="div"
      style={{ position: 'static' }}
      id={title.toLowerCase()}
    >
      {t(title)}
    </ListSubheader>
  );

  return (
    <List classes={{ root: classes.list }} subheader={getSubHeader(item.title)}>
      {item.children.map(child => {
        return child.children && child.children.length > 0 ? (
          <SubMenu key={child.title} openDrawer={openDrawer} {...child} />
        ) : (
          <NavItem
            isRootItem
            href={child.href}
            key={child.title}
            title={t(child.title)}
            icon={child.icon}
            openDrawer={openDrawer}
            disabled={child.disabled}
          />
        );
      })}
    </List>
  );
};

Menu.propTypes = {
  item: PropTypes.shape({
    href: PropTypes.string,
    children: PropTypes.array,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }),
  openDrawer: PropTypes.bool,
};

Menu.defaultProps = {
  item: {},
};

export default Menu;
