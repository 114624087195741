import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import {
  formatNegativeAmount,
  formatPositiveAmount,
} from 'src/utils/formatAmount';
import { Tooltip } from '@material-ui/core';

const FineRow = ({
  date,
  description,
  infractionNumber,
  location,
  municipality,
  plate,
  amount,
  discount,
  totalAmount,
}) => {
  const formattedDate = useMemo(() => moment(date).format('DD-MMM-YY'), [date]);

  return (
    <TableRow hover>
      <TableCell style={{ width: '8%' }}>{formattedDate}</TableCell>
      {description && description.length > 20 ? (
        <Tooltip title={description}>
          <TableCell style={{ width: '15%' }}>
            {`${description.slice(0, 20)}...`}
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell style={{ width: '21%' }}>{description}</TableCell>
      )}
      <TableCell style={{ width: '15%' }}>{infractionNumber}</TableCell>
      {location && location.length > 20 ? (
        <Tooltip title={location}>
          <TableCell style={{ width: '21%' }}>
            {`${location.slice(0, 20)}...`}
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell style={{ width: '21%' }}>{location}</TableCell>
      )}
      <TableCell style={{ width: '8%' }}>{municipality}</TableCell>
      <TableCell style={{ width: '8%' }}>{plate}</TableCell>
      <TableCell style={{ width: '8%' }}>
        {formatPositiveAmount(Number(amount))}
      </TableCell>
      <TableCell style={{ width: '8%' }}>
        {formatNegativeAmount(Number(discount))}
      </TableCell>
      <TableCell style={{ width: '8%' }}>
        {formatPositiveAmount(Number(totalAmount))}
      </TableCell>
    </TableRow>
  );
};

FineRow.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  infractionNumber: PropTypes.string,
  location: PropTypes.string,
  municipality: PropTypes.string,
  plate: PropTypes.string,
  amount: PropTypes.string,
  discount: PropTypes.string,
  totalAmount: PropTypes.string,
};

export default FineRow;
