/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { selectGpsList } from 'src/store/selectors/gpsSelectors';
import { useSelector } from 'react-redux';
import validationSchema from './schema';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = car => {
  return {
    mileage: car.sensor || '',
    maintenanceDate: null,
    niv: '',
    lastMaintenance: '',
    sensor: '',
  };
};

const DatePickerField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      disableFuture
      className={classes.field}
      inputVariant="outlined"
      fullWidth
      name={field.name}
      value={field.value}
      format="DD/MM/YYYY"
      variant="inline"
      label="Fecha de Mantenimiento"
      helperText={currentError}
      error={Boolean(currentError)}
      onError={error => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={date => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...other}
    />
  );
};

const AddMaintenanceDialog = ({ open, handleClose, submit }) => {
  const CarList = useSelector(selectGpsList);
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState({}), []);
  const resetRef = useRef();

  const handleOtherSubmit = useCallback(
    (values, { resetForm }) => {
      submit(values);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle className={classes.header} id="form-last-maintence-update">
        <Typography className={classes.header} color="primary" variant="h2">
          {t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.ADD_NEW_MAINTENANCE')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          onSubmit={handleOtherSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            resetForm,
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.niv && errors.niv)}
                >
                  <Autocomplete
                    options={CarList}
                    getOptionLabel={option => option.niv}
                    includeInputInList
                    onOpen={handleBlur}
                    onChange={(_e, value) => {
                      setFieldValue('niv', value?.niv || '', true);
                      setFieldValue('driverId', value?.driver?.id || '', true);
                      setFieldValue(
                        'lastMaintenance',
                        value?.lastMaintenance || '',
                        true,
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Niv"
                        name="niv"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>

                <TextField
                  error={Boolean(touched.mileage && errors.mileage)}
                  fullWidth
                  helperText={touched.mileage && t(errors.mileage)}
                  label={t(
                    'GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.LAST_MAINTENANCE',
                  )}
                  margin="normal"
                  name="mileage"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.mileage}
                  variant="outlined"
                />
                <Field
                  name="maintenanceDate"
                  className={classes.field}
                  component={DatePickerField}
                  label={t(
                    'GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.DATE_OF_THE_LAST_MAINTENANCE',
                  )}
                />
                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('GPS_VIEW.ADD')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('GPS_VIEW.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddMaintenanceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default AddMaintenanceDialog;
