import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectTransactionsList,
  selectTransactionsListCount,
  selectTransactionsQueryOptions,
  selectMovementsList,
  selectMovementsListCount,
  selectMovementsQueryOptions,
} from 'src/store/selectors/driverSelectors';
import { GetApp } from '@material-ui/icons';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import * as Actions from 'src/store/actions/driverActions';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import TransactionsTable from './TransactionsTable';
import MovementsTable from './MovementsTable';
import Toolbar from './Toolbar';
import DeletePaymentConfirmation from './DeletePaymentConfirmation';
import DriverBalanceFilter from './DriverBalanceFilter';

export const STATUS = {
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED',
};
function getFilterOptions(options) {
  const status = [];

  if (options.pending) {
    status.push(STATUS.PENDING);
  }
  if (options.processed) {
    status.push(STATUS.PROCESSED);
  }

  return { status };
}

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
  preloaderCard: {
    height: '75vh',
  },
  buttonsContainer: {
    display: 'flex',
    minWidth: theme.spacing(55),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: '2% 0',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '220px',
    right: '5px',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  container: {
    position: 'relative',
  },
}));

const DriverBalance = ({ driver }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [trId, setTrId] = useState();
  const [tabValue, setTabValue] = React.useState(0);

  const transactions = useSelector(selectTransactionsList);
  const count = useSelector(selectTransactionsListCount);
  const { page } = useSelector(selectTransactionsQueryOptions);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_TRANSACTIONS.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_TRANSACTIONS.action]),
  );

  const movements = useSelector(selectMovementsList);
  const movementsCount = useSelector(selectMovementsListCount);
  const { page: movementsPage } = useSelector(selectMovementsQueryOptions);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    pending: true,
  });
  const queryOptions = useSelector(selectTransactionsQueryOptions);

  const loadingMovements = useSelector(
    isLoadingSelector([Actions.LOAD_MOVEMENTS.action]),
  );
  const movementsSuccess = useSelector(
    successSelector([Actions.LOAD_MOVEMENTS.action]),
  );

  useEffect(() => {
    dispatch(Actions.loadDriverTransactions(driver.id, { page }));
    dispatch(
      Actions.loadDriverPendingMovements(driver.id, {
        page: movementsPage,
        status: [STATUS.PENDING],
      }),
    );
  }, []);

  const handleTabChange = useCallback(
    (_, newValue) => {
      setTabValue(newValue);
    },
    [setTabValue],
  );

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(() => {
    dispatch(Actions.deleteDriverPayment(trId, driver.id));
    handleClose();
  }, [driver, trId, dispatch, handleClose]);

  const handlePaymentDelete = useCallback(
    transactionId => {
      handleClickOpen();
      setTrId(transactionId);
    },
    [handleClickOpen, setTrId],
  );

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadDriverTransactions(driver.id, { page: value }));
    },
    [dispatch, driver],
  );

  const handleMovementsChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadDriverPendingMovements(driver.id, { page: value }));
    },
    [dispatch, driver],
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      dispatch(
        Actions.loadDriverPendingMovements(driver.id, {
          ...queryOptions,
          page: 0,
          ...getFilterOptions(event),
        }),
      );
      handleCloseFilter();
    },
    [setFilterValues, queryOptions, getFilterOptions],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(Actions.downloadExcelTransactions(driver.id));
  }, [dispatch]);

  const handleExcelExportMovement = useCallback(() => {
    const options = getFilterOptions(filterValues);
    dispatch(
      Actions.downloadExcelMovements(driver.id, {
        ...queryOptions,
        page: 0,
        ...options,
      }),
    );
  }, [dispatch, filterValues]);

  return (
    <Box>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        spacing={3}
        className={classes.container}
      >
        <Grid item xs={12}>
          <Toolbar driver={driver} />
        </Grid>
        <Grid item xs={12}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Tabs for driver balance"
          >
            <Tab label="Transacciones" />
            <Tab label="Movimientos Pendientes" />
          </Tabs>
        </Grid>
        <Grid item xs={12} position="absolute">
          <TabPanel value={tabValue} index={0}>
            {success && !loading && count === 0 ? (
              <Card className={classes.preloaderCard}>
                <NotFoundRecords
                  title={t('NOT_FOUND_RECORDS_MESSAGE.DRIVER_BALANCE_TITLE')}
                  description={t(
                    'NOT_FOUND_RECORDS_MESSAGE.DRIVER_BALANCE_DESCRIPTION',
                  )}
                />
              </Card>
            ) : (
              <>
                <Box
                  className={clsx(
                    classes.actionsContainer,
                    classes.buttonsContainer,
                  )}
                >
                  <Button
                    className={clsx(classes.buttons, classes.outlined)}
                    onClick={handleExcelExport}
                    color="primary"
                  >
                    <GetApp className={classes.icon} />
                  </Button>
                </Box>
                <TransactionsTable
                  t={t}
                  count={count}
                  page={page}
                  transactions={transactions}
                  handleChangePage={handleChangePage}
                  loading={loading}
                  handlePaymentDelete={handlePaymentDelete}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {movementsSuccess && !loadingMovements && movementsCount === 0 ? (
              <>
                <Box
                  className={clsx(
                    classes.actionsContainer,
                    classes.buttonsContainer,
                  )}
                >
                  <DriverBalanceFilter
                    hancleOpen={handleClickFilter}
                    handleClose={handleCloseFilter}
                    anchorEl={anchorEl}
                    filterValues={filterValues}
                    handleFilterSubmit={handleFilterChange}
                  />
                  <Button
                    className={clsx(
                      classes.buttons,
                      classes.outlined,
                      classes.horizontalPadding,
                    )}
                    color="primary"
                    onClick={handleClickFilter}
                  >
                    <FilterIcon className={classes.icon} />
                    {t('COMMON.FILTER')}
                  </Button>
                  {movementsCount !== 0 && (
                    <Button
                      className={clsx(classes.buttons, classes.outlined)}
                      onClick={handleExcelExportMovement}
                      color="primary"
                    >
                      <GetApp className={classes.icon} />
                    </Button>
                  )}
                </Box>
                <Card className={classes.preloaderCard}>
                  <NotFoundRecords
                    title={t('NOT_FOUND_RECORDS_MESSAGE.DRIVER_MOVEMENT_TITLE')}
                    description={t(
                      'NOT_FOUND_RECORDS_MESSAGE.DRIVER_MOVEMENT_DESCRIPTION',
                    )}
                  />
                </Card>
              </>
            ) : (
              <>
                <Box
                  className={clsx(
                    classes.actionsContainer,
                    classes.buttonsContainer,
                  )}
                >
                  <DriverBalanceFilter
                    hancleOpen={handleClickFilter}
                    handleClose={handleCloseFilter}
                    anchorEl={anchorEl}
                    filterValues={filterValues}
                    handleFilterSubmit={handleFilterChange}
                  />
                  <Button
                    className={clsx(
                      classes.buttons,
                      classes.outlined,
                      classes.horizontalPadding,
                    )}
                    color="primary"
                    onClick={handleClickFilter}
                  >
                    <FilterIcon className={classes.icon} />
                    {t('COMMON.FILTER')}
                  </Button>
                  <Button
                    className={clsx(classes.buttons, classes.outlined)}
                    onClick={handleExcelExportMovement}
                    color="primary"
                  >
                    <GetApp className={classes.icon} />
                  </Button>
                </Box>
                <MovementsTable
                  t={t}
                  count={movementsCount}
                  page={movementsPage}
                  movements={movements}
                  handleChangePage={handleMovementsChangePage}
                  loading={loadingMovements}
                />
              </>
            )}
          </TabPanel>
        </Grid>
      </Grid>

      <DeletePaymentConfirmation
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

DriverBalance.propTypes = {
  driver: PropTypes.object,
};

export default DriverBalance;
