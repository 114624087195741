import {
  CLEAR_FINES,
  CLEAR_FINES_OPTIONS,
  LOAD_FINES,
  LOAD_FINES_REPORT,
  SAVE_FINES_OPTIONS,
} from '../actions/fineActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  finesReport: [],
  count: 0,
};

const fineReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FINES.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_FINES_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_FINES_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    case LOAD_FINES_REPORT.success: {
      return {
        ...state,
        finesReport: action.items,
        count: action.count,
      };
    }
    case CLEAR_FINES: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default fineReducer;
