import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Slide,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  name: data ? data.name : '',
  description: data ? data.description : '',
  price: data ? data.price : '',
});

const CarCategoryForm = ({
  open,
  data,
  handleClose,
  handleCreate,
  handleEdit,
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();

  const handleOuterSubmit = useMemo(() => {
    if (data) {
      return handleEdit;
    }
    return handleCreate;
  }, [data, handleEdit, handleCreate]);

  const handleNameChange = setFieldValue => e => {
    if (e.target.value.length <= 2) {
      setFieldValue(e.target.name, e.target.value.toUpperCase());
    }
  };

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Box>
      <Dialog
        aria-labelledby="form-car-category"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-car-category-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data
              ? t('CATALOGS_VIEW.UPDATE_REGISTER')
              : t('CATALOGS_VIEW.CREATE_REGISTER')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue,
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && t(errors.name)}
                    label={t('CATALOGS_VIEW.CAR_CATEGORY.CATEGORY_NAME')}
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleNameChange(setFieldValue)}
                    type="text"
                    value={values.name}
                    variant="outlined"
                    disabled={!!data}
                  />

                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && t(errors.description)}
                    label={t('COMMON.DESCRIPTION')}
                    margin="normal"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.description}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.price && errors.price)}
                    fullWidth
                    helperText={touched.price && t(errors.price)}
                    label={t('COMMON.PRICE')}
                    margin="normal"
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.price}
                    variant="outlined"
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? t('COMMON.UPDATE') : t('COMMON.ADD')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

CarCategoryForm.propTypes = {
  open: PropTypes.func,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
  }),
  handleClose: PropTypes.func,
  handleCreate: PropTypes.func,
  handleEdit: PropTypes.func,
};

export default CarCategoryForm;
