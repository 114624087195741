import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import { makeStyles, Typography } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: theme.spacing(58),
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    '& h2': {
      fontWeight: 500,
      fontSize: theme.spacing(2.5),
      lineHeight: `${theme.spacing(3)}px`,
    },
  },
  box: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`,
  },
  buttons: {
    width: '45%',
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
}));

const RevokeLeaseDialog = ({ open, handleClose, handleRevoke }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle className={classes.title} id="form-general-driver">
        Revocar contrato
      </DialogTitle>
      <DialogContent>
        <Typography variant="h3">
          ¿Estas seguro que quieres revocar el contrato?
        </Typography>
        <Box
          className={classes.box}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            className={classes.buttons}
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            className={classes.buttons}
            onClick={handleRevoke}
            color="primary"
            variant="outlined"
          >
            Revocar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

RevokeLeaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRevoke: PropTypes.func.isRequired,
};

export default RevokeLeaseDialog;
