import { PlatformType, UberProCategory } from 'src/utils/constants';
import * as Yup from 'yup';

export default Yup.object().shape({
  mobile: Yup.string()
    .length(10, 'SCHEMA_ERRORS.ENTER_TEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PHONE_NUMBER'),
  email: Yup.string()
    .email('SCHEMA_ERRORS.ENTER_EMAIL_VALID')
    .required('SCHEMA_ERRORS.ENTER_EMAIL_VALID'),
  economicDependents: Yup.number()
    .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_DEPENDENT_NUMBER'),
  platformRating: Yup.number().when('platform', {
    is: platform =>
      platform === PlatformType.DIDI || platform === PlatformType.UBER,
    then: Yup.number()
      .min(0, 'SCHEMA_ERRORS.ENTER_CERO_AND_FIVE_NUMBER')
      .max(5)
      .required('SCHEMA_ERRORS.ENTER_PLATFORM_RANKING'),
  }),
  tripsNumber: Yup.number()
    .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_TRIPS_NUMBER'),
  zipCode: Yup.string()
    .length(5, 'SCHEMA_ERRORS.INVALID_ZIPCODE')
    .required('SCHEMA_ERRORS.ENTER_ZIPCODE'),
  rfc: Yup.string('SCHEMA_ERRORS.ENTER_ALPHANUMERIC')
    .default('XAXX010101000')
    .min(12, 'SCHEMA_ERRORS.ENTER_TWELVE_AND_THIRTEEN_NUMBER')
    .max(13, 'SCHEMA_ERRORS.ENTER_TWELVE_AND_THIRTEEN_NUMBER'),
  platform: Yup.string()
    .oneOf([...Object.values(PlatformType)], 'SCHEMA_ERRORS.ENTER_OPTION')
    .required('SCHEMA_ERRORS.ENTER_PLATFORM'),
  uberProStatus: Yup.string().when('platform', {
    is: PlatformType.UBER,
    then: Yup.string()
      .oneOf([...Object.values(UberProCategory)], 'SCHEMA_ERRORS.ENTER_OPTION')
      .required('SCHEMA_ERRORS.ENTER_OPTION'),
  }),
});
