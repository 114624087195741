import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CLEAR_CATALOG,
  loadGeofencingZonesList,
  LOAD_GEOFENCE_ZONES,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import GeofencingZonesTable from './GeofencingZonesTable';

const GeofencingZonesList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const zones = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_GEOFENCE_ZONES.action]));

  useEffect(() => {
    dispatch(loadGeofencingZonesList());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency
  return (
    <GeofencingZonesTable
      zones={zones}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
    />
  );
};
GeofencingZonesList.propTypes = {
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default GeofencingZonesList;
