import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ProvidersRow from './ProvidersRow';

const ProvidersTable = ({
  className,
  providers,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const openEdit = useCallback(
    provider => () => {
      handleOpenEdit(provider);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>Nombre de Proveedor</TableCell>
      <TableCell>Tipo de Gasto</TableCell>
      <TableCell>Acciones</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return providers.map(provider => (
        <ProvidersRow
          {...provider}
          key={provider.id}
          openEdit={openEdit(provider)}
          handleDelete={handleDelete}
        />
      ));
    },
    [providers],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

ProvidersTable.propTypes = {
  className: PropTypes.string,
  providers: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ProvidersTable;
