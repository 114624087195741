import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import { useTranslation } from 'react-i18next';
import FineReportRow from './FinesReportRow';

const useStyles = makeStyles(() => ({
  tableRoot: {
    border: 'none',
  },
}));

const Tbody = ({ reports }) =>
  reports.map(report => <FineReportRow {...report} key={report.id} />);

const FinesReportTable = ({
  reports,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('FINE_VIEW.DATE')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.DURATION')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.MUNICIPALITY_COUNT')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.PLATES_COUNT')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.NEW_FINE_COUNT')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.FINES_PAID_COUNT')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.PLATES_FAILED_COUNT')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.MUNICIPALITY_FAILED_COUNT')}</TableCell>
      <TableCell>{t('FINE_VIEW.REPORTS.REPORT_DETAIL')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody reports={reports} />, [reports]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, count, handleChangePage],
  );

  return (
    <Table
      className={classes.tableRoot}
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

FinesReportTable.propTypes = {
  reports: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  handleSortChange: PropTypes.func,
};

export default FinesReportTable;
