import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TablePagination, TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import RentFactorRow from './RentFactorRow';

const useStyles = makeStyles(() => ({
  tableRoot: {
    border: 'none',
  },
}));

const Tbody = ({ factors, tabValue }) => {
  return factors.map(factor => (
    <RentFactorRow key={factor.week} {...factor} tabValue={tabValue} />
  ));
};

const RentFactorTable = ({
  className,
  factors,
  count,
  page,
  loading,
  handleChangePage,
  tabValue,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => {
      return (
        <TableRow>
          <TableCell>{t('COMMON.PERIOD')}</TableCell>
          {tabValue === 0 ? (
            <>
              <TableCell>{t('RENT_FACTOR.RENT')}</TableCell>
              <TableCell>{t('RENT_FACTOR.OCCUPATION')}</TableCell>
              <TableCell>{t('RENT_FACTOR.OPERATION')}</TableCell>
            </>
          ) : (
            <>
              <TableCell>{t('RENT_FACTOR.DISCOUNTS')}</TableCell>
              <TableCell>{t('RENT_FACTOR.VACANCY')}</TableCell>
              <TableCell>{t('RENT_FACTOR.COLLECTED')}</TableCell>
              <TableCell>{t('RENT_FACTOR.POTENTIAL_LOSS')}</TableCell>
            </>
          )}
        </TableRow>
      );
    },
    [t, tabValue],
  );

  const tbody = useMemo(
    () => () => <Tbody factors={factors} tabValue={tabValue} />,
    [factors],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, factors],
  );

  return (
    <Table
      {...rest}
      className={classes.tableRoot}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
      pagination={pagination}
    />
  );
};

RentFactorTable.propTypes = {
  className: PropTypes.string,
  factors: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  tabValue: PropTypes.number,
};

export default RentFactorTable;
