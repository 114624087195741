import { Box, MenuItem, TextField, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DropdownMenu = ({
  list,
  value,
  setValue,
  label,
  error,
  errorText,
  style,
  name,
  disabled,
  tooltipDescription = '',
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(e => {
    setValue(e.target.value, e.target.name);
  }, []);

  return (
    <Box my={2}>
      <Tooltip title={!list.length ? t(tooltipDescription) : ''}>
        <TextField
          value={value}
          name={name}
          onChange={handleChange}
          label={t(label)}
          variant="outlined"
          style={style || { width: '100%' }}
          select
          helperText={errorText}
          error={error}
          disabled={disabled}
        >
          {list.map(item => (
            <MenuItem value={item.key} key={item.key}>
              {t(item.name)}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>
    </Box>
  );
};

export default DropdownMenu;

DropdownMenu.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setValue: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  tooltipDescription: PropTypes.string,
};
