const { makeStyles } = require('@material-ui/core');

export default makeStyles(theme => ({
  preloaderCard: {
    height: '75vh',
  },
  buttonsContainer: {
    display: 'flex',
    minWidth: theme.spacing(55),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: '2% 0',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  container: {
    position: 'relative',
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
  center: {
    textAlign: 'center',
  },
  iconRow: {
    padding: '0 12px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rootForm: {
    width: '100%',
  },
  buttonForm: {
    letterSpacing: 1.25,
    fontSize: theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(10)}px`,
  },
  textForm: {
    width: '100%',
  },
  titleDialog: {
    color: theme.palette.primary,
    marginTop: theme.spacing(2),
    '& h2': {
      lineHeight: `${theme.spacing(6.5)}px`,
      padding: '0px 10px',
    },
  },
  rootDialog: {
    minWidth: theme.spacing(72),
  },
  closeIconDialog: {
    float: 'right',
    marginTop: '-20px',
    width: '20px',
    height: '20px',
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  input: {
    width: '100%',
  },
  closingButtons: {
    marginRight: '-8px',
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: { fontWeight: '400' },
  currentDate: {
    fontWeight: '400',
    display: 'inline',
    marginLeft: '5px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
}));
