/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import {
  formatPositiveAmount,
  formatNegativeAmount,
} from 'src/utils/formatAmount';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { unrecovarableDebtAction } from 'src/store/actions/collectionsActions';
import ConfirmationMessage from 'src/components/ConfirmationMessage';

const CollectionsRow = ({
  driverId,
  driverName,
  initialBalance,
  charges,
  payments,
  finalBalance,
  lastPayment,
  status,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleClose = () => {
    setOpenConfirmation(false);
  };

  const hanldeOpen = () => {
    setOpenConfirmation(true);
  };

  const unSubscribeDriver = useCallback(() => {
    dispatch(unrecovarableDebtAction(Number(driverId), handleClose));
  }, []);

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Link to={`/drivers/detail/${driverId}`}>{driverId}</Link>
        </TableCell>
        <TableCell>
          <Link to={`/drivers/detail/${driverId}`}>{driverName}</Link>
        </TableCell>
        <TableCell>{formatPositiveAmount(initialBalance)}</TableCell>
        <TableCell>{formatPositiveAmount(charges)}</TableCell>
        <TableCell>{formatNegativeAmount(payments)}</TableCell>
        <TableCell>{formatPositiveAmount(finalBalance)}</TableCell>
        <TableCell>{t(`INCOME_VIEW.${status}`)}</TableCell>
        <TableCell>
          {lastPayment ? moment(lastPayment).format('DD-MMM-YY') : '--'}
        </TableCell>
        <TableCell>
          {lastPayment ? moment().diff(moment(lastPayment), 'days') : '--'}
        </TableCell>
        <TableCell>
          <Button onClick={hanldeOpen} disabled={status === 'INACTIVE'}>
            <DeleteIcon
              color={status === 'INACTIVE' ? 'text.disabled' : 'secondary'}
            />
          </Button>
        </TableCell>
      </TableRow>
      <ConfirmationMessage
        open={openConfirmation}
        handleClose={handleClose}
        text={t('INCOME_VIEW.UNSUBSCRIBE_CONFIRMATION')}
        handleAccept={unSubscribeDriver}
      />
    </>
  );
};

export default CollectionsRow;
