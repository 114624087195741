import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { useTranslation } from 'react-i18next';
import {
  clearBusiness,
  createBusiness,
  downloadExcelBusiness,
  loadBusiness,
} from 'src/store/actions/businessActions';
import Breadcrumb from 'src/components/Breadcrumb';
import BusinessForm from './BusinessForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 600,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const Toolbar = props => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreateBusiness = useCallback(
    values => {
      dispatch(createBusiness(values));
      handleClose();
    },
    [dispatch, handleClose],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelBusiness());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadBusiness());
    return () => dispatch(clearBusiness());
  }, []);

  return (
    <>
      <div className={classes.root} {...props}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.DRIVERS']}
          current="NAVIGATION.BUSINESS"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.BUSINESS')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>

            <Button
              className={clsx(classes.buttons, classes.horizontalPadding)}
              color="primary"
              onClick={handleClickOpen}
              variant="contained"
            >
              <PlusIcon style={{ marginRight: 8 }} fill="white" />
              {t('BUSINESS.ADD_BUSINESS')}
            </Button>
          </div>
        </div>
      </div>

      <BusinessForm
        open={open}
        handleCreateBusiness={handleCreateBusiness}
        handleClose={handleClose}
      />
    </>
  );
};

export default Toolbar;
