import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Breadcrumbs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  breadcrumbsContainer: {
    marginBottom: 20,
  },
  breadcrumbsItems: {
    fontSize: 12,
    letterSpacing: 0.4,
    textTransform: 'capitalize',
  },
}));

const Breadcrumb = ({ history, current }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.breadcrumbsContainer}>
      <Breadcrumbs>
        {history.map(h => (
          <Typography
            className={classes.breadcrumbsItems}
            color="textPrimary"
            key={h}
          >
            {t(h)}
          </Typography>
        ))}
        <Typography className={classes.breadcrumbsItems} color="primary">
          {t(current)}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

Breadcrumb.propTypes = {
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  current: PropTypes.string.isRequired,
};

export default Breadcrumb;
