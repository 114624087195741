/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import moment from 'moment';
import { formatPositiveAmount } from 'src/utils/formatAmount';

const ExpenseRow = ({
  id,
  createdAt,
  incidentDate,
  region,
  driverId,
  driverName,
  expenseConcept,
  provider,
  amount,
}) => {
  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell>{moment(createdAt).format('DD-MMM-YY')}</TableCell>
      <TableCell>{moment(incidentDate).format('DD-MMM-YY')}</TableCell>
      <TableCell>{region}</TableCell>
      <TableCell>
        <Link to={`/drivers/detail/${driverId}`}>{driverName}</Link>
      </TableCell>
      <TableCell>{expenseConcept}</TableCell>
      <TableCell>{provider}</TableCell>
      <TableCell>{formatPositiveAmount(amount)}</TableCell>
    </TableRow>
  );
};

export default ExpenseRow;
