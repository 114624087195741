import { FAILURE, NOT_STARTED, START, SUCCESS } from 'src/utils/loadingUtils';

export default (state = {}, action) => {
  const { type } = action;

  const matchesStart = /(.*)_(START)/.exec(type);
  const matchesError = /(.*)_(FAILURE)/.exec(type);
  const matchesReset = /(.*)_(RESET)/.exec(type);
  const matchesSuccess = /(.*)_(SUCCESS)/.exec(type);

  let status = NOT_STARTED;
  let key = null;

  if (matchesStart) {
    const [, requestName] = matchesStart;
    key = requestName;
    status = START;
  } else if (matchesReset) {
    const [, requestName] = matchesReset;
    key = requestName;
    status = NOT_STARTED;
  } else if (matchesError) {
    const [, requestName] = matchesError;
    key = requestName;
    status = FAILURE;
  } else if (matchesSuccess) {
    const [, requestName] = matchesSuccess;
    key = requestName;
    status = SUCCESS;
  }

  if (key) {
    return {
      ...state,
      [key]: status,
    };
  }

  return state;
};
