/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { loadLocations, CLEAR_CATALOG } from 'src/store/actions/catalogActions';
import DropdownMenu from 'src/components/DropdownMenu';
import { IncidentReason } from 'src/utils/constants';
import DatePicker from 'src/components/DatePicker';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  niv: data ? data.niv : '',
  incident: data ? data.incident : '',
  location: data ? data.location : '',
  comment: data ? data.comment : '',
  departure: data ? data.departure : '',
});

// eslint-disable-next-line react/prop-types
const InactiveForm = ({
  open,
  data,
  handleClose,
  handleCreate,
  handleEdit,
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const locations = useSelector(selectCatalogList);

  const handleOuterSubmit = useMemo(() => {
    if (data) {
      return handleEdit;
    }
    return handleCreate;
  }, [data, handleEdit, handleCreate]);

  useEffect(() => {
    if (open) {
      dispatch(loadLocations());
    }

    return () => {
      if (resetRef.current) {
        resetRef.current();
        dispatch({ type: CLEAR_CATALOG });
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-test-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('FLEET_VIEW.EDIT_INACTIVE_CAR')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik initialValues={initialState} onSubmit={handleOuterSubmit}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
              setFieldValue,
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <DropdownMenu
                    list={IncidentReason.map(value => {
                      return {
                        ...value,
                        name: t(`COMMON.${value.name}`),
                      };
                    })}
                    setValue={value => setFieldValue('incident', value, true)}
                    value={values.incident}
                    label={t('COMMON.INCIDENT')}
                    error={Boolean(touched.incident && errors.incident)}
                    errorText={touched.incident && t(errors.incident)}
                    style={{ width: '100%', marginBottom: '10px' }}
                  />

                  <DropdownMenu
                    list={locations.map(value => {
                      return {
                        key: value.name,
                        name: value.name,
                      };
                    })}
                    setValue={value => setFieldValue('location', value, true)}
                    value={values.location}
                    label={t('COMMON.LOCATION')}
                    error={Boolean(touched.location && errors.location)}
                    errorText={touched.location && t(errors.location)}
                  />

                  <TextField
                    error={Boolean(touched.comment && errors.comment)}
                    fullWidth
                    helperText={touched.comment && errors.comment}
                    label={t('COMMON.COMMENT')}
                    margin="normal"
                    name="comment"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.comment}
                    variant="outlined"
                  />
                  <DatePicker
                    field={{ name: 'departure', value: values.departure }}
                    form={{
                      errors,
                      setFieldValue,
                      touched,
                    }}
                    label={t('FLEET_VIEW.STIMATED_OUTPUT')}
                    disablePast
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.SAVE')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InactiveForm;
