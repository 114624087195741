import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import {
  formatPositiveAmount,
  formatNegativeAmount,
} from 'src/utils/formatAmount';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';

const IncomeResumeRow = ({
  date,
  initialBalance,
  finalBalance,
  rent,
  surcharges,
  interests,
  others,
  fines,
  sinisters,
  advances,
  discounts,
  payments,
  charged,
  laidoff,
}) => {
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell style={{ width: '8.33%' }}>{date}</TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatPositiveAmount(initialBalance)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatPositiveAmount(rent)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatPositiveAmount(surcharges)}
      </TableCell>
      <TableCell style={{ width: '8.33%', display: 'none' }}>
        {formatPositiveAmount(interests)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatPositiveAmount(fines)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatPositiveAmount(sinisters)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatPositiveAmount(others)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatNegativeAmount(advances)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatNegativeAmount(discounts)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatNegativeAmount(payments)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatNegativeAmount(laidoff)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {formatPositiveAmount(finalBalance)}
      </TableCell>
      <TableCell style={{ width: '8.33%' }}>
        {charged !== undefined ? `${charged}%` : t('COMMON.NOT_AVAILABLE')}
      </TableCell>
    </TableRow>
  );
};

IncomeResumeRow.propTypes = {
  date: Proptypes.string,
  initialBalance: Proptypes.number,
  finalBalance: Proptypes.number,
  rent: Proptypes.number,
  surcharges: Proptypes.number,
  interests: Proptypes.number,
  others: Proptypes.number,
  fines: Proptypes.number,
  sinisters: Proptypes.number,
  advances: Proptypes.number,
  discounts: Proptypes.number,
  payments: Proptypes.number,
  charged: Proptypes.number,
  laidoff: Proptypes.number,
};

export default IncomeResumeRow;
