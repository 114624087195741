import * as Yup from 'yup';

export default Yup.object().shape({
  company: Yup.string().required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  policyType: Yup.string()
    .oneOf(['Plataforma', 'Normal'], 'SCHEMA_ERRORS.SELECT_VALUE_LIST')
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  cover: Yup.string()
    .oneOf(['Amplia', 'Solo R.C.'], 'SCHEMA_ERRORS.SELECT_VALUE_LIST')
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  policyId: Yup.string().required('SCHEMA_ERRORS.ENTER_POLICY_NUMBER'),
  agent: Yup.string().required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  coverageStart: Yup.date().required('SCHEMA_ERRORS.ENTER_INITIAL_DATE'),
});
