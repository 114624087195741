import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TableCell from 'src/components/Table/TableCell';
import { Map } from '@material-ui/icons';
import ViewGeofencingInMap from 'src/components/GeofencingMap/ViewGeofencingInMap';
import { removeGeofencing } from 'src/store/actions/gpsActions';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, IconButton, TableRow } from '@material-ui/core';
import useStyles from './styles';

const GeofencingRow = ({
  geofencingId,
  name,
  category,
  zone,
  alert,
  longitude,
  latitude,
  radius,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openGeofencingMap, setOpenGeofencingMap] = useState(false);

  const getAlertStatus = status => {
    if (!status) {
      return t('COMMON.INACTIVE');
    }
    return t('COMMON.ACTIVE');
  };

  const handleDeleteGeofencing = () => {
    dispatch(removeGeofencing(geofencingId));
  };

  const handleOpenGeofencingMap = () => {
    setOpenGeofencingMap(true);
  };
  const handleCloseGeofencingMap = () => {
    setOpenGeofencingMap(false);
  };

  return (
    <TableRow hover>
      <TableCell>{geofencingId}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{category?.category}</TableCell>
      <TableCell>{zone?.zone}</TableCell>
      <TableCell>{getAlertStatus(alert)}</TableCell>
      <TableCell>
        <Button onClick={handleOpenGeofencingMap} color="primary">
          <Map className={classes.icon} />
        </Button>
      </TableCell>
      <TableCell style={{ texAling: 'center' }}>
        <IconButton
          aria-label="Edit"
          onClick={handleDeleteGeofencing}
          style={{ padding: '0' }}
        >
          <DeleteIcon color="error" alt="Eliminar" />
        </IconButton>
      </TableCell>

      <ViewGeofencingInMap
        lat={latitude}
        lng={longitude}
        radius={radius}
        open={openGeofencingMap}
        handleClose={handleCloseGeofencingMap}
      />
    </TableRow>
  );
};

GeofencingRow.propTypes = {
  geofencingId: PropTypes.string,
  category: PropTypes.string,
  zone: PropTypes.string,
  alert: PropTypes.bool,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  radius: PropTypes.number,
  name: PropTypes.string,
};

export default GeofencingRow;
