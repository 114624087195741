import {  LOGIN, LOGIN_ERROR, LOGOUT } from '../actions/authActions';

const initialState = {
  token: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        token: action.payload,
      };
    }
    case LOGIN_ERROR: {
      return initialState;
    }
    case LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default auth;
