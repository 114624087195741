import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadAgents,
  LOAD_CATALOG,
  CLEAR_CATALOG,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import AgentsTable from './AgentsTable';

const AgentsList = () => {
  const dispatch = useDispatch();
  const agents = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadAgents());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency

  return <AgentsTable agents={agents} loading={loading} />;
};

export default AgentsList;
