import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { createInsurerAction } from 'src/store/actions/catalogActions';
import InsurersList from './InsurerList';
import InsurerForm from './InsurerForm';
import Toolbar from '../Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const InsurersListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(null);
  }, [setOpen, setData]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCreate = useCallback(
    values => {
      delete values.serviceId;
      dispatch(createInsurerAction(values));
      handleClose();
    },
    [handleClose],
  );

  return (
    <Page className={classes.root} title="Aseguradoras">
      <Container maxWidth={false}>
        <Toolbar catalogLabel="Aseguradoras" handleOpen={handleOpen} />
        <Box mt={3}>
          <InsurersList />
        </Box>
      </Container>
      <InsurerForm
        open={open}
        data={data}
        handleClose={handleClose}
        handleCreate={handleCreate}
      />
    </Page>
  );
};

export default InsurersListView;
