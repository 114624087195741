import React from 'react';
import Proptypes from 'prop-types';
import { TextField } from '@material-ui/core';

const InputText = ({
  helperText,
  error,
  handleBlur,
  onChange,
  value,
  label,
  name,
  props,
  type,
}) => {
  return (
    <TextField
      error={error}
      fullWidth
      helperText={helperText}
      label={label}
      margin="normal"
      name={name}
      onBlur={handleBlur}
      onChange={onChange}
      type={type}
      value={value}
      variant="outlined"
      {...props}
    />
  );
};

InputText.propTypes = {
  error: Proptypes.bool.isRequired,
  helperText: Proptypes.string.isRequired,
  handleBlur: Proptypes.func.isRequired,
  onChange: Proptypes.func.isRequired,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  label: Proptypes.string.isRequired,
  name: Proptypes.string.isRequired,
  type: Proptypes.string,
  props: Proptypes.object,
};

InputText.defaultProps = {
  value: '',
  type: 'text',
};

export default InputText;
