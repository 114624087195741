import { Card } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  loadCampaignListAction,
  LOAD_CAMPAIGNS,
} from 'src/store/actions/campaignsActions';
import {
  selectCampaignsList,
  selectCampaignsListCount,
  selectCampaignsQueryOptions,
} from 'src/store/selectors/campaignsSelector';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import PropTypes from 'prop-types';
import CampaignsTable from './CampaignsTable';

const CampaignsList = ({ campaignType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const campaignsList = useSelector(selectCampaignsList);
  const count = useSelector(selectCampaignsListCount);
  const { page } = useSelector(selectCampaignsQueryOptions);

  const loading = useSelector(isLoadingSelector([LOAD_CAMPAIGNS.action]));
  const success = useSelector(successSelector([LOAD_CAMPAIGNS.action]));

  const handleChangePage = useCallback(
    value => {
      dispatch(loadCampaignListAction({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadCampaignListAction({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.CAMPAIGNS_LIST_TITLE')}
          description={t(
            'NOT_FOUND_RECORDS_MESSAGE.CAMPAIGNS_LIST_DESCRIPTION',
          )}
        />
      </Card>
    );
  }

  return (
    <CampaignsTable
      campaigns={campaignsList}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
      campaignType={campaignType}
    />
  );
};

CampaignsList.propTypes = {
  campaignType: PropTypes.string,
};

export default CampaignsList;
