import { push } from 'connected-react-router';
import {
  getAllCars,
  getCarByNiv,
  getInactiveCars,
  getAvailableCars,
  downloadeExportedCars,
  downloadeExportedInactiveCars,
  updateCarDocuments,
  uploadAndProcessXML,
  getUploadLink,
  uploadFile,
  createNewCar,
  updateCarMode,
  updateCarInactivity,
  unsubscribeCarFromSystem,
  getSoldCars,
  updateCarCategory,
  getCarAudits,
  getCarLocations,
  updateCarStatusService,
  editCarService,
} from 'src/services/carService';
import { cancelAssing } from 'src/services/driverService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { VAT_TAX } from 'src/views/fleet/CarDetail/utils';
import {
  selectCarAuditQueryOptions,
  selectCarLocationQueryOptions,
  selectCarQueryOptions,
} from '../selectors/carSelectors';
import { showMessage } from './snackBarActions';

export const SAVE_OPTIONS = 'SAVE_OPTIONS';
export const CLEAR_OPTIONS = 'CLEAR_OPTIONS';
export const LOAD_CARS = asyncActionCreator('LOAD_CARS');
export const CLEAR_CARS = 'CLEAR_CARS';
export const LOAD_DETAIL_CAR = asyncActionCreator('LOAD_DETAIL_CAR');
export const CANCEL_ASSIGNATION = asyncActionCreator('CANCEL_ASSIGNATION');
export const CLEAR_DETAIL_CAR = 'CLEAR_DETAIL_CAR';
export const UPDATE_CAR = asyncActionCreator('UPDATE_CAR');
export const UPLOAD_CAR_XML = asyncActionCreator('UPLOAD_CAR_XML');
export const CLEAR_UPLOAD_CAR_XML = 'CLEAR_UPLOAD_CAR_XML';
export const CREATE_CAR = asyncActionCreator('CREATE_CAR');
export const LOAD_AVAILABLE_CARS = asyncActionCreator('LOAD_AVAILABLE_CARS');
export const UNSUBSCRIBE_CAR = asyncActionCreator('UNSUBSCRIBE_CAR');
export const LOAD_SOLD_CARS = asyncActionCreator('LOAD_SOLD_CARS');
export const LOAD_CAR_AUDIT_LIST = asyncActionCreator('LOAD_CAR_AUDIT_LIST');
export const SAVE_CAR_AUDIT_OPTIONS = 'SAVE_CAR_AUDIT_OPTIONS';
export const LOAD_CAR_LOCATION_LIST = asyncActionCreator(
  'LOAD_CAR_LOCATION_LIST',
);
export const SAVE_CAR_LOCATION_OPTIONS = 'SAVE_CAR_LOCATION_OPTIONS';
export const UPDATE_CAR_STATUS = asyncActionCreator('UPDATE_CAR_STATUS');
export const EDIT_CAR = asyncActionCreator('EDIT_CAR');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectCarQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.searchCriteria) {
    reqOptions.searchCriteria = queryOpts.searchCriteria;
  }
  if (queryOpts.serie) {
    reqOptions.serie = queryOpts.serie;
  }
  if (queryOpts.plates) {
    reqOptions.plates = queryOpts.plates;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.mode) {
    reqOptions.mode = queryOpts.mode;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

const uploadSingleFile = async file => {
  if (!file) return Promise.reject(new Error('No file'));
  const { type } = file;
  const extension = file.name.split('.').pop();
  if (!extension) {
    throw new Error('No extension :(');
  }
  const link = await getUploadLink({ format: extension, contentType: type });
  const url = new URL(link);
  const attachmentId = url.pathname.slice(1);
  await uploadFile({ targetURL: link, file });
  return `https://meteoros-files.s3.us-east-2.amazonaws.com/${attachmentId}`;
};

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_OPTIONS });
};

export const loadCars = (options, sendLimit) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CARS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState, sendLimit);

    const data = await getAllCars(reqOptions);

    return dispatch({
      type: LOAD_CARS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CARS.failure });
  }
};

export const loadCarDetail = niv => async dispatch => {
  try {
    dispatch({ type: LOAD_DETAIL_CAR.start });
    const data = await getCarByNiv(niv);

    return dispatch({
      type: LOAD_DETAIL_CAR.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DETAIL_CAR.failure });
  }
};

export const loadInactiveCars = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CARS.start });
    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getInactiveCars(reqOptions);

    return dispatch({
      type: LOAD_CARS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CARS.failure });
  }
};

export const loadAvailableCars = () => async dispatch => {
  try {
    dispatch({ type: LOAD_AVAILABLE_CARS.start });
    const data = await getAvailableCars();

    return dispatch({
      type: LOAD_AVAILABLE_CARS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_AVAILABLE_CARS.failure });
  }
};

const updateCarAction = serviceCallback => (niv, values) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CAR.start });
    const data = await serviceCallback({ niv, values });

    dispatch(
      showMessage({
        message: 'Coche actualizado correctamente',
        variant: 'success',
      }),
    );
    return dispatch({
      type: UPDATE_CAR.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_CAR.failure });
  }
};

export const updateCarInactivityAction = values => async dispatch => {
  try {
    dispatch({ type: UPDATE_CAR.start });
    const data = await updateCarInactivity(values);

    dispatch(
      showMessage({
        message: 'Coche actualizado correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadInactiveCars());
    return dispatch({
      type: UPDATE_CAR.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_CAR.failure });
  }
};

export const updateCarModeAction = updateCarAction(updateCarMode);

export const updateCarCategoryAction = updateCarAction(updateCarCategory);

export const updateCarDocumentsAction = updateCarAction(async data => {
  let { tarjeta } = data.values;
  if (tarjeta && tarjeta.inDb) {
    const { values } = data;
    delete values.tarjeta;

    return updateCarDocuments({
      values,
      niv: data.niv,
    });
  }

  if (tarjeta) {
    tarjeta = await uploadSingleFile(tarjeta);
  }
  return updateCarDocuments({
    niv: data.niv,
    values: { ...data.values, tarjeta },
  });
});

export const downloadExcelCars = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadeExportedCars({ type: null, options });
  } catch (error) {
    console.log(dispatch);
    console.error(error);
  }
};

export const downloadExcelInactiveCars = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadeExportedInactiveCars(options);
  } catch (error) {
    console.log(dispatch);
    console.error(error);
  }
};

export const uploadCarXml = xml => async dispatch => {
  try {
    dispatch({ type: UPLOAD_CAR_XML.start });
    const data = await uploadAndProcessXML(xml);

    return dispatch({
      type: UPLOAD_CAR_XML.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPLOAD_CAR_XML.failure });
  }
};

export const clearCarXml = () => dispatch => {
  return dispatch({
    type: CLEAR_UPLOAD_CAR_XML,
  });
};

export const createCar = (values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: CREATE_CAR.start });
    // Upload all assets.
    const { invoicePDF, tarjeta, invoiceXML } = values;
    const invoices = [
      uploadSingleFile(invoicePDF),
      uploadSingleFile(invoiceXML),
    ];
    if (tarjeta) {
      invoices.push(uploadSingleFile(tarjeta));
    }

    const [pdf, xml, img = ''] = await Promise.all(invoices);

    const car = {
      ...values,
      niv: values.serie.slice(-5),
      vat: Number((values.total * VAT_TAX).toFixed(2)),
      invoicePDF: pdf,
      invoiceXML: xml,
      tarjeta: img,
    };

    delete car.catalog;

    await createNewCar(car);

    closeCallback();

    dispatch(
      showMessage({
        message: 'CREATE_CAR',
        variant: 'success',
      }),
    );

    dispatch(push(`/fleet/car/${car.niv}`));

    return dispatch({ type: CREATE_CAR.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_CAR.failure });
  }
};

export const loadSoldCars = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_SOLD_CARS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getSoldCars(reqOptions);

    return dispatch({
      type: LOAD_SOLD_CARS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_SOLD_CARS.failure });
  }
};

export const unsubscribeCar = (values, handleClose) => async dispatch => {
  try {
    dispatch({ type: UNSUBSCRIBE_CAR.start });

    const { saleInvoicePdfFile } = values;

    const urlSaleInvoicePdf = await uploadSingleFile(saleInvoicePdfFile);

    const car = {
      ...values,
      saleInvoicePdf: urlSaleInvoicePdf,
    };

    await unsubscribeCarFromSystem(car);

    dispatch(
      showMessage({
        message: 'CAR_UNSUBSCRIBE',
        variant: 'success',
      }),
    );

    handleClose();

    dispatch(loadInactiveCars());

    return dispatch({ type: UNSUBSCRIBE_CAR.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UNSUBSCRIBE_CAR.failure });
  }
};

export const cancelAssignDriver = ({ driverId, niv }) => async dispatch => {
  try {
    dispatch({ type: CANCEL_ASSIGNATION.start });

    await cancelAssing({ driverId });

    dispatch({ type: CANCEL_ASSIGNATION.success });

    return dispatch(loadCarDetail(niv));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch({ type: CANCEL_ASSIGNATION.failure });
    return dispatch({ type: LOAD_DETAIL_CAR.failure });
  }
};

function getCarAuditOptions(getState, sendLimit = true) {
  const queryOpts = selectCarAuditQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadCarAuditList = (options, niv) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_CAR_AUDIT_LIST.start });

    dispatch({
      type: SAVE_CAR_AUDIT_OPTIONS,
      options,
    });
    const reqOptions = getCarAuditOptions(getState);

    const data = await getCarAudits({ niv, options: reqOptions });

    return dispatch({
      type: LOAD_CAR_AUDIT_LIST.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CAR_AUDIT_LIST.failure });
  }
};

function getCarLocationOptions(getState, sendLimit = true) {
  const queryOpts = selectCarLocationQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.carniv) {
    reqOptions.carniv = queryOpts.carniv;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadCarLocationList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CAR_LOCATION_LIST.start });

    dispatch({
      type: SAVE_CAR_LOCATION_OPTIONS,
      options,
    });
    const reqOptions = getCarLocationOptions(getState);

    const data = await getCarLocations(reqOptions);

    return dispatch({
      type: LOAD_CAR_LOCATION_LIST.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CAR_LOCATION_LIST.failure });
  }
};

export const updateCarStatus = options => async dispatch => {
  try {
    await updateCarStatusService(options);

    dispatch({
      type: UPDATE_CAR.success,
    });

    dispatch(
      showMessage({
        message: 'CAR_PUT_ON_SALE',
        variant: 'success',
      }),
    );

    return dispatch(loadInactiveCars());
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_CAR.failure });
  }
};

export const editCar = options => async dispatch => {
  try {
    await editCarService(options);

    dispatch({
      type: EDIT_CAR.success,
    });

    dispatch(
      showMessage({
        message: 'EDIT_CAR',
        variant: 'success',
      }),
    );

    return dispatch(loadInactiveCars());
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: EDIT_CAR.failure });
  }
};
