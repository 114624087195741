import * as Yup from 'yup';

export default Yup.object().shape({
  civilStatus: Yup.string().nullable(),
  relationShip: Yup.string()
    .oneOf(
      ['Esposa', 'Hijo', 'Padre', 'Madre'],
      'SCHEMA_ERRORS.SELECT_VALUE_LIST',
    )
    .nullable(),
  mobile: Yup.string()
    .max(15, 'SCHEMA_ERRORS.ENTER_FIFTEEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PHONE_NUMBER'),
  email: Yup.string()
    .email()
    .required('SCHEMA_ERRORS.ENTER_EMAIL_VALID'),
  economicDependents: Yup.number()
    .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .nullable(),
  homeTown: Yup.string().nullable(),
  houseStatus: Yup.string().nullable(),
  homeYears: Yup.number().nullable(),
  ifeLicence: Yup.number().nullable(),
});
