import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Breadcrumb from 'src/components/Breadcrumb';
import { Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import {
  CLEAR_DEVICE,
  createGeofencing,
  loadGeofencingList,
} from 'src/store/actions/gpsActions';
import GeofencingForm from './GeofencingForm';
import useStyles from './styles';

const GeofencingToolbar = ({
  className,
  handleCloseConfirmation,
  handleSubmitAction,
  openConfirmation,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(loadGeofencingList());
    return () => dispatch({ type: CLEAR_DEVICE });
  }, []);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreateGeofencing = useCallback(
    values => {
      dispatch(createGeofencing(values, () => handleClose()));
    },
    [dispatch, handleClose],
  );

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'GPS']}
          current="NAVIGATION.GEOFENCING"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400', textTransform: 'capitalize' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.GEOFENCING')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Button
              className={clsx(classes.buttons, classes.horizontalPadding)}
              onClick={handleClickOpen}
              color="primary"
              variant="contained"
            >
              <PlusIcon style={{ marginRight: 8 }} fill="white" />
              {t('GPS_VIEW.ADD_GEOFENCE')}
            </Button>
          </div>
        </div>
      </div>

      <GeofencingForm
        open={open}
        handleCreateGeofencing={handleCreateGeofencing}
        handleClose={handleClose}
      />
    </>
  );
};

GeofencingToolbar.propTypes = {
  className: PropTypes.string,
  openConfirmation: PropTypes.bool,
  handleCloseConfirmation: PropTypes.func,
  handleSubmitAction: PropTypes.func,
};

export default GeofencingToolbar;
