import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import PrivateRoute from 'src/components/PrivateRoute';
import FinesListView from './FinesList';
import FinesReportView from './FinesReportView';

const FinesView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}/list`} component={FinesListView} />
      <PrivateRoute path={`${match.url}/reports`} component={FinesReportView} />
      <PrivateRoute
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/list`} />}
      />
    </>
  );
};

FinesView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default FinesView;
