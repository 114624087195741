import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DropdownMenu from 'src/components/DropdownMenu';
import {
  loadGeofencingCategoriesList,
  loadGeofencingZonesList,
} from 'src/store/actions/catalogActions';

import useStyles from '../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const StepOne = ({ data, handleBack, handleNext }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [categories, setCategories] = React.useState([]);
  const [zones, setZones] = React.useState([]);

  useEffect(() => {
    dispatch(loadGeofencingCategoriesList(setCategories));
    dispatch(loadGeofencingZonesList(setZones));
  }, []);

  const onBack = useCallback(
    resetForm => () => {
      resetForm();
      handleBack();
    },
    [handleBack],
  );

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext],
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <DropdownMenu
            list={zones.map(zone => {
              return { key: zone.id, name: zone.zone };
            })}
            value={values.zoneId}
            setValue={value => setFieldValue('zoneId', value)}
            label={t('COMMON.ZONE')}
            error={Boolean(touched.zoneId && errors.zoneId)}
            errorText={touched.zoneId && t(errors.zoneId)}
          />

          <DropdownMenu
            list={categories.map(category => {
              return { key: category.id, name: category.category };
            })}
            value={values.categoryId}
            setValue={value => setFieldValue('categoryId', value)}
            label={t('COMMON.CATEGORY')}
            error={Boolean(touched.categoryId && errors.categoryId)}
            errorText={touched.categoryId && t(errors.categoryId)}
          />

          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && t(errors.name)}
            label={t('COMMON.NAME')}
            margin="normal"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.name}
            variant="outlined"
          />

          <Box
            className={classes.box}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              className={classes.buttons}
              onClick={onBack(resetForm)}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.CLOSE')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.buttonMain)}
              type="submit"
              color="primary"
              variant="outlined"
            >
              {t('COMMON.NEXT')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
StepOne.propTypes = {
  data: PropTypes.object,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export default StepOne;
