import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  Box,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Map, TileLayer, Popup, Marker } from 'react-leaflet';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectGpsDevicesLocation } from 'src/store/selectors/gpsSelectors';
import { loadDevicesLocationList } from 'src/store/actions/gpsActions';
import { MAP_UPDATE_LITERAL } from 'src/utils/constants';
import useStyles from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ListDevicesInMapDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const locations = useSelector(selectGpsDevicesLocation) || [];

  useEffect(() => {
    dispatch(loadDevicesLocationList());
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  useEffect(() => {
    if (open) {
      dispatch(loadDevicesLocationList());
    }
    const mapReload = setInterval(() => {
      if (open === true) {
        dispatch(loadDevicesLocationList());
      } else {
        clearInterval(mapReload);
      }
    }, MAP_UPDATE_LITERAL);

    return () => {
      clearInterval(mapReload);
    };
  }, [open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogContent className={classes.dialogContainer}>
        <Box
          container
          direction="column"
          alignItems="stretch"
          alignContent="flex-end"
          position="absolute"
          top="25px"
          right="25px"
          zIndex="40001"
        >
          <IconButton
            aria-label="delete"
            size="large"
            classname={clsx(classes.label, classes.closeButton)}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Map
          center={['25.6708891', '-100.3789822']}
          zoom={10}
          scrollWheelZoom={false}
          className={clsx(classes.map)}
        >
          <TileLayer
            attribution="&copy; <a>Meteoros</a> "
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {locations.map(device => {
            return (
              <Marker
                position={[device.latitude || '', device.longitude || '']}
              >
                <Popup>
                  <span>{device.name}</span>
                </Popup>
              </Marker>
            );
          })}
        </Map>
      </DialogContent>
    </Dialog>
  );
};

ListDevicesInMapDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ListDevicesInMapDialog;
