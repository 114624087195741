import React from 'react';
import moment from 'moment';
import DetailRow from 'src/components/DetailRow';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import TwoColumnsRow from 'src/components/TwoColumnsRow';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from '../styles';
import { CAR_MODES } from '../utils';

const BasicCarInfo = ({ car, handleOpenChangeCategory }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {car.mode === CAR_MODES.PLATFORM && (
        <TwoColumnsRow
          name={t('COMMON.CATEGORY')}
          value={car.category || t('COMMON.WITHOUT_CATEGORY')}
          extraStyles={{ justify: 'flex-end' }}
          extra={
            <Tooltip title={t('TOOLTIPS_MESSAGES.CAR_CATEGORY_CHANGE')}>
              <Button
                variant="contained"
                onClick={handleOpenChangeCategory}
                className={classes.twoColumnIcon}
              >
                <Edit color="primary" />
              </Button>
            </Tooltip>
          }
        />
      )}

      <DetailRow name={t('COMMON.NIV')} value={car.niv} />

      <DetailRow name={t('COMMON.BRAND')} value={car.carModel} />

      <DetailRow name={t('FLEET_VIEW.MODEL')} value={car.type} />

      <DetailRow name={t('FLEET_VIEW.TYPE')} value={car.version} />

      <DetailRow name={t('FLEET_VIEW.YEAR')} value={car.year} />

      <DetailRow name={t('COMMON.TRANSMISSION')} value={car.transmission} />

      <DetailRow name={t('COMMON.COLOR')} value={car.color} />

      <DetailRow name={t('CAR_DETAIL.SERIAL_NUMBER')} value={car.serie} />

      <DetailRow name={t('CAR_DETAIL.ENGINE_NUMBER')} value={car.motor} />

      <DetailRow
        name={t('INSURANCE_BALANCE_VIEW.MONTHS_OF_LIFE')}
        value={
          car.purchaseDate
            ? moment().diff(moment(car.purchaseDate), 'months')
            : ''
        }
      />

      <DetailRow
        name={t('COMMON.PRICE')}
        value={formatPositiveAmount(car.price)}
      />
    </>
  );
};

BasicCarInfo.propTypes = {
  car: PropTypes.shape({
    niv: PropTypes.string,
    mode: PropTypes.string,
    category: PropTypes.string,
    carModel: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.string,
    year: PropTypes.string,
    transmission: PropTypes.string,
    color: PropTypes.string,
    serie: PropTypes.string,
    motor: PropTypes.string,
    purchaseDate: PropTypes.instanceOf(Date),
    price: PropTypes.number,
  }),
  handleOpenChangeCategory: PropTypes.func,
};

export default BasicCarInfo;
