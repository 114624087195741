import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 48,
    width: 200,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    minWidth: 'unset',
    paddingBottom: theme.spacing(2),
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  typography: {
    padding: theme.spacing(2),
    fontSize: 14,
    fontWeight: 'bold',
  },
  buttonMargin: {
    marginBottom: '16px !important',
  },
}));

const makeInitialState = filters => {
  return {
    pending: filters.pending || false,
    processed: filters.processed || false,
  };
};

const DriverBalanceFilter = ({
  anchorEl,
  handleClose,
  filterValues,
  handleFilterSubmit,
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();

  const initialState = useMemo(() => makeInitialState(filterValues), [
    filterValues,
    anchorEl,
  ]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, anchorEl]);

  return (
    <div className={classes.root}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Formik initialValues={initialState} onSubmit={handleFilterSubmit}>
          {({ handleChange, handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormGroup className={classes.horizontalPadding}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.typography}
                  >
                    {t('MOVEMENT.BY_STATUS')}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="pending"
                        color="primary"
                        value={values.pending}
                        checked={values.pending}
                        onChange={handleChange}
                      />
                    }
                    label={t('MOVEMENT.STATUS.PENDING')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="processed"
                        color="primary"
                        value={values.processed}
                        checked={values.processed}
                        onChange={handleChange}
                      />
                    }
                    label={t('MOVEMENT.STATUS.PROCESSED')}
                  />
                  <Button
                    className={
                      (classes.buttons,
                      classes.typography,
                      classes.horizontalPadding,
                      classes.buttonMargin)
                    }
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {t('COMMON.APPLY')}
                  </Button>
                </FormGroup>
              </form>
            );
          }}
        </Formik>
      </Popover>
    </div>
  );
};

DriverBalanceFilter.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  filterValues: PropTypes.any,
};

export default DriverBalanceFilter;
