/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { FileMinus } from 'react-feather';
import { useDispatch } from 'react-redux';
import Tooltip from 'src/components/Tooltip';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { revokeLeaseAction } from 'src/store/actions/driverActions';
import { ContractStatus } from 'src/utils/constants';
import RevokeLeaseDialog from './RevokeLease';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'white',
    color: 'white',
  },
  tooltip: {
    fontSize: 15,
  },
}));

const initialState = {
  hitch: '',
  startDate: '',
  endDate: '',
  optionToBuy: false,
};

const remainingPorcentageAvance = (initialDate, endDate) => {
  const daysRemaing = moment(initialDate).diff(endDate, 'days');
  const actualCurrentDays = moment(initialDate).diff(moment(), 'days');
  return Math.round((actualCurrentDays / daysRemaing) * 100);
};

const GeneralLease = ({ driver }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openRevoke, setOpenRevoke] = useState(false);
  const [{ hitch, startDate, endDate, optionToBuy }, setLeaseData] = useState(
    initialState,
  );

  useEffect(() => {
    if (driver.actualContract) {
      return setLeaseData({
        hitch: driver.actualContract.hitch,
        startDate: driver.actualContract.startDate,
        endDate: driver.actualContract.endDate,
        optionToBuy: driver.actualContract.optionToBuy,
      });
    }
    if (driver.actualDriverLease) {
      return setLeaseData({
        hitch: driver.actualDriverLease.hitch,
        startDate: driver.actualDriverLease.initialDate,
        endDate: driver.actualDriverLease.endDate,
        optionToBuy: driver.actualDriverLease.optionToBuy,
      });
    }

    return setLeaseData(initialState);
  }, [driver]);

  const handleClickOpen = useCallback(() => {
    setOpenRevoke(true);
  }, [setOpenRevoke]);

  const handleClose = useCallback(() => {
    setOpenRevoke(false);
  }, [setOpenRevoke]);

  const handleRevoke = useCallback(() => {
    if (driver.actualDriverLease) {
      dispatch(revokeLeaseAction(driver.actualDriverLease.id, driver.id));
    }
    handleClose();
  }, [handleClose, dispatch, driver]);

  const setContractStatus = assignationStatus => {
    if (assignationStatus === ContractStatus.PENDING_SIGNATURE) {
      return 'DRIVER_DETAIL_VIEW.LEASE.CONTRACT_STATUS_PENDING_SIGNATURE';
    }
    if (assignationStatus === ContractStatus.CANCELLED) {
      return 'DRIVER_DETAIL_VIEW.LEASE.CONTRACT_STATUS_CANCELLED';
    }
    if (assignationStatus === ContractStatus.ENDED) {
      return 'DRIVER_DETAIL_VIEW.LEASE.CONTRACT_STATUS_ENDED';
    }
    return 'DRIVER_DETAIL_VIEW.LEASE.CONTRACT_STATUS_IN_PROGRESS';
  };

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography component="h5" variant="h5" color="primary">
            {t('DRIVER_DETAIL_VIEW.LEASE.CONTRACT_INFORMATION')}
          </Typography>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            {driver.actualDriverLease || driver.actualContract ? (
              <>
                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.CONTRACT_INFORMATION')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {formatPositiveAmount(hitch)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.PURCHASE_OPTION')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {optionToBuy ? t('COMMON.YES') : t('COMMON.NO')}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.CONTRACT_START')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {moment(startDate).format('DD-MMM-YY')}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.CONTRACT_END')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {moment(endDate).format('DD-MMM-YY')}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.PORCENTAGE_COMPLETED')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {remainingPorcentageAvance(startDate, endDate)}
                      %
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.REMAINING_WEEKS')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {moment(endDate).diff(moment(), 'weeks')}
                    </Typography>
                  </TableCell>
                </TableRow>

                {driver.actualContract && (
                  <TableRow>
                    <TableCell style={{ fontWeight: 500 }}>
                      {t('DRIVER_DETAIL_VIEW.LEASE.CONTRACT_STATUS')}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="textSecondary">
                        {t(setContractStatus(driver.actualContract.status))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : null}
          </TableBody>
        </Table>

        <RevokeLeaseDialog
          open={openRevoke}
          handleClose={handleClose}
          handleRevoke={handleRevoke}
        />

        {driver.actualDriverLease && (
          <Box p={1} display="flex" justifyContent="center">
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              condition={driver.actualDriverLease && !!driver.actualDriverName}
              text={t('DRIVER_DETAIL_VIEW.LEASE.REVOKE_CONTRACT_TEXT')}
            >
              <Button
                style={{ width: '100%' }}
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
                startIcon={<FileMinus />}
                disabled={driver.actualDriverLease && !!driver.actualDriverName}
              >
                {t('DRIVER_DETAIL_VIEW.LEASE.REVOKE_CONTRACT')}
              </Button>
            </Tooltip>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default GeneralLease;
