import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import InsuranceRow from './InsuranceRow';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    maxWidth: '10%',
  },
}));

const InsurancesTable = ({
  className,
  insurances,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('niv');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
      handleSortChange(property, isDesc ? 'asc' : 'desc');
    },
    [order, orderBy, setOrder, setOrderBy, handleSortChange],
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell variant="head">ID</TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('COMMON.STATUS')}
      </TableCell>
      <TableCell
        variant="head"
        key="remainingDays"
        sortDirection={orderBy === 'remainingDays' ? order : false}
        className={classes.tableHead}
      >
        <TableSortLabel
          active={orderBy === 'remainingDays'}
          direction={orderBy === 'remainingDays' ? order : 'asc'}
          onClick={createSortHandler('remainingDays')}
        >
          {t('COMMON.EXPIRATION')}
          {orderBy === 'remainingDays' ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('INSURANCE_VIEW.POLICY_NUMBER')}
      </TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('INSURANCE_VIEW.INSURER')}
      </TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('INSURANCE_VIEW.AGENT')}
      </TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('INSURANCE_VIEW.POLICY_TYPE')}
      </TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('INSURANCE_VIEW.COVERAGE')}
      </TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('COMMON.MODALITY')}
      </TableCell>
      <TableCell variant="head" className={classes.tableHead}>
        {t('COMMON.PLATES')}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return insurances.map(insurance => (
        <InsuranceRow {...insurance} key={insurance.policyId} />
      ));
    },
    [insurances],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, insurances],
  );

  return (
    <Table
      {...rest}
      theads={theads}
      tbody={tbody}
      pagination={pagination}
      loading={loading}
    />
  );
};

InsurancesTable.propTypes = {
  className: PropTypes.string,
  insurances: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default InsurancesTable;
