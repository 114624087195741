import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useHistory } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import GeneralView from './General';
import DriversHistory from './DriversHistory';
import GpsView from './GpsView';
import MaintenanceView from './MaintenanceView';
import CarPositionView from './CarPositionView';
import Audit from './CarAudit';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `car-detail-tab-${index}`,
    'aria-controls': `car-detail-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tabs: { overflow: 'auto', whiteSpace: 'nowrap' },
  tab: { pointerEvents: 'auto !important' },
}));

export default function CarTabs({ car }) {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  const selectTabStatus = (label, error) => {
    if (error) {
      return (
        <Tooltip title={t('COMMON.NO_INFORMATION')}>
          <span>{label}</span>
        </Tooltip>
      );
    }
    return <span>{label}</span>;
  };

  return (
    <div className={classes.root}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.FLEET', 'FLEET_VIEW.TITLE']}
        current={car.niv}
      />
      <AppBar
        variant="outlined"
        position="static"
        style={{
          background: 'transparent',
          border: 'none',
          flexDirection: 'row',
        }}
      >
        <IconButton
          onClick={goBack}
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="tabs for car detail"
        >
          <Tab label="General" {...a11yProps(0)} className={classes.tab} />
          <Tab
            label={t('CAR_DETAIL.DRIVER_HISTORY')}
            {...a11yProps(1)}
            className={classes.tab}
          />
          <Tab
            label={t('FLEET_VIEW.AUDIT')}
            {...a11yProps(2)}
            className={classes.tab}
          />
          <Tab
            label={selectTabStatus('GPS', !car.gpsDevices.length)}
            disabled={!car.gpsDevices.length}
            {...a11yProps(3)}
            className={classes.tab}
          />
          <Tab
            // disable for now until refactor disabled={!car.carPosition.length}disabled={!car.maintenances.length}
            disabled
            label={selectTabStatus(
              t('COMMON.MAINTENANCE'),
              !car.maintenances.length,
            )}
            {...a11yProps(4)}
            className={classes.tab}
          />
          <Tab
            disabled={!car.carPosition.length}
            label={selectTabStatus(
              t('CAR_DETAIL.COORDINATE_REGISTRATION'),
              !car.carPosition.length,
            )}
            {...a11yProps(5)}
            className={classes.tab}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeneralView car={car} t={t} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DriversHistory car={car} t={t} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Audit car={car} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GpsView car={car} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <MaintenanceView car={car} t={t} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <CarPositionView car={car} t={t} />
      </TabPanel>
    </div>
  );
}
CarTabs.propTypes = {
  car: PropTypes.object.isRequired,
};
