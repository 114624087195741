/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { Card } from '@material-ui/core';
import {
  selectCarLocationList,
  selectCarLocationListCount,
  selectCarLocationQueryOptions,
} from 'src/store/selectors/carSelectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import {
  loadCarLocationList,
  LOAD_CAR_LOCATION_LIST,
} from 'src/store/actions/carActions';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { useTranslation } from 'react-i18next';
import CarPositionTable from './CarPositionTable/CarPositionTable';

const CarPositionView = ({ car }) => {
  const { t } = useTranslation();
  const locations = useSelector(selectCarLocationList);
  const count = useSelector(selectCarLocationListCount);
  const { page } = useSelector(selectCarLocationQueryOptions);
  const loading = useSelector(
    isLoadingSelector([LOAD_CAR_LOCATION_LIST.action]),
  );
  const success = useSelector(successSelector([LOAD_CAR_LOCATION_LIST.action]));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCarLocationList({ carniv: car.niv }));
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadCarLocationList({ carniv: car.niv, page: value }, car.niv));
    },
    [dispatch],
  );

  if (!success && locations && locations.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.COORDINATES_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.COORDINATES_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <CarPositionTable
      locations={locations}
      handleChangePage={handleChangePage}
      count={count}
      page={page}
      loading={loading}
    />
  );
};

export default CarPositionView;
