import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';

const BillingParametersRow = ({ value, description }) => (
  <TableRow hover>
    <TableCell>{description}</TableCell>
    <TableCell>{value}</TableCell>
  </TableRow>
);

BillingParametersRow.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
};

export default BillingParametersRow;
