/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import { DriverStatus } from 'src/utils/constants';
import GeneralView from './General';
import BalanceView from './Balance';
import IncidentsHistory from './IncidentsHistory';
import LeaseView from './Lease';
import AvalView from './Aval';
import Metrics from './Metrics';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

export default function DriverTabs({ driver }) {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  const selectTabStatus = (label, error) => {
    if (error) {
      return (
        <Tooltip title={t('COMMON.NO_INFORMATION')}>
          <span>{label}</span>
        </Tooltip>
      );
    }
    return <span>{label}</span>;
  };

  const getStatusText = useMemo(() => {
    switch (driver.status) {
      case DriverStatus.LEFT:
        return t('NAVIGATION.LEFT');
      case DriverStatus.ACTIVE:
        return t('NAVIGATION.ACTIVE');
      case DriverStatus.REJECTED:
        return t('NAVIGATION.REJECTED');
      default:
        return t('NAVIGATION.CANDIDATES');
    }
  }, [driver]);

  const disabledTabForActiveOrLeftStatus = status => {
    switch (status) {
      case DriverStatus.ACTIVE:
        return false;
      case DriverStatus.LEFT:
        return false;
      default:
        return true;
    }
  };

  return (
    <div className={classes.root}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.DRIVERS', getStatusText]}
        current={driver.fullName}
      />
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('DRIVER_DETAIL_VIEW.GENERAL_TAB')} />
          <Tab label={t('DRIVER_DETAIL_VIEW.AVAL_DATA')} />

          <Tab
            label={selectTabStatus(
              t('DRIVER_DETAIL_VIEW.BALANCE_TAB'),
              disabledTabForActiveOrLeftStatus(driver.status),
            )}
            disabled={disabledTabForActiveOrLeftStatus(driver.status)}
          />

          <Tab
            label={selectTabStatus(
              t('DRIVER_DETAIL_VIEW.INCIDENTS_TAB'),
              disabledTabForActiveOrLeftStatus(driver.status),
            )}
            disabled={disabledTabForActiveOrLeftStatus(driver.status)}
          />

          <Tab
            label={t('DRIVER_DETAIL_VIEW.LEASE_TAB')}
            disabled={!driver.actualDriverCar}
          />

          <Tab
            // disable for now until refactor
            label={t('DRIVER_DETAIL_VIEW.METRICS_TAB')}
            disabled
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeneralView driver={driver} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AvalView driver={driver} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BalanceView driver={driver} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <IncidentsHistory driver={driver} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <LeaseView driver={driver} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Metrics driver={driver} />
      </TabPanel>
    </div>
  );
}
