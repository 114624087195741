import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { useTranslation } from 'react-i18next';
import {
  clearIncomeResume,
  loadIncomeResume,
  downloadIncomeResume,
} from 'src/store/actions/incomeResumeActions';
import IncomeResumeStatus from 'src/utils/IncomeResumeStatus';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { selectIncomeResumeOptions } from 'src/store/selectors/incomeResumeSelectors';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { BalanceType } from 'src/utils/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const filters = [
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'status',
    items: [
      {
        label: 'COMMON.ALL',
        value: IncomeResumeStatus.ALL,
      },
      {
        label: 'INCOME_VIEW.ACTIVE',
        value: IncomeResumeStatus.ACTIVE,
      },
      {
        label: 'INCOME_VIEW.INACTIVE',
        value: IncomeResumeStatus.INACTIVE,
      },
      {
        label: 'INCOME_VIEW.EXTRAJUDICIAL',
        value: IncomeResumeStatus.EXTRAJUDICIAL,
      },
    ],
  },
  {
    title: 'COMMON.BY_BALANCE_TYPE',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'balanceType',
    items: [
      {
        label: 'COMMON.TOTAL',
        value: BalanceType.TOTAL,
      },
      {
        label: 'COMMON.RENT',
        value: BalanceType.RENT,
      },
      {
        label: 'COMMON.OTHERS',
        value: BalanceType.OTHERS,
      },
    ],
  },
  {
    title: 'FLEET_VIEW.MODE',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'carMode',
    items: [
      {
        label: 'COMMON.ALL',
        value: 'ALL',
      },
      {
        label: 'FLEET_VIEW.LEASE',
        value: 'LEASE',
      },
      {
        label: 'FLEET_VIEW.PLATFORM',
        value: 'PLATFORM',
      },
    ],
  },
];

const Toolbar = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectIncomeResumeOptions);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    status: IncomeResumeStatus.ALL,
    balanceType: BalanceType.TOTAL,
    carMode: 'ALL',
  });

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      dispatch(
        loadIncomeResume({
          ...queryOptions,
          ...event,
          page: 0,
        }),
      );
      handleCloseFilter();
    },
    [queryOptions],
  );

  useEffect(() => {
    dispatch(
      loadIncomeResume({
        balanceType: BalanceType.TOTAL,
      }),
    );
    return () => dispatch(clearIncomeResume());
  }, []);

  const handleExcelExport = useCallback(() => {
    dispatch(downloadIncomeResume());
  }, [dispatch]);

  return (
    <>
      <div className={classes.root} {...props}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.INCOME']}
          current="NAVIGATION.RENT_RESUME"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.RENT_RESUME')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleOpenFilter}
            >
              <FilterIcon className={classes.icon} style={{ marginRight: 8 }} />
              {t('COMMON.FILTER')}
            </Button>
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
