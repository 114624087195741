import moment from 'moment';

export default values => ({
  purchaseDate: values.purchaseDate || moment(),
  rfc: values.rfc || '',
  motor: values.motor || '',
  brand: values.brand || '',
  year: values.year ? moment().year(values.year) : moment(),
  type: values.type || '',
  version: values.version || '',
  transmission: values.transmission || '',
  color: values.color || '',
  category: values.category || '',
});
