/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateContactBusinessInfoAction } from 'src/store/actions/businessActions';
import ContactBusinessInfo from './ContactBusinessInfo';
import ContactBusinessDialog from './ContactBusinessDialog';
import useStyles from '../styles';

const GeneralBusiness = ({ business }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateContactBusinessInfoAction(business.id, values));
    },
    [business, dispatch],
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('COMMON.CONTACT')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <ContactBusinessInfo business={business} />
          </TableBody>
        </Table>
        <ContactBusinessDialog
          open={open}
          business={business}
          handleClose={handleClose}
          submit={handleSubmit}
        />

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<EditIcon />}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GeneralBusiness;
