import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  Grid,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { modifyAval } from 'src/store/actions/driverActions';
import { useTranslation } from 'react-i18next';
import { DriverStatus } from 'src/utils/constants';
import PropTypes from 'prop-types';
import useStyles from '../styles';
import AddressInfo from './AddressInfo';
import EditAddressDialog from './EditAddressDialog';

const AvalAddress = ({ driverId, aval, driverStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSumbit = useCallback(
    values => {
      dispatch(modifyAval(driverId, values));
    },
    [aval, dispatch],
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('DRIVER_DETAIL_VIEW.AVAL.RESIDENCE')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      {aval && (
        <CardContent style={{ padding: 0, marginBottom: '16px' }}>
          <Table>
            <TableBody>
              <AddressInfo address={aval.address} />
            </TableBody>
          </Table>
          <Grid container direction="column" alignItems="stretch">
            <Grid item className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.label, classes.buttons)}
                startIcon={<EditIcon />}
                onClick={handleOpen}
                disabled={driverStatus === DriverStatus.LEFT}
              >
                {t('COMMON.EDIT')}
              </Button>
            </Grid>
          </Grid>
          <EditAddressDialog
            aval={aval}
            open={open}
            handleClose={handleClose}
            submit={handleSumbit}
          />
        </CardContent>
      )}
    </Card>
  );
};

AvalAddress.propTypes = {
  driverId: PropTypes.string,
  driverStatus: PropTypes.string,
  aval: PropTypes.object,
};

export default AvalAddress;
