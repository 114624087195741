import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.ENTER_NAME'),
  email: Yup.string()
    .email()
    .required('SCHEMA_ERRORS.ENTER_EMAIL_VALID'),
  paternalName: Yup.string().required('SCHEMA_ERRORS.ENTER_PATERNAL_NAME'),
  maternalName: Yup.string().required('SCHEMA_ERRORS.ENTER_MATERNAL_NAME'),
  region: Yup.string()
    .oneOf(
      ['México', 'Nuevo León', 'Aguascalientes'],
      'SCHEMA_ERRORS.SELECT_VALUE_LIST',
    )
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  mobile: Yup.string()
    .length(10, 'SCHEMA_ERRORS.ENTER_TEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PHONE_NUMBER'),
});
