/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  makeStyles,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { unsubscribeCar } from 'src/store/actions/carActions';
import { useDispatch } from 'react-redux';
import RemoveCarForm from './RemoveCarForm';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`,
    },
  },
  root: {
    minWidth: theme.spacing(68),
  },
  closeIcon: {
    float: 'right',
    marginTop: '0px',
    width: '20px',
    height: '20px',
  },
}));

const RemoveCarFormDialog = ({ open, handleClose, data }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    if (resetRef.current) {
      resetRef.current();
    }
    handleClose();
  }, [handleClose, resetRef]);

  const handleSubmitForm = useCallback(
    values => {
      dispatch(unsubscribeCar(values, handleClose));
    },
    [data],
  );

  return (
    <Box>
      <Dialog
        aria-labelledby="form-unsubscribe-car-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={onClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-unsubscribe-car-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('FLEET_VIEW.UNSUBSCRIBE_CAR')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <RemoveCarForm
            resetRef={resetRef}
            handleClose={onClose}
            data={data}
            handleSubmitForm={handleSubmitForm}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default RemoveCarFormDialog;
