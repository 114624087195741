import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from 'src/components/Table/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from '../styles';

const CarCategoryRow = ({
  name,
  description,
  price,
  openEdit,
  handleDelete,
}) => {
  const classes = useStyles();

  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell style={{ width: 130 }}>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={openEdit}
          key="edit"
          aria-label="Edit"
          color="inherit"
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={() => handleDelete(name)}
          key="delete"
          aria-label="Delete"
          color="inherit"
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

CarCategoryRow.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  openEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default CarCategoryRow;
