import React, { useMemo } from 'react';
import { TableRow, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TableCell from 'src/components/Table/TableCell';
import { ReactComponent as UnssignedIcon } from 'src/assets/icons/Pending.svg';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { ErrorOutline } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CAR_STATUS } from '../CarDetail/utils';
import useStyles from './styles';
import { IconStatusAndColor } from '../../../utils/constants';

const getStatusIcon = (status, t) => {
  if (!status) {
    return null;
  }

  switch (status) {
    case CAR_STATUS.PENDING_ASSIGNATION:
      return (
        <Tooltip title={t('FLEET_VIEW.PENDING_ASSIGNATION')}>
          <ErrorOutline style={{ color: IconStatusAndColor.WARNING.color }} />
        </Tooltip>
      );
    case CAR_STATUS.ASSIGNED:
      return (
        <Tooltip title={t('FLEET_VIEW.ASSIGNED')}>
          <CheckCircleIcon style={{ color: IconStatusAndColor.OK.color }} />
        </Tooltip>
      );
    case CAR_STATUS.AVAILABLE:
      return (
        <Tooltip title={t('FLEET_VIEW.AVAILABLE')}>
          <UnssignedIcon fill="#fdd835" />
        </Tooltip>
      );

    default:
      return null;
  }
};

const CarRow = ({
  niv,
  status,
  mode,
  driver,
  plates,
  policyId,
  carModel,
  type,
  year,
  costPerWeek,
  audit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const client = useMemo(
    () =>
      driver
        ? `${driver.name} ${driver.paternalName} ${driver.maternalName}`
        : '',
    [driver],
  );

  const statusIcon = getStatusIcon(status, t);

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/fleet/car/${niv}`}>{niv}</Link>
      </TableCell>
      <TableCell>{statusIcon}</TableCell>
      <TableCell>{t(`FLEET_VIEW.${mode}`) || ''}</TableCell>
      <TableCell>
        <Link
          to={
            driver && driver.isBusinessDriver
              ? `/drivers/businesses`
              : `/drivers/detail/${driver ? driver.id : ''}`
          }
        >
          {client}
        </Link>
      </TableCell>
      <TableCell>{plates}</TableCell>
      <TableCell>{policyId || ''}</TableCell>
      <TableCell>{carModel}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{year}</TableCell>
      <TableCell>{formatPositiveAmount(costPerWeek)}</TableCell>
      <TableCell className={audit > 0 && classes.pending}>{audit}</TableCell>
    </TableRow>
  );
};

CarRow.propTypes = {
  niv: PropTypes.string,
  status: PropTypes.string,
  mode: PropTypes.string,
  driver: PropTypes.string,
  plates: PropTypes.string,
  policyId: PropTypes.string,
  carModel: PropTypes.string,
  type: PropTypes.string,
  year: PropTypes.string,
  costPerWeek: PropTypes.number,
  audit: PropTypes.string,
};

export default CarRow;
