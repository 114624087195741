export const selectAccounting = state => state.accounting;
export const selectDepreciationBalance = state =>
  state.accounting.depreciationBalance;
export const selectDepreciationBalanceList = state =>
  selectDepreciationBalance(state).items;
export const selectDepreciationBalanceListCount = state =>
  selectDepreciationBalance(state).count;
export const selectDepreciationBalanceQueryOptions = state => {
  const { options } = selectDepreciationBalance(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
export const selectFleetMovementsList = state =>
  selectDepreciationBalance(state).movementsItems;
export const sselectFleetMovementsListCount = state =>
  selectDepreciationBalance(state).movementsCount;
export const selectDepreciationResume = state =>
  selectDepreciationBalance(state).resume;

export const selectAmortizationBalance = state =>
  state.accounting.amortizationBalance;
export const selectAmortizationBalanceList = state =>
  selectAmortizationBalance(state).items;
export const selectAmortizationBalanceListCount = state =>
  selectAmortizationBalance(state).count;
export const selectAmortizationBalanceQueryOptions = state => {
  const { options } = selectAmortizationBalance(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
export const selectAmortizationResume = state =>
  selectAmortizationBalance(state).resume;
