/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { useTranslation } from 'react-i18next';
import { VAT_TAX } from 'src/views/fleet/CarDetail/utils';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.PDF_INVOICE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.invoicePDF ? values.invoicePDF.name : ''}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.XML_INVOINE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.invoiceXML ? values.invoiceXML.name : ''}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.SERIAL_NUMBER')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.serie}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.TOTAL_COST')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(values.total)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.VAT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount((values.total * VAT_TAX).toFixed(2))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('DEPRECIATION_BALANCE_VIEW.PURCHASE_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {moment(values.purchaseDate).format('DD-MMM-YY')}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.RFC')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.rfc}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.ENGINE_NUMBER')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.motor}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.BRAND')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.brand}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.TYPE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.type}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.VERSION')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.version}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.COLOR')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.color}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.YEAR')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.year}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.TRANSMISSION')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.transmission}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CAR_DETAIL.STATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.estado}
            </Typography>
          </TableCell>
        </TableRow>

        {values.plates ? (
          <TableRow component="div">
            <TableCell component="div" className={classes.reviewDataLabel}>
              {t('COMMON.PLATES')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {values.plates}
              </Typography>
            </TableCell>
          </TableRow>
        ) : null}

        {values.tarjeta ? (
          <TableRow component="div">
            <TableCell component="div" className={classes.reviewDataLabel}>
              {t('CAR_DETAIL.CIRCULATION_CARD')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {values.tarjeta.name}
              </Typography>
            </TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    </Table>
  );
};

export default ReviewData;
