import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@material-ui/core';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import AuditRow from './AuditRow';

const AuditTable = ({
  audits,
  count,
  className,
  handleChangePage,
  loading,
  page,
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.DRIVER')}</TableCell>
      <TableCell>{t('DRIVER_DETAIL_VIEW.AUDIT.FRONT_PHOTO')}</TableCell>
      <TableCell>{t('DRIVER_DETAIL_VIEW.AUDIT.BACK_PHOTO')}</TableCell>
      <TableCell>{t('DRIVER_DETAIL_VIEW.AUDIT.LEFT_PHOTO')}</TableCell>
      <TableCell>{t('DRIVER_DETAIL_VIEW.AUDIT.RIGHT_PHOTO')}</TableCell>
      <TableCell>{t('COMMON.DATE')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return audits.map(audit => <AuditRow {...audit} key={audit.id} />);
    },
    [audits],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, audits],
  );

  return (
    <Table
      tbody={tbody}
      theads={theads}
      className={className}
      pagination={pagination}
      loading={loading}
    />
  );
};

AuditTable.propTypes = {
  className: PropTypes.string,
  audits: PropTypes.array.isRequired,
  count: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  page: PropTypes.number,
};

export default AuditTable;
