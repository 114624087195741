/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { ExternalLink } from 'react-feather';
import DetailRow from 'src/components/DetailRow';
import { useTranslation } from 'react-i18next';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import useStyles from '../styles';

const DocumentLink = ({ link }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return link ? (
    <>
      <a href={link} className={classes.documentLink}>
        <Typography
          variant="body2"
          component="div"
          className={classes.documentText}
        >
          {t('COMMON.DOCUMENT_VIEW')}
          <ExternalLink style={{ marginLeft: '8px' }} size={20} />
        </Typography>
      </a>
    </>
  ) : (
    <>{t('COMMON.NOT_AVAILABLE')}</>
  );
};

const CarDocumentInfo = ({ car }) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailRow
        name={t('COMMON.INVOICE_PDF')}
        value={<DocumentLink link={car.invoicePDF} />}
      />
      <DetailRow
        name={t('COMMON.INVOICE_XML')}
        value={<DocumentLink link={car.invoiceXML} />}
      />

      <DetailRow
        name={t('CAR_DETAIL.CIRCULATION_CARD')}
        value={<DocumentLink link={car.circulationCard} />}
      />

      <DetailRow
        name={t('COMMON.PURCHASE_DATE')}
        value={
          car.purchaseDate ? moment(car.purchaseDate).format('DD-MMM-YY') : ''
        }
      />

      <DetailRow name={t('COMMON.PLATES')} value={car.plates} />

      <DetailRow name={t('COMMON.PLAZA')} value={car.state} />

      {car.saleReason && (
        <DetailRow
          name={t('FLEET_MOVEMENTS.SALE_REASON_TITLE')}
          value={t(`FLEET_MOVEMENTS.SALE_REASON.${car.saleReason}`)}
        />
      )}

      {car.saleDate && (
        <DetailRow
          name={t('FLEET_MOVEMENTS.SALE_DATE')}
          value={moment(car.saleDate).format('DD-MMM-YY')}
        />
      )}

      {car.salePrice && (
        <DetailRow
          name={t('FLEET_MOVEMENTS.SALE_PRICE')}
          value={formatPositiveAmount(car.salePrice)}
        />
      )}

      {car.facturaBajaPdf && (
        <DetailRow
          name={t('FLEET_VIEW.SALE_INVOICE')}
          value={<DocumentLink link={car.facturaBajaPdf} />}
        />
      )}
    </>
  );
};

export default CarDocumentInfo;
