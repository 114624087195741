/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import {
  updateBasicDriverInfoAction,
  enableDriver,
  unactiveDriver,
} from 'src/store/actions/driverActions';
import { useTranslation } from 'react-i18next';
import { DriverStatus } from 'src/utils/constants';
import GeneralDriverInfo from './GeneralDriverInfo';
import GeneralDriverDialog from './GeneralDriverDialog';
import EnableDriverDialog from './EnableDriverDialog';
import useStyles from '../styles';
import UnactiveDriverDialog from './UnactiveDriverDialog';

const GeneralDriver = ({ driver }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openEnable, setOpenEnable] = useState(false);
  const [openUnactive, setOpenUnactive] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateBasicDriverInfoAction(driver.id, values));
    },
    [driver, dispatch],
  );

  const handleClickOpenEnable = useCallback(() => {
    setOpenEnable(true);
  }, [setOpenEnable]);

  const handleCloseEnable = useCallback(() => {
    setOpenEnable(false);
  }, [setOpenEnable]);

  const handleSubmitEnable = useCallback(() => {
    dispatch(enableDriver(driver.id));
    handleCloseEnable();
  }, [driver, dispatch]);

  const handleClickOpenUnactive = useCallback(() => {
    setOpenUnactive(true);
  }, [setOpenUnactive]);

  const handleCloseUnactive = useCallback(() => {
    setOpenUnactive(false);
  }, [setOpenUnactive]);

  const handleSubmitUnactive = useCallback(() => {
    dispatch(unactiveDriver(driver.id));
    handleCloseUnactive();
  }, [driver, dispatch, handleCloseUnactive]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('DRIVER_DETAIL_VIEW.GENERAL_DRIVER_DATA')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0, marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <GeneralDriverInfo driver={driver} />
          </TableBody>
        </Table>
        <GeneralDriverDialog
          open={open}
          driver={driver}
          handleClose={handleClose}
          submit={handleSubmit}
        />
        <EnableDriverDialog
          audit={driver.audit}
          open={openEnable}
          handleClose={handleCloseEnable}
          handleSubmit={handleSubmitEnable}
        />

        <UnactiveDriverDialog
          open={openUnactive}
          handleClose={handleCloseUnactive}
          handleSubmit={handleSubmitUnactive}
        />

        <Grid container direction="column" alignItems="stretch">
          <Grid item className={classes.buttons}>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              className={clsx(classes.label, classes.buttons)}
              startIcon={<EditIcon />}
              disabled={driver.status === DriverStatus.LEFT}
            >
              {t('COMMON.EDIT')}
            </Button>
          </Grid>

          {driver.status === DriverStatus.REVIEW && (
            <Grid item className={classes.buttons}>
              <Button
                onClick={handleClickOpenEnable}
                variant="contained"
                color="primary"
                className={clsx(classes.label, classes.buttons)}
                startIcon={<EditIcon />}
              >
                {t('DRIVER_DETAIL_VIEW.REACTIVATE_DRIVER')}
              </Button>
            </Grid>
          )}

          {driver.status === DriverStatus.ACTIVE && (
            <Grid item className={classes.buttons}>
              <Button
                onClick={handleClickOpenUnactive}
                variant="contained"
                color="primary"
                className={clsx(classes.label, classes.buttons)}
                startIcon={<EditIcon />}
              >
                {t('DRIVER_DETAIL_VIEW.DEACTIVATE_DRIVER')}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GeneralDriver;
