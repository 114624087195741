import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import { useTranslation } from 'react-i18next';
import InactiveCarRow from './InactiveCarRow';
import useStyles from './styles';
import { CAR_STATUS } from '../CarDetail/utils';

const InactiveCarsTable = ({
  className,
  cars,
  count,
  page,
  handleChangePage,
  handleSortChange,
  handleOpenEdit,
  loading,
  status,
  handleOpenConfirmationModal,
  handleOpenSellModal,
  handleOpenEditModal,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit],
  );

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleSortChange(property, isAsc ? 'asc' : 'desc');
    },
    [order, orderBy, setOrder, setOrderBy, handleSortChange],
  );

  const createSortHandler = useCallback(
    property => event => {
      handleRequestSort(event, property);
    },
    [handleRequestSort],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell variant="head">{t('COMMON.ID')}</TableCell>
      <TableCell variant="head">{t('COMMON.PLATES')}</TableCell>
      <TableCell variant="head" style={{ minWidth: '150px' }}>
        {t('COMMON.DETAILS')}
      </TableCell>
      {status === CAR_STATUS.AVAILABLE && (
        <TableCell variant="head">{t('COMMON.INCIDENT')}</TableCell>
      )}
      <TableCell variant="head">{t('COMMON.LOCATION')}</TableCell>
      <TableCell
        variant="head"
        sortDirection={orderBy === 'last_assignation_end' ? order : false}
      >
        <TableSortLabel
          active={orderBy === 'last_assignation_end'}
          direction={orderBy === 'last_assignation_end' ? order : 'desc'}
          onClick={createSortHandler('last_assignation_end')}
        >
          {t('COMMON.DAYS')}
          {orderBy === 'last_assignation_end' ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
      <TableCell variant="head">
        {t('FLEET_VIEW.ESTIMATED_DEPARTURE')}
      </TableCell>
      {status === CAR_STATUS.ON_SALE && (
        <TableCell variant="head">{t('COMMON.PRICE')}</TableCell>
      )}
      {status === CAR_STATUS.ON_SALE && (
        <TableCell variant="head">{t('COMMON.UTILITY')}</TableCell>
      )}
      <TableCell variant="head" style={{ minWidth: '100px' }}>
        {t('COMMON.COMMENT')}
      </TableCell>
      <TableCell variant="head" />
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return cars.map(car => (
        <InactiveCarRow
          {...car}
          key={car.niv}
          openEdit={openEdit(car)}
          status={status}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleOpenSellModal={handleOpenSellModal}
          handleOpenEditModal={handleOpenEditModal}
        />
      ));
    },
    [cars],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, cars],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

InactiveCarsTable.propTypes = {
  className: PropTypes.string,
  cars: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  loading: PropTypes.bool,
  status: PropTypes.string,
  handleOpenConfirmationModal: PropTypes.func,
  handleOpenSellModal: PropTypes.func,
  handleOpenEditModal: PropTypes.func,
};

InactiveCarsTable.defaultProps = {
  count: 0,
};

export default InactiveCarsTable;
