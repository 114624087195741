import {
  createAgent,
  createBrand,
  createCompany,
  createIncident,
  createLocation,
  createService,
  createInsurer,
  createExpenseTypes,
  createProvider,
  deleteBrand,
  deleteCompany,
  deleteIncident,
  deleteLocation,
  deleteService,
  deleteExpenseTypes,
  deleteProvider,
  editBrand,
  editCompany,
  editIncident,
  editLocation,
  editService,
  editExpenseTypes,
  editProvider,
  getAgents,
  getBrands,
  getCompanies,
  getIncidents,
  getLocations,
  getServices,
  getInsurers,
  getExpenseTypes,
  getProviders,
  getDevices,
  editDevice,
  deleteDevice,
  createDevice,
  getCarCategories,
  createCarCategory,
  editCarCategory,
  deleteCarCartegory,
  createOperationalEmail,
  getOperationalEmails,
  deleteOperationalEmail,
  createGeofencingCategory,
  getGeofencingCategories,
  getGeofencingZones,
  editGeofencingCategory,
  editGeofencingZone,
  deleteGeofencingZone,
  createGeofencingZone,
  deleteGeofencingCategory,
  getUnassignReasons,
  createUnassignReason,
  editUnassignReason,
  deleteUnassignReason,
} from 'src/services/catalogService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { showMessage } from './snackBarActions';

export const LOAD_CATALOG = asyncActionCreator('LOAD_CATALOG');
export const LOAD_AGENTS = asyncActionCreator('LOAD_AGENTS');
export const LOAD_INSURERS = asyncActionCreator('LOAD_INSURERS');
export const LOAD_EXPENSE_TYPES = asyncActionCreator('LOAD_EXPENSE_TYPES');
export const LOAD_DEVICES = asyncActionCreator('LOAD_DEVICES');
export const LOAD_GEOFENCE_CATEGORIES = asyncActionCreator(
  'LOAD_GEOFENCE_CATEGORIES',
);
export const LOAD_GEOFENCE_ZONES = asyncActionCreator('LOAD_GEOFENCE_ZONES');
export const LOAD_UNASSIGN_REASONS = asyncActionCreator(
  'LOAD_UNASSIGN_REASONS',
);
export const CLEAR_CATALOG = 'CLEAR_CATALOG';

export const loadCatalog = service => () => async dispatch => {
  try {
    dispatch({ type: LOAD_CATALOG.start });

    const data = await service();

    return dispatch({
      type: LOAD_CATALOG.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CATALOG.failure });
  }
};

export const clearCatalog = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_CATALOG });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadAgents = loadCatalog(getAgents);
export const loadCompanies = loadCatalog(getCompanies);
export const loadBrands = loadCatalog(getBrands);
export const loadServices = loadCatalog(getServices);
export const loadIncidents = loadCatalog(getIncidents);
export const loadLocations = loadCatalog(getLocations);
export const loadInsurers = loadCatalog(getInsurers);
export const loadExpenseTypes = loadCatalog(getExpenseTypes);
export const loadProviders = loadCatalog(getProviders);
export const loadDevices = loadCatalog(getDevices);
export const loadCarCategories = loadCatalog(getCarCategories);
export const loadOperationalEmails = loadCatalog(getOperationalEmails);
export const loadGeofencingCategories = loadCatalog(getGeofencingCategories);
export const loadGeofencingZones = loadCatalog(getGeofencingZones);
export const loadUnassignReasons = loadCatalog(getUnassignReasons);

export const loadInsurersList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_INSURERS.start });

    const data = await getInsurers();

    return dispatch({
      type: LOAD_INSURERS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INSURERS.failure });
  }
};

export const loadAgentsList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_AGENTS.start });

    const data = await getAgents();

    return dispatch({
      type: LOAD_AGENTS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_AGENTS.failure });
  }
};

export const loadDeviceList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_DEVICES.start });

    const data = await getDevices();

    return dispatch({
      type: LOAD_DEVICES.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DEVICES.failure });
  }
};

export const loadExpenseTypesList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_EXPENSE_TYPES.start });

    const data = await getExpenseTypes();

    return dispatch({
      type: LOAD_EXPENSE_TYPES.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_EXPENSE_TYPES.failure });
  }
};

export const loadGeofencingCategoriesList = (
  setState = () => {},
) => async dispatch => {
  try {
    dispatch({ type: LOAD_CATALOG.start });

    const data = await getGeofencingCategories();
    setState(data);
    return dispatch({
      type: LOAD_CATALOG.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CATALOG.failure });
  }
};

export const loadGeofencingZonesList = (
  setState = () => {},
) => async dispatch => {
  try {
    dispatch({ type: LOAD_CATALOG.start });

    const data = await getGeofencingZones();
    setState(data);
    return dispatch({
      type: LOAD_CATALOG.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CATALOG.failure });
  }
};

export const loadUnassignReasonsList = (
  setState = () => {},
) => async dispatch => {
  try {
    dispatch({ type: LOAD_UNASSIGN_REASONS.start });

    const data = await getUnassignReasons();
    setState(data);
    return dispatch({
      type: LOAD_UNASSIGN_REASONS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_UNASSIGN_REASONS.failure });
  }
};

const catalogAction = (
  service,
  fetchAction,
  message,
) => values => async dispatch => {
  try {
    await service(values);
    dispatch(
      showMessage({
        message,
        variant: 'success',
      }),
    );
    return dispatch(fetchAction());
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const createProviderAction = catalogAction(
  createProvider,
  loadProviders,
  'Proveedor creado correctamente',
);

export const editProviderAction = catalogAction(
  editProvider,
  loadProviders,
  'Proveedor editado correctamente',
);

export const editDeviceAction = catalogAction(
  editDevice,
  loadDevices,
  'Dispositivo editado correctamente',
);

export const deleteDeviceAction = catalogAction(
  deleteDevice,
  loadDevices,
  'Dispositivo eliminado correctamente',
);

export const createDeviceAction = catalogAction(
  createDevice,
  loadDevices,
  'Disposivo creado correctamente',
);

export const deleteProviderAction = catalogAction(
  deleteProvider,
  loadProviders,
  'Proveedor eliminado correctamente',
);

export const createExpenseTypesAction = catalogAction(
  createExpenseTypes,
  loadExpenseTypes,
  'Categoría de gasto creada correctamente',
);

export const editExpenseTypesAction = catalogAction(
  editExpenseTypes,
  loadExpenseTypes,
  'Categoría de gasto editada correctamente',
);

export const deleteExpenseTypesAction = catalogAction(
  deleteExpenseTypes,
  loadExpenseTypes,
  'Categoría de gasto eliminada correctamente',
);

export const createIncidentAction = catalogAction(
  createIncident,
  loadIncidents,
  'Incidente creado correctamente',
);

export const editIncidentAction = catalogAction(
  editIncident,
  loadIncidents,
  'Incidente editado correctamente',
);

export const deleteIncidentAction = catalogAction(
  deleteIncident,
  loadIncidents,
  'Incidente eliminado correctamente',
);

export const createLocationAction = catalogAction(
  createLocation,
  loadLocations,
  'Ubicación creada correctamente',
);

export const editLocationAction = catalogAction(
  editLocation,
  loadLocations,
  'Ubicación editada correctamente',
);

export const deleteLocationAction = catalogAction(
  deleteLocation,
  loadLocations,
  'Ubicación eliminada correctamente',
);

export const createServiceAction = catalogAction(
  createService,
  loadServices,
  'Servicio creado correctamente',
);

export const editServiceAction = catalogAction(
  editService,
  loadServices,
  'Servicio editado correctamente',
);

export const deleteServiceAction = catalogAction(
  deleteService,
  loadServices,
  'Servicio eliminado correctamente',
);

export const createBrandAction = catalogAction(
  createBrand,
  loadBrands,
  'Marca creada correctamente',
);

export const editBrandAction = catalogAction(
  editBrand,
  loadBrands,
  'Marca editada correctamente',
);

export const deleteBrandAction = catalogAction(
  deleteBrand,
  loadBrands,
  'Marca eliminada correctamente',
);

export const createCompanyAction = catalogAction(
  createCompany,
  loadCompanies,
  'Compañia creada correctamente',
);

export const editCompanyAction = catalogAction(
  editCompany,
  loadCompanies,
  'Compañia editada correctamente',
);

export const deleteCompanyAction = catalogAction(
  deleteCompany,
  loadCompanies,
  'Compañia eliminada correctamente',
);

export const createAgentAction = catalogAction(
  createAgent,
  loadAgents,
  'Agente creado correctamente',
);

export const createInsurerAction = catalogAction(
  createInsurer,
  loadInsurers,
  'Aseguradora creada correctamente',
);

export const createCarCategoryAction = catalogAction(
  createCarCategory,
  loadCarCategories,
  'Categoría creada correctamente',
);

export const editCarCategoryAction = catalogAction(
  editCarCategory,
  loadCarCategories,
  'Categoría editada correctamente',
);

export const deleteCarCategoryAction = catalogAction(
  deleteCarCartegory,
  loadCarCategories,
  'Categoría eliminada correctamente',
);

export const createOperationalEmailAction = catalogAction(
  createOperationalEmail,
  loadOperationalEmails,
  'CREATE_OPERATIONAL_EMAIL',
);

export const deleteOperationalEmailAction = catalogAction(
  deleteOperationalEmail,
  loadOperationalEmails,
  'DELETE_OPERATIONAL_EMAIL',
);

export const createGeofencingCategoryAction = catalogAction(
  createGeofencingCategory,
  loadGeofencingCategoriesList,
  'CREATE_GEOFENCING_CATEGORY',
);

export const deleteGeofencingCategoryAction = catalogAction(
  deleteGeofencingCategory,
  loadGeofencingCategoriesList,
  'DELETE_GEOFENCING_CATEGORY',
);

export const editGeofencingCategoryAction = catalogAction(
  editGeofencingCategory,
  loadGeofencingCategoriesList,
  'UPDATE_GEOFENCING_CATEGORY',
);

export const createGeofencingZoneAction = catalogAction(
  createGeofencingZone,
  loadGeofencingZonesList,
  'CREATE_GEOFENCING_ZONE',
);

export const deleteGeofencingZoneAction = catalogAction(
  deleteGeofencingZone,
  loadGeofencingZonesList,
  'DELETE_GEOFENCING_ZONE',
);

export const editGeofencingZoneAction = catalogAction(
  editGeofencingZone,
  loadGeofencingZonesList,
  'UPDATE_GEOFENCING_ZONE',
);

export const createUnassignReasonAction = catalogAction(
  createUnassignReason,
  loadUnassignReasons,
  'CREATE_UNASSIGN_REASON',
);

export const editUnassignReasonAction = catalogAction(
  editUnassignReason,
  loadUnassignReasons,
  'UPDATE_UNASSIGN_REASON',
);

export const deleteUnassignReasonAction = catalogAction(
  deleteUnassignReason,
  loadUnassignReasons,
  'DELETE_UNASSIGN_REASON',
);
