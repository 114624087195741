/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import validationSchema from './schema';

const makeInitialState = () => {
  return {
    homeTown: '',
    mobile: '',
    email: '',
    name: '',
    paternalName: '',
    maternalName: '',
  };
};

const DriverCreationInfo = ({ handleClose, submit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(), []);

  const onClose = useCallback(
    resetForm => () => {
      resetForm();
      handleClose();
    },
    [handleClose],
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        resetForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && t(errors.name)}
              label={t('COMMON.NAME')}
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.name}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.paternalName && errors.paternalName)}
              fullWidth
              helperText={touched.paternalName && t(errors.paternalName)}
              label={t('DRIVERS_VIEW.PATERNAL_NAME')}
              margin="normal"
              name="paternalName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.paternalName}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.maternalName && errors.maternalName)}
              fullWidth
              helperText={touched.maternalName && t(errors.maternalName)}
              label={t('DRIVERS_VIEW.MATERNAL_NAME')}
              margin="normal"
              name="maternalName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.maternalName}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && t(errors.email)}
              label={t('COMMON.EMAIL')}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.email}
              variant="outlined"
            />
            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.region && errors.region)}
            >
              <InputLabel id="region-select-label">
                {t('COMMON.PLAZA')}
              </InputLabel>
              <Select
                onBlur={handleBlur}
                labelId="region-label"
                label={t('COMMON.PLAZA')}
                id="region"
                value={values.region}
                onChange={handleChange}
                name="region"
              >
                <MenuItem value="" />
                <MenuItem value="México">México</MenuItem>
                <MenuItem value="Nuevo León">Nuevo León</MenuItem>
                <MenuItem value="Aguascalientes">Aguascalientes</MenuItem>
              </Select>
              {Boolean(touched.region && errors.region) && (
                <FormHelperText>{t(errors.region)}</FormHelperText>
              )}
            </FormControl>
            <TextField
              error={Boolean(touched.mobile && errors.mobile)}
              fullWidth
              helperText={touched.mobile && t(errors.mobile)}
              label={t('COMMON.MOBILE')}
              margin="normal"
              name="mobile"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.mobile}
              variant="outlined"
            />
            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Grid container direction="column" alignItems="stretch">
                <Grid item>
                  <Button
                    className={clsx(classes.label, classes.buttons)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('DRIVERS_VIEW.ADD_DRIVER')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={clsx(classes.outlined, classes.buttons)}
                    onClick={onClose(resetForm)}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.CANCEL')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default DriverCreationInfo;
