import {
  getFines,
  downloadeExportedFines,
  getFinesReportList,
  getFineReportDetail,
} from 'src/services/fineService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectFineQueryOptions } from '../selectors/fineSelectors';
import { showMessage } from './snackBarActions';

export const SAVE_FINES_OPTIONS = 'SAVE_FINES_OPTIONS';
export const CLEAR_FINES_OPTIONS = 'CLEAR_FINES_OPTIONS';
export const LOAD_FINES = asyncActionCreator('LOAD_FINES');
export const LOAD_FINES_REPORT = asyncActionCreator('LOAD_FINES_REPORT');
export const DOWNLOAD_REPORT = asyncActionCreator('DOWNLOAD_REPORT');
export const CLEAR_FINES = 'CLEAR_FINES';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectFineQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.plateOrInfraction) {
    reqOptions.plateOrInfraction = queryOpts.plateOrInfraction;
  }
  if (queryOpts.startDate) {
    reqOptions.startDate = new Date(queryOpts.startDate);
  }
  if (queryOpts.endDate) {
    reqOptions.endDate = new Date(queryOpts.endDate);
  }

  if (queryOpts.withDiscount) {
    reqOptions.withDiscount = queryOpts.withDiscount;
  }

  if (queryOpts.sortOrder && queryOpts.sortProperty) {
    reqOptions.sortOrder = queryOpts.sortOrder;
    reqOptions.sortProperty = queryOpts.sortProperty;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_FINES_OPTIONS });
};

export const loadFines = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_FINES.start });
    dispatch({
      type: SAVE_FINES_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getFines(reqOptions);

    return dispatch({
      type: LOAD_FINES.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_FINES.failure });
  }
};

export const downloadExcelFines = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadeExportedFines(options);
  } catch (error) {
    console.error(error);
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadFinesReport = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_FINES_REPORT.start });
    dispatch({
      type: SAVE_FINES_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getFinesReportList(reqOptions);

    return dispatch({
      type: LOAD_FINES_REPORT.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_FINES_REPORT.failure });
  }
};

export const downloadFinesReport = options => async dispatch => {
  try {
    dispatch({ type: DOWNLOAD_REPORT.start });
    await getFineReportDetail(options);
    return dispatch({ type: DOWNLOAD_REPORT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_REPORT.failure });
  }
};
