import React, { useCallback, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useDispatch } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import {
  editCar,
  updateCarInactivityAction,
  updateCarStatus,
} from 'src/store/actions/carActions';
import { useTranslation } from 'react-i18next';
import ConfirmationMessage from 'src/components/ConfirmationMessage';
import Toolbar from './Toolbar';
import InactiveForm from './InactiveForm';
import InactiveCarTabsList from './InactiveCarListTab';
import EditCarsOnSaleForm from './EditCarsOnSaleForm';
import RemoveCarFormDialog from '../SoldCars/RemoveCarForm';
import { CAR_STATUS } from '../CarDetail/utils';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CONFIRMATION = 'CONFIRMATION';
const SELL = 'SELL';
const EDIT = 'EDIT';

const InactiveCarsListView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState();
  const [rowData, setRowData] = useState({
    niv: '',
    salePrice: '',
  });

  const handleOpenConfirmationModal = values => {
    setRowData(values);
    setOpenModal(CONFIRMATION);
  };

  const handleOpenSellModal = values => {
    setRowData(values);
    setOpenModal(SELL);
  };

  const handleOpenEditModal = values => {
    setRowData(values);
    setOpenModal(EDIT);
  };

  const handleCloseModal = () => {
    setOpenModal('');
    setData({
      niv: '',
      salePrice: '',
    });
  };

  const handlePutCarOnSale = () => {
    dispatch(updateCarStatus({ niv: rowData.niv, status: CAR_STATUS.ON_SALE }));
    handleCloseModal();
  };

  const handleEditCarOnSale = values => {
    dispatch(
      editCar({
        ...values,
        niv: rowData.niv,
      }),
    );
    handleCloseModal();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(null);
  }, [setOpen, setData]);

  const handleOpenEdit = useCallback(
    values => {
      setOpen(true);
      setData(values);
    },
    [setOpen, setData],
  );

  const handleEdit = useCallback(
    values => {
      dispatch(updateCarInactivityAction(values));
      handleClose();
    },
    [handleClose, dispatch],
  );

  return (
    <Page className={classes.root} title={t('NAVIGATION.INACTIVE_CARS')}>
      <FadeIn transitionDuration={100}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <InactiveCarTabsList
              handleOpenEdit={handleOpenEdit}
              handleOpenConfirmationModal={handleOpenConfirmationModal}
              handleOpenSellModal={handleOpenSellModal}
              handleOpenEditModal={handleOpenEditModal}
            />
          </Box>
        </Container>
      </FadeIn>
      <InactiveForm
        open={open}
        data={data}
        handleClose={handleClose}
        handleEdit={handleEdit}
      />

      <RemoveCarFormDialog
        open={openModal === SELL}
        handleClose={handleCloseModal}
        data={rowData}
      />

      {console.log(rowData, 'data')}

      <ConfirmationMessage
        open={openModal === CONFIRMATION}
        handleClose={handleCloseModal}
        handleAccept={handlePutCarOnSale}
        text={t('FLEET_VIEW.PUT_CAT_ON_SALE_CONFIRMATION_TEXT')}
      />

      <EditCarsOnSaleForm
        open={openModal === EDIT}
        handleClose={handleCloseModal}
        data={rowData}
        handleEdit={handleEditCarOnSale}
      />
    </Page>
  );
};

export default InactiveCarsListView;
