import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DropdownMenu from 'src/components/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import {
  CLEAR_CATALOG,
  loadCarCategories,
} from 'src/store/actions/catalogActions';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = category => {
  return {
    category: category || '',
  };
};

const validationSchema = Yup.object().shape({
  category: Yup.string().required('CAR_DETAIL.CAR_CATEGORY_REQUIRED'),
});

const ChangeCategoryDialog = ({ open, category, handleClose, submit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const resetRef = useRef();
  const categories = useSelector(selectCatalogList);
  const dispatch = useDispatch();

  const initialState = useMemo(() => makeInitialState(category), [category]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      submit(values);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose],
  );

  useEffect(() => {
    dispatch(loadCarCategories());
    dispatch({ type: CLEAR_CATALOG });
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle disableTypography className={classes.header}>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('COMMON.EDIT')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.carCategory}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);

            return (
              <form onSubmit={handleSubmit}>
                <DropdownMenu
                  list={categories.map(carCategory => {
                    return {
                      key: carCategory.name,
                      name: carCategory.name,
                    };
                  })}
                  value={values.category}
                  setValue={value => setFieldValue('category', value)}
                  label={t('COMMON.CATEGORY')}
                  error={Boolean(touched.category && errors.category)}
                  errorText={touched.category && t(errors.category)}
                />
                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={category === values.category}
                    >
                      {t('COMMON.ACCEPT')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ChangeCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  category: PropTypes.string,
};

export default ChangeCategoryDialog;
