export const selectIncomeResume = state => state.incomeResume;
export const selectIncomeResumeList = state => selectIncomeResume(state).items;
export const selectIncomeResumeCount = state => selectIncomeResume(state).count;
export const selectIncomeResumeOptions = state => {
    const { options } = selectIncomeResume(state);
    
    Object.keys(options).forEach(key => {
      if (options[key] === '') {
        delete options[key];
      }
    });
    
    return options;
  };
