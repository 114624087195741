import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import useDebounce from 'src/hooks/useDebounce';
import { selectCollectionsOptions } from 'src/store/selectors/collectionsSelector';
import {
  clearCollections,
  downloadExcelCollections,
  loadCollections,
} from 'src/store/actions/collectionsActions';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import DateRange from 'src/utils/DateRangeType';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    width: '100%',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const CollectionStatus = {
  EXTRAJUDICIAL: 'EXTRAJUDICIAL',
  INACTIVE: 'INACTIVE',
};

const filters = [
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'status',
    items: [
      {
        label: 'COMMON.ALL',
        value: '',
      },
      {
        label: 'INCOME_VIEW.EXTRAJUDICIAL',
        value: CollectionStatus.EXTRAJUDICIAL,
      },
      {
        label: 'INCOME_VIEW.INACTIVE',
        value: CollectionStatus.INACTIVE,
      },
    ],
  },
];

const Toolbar = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCollectionsOptions);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    status: '',
  });

  useEffect(() => {
    dispatch(loadCollections({ timePeriod: DateRange.WEEKLY }));
    return () => dispatch(clearCollections());
  }, []);

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const debounceSearch = useDebounce((value, queryOpts) => {
    dispatch(loadCollections({ ...queryOpts, driverName: value }));
    handleCloseFilter();
  }, 400);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        ...event,
      });
    },
    [searchValue, debounceSearch, queryOptions],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelCollections());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <>
      <div className={classes.root} {...props}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.INCOME']}
          current="NAVIGATION.RENT_OVERDUE"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.RENT_OVERDUE')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Paper
              elevation={0}
              className={classes.search}
              style={{ marginLeft: 0 }}
            >
              <Input
                className={classes.input}
                startAdornment={Addornment}
                placeholder={t('DRIVERS_VIEW.SEARCH_CLIENT')}
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                type="text"
                onChange={handleSearch}
              />
            </Paper>
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleOpenFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
