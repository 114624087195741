import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Input,
  InputAdornment,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { CLEAR_CARS, loadSoldCars } from 'src/store/actions/carActions';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectCarQueryOptions } from 'src/store/selectors/carSelectors';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCarQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (searchCriteria, queryOpts) =>
      dispatch(loadSoldCars({ ...queryOpts, searchCriteria })),
    400,
  );

  useEffect(() => {
    dispatch(loadSoldCars());
    return () => dispatch({ type: CLEAR_CARS });
  }, []);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <Box className={clsx(classes.rootToolbar, className)} {...rest}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.FLEET']}
        current="NAVIGATION.SOLD_CARS"
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography className={classes.fontBold} color="primary" variant="h1">
          {t('NAVIGATION.SOLD_CARS')}
        </Typography>
        <Box className={classes.actionsContainer}>
          <Paper elevation={0} className={classes.search}>
            <Input
              className={classes.input}
              startAdornment={Addornment}
              placeholder={t('FLEET_VIEW.SEARCH_CAR')}
              disableUnderline
              value={searchValue}
              inputProps={{
                'aria-label': 'Search',
              }}
              type="string"
              onChange={handleSearch}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
