import {
  CLEAR_CATALOG,
  LOAD_CATALOG,
  LOAD_INSURERS,
  LOAD_AGENTS,
  LOAD_EXPENSE_TYPES,
  LOAD_DEVICES,
} from '../actions/catalogActions';

const initialState = {
  items: [],
  insurers: [],
  agents: [],
  expenseTypes: [],
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CATALOG.success: {
      return {
        ...state,
        items: action.data,
      };
    }
    case LOAD_INSURERS.success: {
      return {
        ...state,
        insurers: action.data,
      };
    }
    case LOAD_AGENTS.success: {
      return {
        ...state,
        agents: action.data,
      };
    }
    case LOAD_EXPENSE_TYPES.success: {
      return {
        ...state,
        expenseTypes: action.data,
      };
    }
    case LOAD_DEVICES.success: {
      return {
        ...state,
        items: action.data,
      };
    }
    case CLEAR_CATALOG: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default catalogReducer;
