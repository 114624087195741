export const selectExpense = state => state.expense;
export const selectExpenseList = state => selectExpense(state).items;
export const selectExpenseCount = state => selectExpense(state).count;
export const selectExpenseAutcompletes = state => selectExpense(state).autocompletes;
export const selectExpenseOptions = state => {
  const { options } = selectExpense(state);
  
  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });
  
  return options;
};