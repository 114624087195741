import React, { useCallback, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@material-ui/core';
import { ReactComponent as KeyboardArrowDown } from 'src/assets/icons/keyboard_arrow_down.svg';
import { ReactComponent as SpanishFlag } from 'src/assets/icons/es_flag.svg';
import EEUUFlag from 'src/assets/images/us_flag.png';

const I18nMenu = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const changeLanguage = useCallback(
    lng => () => {
      i18n.changeLanguage(lng);
      localStorage.setItem('lang', lng);
      handleClose();
    },
    [i18n, handleClose],
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        color="inherit"
        aria-label="lang"
        aria-controls="lang-menu"
        aria-haspopup="true"
        endIcon={<KeyboardArrowDown fill={theme.palette.text.primary} />}
        onClick={handleClick}
      >
        {t('DASHBOARD.LANG_ACRONYM')}
      </Button>
      {i18n.language === 'es' ? (
        <SpanishFlag />
      ) : (
        <img src={EEUUFlag} width={24} height={16} alt="EN Flag" />
      )}

      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          selected={i18n.language === 'es'}
          onClick={changeLanguage('es')}
        >
          Español
        </MenuItem>
        <MenuItem
          selected={i18n.language === 'en'}
          onClick={changeLanguage('en')}
        >
          English
        </MenuItem>
      </Menu>
    </div>
  );
};

export default I18nMenu;
