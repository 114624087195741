import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAllGps = serviceBase({
  request: options =>
    axiosClient.get('/gps', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 1,
});

export const createNewDeviceGps = serviceBase({
  request: gps => axiosClient.post(`/gps`, gps),
  retry: 0,
});

export const switchCarEngineStatus = serviceBase({
  request: options =>
    axiosClient.post(`/gps/engine-control/${options.niv}`, options),
});

export const deleteGpsDevice = serviceBase({
  request: id => axiosClient.delete(`/gps/delete/${id}`),
  retry: 0,
});

export const updateDeviceGps = serviceBase({
  request: gps => axiosClient.put(`/gps/edit/${gps.id}`, gps),
  retry: 0,
});

export const unassignGpsDevice = serviceBase({
  request: carniv => axiosClient.post(`/gps/unassign/${carniv}`),
  retry: 0,
});

export const getTransmissionList = serviceBase({
  request: options =>
    axiosClient.get('/gps/transmission-list', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 1,
});

export const downloadListDevice = serviceBase({
  request: options =>
    axiosClient.get('/gps/list/download', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'Dispositivos Gps.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const getRentLockoutList = serviceBase({
  request: options =>
    axiosClient.get('/gps/rent-lockout-list', {
      params: options,
    }),
  retry: 1,
});

export const addOdometerForDevice = serviceBase({
  request: id => axiosClient.post(`/gps/add-oddometer/${id}`),
  retry: 0,
});

export const getMaintenanceLockoutList = serviceBase({
  request: options =>
    axiosClient.get('/gps/mmto-lockout-list', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 1,
});

export const AddMaintenanceInCar = serviceBase({
  request: data =>
    axiosClient.post(
      `/gps/mmto-lockout-list/add-maintenance/${data.niv}`,
      data,
    ),
  retry: 0,
});

export const getGeofencingList = serviceBase({
  request: options =>
    axiosClient.get('/gps/geofencing', {
      params: options,
    }),
  retry: 1,
});

export const createNewGeofencing = serviceBase({
  request: geofencing => axiosClient.post('/gps/geofencing', geofencing),
  retry: 0,
});

export const removeGeofencingById = serviceBase({
  request: id => axiosClient.delete(`/gps/geofencing/${id}`),
  retry: 0,
});

export const getMileageExcedentList = serviceBase({
  request: options =>
    axiosClient.get('/gps/mileage-excedent-list', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 1,
});

export const getDevicesLocationList = serviceBase({
  request: () => axiosClient.get('/gps/locations'),
  retry: 0,
});
