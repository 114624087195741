/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadInactiveCars } from 'src/store/actions/carActions';
import { CAR_STATUS } from '../CarDetail/utils';
import InactiveCarsList from './InactiveCarList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

function selectCarStatus(value) {
  switch (Number(value)) {
    case 0:
      return CAR_STATUS.AVAILABLE;
    case 1:
      return CAR_STATUS.ON_SALE;
    default:
      return CAR_STATUS.AVAILABLE;
  }
}

const InactiveCarTabsList = ({
  handleOpenEdit,
  handleOpenConfirmationModal,
  handleOpenSellModal,
  handleOpenEditModal,
}) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadInactiveCars({
          status: selectCarStatus(newValue),
          page: 0,
        }),
      );
    },
    [setTabIndex],
  );

  return (
    <div className={classes.root}>
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.INACTIVE')} />
          <Tab label={t('FLEET_VIEW.ON_SALE')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <InactiveCarsList
          handleOpenEdit={handleOpenEdit}
          status={CAR_STATUS.AVAILABLE}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleOpenSellModal={handleOpenSellModal}
          handleOpenEditModal={handleOpenEditModal}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <InactiveCarsList
          handleOpenEdit={handleOpenEdit}
          status={CAR_STATUS.ON_SALE}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleOpenSellModal={handleOpenSellModal}
          handleOpenEditModal={handleOpenEditModal}
        />
      </TabPanel>
    </div>
  );
};

InactiveCarsList.propTypes = {
  handleOpenEdit: PropTypes.func,
  handleOpenConfirmationModal: PropTypes.func,
  handleOpenSellModal: PropTypes.func,
  handleOpenEditModal: PropTypes.func,
};

export default InactiveCarTabsList;
