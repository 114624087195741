import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import DateRange from 'src/utils/DateRangeType';
import IncomeResumeRow from './IncomeResumeRow';

const useStyles = makeStyles(() => ({
  tableRoot: {
    border: 'none',
  },
}));

const selectTransation = value => {
  switch (value) {
    case DateRange.WEEKLY:
      return 'COMMON.WEEK';
    case DateRange.MONTHLY:
      return 'COMMON.MONTH';
    case DateRange.QUARTERLY:
      return 'COMMON.QUARTER';
    case DateRange.YEARLY:
      return 'COMMON.YEAR';
    default:
      return '';
  }
};

const Tbody = ({ incomeResumes, rangeType }) =>
  incomeResumes.map(incomeResume => (
    <IncomeResumeRow
      key={incomeResume.week}
      {...incomeResume}
      rangeType={rangeType}
    />
  ));

const IncomeResumeTable = ({
  className,
  incomeResumes,
  count,
  page,
  handleChangePage,
  loading,
  rangeType,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell style={{ width: '8.33%' }}>
          {t(selectTransation(rangeType))}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.INITIAL_BALANCE')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.RENT')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.SURCHARGES')}
        </TableCell>
        <TableCell style={{ width: '8.33%', display: 'none' }}>
          {t('INCOME_VIEW.INTERESTS')}
        </TableCell>

        <TableCell style={{ width: '8.33%' }}>
          {t('NAVIGATION.FINES')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.SINISTERS')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.OTHERS')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.ADVANCES')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.DISCOUNTS')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.PAYMENTS')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.DISCHARGES')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.FINAL_BALANCE')}
        </TableCell>
        <TableCell style={{ width: '8.33%' }}>
          {t('INCOME_VIEW.GLOBAL_CHARGED')}
        </TableCell>
      </TableRow>
    ),
    [t],
  );

  const tbody = useMemo(
    () => () => <Tbody incomeResumes={incomeResumes} rangeType={rangeType} />,
    [incomeResumes],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, count, handleChangePage],
  );

  return (
    <Table
      {...rest}
      className={classes.tableRoot}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

IncomeResumeTable.propTypes = {
  className: PropTypes.string,
  incomeResumes: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
  rangeType: PropTypes.string,
};

export default IncomeResumeTable;
