/* eslint-disable import/prefer-default-export */
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getDriverPendingMovementsById = serviceBase({
  request: ({ id, reqOptions }) =>
    axiosClient.get(`/deferred-charge/pending/${id}`, {
      params: reqOptions,
    }),
  retry: 0,
});
