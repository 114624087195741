import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_CATALOG,
  CLEAR_CATALOG,
  loadOperationalEmails,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import PropTypes from 'prop-types';
import OperationalEmailsTable from './OperationalEmailsTable';

const OperationalEmailsList = ({ handleDelete }) => {
  const dispatch = useDispatch();
  const operationalEmails = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadOperationalEmails());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency

  return (
    <OperationalEmailsTable
      operationalEmails={operationalEmails}
      loading={loading}
      handleDelete={handleDelete}
    />
  );
};

OperationalEmailsList.propTypes = {
  handleDelete: PropTypes.func,
};

export default OperationalEmailsList;
