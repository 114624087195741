import { TransactionCategory } from './constants';

export const PaymentComments = {
  UBER_PAYMENT: 'UBER_PAYMENT',
  DISCOUNTS: 'DISCOUNTS',
  BANK_ACCOUNT_PAYMENT: 'BANK_ACCOUNT_PAYMENT',
  // TODO: the code is commented to be implemented again in the future
  // RESIDUAL: 'RESIDUAL',
};

export const PaymentCategoryOptions = [
  { key: TransactionCategory.DISCOUNTS, name: 'COMMON.DISCOUNTS' },

  {
    key: TransactionCategory.OTHERS,
    name: 'COMMON.OTHERS',
  },
];

// for each transaction category create key name object
export const getPaymentCategoryOptions = t =>
  Object.values(TransactionCategory).map(key => ({
    key,
    name: t(`TRANSACTION.CATEGORY.${key}`),
  }));

export const getPaymentCommentlist = t =>
  Object.values(PaymentComments).map(key => t(`COMMON.${key}`));

export const PaymentCommentOptions = [
  { key: PaymentComments.UBER_PAYMENT, name: 'Pagos UBER' },
  { key: PaymentComments.DISCOUNTS, name: 'Descuentos' },
  {
    key: PaymentComments.BANK_ACCOUNT_PAYMENT,
    name: 'Pago a Cuenta Bancaria',
  },
  /* 
    TODO: the code is commented to be implemented again in the future
  { key: PaymentComments.RESIDUAL, name: 'Descuento a residual' }, */
];
