import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import useStyles from './styles';
import FleetMovementsRow from './FleetMovementsRow';

const Tbody = ({ movements }) =>
  movements?.map(movement => (
    <FleetMovementsRow key={movement.niv} {...movement} />
  ));

const FleetMovementsTable = ({
  className,
  movements,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell key="niv" className={classes.textCenter}>
          NIV
        </TableCell>
        <TableCell key="model" className={classes.textCenter}>
          {t('COMMON.MODEL')}
        </TableCell>
        <TableCell key="purchaseDate" className={classes.textCenter}>
          {t('DEPRECIATION_BALANCE_VIEW.PURCHASE_DATE')}
        </TableCell>
        <TableCell key="saleDate" className={classes.textCenter}>
          {t('FLEET_MOVEMENTS.SALE_DATE')}
        </TableCell>
        <TableCell key="monthsOfLife" className={classes.textCenter}>
          {t('INSURANCE_BALANCE_VIEW.MONTHS_OF_LIFE')}
        </TableCell>
        <TableCell key="purchasePrice" className={classes.textRight}>
          {t('FLEET_MOVEMENTS.PURCHASE_PRICE')}
        </TableCell>
        <TableCell key="depreciation" className={classes.textRight}>
          {t('DEPRECIATION_BALANCE_VIEW.DEPRECIATION')}
        </TableCell>
        <TableCell key="commercialPrice" className={classes.textRight}>
          {t('FLEET_MOVEMENTS.COMMERCIAL_PRICE')}
        </TableCell>
        <TableCell key="salePrice" className={classes.textRight}>
          {t('FLEET_MOVEMENTS.SALE_PRICE')}
        </TableCell>
        <TableCell key="balance" className={classes.textRight}>
          {t('COMMON.BALANCE')}
        </TableCell>
      </TableRow>
    ),
    [classes, t],
  );

  const tbody = useMemo(() => () => <Tbody t={t} movements={movements} />, [
    movements,
    t,
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, movements],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

FleetMovementsTable.propTypes = {
  className: PropTypes.string,
  movements: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
  campaignType: PropTypes.string,
};

FleetMovementsTable.defaultProps = {
  count: 0,
};

export default FleetMovementsTable;
