import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from 'src/components/Table/TableCell';
import useStyles from '../styles';

const OperationalEmailsRow = ({ id, email, handleDelete }) => {
  const classes = useStyles();

  return (
    <TableRow hover>
      <TableCell>{email}</TableCell>
      <TableCell style={{ width: 130 }}>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={() => handleDelete(id)}
          key="delete"
          aria-label="Delete"
          color="inherit"
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

OperationalEmailsRow.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  handleDelete: PropTypes.func,
};

export default OperationalEmailsRow;
