import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { activeDriver } from 'src/store/actions/driverActions';
import DetailRow from 'src/components/DetailRow';
import { DriverLeftReason } from 'src/utils/constants';
import ConfirmationMessage from 'src/components/ConfirmationMessage';
import useStyles from '../styles';

const DriverDeactivationInfo = ({ driver }) => {
  const { t } = useTranslation();
  const getReason = leftReason => {
    switch (leftReason) {
      case DriverLeftReason.TURN_CHANGE:
        return t('DRIVERS_VIEW.REASON_TURN_CHANGE');
      case DriverLeftReason.PRICE:
        return t('DRIVERS_VIEW.REASON_PRICE');
      case DriverLeftReason.SERVICE:
        return t('DRIVERS_VIEW.REASON_SERVICE');
      case DriverLeftReason.CAR_CARE:
        return t('DRIVERS_VIEW.REASON_CAR_CARE');
      case DriverLeftReason.NON_PAYMENT:
        return t('DRIVERS_VIEW.REASON_NON_PAYMENT');
      case DriverLeftReason.CRIME:
        return t('DRIVERS_VIEW.REASON_CRIME');
      default:
        return t('DRIVERS_VIEW.REASON_OTHER');
    }
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClickClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(() => {
    dispatch(activeDriver(driver.id));
    handleClickClose();
  }, [driver, dispatch, handleClickClose]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('DRIVER_DETAIL_VIEW.DEACTIVATION_INFO')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <DetailRow
              name={t('DRIVER_DETAIL_VIEW.LEFT_REASON')}
              value={getReason(driver.firedLeftReason)}
            />
          </TableBody>
        </Table>
        <Box alignItems="center" display="flex" className={classes.buttons}>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            className={clsx(classes.label, classes.buttons)}
            startIcon={<EditIcon />}
          >
            {t('DRIVER_DETAIL_VIEW.ACTIVATE_DRIVER')}
          </Button>
        </Box>
      </CardContent>
      <ConfirmationMessage
        open={open}
        handleClose={handleClickClose}
        text={t('DRIVERS_VIEW.REACTIVATE_DRIVER_CONFIRMATION')}
        handleAccept={handleSubmit}
      />
    </Card>
  );
};

DriverDeactivationInfo.propTypes = {
  driver: PropTypes.object,
};

export default DriverDeactivationInfo;
