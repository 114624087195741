import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HistoryIcon from '@material-ui/icons/History';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DriverResidualListDialog from './DriverResidualListDialog';

const DriverResidualSubMenu = ({ hasResidualAmount }) => {
  const { t } = useTranslation();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [anchorDialog, setAnchorDialog] = useState(null);
  const openDialog = Boolean(anchorDialog);
  const openMenu = Boolean(anchorMenu);

  const handleClick = event => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleCloseDialog = () => {
    setAnchorDialog(null);
  };

  const handleOpenDialog = () => {
    setAnchorDialog(true);
    handleClose();
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={openMenu}
        anchorEl={anchorMenu}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem onClick={handleOpenDialog} disabled={hasResidualAmount}>
          <HistoryIcon style={{ marginRight: '5px' }} />
          {t('COMMON.HISTORY')}
        </MenuItem>
      </Menu>
      <DriverResidualListDialog
        openDialog={openDialog}
        onCloseModal={handleCloseDialog}
      />
    </Box>
  );
};

DriverResidualSubMenu.propTypes = {
  hasResidualAmount: PropTypes.bool,
};

export default DriverResidualSubMenu;
