import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import * as Actions from 'src/store/actions/collectionsActions';
import {
  selectCollectionsCount,
  selectCollectionsList,
  selectCollectionsOptions,
} from 'src/store/selectors/collectionsSelector';
import CollectionsTable from './CollectionsTable';

const CollectionsList = () => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollectionsList);
  const count = useSelector(selectCollectionsCount);
  const { page } = useSelector(selectCollectionsOptions);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_COLLECTIONS.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_COLLECTIONS.action]),
  );

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadCollections({ page: value }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado registros"
          description="Ningún registro coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <CollectionsTable
      collection={collection}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default CollectionsList;
