import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const Toolbar = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.FINES']}
        current="NAVIGATION.SCRAPER_REPORTS"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontWeight: '400', textTransform: 'capitalize' }}
          color="primary"
          variant="h1"
        >
          {t('NAVIGATION.SCRAPER_REPORTS')}
        </Typography>
      </div>
    </div>
  );
};

export default Toolbar;
