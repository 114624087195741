import qs from 'qs';
import { saveAs } from 'file-saver';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getFines = serviceBase({
  request: options =>
    axiosClient.get('/fines', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 0,
});

export const downloadeExportedFines = serviceBase({
  request: options =>
    axiosClient.get('/fines/export', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'fines.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const getFinesReportList = serviceBase({
  request: options =>
    axiosClient.get('/fines/scraper-logs', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 0,
});

export const getFineReportDetail = serviceBase({
  request: options =>
    axiosClient.get(`/fines/scraper-logs/report/${options.id}`, {
      responseType: 'blob',
    }),
  transformResponse: res => {
    try {
      const filename = 'Reporte.pdf';
      saveAs(res.data, filename);
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
    }
  },
  retry: 0,
});
