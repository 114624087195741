import {
  LOAD_RENT_RESUME,
  CLEAR_RENT_OPTIONS,
  CLEAR_RENT_RESUME,
  SAVE_RENT_OPTIONS,
} from '../actions/rentResumeActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  count: 0,
};

const rentResumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RENT_RESUME.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_RENT_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_RENT_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    case CLEAR_RENT_RESUME: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default rentResumeReducer;
