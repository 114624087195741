/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core';
import { UserPlus, UserX } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  cancelAssignDriver,
  CANCEL_ASSIGNATION,
  updateCarModeAction,
} from 'src/store/actions/carActions';
import { Person } from '@material-ui/icons';
import Loading from 'src/components/Loading';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  CHANGE_DRIVER_ASSIGNATION,
  DRIVER_ASSIGNATION,
  resendSignNotificationAction,
  RESEND_SIGN_NOTIFICATION,
  UNASSIGN_DRIVER,
} from 'src/store/actions/driverActions';
import { useTranslation } from 'react-i18next';
import { AssignationStatus } from 'src/utils/constants';
import AssignDriverDialog from './AssignDriverDialog';
import { CAR_STATUS, carIsBusinessMode, carIsLeaseMode } from '../utils';
import ResumeInfo from './ResumeInfo';
import useStyles from '../styles';
import CarModeDialog from '../BasicCar/CarModeDialog';

const CarDriver = ({
  actualDriverName,
  actualDriverId,
  actualDriverAssignation,
  actualDriverAssignationId,
  niv,
  carMode,
  carLease,
  rent,
  costPerWeek,
  car,
  actualDriverAssignationStatus,
  contract,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const cancelAssignationLoading = useSelector(
    isLoadingSelector([CANCEL_ASSIGNATION.action]),
  );
  const driverAssignationLoading = useSelector(
    isLoadingSelector([DRIVER_ASSIGNATION.action]),
  );
  const changeAssignDriverLoading = useSelector(
    isLoadingSelector([CHANGE_DRIVER_ASSIGNATION.action]),
  );

  const isCarStatusSold = car.status === CAR_STATUS.SOLD;

  const unassingDriverLoading = useSelector(
    isLoadingSelector([UNASSIGN_DRIVER.action]),
  );

  const resendSignNotificationLoading = useSelector(
    isLoadingSelector([RESEND_SIGN_NOTIFICATION.action]),
  );

  const [openModeDialog, setOpenModeDialog] = useState(false);

  const handleClickOpenMode = useCallback(() => {
    setOpenModeDialog(true);
  }, [setOpenModeDialog]);

  const handleCloseMode = useCallback(() => {
    setOpenModeDialog(false);
  }, [setOpenModeDialog]);

  const handleModeSubmit = useCallback(
    values => {
      dispatch(updateCarModeAction(car.niv, values));
    },
    [car, dispatch],
  );

  const handleCancelAsignation = useCallback(() => {
    dispatch(cancelAssignDriver({ driverId: actualDriverId, niv }));
  }, []);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    action => values => {
      dispatch(action({ ...values, niv, id: actualDriverAssignationId }));
    },
    [niv, actualDriverAssignationId, dispatch],
  );

  const hanldeResendSignNotification = () => {
    dispatch(resendSignNotificationAction(contract.id));
  };

  const username = useMemo(() => actualDriverName || t('COMMON.NO_DRIVER'), [
    actualDriverName,
  ]);

  const isLeaseMode = useMemo(() => carIsLeaseMode(carMode), [carMode]);
  const isBusinessMode = useMemo(() => carIsBusinessMode(carMode), [carMode]);

  const assignation = useMemo(
    () =>
      actualDriverAssignation
        ? `Asignado desde el ${moment(actualDriverAssignation).format(
            'DD-MMM-YY',
          )}`
        : '',
    [actualDriverAssignation],
  );

  const driverOrBusiness = useMemo(
    () => (isBusinessMode ? t('FLEET_VIEW.COMPANIES') : t('COMMON.DRIVER')),
    [isBusinessMode],
  );

  const setLoadingMessage = useCallback(() => {
    switch (true) {
      case cancelAssignationLoading:
        return 'LOADING_MESSAGE.CANCEL_ASSIGNATION';
      case driverAssignationLoading:
        return 'LOADING_MESSAGE.DRIVER_ASSIGNATION';
      case changeAssignDriverLoading:
        return 'LOADING_MESSAGE.CHANGE_DRIVER_ASSIGNATION';
      case unassingDriverLoading:
        return 'LOADING_MESSAGE.UNASSIGN_DRIVER';
      default:
        return '';
    }
  });

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {driverOrBusiness}
            &nbsp;actual
          </Typography>
        }
      />
      <Divider />
      {driverAssignationLoading ||
      changeAssignDriverLoading ||
      unassingDriverLoading ? (
        <Loading label={t(setLoadingMessage())} />
      ) : (
        <>
          <CardContent>
            <Box alignItems="center" display="flex">
              <Avatar className={classes.avatar}>
                <Person className={classes.icon} />
              </Avatar>
              <Box
                marginLeft={2}
                alignItems="flex-start"
                display="flex"
                flexDirection="column"
              >
                <Typography color="textPrimary" variant="h4">
                  {actualDriverId ? (
                    <Link to={`/drivers/detail/${actualDriverId}`}>
                      {username}
                    </Link>
                  ) : (
                    username
                  )}
                </Typography>
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body2"
                >
                  {assignation}
                </Typography>
              </Box>
            </Box>
            <AssignDriverDialog
              assigned={!!actualDriverName}
              open={open}
              niv={niv}
              carMode={carMode}
              isLeaseMode={isLeaseMode}
              isBusinessCar={isBusinessMode}
              handleClose={handleClose}
              submit={handleSubmit}
            />
            <CarModeDialog
              open={openModeDialog}
              car={car}
              handleClose={handleCloseMode}
              submit={handleModeSubmit}
            />
          </CardContent>
          <Divider />
          {cancelAssignationLoading || resendSignNotificationLoading ? (
            <Loading
              label={
                resendSignNotificationLoading
                  ? t('LOADING_MESSAGE.RESEND_SIGN_NOTIFICATION')
                  : t('LOADING_MESSAGE.CANCEL_ASSIGNATION')
              }
            />
          ) : (
            <>
              {actualDriverName && (
                <Box alignItems="center" display="flex">
                  <ResumeInfo
                    carMode={carMode}
                    carLease={carLease}
                    rent={rent}
                    costPerWeek={costPerWeek}
                    assignationStatus={actualDriverAssignationStatus}
                    niv={niv}
                    car={car}
                  />
                </Box>
              )}

              <Box
                style={{ padding: '16px' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex"
              >
                {actualDriverAssignationStatus === AssignationStatus.PENDING ? (
                  <Box>
                    <Button
                      onClick={hanldeResendSignNotification}
                      variant="contained"
                      color="primary"
                      className={clsx(classes.label, classes.buttons)}
                      startIcon={<SendIcon />}
                      disabled={isLeaseMode && carLease}
                    >
                      {t('COMMON.RESEND_TO_SIGN')}
                    </Button>
                    <Button
                      onClick={handleCancelAsignation}
                      variant="contained"
                      color="primary"
                      className={clsx(classes.label, classes.buttons)}
                      startIcon={<EditIcon />}
                      disabled={isLeaseMode && carLease}
                    >
                      {t('DRIVER_DETAIL_VIEW.LEASE.CANCEL_ASSIGNATION')}
                    </Button>
                  </Box>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={actualDriverName ? <UserX /> : <UserPlus />}
                      onClick={handleClickOpen}
                      className={clsx(classes.label, classes.buttons)}
                      disabled={isCarStatusSold}
                    >
                      {actualDriverName
                        ? `${t(
                            'FLEET_VIEW.UNASSIGN',
                          )} ${driverOrBusiness.toLowerCase()}`
                        : `${t(
                            'FLEET_VIEW.ASSIGN',
                          )} ${driverOrBusiness.toLowerCase()}`}
                    </Button>

                    <Button
                      onClick={handleClickOpenMode}
                      variant="contained"
                      color="primary"
                      className={clsx(classes.label, classes.buttons)}
                      startIcon={<EditIcon />}
                      disabled={(isLeaseMode && carLease) || isCarStatusSold}
                    >
                      {t('FLEET_VIEW.CHANGE_MODALITY')}
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default CarDriver;
