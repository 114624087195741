import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import TableRow from '@material-ui/core/TableRow';
import DriverMetricsRow from './DriverMetricsRow';

const Tbody = ({ mileages, t }) =>
  mileages.map(mileage => (
    <DriverMetricsRow key={mileage.id} t={t} {...mileage} />
  ));

const DriverMetricsTable = ({
  mileages,
  className,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell style={{ width: '30%' }}>{t('COMMON.WEEK')}</TableCell>
      <TableCell style={{ width: '30%' }}>
        {t('DRIVER_DETAIL_VIEW.METRICS.MILEAGE_TRAVELED')}
      </TableCell>
      <TableCell style={{ width: '30%' }}>
        {t('DRIVER_DETAIL_VIEW.METRICS.MILEAGE_REGISTERED')}
      </TableCell>
      <TableCell style={{ width: '10%' }}>
        {t('DRIVER_DETAIL_VIEW.INCIDENTS.CAR_ASSIGNED')}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody mileages={mileages} t={t} />, [
    mileages,
    t,
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, mileages],
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

DriverMetricsTable.propTypes = {
  className: PropTypes.string,
  mileages: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default DriverMetricsTable;
