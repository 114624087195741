/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TableCell from 'src/components/Table/TableCell';

const MaintenanceRow = ({
  driverId,
  driver,
  diferenceInMaintenance,
  mileage,
  maintenanceDate,
  diferenceInMileage,
}) => (
  <TableRow hover>
    <TableCell>
      <Link to={`/drivers/detail/${driverId}`}>{driver.name}</Link>
    </TableCell>
    <TableCell>{moment(maintenanceDate).format('DD-MMM-YY')}</TableCell>
    <TableCell>{diferenceInMaintenance}</TableCell>
    <TableCell>{mileage}</TableCell>
    <TableCell>{diferenceInMileage}</TableCell>
  </TableRow>
);

export default MaintenanceRow;
