import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { Link } from 'react-router-dom';

const DriverMetricsRow = ({
  createdAt,
  carniv,
  diferenceToLastWeek,
  mileage,
}) => {
  return (
    <TableRow hover>
      <TableCell>
        {moment(createdAt)
          .startOf('isoWeek')
          .format('DD-MMM-YYYY')}
      </TableCell>
      <TableCell>{diferenceToLastWeek}</TableCell>
      <TableCell>{mileage}</TableCell>
      <TableCell>
        <Link style={{ color: 'blue' }} to={`/fleet/car/${carniv}`}>
          {carniv}
        </Link>
      </TableCell>
    </TableRow>
  );
};

DriverMetricsRow.propTypes = {
  carniv: PropTypes.string,
  createdAt: PropTypes.string,
  diferenceToLastWeek: PropTypes.number,
  mileage: PropTypes.number,
};

export default DriverMetricsRow;
