import {
  CLEAR_CAMPAIGNS,
  CLEAR_CAMPAIGNS_OPTIONS,
  LOAD_CAMPAIGNS,
  SAVE_CAMPAIGNS_OPTIONS,
} from '../actions/campaignsActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  count: 0,
};

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGNS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_CAMPAIGNS_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_CAMPAIGNS_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    case CLEAR_CAMPAIGNS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default campaignsReducer;
