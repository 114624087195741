import React, { useEffect } from 'react';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'src/store/actions/businessActions';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import { useParams } from 'react-router';
import { selectBusinessDetail } from 'src/store/selectors/businessSelectors';
import BusinessTabs from './BusinessTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  preloaderCard: {
    height: '82vh',
  },
}));

const BusinessDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const business = useSelector(selectBusinessDetail);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_DETAIL_BUSINESS.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_DETAIL_BUSINESS.action]),
  );

  useEffect(() => {
    dispatch(Actions.loadBusinessDetail(id));
  }, [dispatch, id]);

  return (
    <Page
      className={classes.root}
      title={`Empresa ${business ? business.id : ''}`}
    >
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            {success && !loading && business ? (
              <BusinessTabs business={business} />
            ) : (
              <Card className={classes.preloaderCard}>
                <Preloader />
              </Card>
            )}
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default BusinessDetailView;
