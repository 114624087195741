/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  Slide,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Formik } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInsurersList,
  selectAgentsList,
} from 'src/store/selectors/catalogSelectors';
import {
  clearCatalog,
  loadInsurersList,
  loadAgentsList,
} from 'src/store/actions/catalogActions';
import UploadFile from 'src/components/UploadFile';
import { useTranslation } from 'react-i18next';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = car => {
  return {
    company: car.insurance ? car.insurance.company : '',
    policyType: car.insurance ? car.insurance.policyType : '',
    cover: car.insurance ? car.insurance.cover : '',
    policyId: car.insurance ? car.insurance.policyId : '',
    carniv: car.niv,
    agent: car.insurance ? car.insurance.agent : '',
    coverageStart: moment().toDate(),
    policyPDF: car.insurance
      ? {
          name: 'Ver actual póliza',
          link: car.insurance.policyPDF,
          inDb: true,
        }
      : '',
    price: car.insurance ? car.insurance.price : '',
  };
};

const DatePickerField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];
  const { t } = useTranslation();

  return (
    <DatePicker
      className={classes.field}
      inputVariant="outlined"
      fullWidth
      name={field.name}
      value={field.value}
      format="DD/MM/YYYY"
      variant="inline"
      label={t('INSURANCE_BALANCE_VIEW.COVERAGE_START_DATE')}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={error => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={date => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...other}
    />
  );
};

const CarInsuranceDialog = ({ open, car, handleClose, submit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(car), [car]);
  const resetRef = useRef();
  const insurers = useSelector(selectInsurersList);
  const agents = useSelector(selectAgentsList);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleClose();
        handleConfirmationClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  const handleUploadChange = useCallback(
    setFieldValue => e => {
      if (e.target.files[0]) {
        setFieldValue(e.target.name, e.target.files[0]);
      }
    },
    [],
  );

  useEffect(() => {
    if (open) {
      dispatch(loadInsurersList());
      dispatch(loadAgentsList());
    }

    return () => {
      dispatch(clearCatalog());
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-insurance-car"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('INSURANCE_VIEW.INSURANCE_INFORMATION')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="alert-form-insurance-car-dialog"
                  >
                    Al hacer click en&nbsp;
                    <b>Aceptar</b>
                    , acepta que la póliza de sguro actual
                    será&nbsp;
                    <b>reemplazada.</b>
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.company && errors.company)}
                >
                  <InputLabel id="company-select-label">
                    {t('INSURANCE_VIEW.INSURER')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="company-select-label"
                    label={t('INSURANCE_VIEW.INSURER')}
                    id="company-select"
                    value={values.company}
                    onChange={handleChange}
                    name="company"
                  >
                    {insurers?.map(insurer => (
                      <MenuItem value={insurer.name} key={insurer.name}>
                        {insurer.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(touched.company && errors.company) && (
                    <FormHelperText>{t(errors.company)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.agent && errors.agent)}
                >
                  <InputLabel id="agent-select-label">
                    {t('INSURANCE_VIEW.AGENT')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="agent-select-label"
                    label={t('INSURANCE_VIEW.AGENT')}
                    id="agent-select"
                    value={values.agent}
                    onChange={handleChange}
                    name="agent"
                  >
                    {agents?.map(agent => (
                      <MenuItem value={agent.name} key={agent.name}>
                        {agent.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(touched.agent && errors.agent) && (
                    <FormHelperText>{t(errors.agent)}</FormHelperText>
                  )}
                </FormControl>

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="policyPDF"
                  textLabel={t('COMMON.UPLOAD_PDF')}
                />

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.policyType && errors.policyType)}
                >
                  <InputLabel id="policyType-select-label">
                    {t('INSURANCE_VIEW.POLICY_TYPE')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="policyType-select-label"
                    label={t('INSURANCE_VIEW.POLICY_TYPE')}
                    id="policyType-select"
                    value={values.policyType}
                    onChange={handleChange}
                    name="policyType"
                  >
                    <MenuItem value="Plataforma">
                      {t('COMMON.PLATFORM')}
                    </MenuItem>
                    <MenuItem value="Normal">{t('COMMON.NORMAL')}</MenuItem>
                  </Select>
                  {Boolean(touched.policyType && errors.policyType) && (
                    <FormHelperText>{t(errors.policyType)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.cover && errors.cover)}
                >
                  <InputLabel id="cover-select-label">
                    {t('INSURANCE_VIEW.COVERAGE')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="cover-select-label"
                    label={t('INSURANCE_VIEW.COVERAGE')}
                    id="cover-select"
                    value={values.cover}
                    onChange={handleChange}
                    name="cover"
                  >
                    <MenuItem value="Amplia">Amplia</MenuItem>
                    <MenuItem value="Solo R.C.">Solo R.C.</MenuItem>
                  </Select>
                  {Boolean(touched.cover && errors.cover) && (
                    <FormHelperText>{t(errors.cover)}</FormHelperText>
                  )}
                </FormControl>

                <Field name="coverageStart" component={DatePickerField} />

                <TextField
                  error={Boolean(touched.price && errors.price)}
                  fullWidth
                  helperText={touched.price && errors.price}
                  label={t('COMMON.PRICE')}
                  margin="normal"
                  name="price"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.price}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.policyId && errors.policyId)}
                  fullWidth
                  helperText={touched.policyId && t(errors.policyId)}
                  label={t('INSURANCE_VIEW.POLICY_NUMBER')}
                  margin="normal"
                  name="policyId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.policyId}
                  variant="outlined"
                  disabled={car.insurance}
                />

                <TextField
                  fullWidth
                  label="NIV"
                  margin="normal"
                  name="niv"
                  type="text"
                  value={car.serie}
                  variant="outlined"
                  disabled
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {car.insurance
                        ? t('COMMON.UPDATE')
                        : t('INSURANCE_VIEW.ADD_INSURANCE')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CarInsuranceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  car: PropTypes.object,
};

export default CarInsuranceDialog;
