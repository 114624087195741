import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CLEAR_CATALOG,
  loadGeofencingCategoriesList,
  LOAD_GEOFENCE_CATEGORIES,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import GeofencingCategoryTable from './GeofencingCategoryTable';

const GeofencingCategoryList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const geofencings = useSelector(selectCatalogList);
  const loading = useSelector(
    isLoadingSelector([LOAD_GEOFENCE_CATEGORIES.action]),
  );

  useEffect(() => {
    dispatch(loadGeofencingCategoriesList());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency
  return (
    <GeofencingCategoryTable
      geofencings={geofencings}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
    />
  );
};
GeofencingCategoryList.propTypes = {
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default GeofencingCategoryList;
