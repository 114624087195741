/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Typography,
  Slide,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = business => {
  return {
    id: business.id,
    contactName: business.contactName,
    contactType: business.contactType,
    contactPhoneOffice: business.contactPhoneOffice,
    contactPhonePersonal: business.contactPhonePersonal,
    contactMailAddress: business.contactMailAddress,
  };
};

const ContactBusinessDialog = ({ open, business, handleClose, submit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(business), [business]);
  const resetRef = useRef();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-contact-business"
      >
        {!confirmationOpen && (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography className={classes.header} color="primary" variant="h2">
          {t('COMMON.CONTACT')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-contact-business-dialog"
                  >
                    {t('BUSINESS.UPDATE_BUSINESS_DATA')}
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.contactName && errors.contactName)}
                >
                  <TextField
                    error={Boolean(touched.contactName && errors.contactName)}
                    fullWidth
                    helperText={touched.contactName && t(errors.contactName)}
                    label="Nombre"
                    margin="none"
                    name="contactName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.contactName}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.contactType && errors.contactType)}
                >
                  <InputLabel id="contactType-select-label">
                    {t('BUSINESS.CONTACT_TYPE')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="contactType-label"
                    label={t('BUSINESS.CONTACT_TYPE')}
                    id="contactType"
                    value={values.contactType}
                    onChange={handleChange}
                    name="contactType"
                  >
                    <MenuItem value="COMMERCIAL">
                      {t('BUSINESS.COMMERCIAL')}
                    </MenuItem>
                    <MenuItem value="COLLECTION">
                      {t('BUSINESS.COLLECTION')}
                    </MenuItem>
                    <MenuItem value="LEGAL">{t('BUSINESS.LEGAL')}</MenuItem>
                  </Select>
                  {Boolean(touched.contactType && errors.contactType) && (
                    <FormHelperText>{t(errors.contactType)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(
                    touched.contactPhoneOffice && errors.contactPhoneOffice,
                  )}
                >
                  <TextField
                    error={Boolean(
                      touched.contactPhoneOffice && errors.contactPhoneOffice,
                    )}
                    fullWidth
                    helperText={
                      touched.contactPhoneOffice && t(errors.contactPhoneOffice)
                    }
                    label={t('BUSINESS.PHONE_OFFICE')}
                    margin="none"
                    name="contactPhoneOffice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.contactPhoneOffice}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(
                    touched.contactPhonePersonal && errors.contactPhonePersonal,
                  )}
                >
                  <TextField
                    error={Boolean(
                      touched.contactPhonePersonal &&
                        errors.contactPhonePersonal,
                    )}
                    fullWidth
                    helperText={
                      touched.contactPhonePersonal &&
                      t(errors.contactPhonePersonal)
                    }
                    label={t('COMMON.PHONE')}
                    margin="none"
                    name="contactPhonePersonal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.contactPhonePersonal}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(
                    touched.contactMailAddress && errors.contactMailAddress,
                  )}
                >
                  <TextField
                    error={Boolean(
                      touched.contactMailAddress && errors.contactMailAddress,
                    )}
                    fullWidth
                    helperText={
                      touched.contactMailAddress && t(errors.contactMailAddress)
                    }
                    label={t('COMMON.EMAIL')}
                    margin="none"
                    name="contactMailAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.contactMailAddress}
                    variant="outlined"
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.UPDATE')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ContactBusinessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  business: PropTypes.object,
};

export default ContactBusinessDialog;
