import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';

const IncidentHistoryRow = ({ reason, date, carniv }) => {
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell>{moment(date).format('DD-MMM-YY')}</TableCell>
      <TableCell>{t(`DRIVER_DETAIL_VIEW.INCIDENTS.${reason}`)}</TableCell>
      <TableCell>{carniv || '--'}</TableCell>
    </TableRow>
  );
};

IncidentHistoryRow.propTypes = {
  reason: PropTypes.string,
  carniv: PropTypes.string,
  date: PropTypes.string,
};

export default IncidentHistoryRow;
