/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import FleetView from 'src/views/fleet';
import DriversView from 'src/views/driver';
import IncomeView from 'src/views/income';
import CatalogView from 'src/views/catalog';
import ParameterView from './parameter';
import ExpenseView from './expense';
import GpsView from './gps';
import TransactionsView from './transactions';
import CampaignsViews from './campaigns';
import AcccountingView from './accounting';
import BillingParametersListView from './billing';
import FinesView from './fines';

const DashboardViews = ({ match }) => (
  <DashboardLayout>
    <Route path="/fleet" component={FleetView} />
    <Route path="/drivers" component={DriversView} />
    <Route path="/gps" component={GpsView} />
    <Route path="/income" component={IncomeView} />
    <Route path="/catalogs" component={CatalogView} />
    <Route path="/parameters" component={ParameterView} />
    <Route path="/expenses" component={ExpenseView} />
    <Route path="/transactions" component={TransactionsView} />
    <Route path="/campaigns" component={CampaignsViews} />
    <Route path="/accounting" component={AcccountingView} />
    <Route path="/billing" component={BillingParametersListView} />
    <Route path="/fines" component={FinesView} />
    <Route
      exact
      path={`${match.url}`}
      component={() => <Redirect to="fleet/cars" />}
    />
  </DashboardLayout>
);

export default DashboardViews;
