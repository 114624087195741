export const CAR_MODES = {
  LEASE: 'LEASE',
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  PLATFORM: 'PLATFORM',
  COMPANIES: 'COMPANIES',
};

export const CAR_STATUS = {
  ASSIGNED: 'ASSIGNED',
  AVAILABLE: 'AVAILABLE',
  SOLD: 'SOLD',
  PENDING_ASSIGNATION: 'PENDING_ASSIGNATION',
  ON_SALE: 'ON_SALE',
};

export const TRANSMISSION_TYPE = {
  MANUAL: 'Manual',
  AUTOMATIC: 'Automatico',
  SEMIMANUAL: 'Semimanual',
};

export const VAT_TAX = 0.13793102;

export const carIsLeaseMode = mode => mode === CAR_MODES.LEASE;
export const carIsAdministrativeMode = mode =>
  mode === CAR_MODES.ADMINISTRATIVE;
export const carIsBusinessMode = mode => mode === CAR_MODES.COMPANIES;

export const getBrandNameList = brands => {
  const brandList = [];

  brands
    .map(item => item.brand)
    .forEach(brand => {
      if (!brandList.includes(brand)) {
        brandList.push(brand);
      }
    });

  return brandList;
};
