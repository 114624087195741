import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';

const AgentsRow = ({ name, city }) => (
  <TableRow hover>
    <TableCell>{name}</TableCell>
    <TableCell>{city}</TableCell>
  </TableRow>
);

AgentsRow.propTypes = {
  name: PropTypes.string,
  city: PropTypes.string,
};

export default AgentsRow;
