import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationCustom = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStyles();

  const handleChange = (event, value) => {
    onChangePage(event, value - 1);
  };

  return (
    <div className={classes.root}>
      <Pagination
        showFirstButton
        showLastButton
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        defaultPage={1}
        onChange={handleChange}
        shape="rounded"
        siblingCount={1}
        boundaryCount={1}
        color="primary"
      />
    </div>
  );
};

TablePaginationCustom.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationCustom;
