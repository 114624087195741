import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  downloadeExportedFactor,
  getRentFactor,
} from 'src/services/rentFactorService';
import { showMessage } from './snackBarActions';
import { selectRentFactorQueryList } from '../selectors/rentFactorSelectors';

export const LOAD_RENT_FACTOR = asyncActionCreator('LOAD_RENT_FACTOR');
export const CLEAR_RENT_FACTOR = 'CLEAR_RENT_FACTOR';
export const SAVE_RENT_FACTOR_OPTIONS = 'SAVE_RENT_FACTOR_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectRentFactorQueryList(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.timePeriod) {
    reqOptions.timePeriod = queryOpts.timePeriod;
  }
  if (queryOpts.carMode) {
    reqOptions.carMode = queryOpts.carMode;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadRentFactor = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_RENT_FACTOR.start });

    dispatch({
      type: SAVE_RENT_FACTOR_OPTIONS,
      options,
    });

    const reqOptions = getOptions(getState);

    const data = await getRentFactor(reqOptions);

    return dispatch({
      type: LOAD_RENT_FACTOR.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_RENT_FACTOR.failure });
  }
};

export const clearRentFactor = () => dispatch => {
  dispatch({ type: CLEAR_RENT_FACTOR });
};

export const downloadExcelFactor = () => async dispatch => {
  try {
    return downloadeExportedFactor();
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};
