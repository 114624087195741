import React from 'react';
import { useTranslation } from 'react-i18next';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';

const AddressInfo = ({ address }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('COMMON.STREET')}
        value={address.street || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.COLONIA')}
        value={address.colonia || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.EXTERNAL_NUMBER')}
        value={address.externalHomeNumber || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.INSIDE_NUMBER')}
        value={address.insideHomeNumber || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.HOME_TOWN')}
        value={address.homeTown || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.ZIP_CODE')}
        value={address.zipCode || t('COMMON.NO_DATA')}
      />
    </>
  );
};

AddressInfo.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    colonia: PropTypes.string,
    externalHomeNumber: PropTypes.string,
    insideHomeNumber: PropTypes.string,
    homeTown: PropTypes.string,
    zipCode: PropTypes.string,
  }),
};

export default AddressInfo;
