export const EXTERNAL_PAYMENT_METHODS = [
  'OPEN_PAY',
  'PAYCASH',
  'OPEN_PAY_CARD',
  'BODEGA_AURRERA',
  'BBVA',
  'SORIANA',
  'WALMART',
  'SEVEN_ELEVEN',
];

export const UNASSIGN_TYPE = {
  CAR_CHANGE: 'CAR_CHANGE',
  DISCHARGE: 'DISCHARGE',
};

export const statusEngine = {
  ACTIVE: true,
  INACTIVE: false,
};

export const MAP_UPDATE_LITERAL = 15000;

export const CAMPAIGN_TYPE = {
  GENERAL: 'GENERAL',
  PERSONAL: 'PERSONAL',
};

export const DriverStatus = {
  ACTIVE: 'Active',
  PIPELINE: 'Pipeline',
  REVIEW: 'Review',
  REJECTED: 'Rejected',
  LEFT: 'Left',
};

export const DriverLeftReason = {
  TURN_CHANGE: 'TURN_CHANGE',
  PRICE: 'PRICE',
  SERVICE: 'SERVICE',
  CAR_CARE: 'CAR_CARE',
  NON_PAYMENT: 'NON_PAYMENT',
  CRIME: 'CRIME',
  OTHER: 'OTHER',
};

export const AmortizationBalanceType = {
  AMORTIZATION: 'AMORTIZATION',
  ASSETS: 'ASSETS',
};

export const DepreciationBalanceType = {
  DEPRECIATION: 'DEPRECIATION',
  ASSETS: 'ASSETS',
};

export const TimePeriod = {
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
  HISTORY: 'HISTORY',
};

export const CarSaleReason = {
  TURN_OVER: 'TURN_OVER',
  LEASE: 'LEASE',
  ACCIDENT_INSURANCE_CARRIER: 'ACCIDENT_INSURANCE_CARRIER',
  ACCIDENT_DIRECT: 'ACCIDENT_DIRECT',
};

export const IconStatusAndColor = {
  OK: { status: 'OK', color: '#7cb342' },
  WARNING: { status: 'WARNING', color: '#fdd835' },
  ERROR: { status: ' ERROR', color: '#df4d49' },
  NONE: { status: 'NONE', color: '#f44336' },
};

export const CommonStatus = {
  OK: 'OK',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  NONE: 'NONE',
};

export const CommonIconColor = {
  OK: 'OK',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  NONE: 'NONE',
};

export const DriverStatusAction = {
  ACTIVATE_DRIVER: 'ACTIVATE',
  REJECT_DRIVER: 'REJECT',
};

export const LicenceStatus = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  EXPIRING: 'EXPIRING',
  UNREGISTERED: 'UNREGISTERED',
};

export const InsuranceStatus = {
  ERROR: 'ERROR',
  ACTIVE: 'ACTIVE',
};

export const ParameterType = {
  BOOLEAN: 'BOOLEAN',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
};

export const PlatformType = {
  UBER: 'Uber',
  DIDI: 'Didi',
  PRIVADO: 'Privado',
};

export const UberProCategory = {
  PLATE: 'Plata',
  GOLD: 'Oro',
  PLATINUM: 'Platino',
};

export const Periods = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
};

export const TransmissionRange = {
  NORMAL: 60,
  MEDIUM: 120,
};

export const ParameterSection = {
  GENERAL: 'GENERAL',
  PRICING: 'PRICING',
};

export const DeviceBrand = [
  { key: 'Ruptela', name: 'Ruptela' },
  { key: 'Coban', name: 'Coban' },
];

export const DeviceType = [
  { key: 'OBD', name: 'OBD' },
  { key: 'Internal', name: 'Internal' },
  { key: 'Canbus', name: 'Canbus' },
];

export const BalanceType = {
  TOTAL: 'TOTAL',
  RENT: 'RENT',
  OTHERS: 'OTHERS',
};

export const AssignationStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const ContractStatus = {
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  IN_PROGRESS: 'IN_PROGRESS',
  ENDED: 'ENDED',
  CANCELLED: 'CANCELLED',
};

export const TransactionCategory = {
  RENT: 'RENT',
  SURCHARGE: 'SURCHARGE',
  INTERESTS: 'INTERESTS',
  TERMINATION: 'TERMINATION',
  OTHERS: 'OTHERS',
  DISCOUNTS: 'DISCOUNTS',
  FINES: 'FINES',
  SINISTER: 'SINISTER',
  ADVANCE: 'ADVANCE',
  RESIDUAL: 'RESIDUAL',
};
export const DateTypeFormat = {
  DAY: 'DAY',
  MONTH_AND_YEAR: 'MONTH_AND_YEAR',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
};

export const IncidentReason = [
  { key: 'Disponible', name: 'AVAILABLE' },
  { key: 'Taller', name: 'MECHANICAL_WORKSHOP' },
  { key: 'Corralón', name: 'DETENTION' },
  { key: 'Desaparición', name: 'DISAPPAREANCE' },
  { key: 'Otros', name: 'OTHER' },
];

export const TransactionType = {
  CHARGE: 'CHARGE',
  PAYMENT: 'PAYMENT',
};

export const CarModeQuery = {
  ALL: 'ALL',
  LEASE: 'LEASE',
  PLATFORM: 'PLATFORM',
};

export const DriverSemaphoreStatus = {
  RED: 'RED',
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
};

export const BillingType = {
  CONCEPT: 'CONCEPT',
  PAYMENT_FORM: 'PAYMENT_FORM',
};

export const NumberPositionRow = 'center';
