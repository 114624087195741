import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import {
  createDeviceAction,
  deleteDeviceAction,
  editDeviceAction,
} from 'src/store/actions/catalogActions';
import DeviceList from './DeviceList';
import DeviceForm from './DeviceForm';
import Toolbar from '../Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DeviceListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(null);
  }, [setOpen, setData]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleOpenEdit = useCallback(
    values => {
      setOpen(true);
      setData(values);
    },
    [setOpen, setData],
  );

  const handleEdit = useCallback(
    values => {
      dispatch(editDeviceAction(values));
      handleClose();
    },
    [handleClose, dispatch],
  );

  const handleCreate = useCallback(
    values => {
      delete values.serviceId;
      dispatch(createDeviceAction(values));
      handleClose();
    },
    [handleClose],
  );

  const handleDelete = useCallback(
    id => {
      dispatch(deleteDeviceAction(id));
      handleClose();
    },
    [handleClose, dispatch],
  );

  return (
    <Page className={classes.root} title={t('FLEET_VIEW.DEVICE')}>
      <Container maxWidth={false}>
        <Toolbar
          catalogLabel={t('FLEET_VIEW.DEVICE')}
          handleOpen={handleOpen}
        />
        <Box mt={3}>
          <DeviceList
            handleOpenEdit={handleOpenEdit}
            handleDelete={handleDelete}
          />
        </Box>
      </Container>
      <DeviceForm
        open={open}
        data={data}
        handleClose={handleClose}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
      />
    </Page>
  );
};

export default DeviceListView;
