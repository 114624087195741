import {
  CLEAR_EXPENSE,
  CLEAR_EXPENSE_AUTOCOMPLETES,
  CLEAR_EXPENSE_OPTIONS,
  LOAD_EXPENSE,
  LOAD_EXPENSE_AUTOCOMPLETES,
  SAVE_EXPENSE_OPTIONS,
} from '../actions/expenseActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  count: 0,
  autocompletes: {
    providers: [],
    expenses: [],
    plates: [],
  },
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EXPENSE.success: {
      return {
        options: state.options,
        autocompletes: state.autocompletes,
        items: action.items,
        count: action.count,
      };
    }
    case LOAD_EXPENSE_AUTOCOMPLETES.success: {
      return {
        options: state.options,
        items: state.items,
        count: state.count,
        autocompletes: action.data,
      };
    }
    case CLEAR_EXPENSE_AUTOCOMPLETES: {
      return {
        options: state.options,
        items: state.items,
        count: state.count,
        autocompletes: initialState.autocompletes,
      };
    }
    case SAVE_EXPENSE_OPTIONS: {
      return {
        items: state.items,
        count: state.count,
        autocompletes: state.autocompletes,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_EXPENSE_OPTIONS: {
      return {
        options: initialState.options,
        items: state.items,
        autocompletes: state.autocompletes,
        count: state.count,
      };
    }
    case CLEAR_EXPENSE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default expenseReducer;
