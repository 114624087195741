import { push } from 'connected-react-router';
import {
  changePass,
  loginWithCredentials,
  recoverPass,
  validateTokenWithUsername,
} from 'src/services/authService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { showMessage } from './snackBarActions';

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const VALIDATE_TOKEN = asyncActionCreator('VALIDATE_TOKEN');
export const RECOVER_PASS = asyncActionCreator('RECOVER_PASS');
export const CHANGE_PASS = asyncActionCreator('CHANGE_PASS');

function getErrorMessage(error) {
  let message;
  if (error.message === 'Unauthorized') {
    message = 'Correo electrónico y / o contraseña inválido';
  } else if (
    error.message === 'password must be longer than or equal to 8 characters'
  ) {
    message = 'La contraseña debe tener más de 8 caracteres o más';
  } else {
    message = error.message;
  }
  return message;
}

export function login({ username, password, keepLogged }) {
  return async dispatch => {
    try {
      const token = await loginWithCredentials({
        username,
        password,
        keepLogged,
      });
      localStorage.setItem('sessionToken', token);

      dispatch({
        type: LOGIN,
        payload: token,
      });
      dispatch(push('/'));
    } catch (error) {
      const message = getErrorMessage(error);
      dispatch(showMessage({ message, variant: 'error' }));
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };
}

export function loginWithToken(token) {
  return async dispatch => {
    try {
      const { location } = document;
      const referrer = location.pathname;

      localStorage.setItem('sessionToken', token);

      dispatch({
        type: LOGIN,
        payload: token,
      });
      dispatch(push(referrer));
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };
}

export function logout() {
  return dispatch => {
    localStorage.removeItem('sessionToken');
    dispatch({ type: LOGOUT });
    dispatch(push('/login'));
  };
}

export function validateToken(token, userName) {
  return async dispatch => {
    try {
      dispatch({
        type: VALIDATE_TOKEN.start,
      });

      await validateTokenWithUsername({ token, userName });

      dispatch({
        type: VALIDATE_TOKEN.success,
      });
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: 'error' }));
      dispatch({
        type: VALIDATE_TOKEN.failure,
      });
    }
  };
}

export function sendRecoverPassEmail(email, resetForm, setSubmitting) {
  return async dispatch => {
    try {
      dispatch({
        type: RECOVER_PASS.start,
      });

      await recoverPass({ email });

      dispatch(
        showMessage({
          message:
            'Un mensaje fue enviado a su correo electrónico para recuperar la contraseña',
          variant: 'success',
        }),
      );

      resetForm();

      dispatch({
        type: RECOVER_PASS.success,
      });
    } catch (error) {
      setSubmitting(false);
      dispatch(showMessage({ message: error.message, variant: 'error' }));
      dispatch({
        type: RECOVER_PASS.failure,
      });
    }
  };
}

export function changePassword(data, resetForm, setSubmitting) {
  return async dispatch => {
    try {
      dispatch({
        type: CHANGE_PASS.start,
      });

      await changePass(data);

      dispatch(
        showMessage({
          message:
            'La contraseña fue cambiada con éxito, será redirigido a la ventana para iniciar sesión',
          variant: 'success',
        }),
      );

      resetForm();
      dispatch({
        type: CHANGE_PASS.success,
      });

      setTimeout(() => {
        dispatch(push('/login'));
      }, 1000);
    } catch (error) {
      setSubmitting(false);
      const message = getErrorMessage(error);
      dispatch(showMessage({ message, variant: 'error' }));
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };
}
