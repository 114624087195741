/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import DetailRow from 'src/components/DetailRow';

const ContactBusinessInfo = ({ business }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow name={t('COMMON.NAME')} value={business.contactName} />
      <DetailRow
        name={t('COMMON.TYPE')}
        value={t(`BUSINESS.${business.contactType}`)}
      />
      <DetailRow
        name={t('BUSINESS.OFFICE_PHONE')}
        value={business.contactPhoneOffice}
      />
      <DetailRow
        name={t('COMMON.PHONE')}
        value={business.contactPhonePersonal}
      />
      <DetailRow name={t('COMMON.EMAIL')} value={business.contactMailAddress} />
    </>
  );
};

export default ContactBusinessInfo;
