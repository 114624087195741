import React from 'react';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from 'src/components/Table/TableCell';
import { Link } from 'react-router-dom';
import { IconStatusAndColor } from 'src/utils/constants';
import StatusIcon from 'src/components/StatusIcon';
import { formatTransmissionTime, GpsTransmissionStatus } from 'src/utils/gps';

const TransmissionGpsRow = ({
  carniv,
  plates,
  driver,
  timeSinceLastTransmission,
  driverId,
  status,
  model,
}) => {
  const getTransmissionStatus = transmissionStatus => {
    if (transmissionStatus === GpsTransmissionStatus.ONLINE) {
      return {
        description: 'COMMON.ONLINE',
        type: IconStatusAndColor.OK.status,
      };
    }

    if (transmissionStatus === GpsTransmissionStatus.OFFLINE) {
      return {
        description: 'COMMON.OFFLINE',
        type: IconStatusAndColor.ERROR.status,
      };
    }

    return {
      description: 'COMMON.NO_DATA',
      type: IconStatusAndColor.NONE.status,
    };
  };
  return (
    <TableRow hover>
      <TableCell>
        {carniv ? <Link to={`/fleet/car/${carniv}`}>{carniv}</Link> : '-'}
      </TableCell>
      <TableCell>{plates || '-'}</TableCell>
      <TableCell>{model || '-'}</TableCell>
      <TableCell>
        {driver ? (
          <Link to={`/drivers/detail/${driverId}`}>{driver}</Link>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{formatTransmissionTime(timeSinceLastTransmission)}</TableCell>
      <TableCell>
        <StatusIcon {...getTransmissionStatus(status)} />
      </TableCell>
    </TableRow>
  );
};

TransmissionGpsRow.propTypes = {
  carniv: PropTypes.string.isRequired,
  plates: PropTypes.string.isRequired,
  driver: PropTypes.string,
  driverId: PropTypes.number,
  timeSinceLastTransmission: PropTypes.number,
  status: PropTypes.string,
  model: PropTypes.string,
};

export default TransmissionGpsRow;
