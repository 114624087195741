export const selectInsurance = state => state.insurance;
export const selectInsuranceList = state => selectInsurance(state).items;
export const selectInsuranceListCount = state => selectInsurance(state).count;
export const selectInsuranceQueryOptions = state => {
  const { options } = selectInsurance(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
