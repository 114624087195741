import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

const buildCrud = (basename, pluralBasename) => ({
  create: serviceBase({
    request: values => axiosClient.post(`/catalog/${basename}`, values),
    retry: 0,
  }),
  read: serviceBase({
    request: () => axiosClient.get(`/catalog/${pluralBasename}`),
    retry: 0,
  }),
  update: serviceBase({
    request: values => axiosClient.put(`/catalog/${basename}`, values),
    retry: 0,
  }),
  delete: serviceBase({
    request: id => axiosClient.delete(`/catalog/${basename}/${id}`),
    retry: 0,
  }),
});

const {
  create: createIncident,
  read: getIncidents,
  update: editIncident,
  delete: deleteIncident,
} = buildCrud('incident', 'incidents');

const {
  create: createExpenseTypes,
  read: getExpenseTypes,
  update: editExpenseTypes,
  delete: deleteExpenseTypes,
} = buildCrud('expense', 'expenses');

const {
  create: createLocation,
  read: getLocations,
  update: editLocation,
  delete: deleteLocation,
} = buildCrud('location', 'locations');

const {
  create: createService,
  read: getServices,
  update: editService,
  delete: deleteService,
} = buildCrud('service', 'services');

const {
  create: createBrand,
  read: getBrands,
  update: editBrand,
  delete: deleteBrand,
} = buildCrud('brand', 'brands');

const {
  create: createCompany,
  read: getCompanies,
  update: editCompany,
  delete: deleteCompany,
} = buildCrud('company', 'companies');

const {
  create: createAgent,
  read: getAgents,
  update: editAgent,
  delete: deleteAgent,
} = buildCrud('agent', 'agents');

const {
  create: createInsurer,
  read: getInsurers,
  update: editInsurer,
  delete: deleteInsurer,
} = buildCrud('insurer', 'insurers');

const {
  create: createProvider,
  read: getProviders,
  update: editProvider,
  delete: deleteProvider,
} = buildCrud('provider', 'providers');

const {
  create: createDevice,
  read: getDevices,
  update: editDevice,
  delete: deleteDevice,
} = buildCrud('device', 'devices');

const deleteCarCartegory = serviceBase({
  request: name => axiosClient.delete(`catalog/car-categories/${name}`),
  transformResponse: res => res.data.data,
  retry: 0,
});

const {
  create: createCarCategory,
  read: getCarCategories,
  update: editCarCategory,
} = buildCrud('car-category', 'car-categories');

const {
  create: createOperationalEmail,
  read: getOperationalEmails,
  delete: deleteOperationalEmail,
} = buildCrud('operational-email', 'operational-emails');

const {
  create: createGeofencingCategory,
  read: getGeofencingCategories,
  update: editGeofencingCategory,
  delete: deleteGeofencingCategory,
} = buildCrud('geofencing-categories', 'geofencing-categories');

const {
  create: createGeofencingZone,
  update: editGeofencingZone,
  read: getGeofencingZones,
  delete: deleteGeofencingZone,
} = buildCrud('geofencing-zones', 'geofencing-zones');

const {
  create: createUnassignReason,
  read: getUnassignReasons,
  update: editUnassignReason,
  delete: deleteUnassignReason,
} = buildCrud('unassign-reasons', 'unassign-reasons');

export {
  createAgent,
  getAgents,
  editAgent,
  deleteAgent,
  createIncident,
  getIncidents,
  editIncident,
  deleteIncident,
  getCompanies,
  createCompany,
  editCompany,
  deleteCompany,
  getServices,
  createService,
  editService,
  deleteService,
  getBrands,
  createBrand,
  editBrand,
  deleteBrand,
  getLocations,
  createLocation,
  editLocation,
  deleteLocation,
  createInsurer,
  getInsurers,
  editInsurer,
  deleteInsurer,
  createExpenseTypes,
  getExpenseTypes,
  editExpenseTypes,
  deleteExpenseTypes,
  createProvider,
  getProviders,
  editProvider,
  deleteProvider,
  createDevice,
  getDevices,
  deleteDevice,
  editDevice,
  getCarCategories,
  createCarCategory,
  editCarCategory,
  deleteCarCartegory,
  createOperationalEmail,
  getOperationalEmails,
  deleteOperationalEmail,
  createGeofencingCategory,
  getGeofencingCategories,
  deleteGeofencingCategory,
  editGeofencingCategory,
  createGeofencingZone,
  getGeofencingZones,
  deleteGeofencingZone,
  editGeofencingZone,
  createUnassignReason,
  getUnassignReasons,
  editUnassignReason,
  deleteUnassignReason,
};
