import DateRange from 'src/utils/DateRangeType';
import IncomeResumeStatus from 'src/utils/IncomeResumeStatus';
import {
  CLEAR_INCOME_RESUME,
  LOAD_INCOME_RESUME,
  SAVE_INCOME_RESUME_OPTIONS,
  CLEAR_INCOME_RESUME_OPTIONS,
} from '../actions/incomeResumeActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    rangeType: DateRange.WEEKLY,
    status: IncomeResumeStatus.ALL,
    carMode: 'ALL',
  },
  items: [],
  count: 0,
};

const incomeResumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INCOME_RESUME.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_INCOME_RESUME_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_INCOME_RESUME_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    case CLEAR_INCOME_RESUME: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default incomeResumeReducer;
