/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import GpsListView from './gpsListView';
import TransmissionGpsListView from './transmissionGpsView';
import RentLockoutGpsView from './rentLockoutGpsView';
import MmtoLockoutGpsGpsView from './mmtoLockoutGpsView';
import GeofencingView from './geofencing';

const GpsView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/list`} component={GpsListView} />
      <Route
        path={`${match.url}/transmission`}
        component={TransmissionGpsListView}
      />
      <Route
        path={`${match.url}/rent-lockout`}
        component={RentLockoutGpsView}
      />
      <Route
        path={`${match.url}/mmto-lockout`}
        component={MmtoLockoutGpsGpsView}
      />
      <Route path={`${match.url}/geofencing`} component={GeofencingView} />
    </>
  );
};

export default GpsView;
