import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import store from 'src/store';
import AppRoutes from 'src/routes/AppRoutes';
import SnackbarMessage from './components/Snackbar/SnackbarMessage';
import Preloader from './components/Preloader';

const App = () => {
  return (
    <Suspense fallback={<Preloader />}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppRoutes />
          </MuiPickersUtilsProvider>
          <SnackbarMessage />
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
