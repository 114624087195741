import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker as DatePickerMui } from '@material-ui/pickers';
import { InsertInvitation } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
}));

const defaultFormat = 'DD/MM/YYYY';

const DatePicker = ({ field, label, form, ...rest }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];
  const { t } = useTranslation();
  return (
    <DatePickerMui
      className={classes.field}
      disablePast={rest.disablePast || false}
      inputVariant="outlined"
      fullWidth
      name={field.name}
      value={field.value}
      format={rest.format || defaultFormat}
      variant="inline"
      label={label}
      helperText={form.touched[field.name] && t(currentError)}
      error={Boolean(form.touched[field.name] && currentError)}
      onChange={date => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...rest}
    />
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.instanceOf(Date),
  }),
  form: PropTypes.shape({
    errors: PropTypes.object,
    setFieldError: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.object,
  }),
};

export default DatePicker;
