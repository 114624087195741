/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const classes = useStyles();
  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            Gasto
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.expenseConcept}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            Proveedor
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.provider}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            Placas
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.plate}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            Conductor
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.driverName}
            </Typography>
          </TableCell>
        </TableRow>
 
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            Region
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.region}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            Monto
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.amount}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            Fecha de incidente
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {moment(values.incidentDate).format('DD-MMM-YY')}
            </Typography>
          </TableCell>
        </TableRow>

      </TableBody>
    </Table>
  );
};

export default ReviewData;
