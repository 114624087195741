import React, { useCallback, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateCarCategoryAction } from 'src/store/actions/carActions';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BasicCarInfo from './BasicCarInfo';
import useStyles from '../styles';
import ChangeCategoryDialog from './ChangeCategoryDialog';

const BasicCar = ({ car }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openChangeCategory, setOpenChangeCategory] = useState();
  const dispatch = useDispatch();

  const handleOpenChangeCategory = useCallback(() => {
    setOpenChangeCategory(true);
  }, [setOpenChangeCategory]);

  const handleCloseChangeCategory = useCallback(() => {
    setOpenChangeCategory(false);
  }, [setOpenChangeCategory]);

  const changeCategorySumbit = useCallback(
    values => {
      dispatch(updateCarCategoryAction(car.niv, values));
    },
    [car, dispatch],
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('CAR_DETAIL.VEHICLE_DATA')}
          </Typography>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <BasicCarInfo
              car={car}
              handleOpenChangeCategory={handleOpenChangeCategory}
            />
          </TableBody>
        </Table>
        <ChangeCategoryDialog
          open={openChangeCategory}
          handleClose={handleCloseChangeCategory}
          submit={changeCategorySumbit}
          category={car.category}
        />
      </CardContent>
    </Card>
  );
};

BasicCar.propTypes = {
  car: PropTypes.shape({
    niv: PropTypes.string,
    mode: PropTypes.string,
    category: PropTypes.string,
    carModel: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.string,
    year: PropTypes.string,
    transmission: PropTypes.string,
    color: PropTypes.string,
    serie: PropTypes.string,
    motor: PropTypes.string,
    purchaseDate: PropTypes.instanceOf(Date),
    price: PropTypes.number,
  }),
};

export default BasicCar;
