export const selectBillingState = state => state.billing;
export const selectBillingParameters = state =>
  selectBillingState(state).parameters;
export const selectBillingOptions = state => {
  const { options } = selectBillingState(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
