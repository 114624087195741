import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Grid,
  Divider,
  DialogContentText,
} from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { switchEngineStatus } from 'src/store/actions/gpsActions';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EngineControllerModal = ({ open, gps, handleClose }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleChangeCarEngineStatus = useCallback(() => {
    dispatch(switchEngineStatus({ niv: gps.niv, isEngineOn: !gps.isEngineOn }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-driver"
      >
        <Typography className={classes.header} color="primary" variant="h2">
          {t('GPS_VIEW.ENGINE_MODAL')}
        </Typography>
      </DialogTitle>
      <Divider light variant="middle" className={classes.divider} />
      <DialogContent>
        <DialogContentText variant="button">
          {gps.isEngineOn ? (
            <>
              <p>{`${t('GPS_VIEW.ENGINE_CONTROL_OFF')}`}</p>
              <br />
              <p>{`${t('GPS_VIEW.ENGINE_CONTROL_OFF_QUESTION')}`}</p>
            </>
          ) : (
            <>
              <p>{`${t('GPS_VIEW.ENGINE_CONTROL_ON')}`}</p>
              <br />
              <p>{`${t('GPS_VIEW.ENGINE_CONTROL_ON_QUESTION')}`}</p>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogContent className={classes.divider}>
        <Grid container direction="row" alignItems="stretch" justify="flex-end">
          <Grid item style={{ marginRight: '3%' }}>
            <Button
              className={clsx(classes.buttons)}
              color="primary"
              onClick={handleClose}
              variant="outlined"
            >
              {t('GPS_VIEW.CLOSE')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={clsx(classes.label, classes.buttons)}
              onClick={() => handleChangeCarEngineStatus()}
              color="primary"
              variant="outlined"
            >
              {gps.isEngineOn ? t('GPS_VIEW.DISABLE') : t('GPS_VIEW.ACTIVATE')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

EngineControllerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  gps: PropTypes.object.isRequired,
};

export default EngineControllerModal;
