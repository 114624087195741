/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import UploadFile from 'src/components/UploadFile';
import clsx from 'clsx';
import useStyles from '../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const StepThree = ({ handleBack, handleNext, data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleUploadChange = useCallback(
    setFieldValue => e => {
      if (e.target.files[0]) {
        setFieldValue('tarjeta', e.target.files[0]);
      }
    },
    [],
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={handleNext}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormControl
            className={classes.field}
            variant="outlined"
            fullWidth
            error={Boolean(touched.estado && errors.estado)}
          >
            <InputLabel id="estado-select-label">Plaza</InputLabel>
            <Select
              onBlur={handleBlur}
              labelId="estado-select-label"
              label="Plaza"
              id="estado-select"
              value={values.estado}
              onChange={handleChange}
              name="estado"
            >
              <MenuItem value="" disabled />
              <MenuItem value="Monterrey">Monterrey</MenuItem>
              <MenuItem value="Saltillo">Saltillo</MenuItem>
              <MenuItem value="Torreón">Torreón</MenuItem>
            </Select>
            {Boolean(touched.estado && errors.estado) && (
              <FormHelperText>{errors.estado}</FormHelperText>
            )}
          </FormControl>

          <TextField
            error={Boolean(touched.plates && errors.plates)}
            fullWidth
            helperText={touched.plates && errors.plates}
            label="Placas (opcional)"
            margin="normal"
            name="plates"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.plates}
            variant="outlined"
          />

          <UploadFile
            values={values}
            errors={errors}
            touched={touched}
            handleUploadChange={handleUploadChange}
            setFieldValue={setFieldValue}
            accept="image/*"
            valueLabel="tarjeta"
            textLabel="Sube la tarjeta de circulación"
          />

          <Box
            className={classes.box}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              className={classes.buttons}
              onClick={handleBack}
              color="primary"
              variant="outlined"
            >
              {t('COMMON.BACK')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.buttonMain)}
              type="submit"
              color="primary"
              variant="outlined"
            >
              {t('COMMON.NEXT')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default StepThree;
