import React, { useCallback } from 'react';
import { Box, Button, Container, TextField } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DatePicker from 'src/components/DatePicker';
import PropTypes from 'prop-types';
import DropdownMenu from 'src/components/DropdownMenu';
import {
  createCampaign,
  updateCampaignAction,
} from 'src/store/actions/campaignsActions';
import { CAMPAIGN_TYPE } from 'src/utils/constants';
import validationSchema from './schema';
import useStyles from '../styles';
import makeInitialValues from './initialState';

const DriverSemaphoreStatus = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
};

const CreateOrEditCampaignForm = ({ campaign, handleClose }) => {
  const initialValues = makeInitialValues(campaign);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitCampaign = useCallback(
    async (values, { resetForm }) => {
      if (campaign) {
        return dispatch(updateCampaignAction(values, resetForm, handleClose));
      }
      return dispatch(createCampaign(values, resetForm, handleClose));
    },
    [createCampaign],
  );

  return (
    <div className={classes.rootForm}>
      <Box display="flex" flexDirection="column">
        <Container>
          <FadeIn transitionDuration={200}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitCampaign}
            >
              {({
                errors,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                handleChange,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    className={classes.textForm}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && t(errors.description)}
                    label={t('COMMON.DESCRIPTION')}
                    margin="normal"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.description}
                    variant="outlined"
                    disabled={values.type === CAMPAIGN_TYPE.PERSONAL}
                  />
                  <TextField
                    className={classes.textForm}
                    error={Boolean(touched.discountDays && errors.discountDays)}
                    helperText={touched.discountDays && t(errors.discountDays)}
                    label={t('CAMPAIGNS_VIEW.DISCOUNT_DAYS')}
                    margin="normal"
                    name="discountDays"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.discountDays}
                    variant="outlined"
                  />
                  <DropdownMenu
                    list={Object.keys(DriverSemaphoreStatus).map(status => {
                      return {
                        key: status,
                        name: t(`COMMON.${status}`),
                      };
                    })}
                    setValue={value =>
                      setFieldValue('minDriverLevel', value, true)
                    }
                    value={values.minDriverLevel}
                    label={t('COMMON.SEMAPHORE')}
                    error={Boolean(
                      touched.minDriverLevel && errors.minDriverLevel,
                    )}
                    errorText={
                      touched.minDriverLevel && t(errors.minDriverLevel)
                    }
                  />
                  <DropdownMenu
                    list={[
                      { key: true, name: t('COMMON.YES') },
                      { key: false, name: t('COMMON.NO') },
                    ]}
                    setValue={value => setFieldValue('recurrent', value, true)}
                    value={values.recurrent}
                    label={t('CAMPAIGNS_VIEW.RECURRENCE')}
                    error={Boolean(touched.recurrent && errors.recurrent)}
                    errorText={touched.recurrent && t(errors.recurrent)}
                  />

                  {values.active !== null && (
                    <DropdownMenu
                      list={[
                        { key: true, name: t('COMMON.ACTIVE') },
                        { key: false, name: t('COMMON.INACTIVE') },
                      ]}
                      setValue={value => setFieldValue('active', value, true)}
                      value={values.active}
                      label={t('CAMPAIGNS_VIEW.CAMPAIGN_STATUS')}
                      error={Boolean(touched.active && errors.active)}
                      errorText={touched.active && t(errors.active)}
                    />
                  )}

                  {(!values.type || values.type === CAMPAIGN_TYPE.GENERAL) && (
                    <DatePicker
                      field={{
                        name: 'discountDate',
                        value: values.discountDate,
                      }}
                      form={{
                        errors,
                        setFieldValue,
                        touched,
                      }}
                      label={t('CAMPAIGNS_VIEW.DISCOUNT_DATE')}
                    />
                  )}

                  <Box my={3}>
                    <Button
                      className={classes.buttonForm}
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t('COMMON.ACCEPT')}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </FadeIn>
        </Container>
      </Box>
    </div>
  );
};

CreateOrEditCampaignForm.propTypes = {
  handleClose: PropTypes.func,
  campaign: PropTypes.object,
};

export default CreateOrEditCampaignForm;
