import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IncidentHistoryRow from './IncidentHistoryRow';

const IncidentHistoryTable = ({ history, className }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell style={{ width: '20%' }}>{t('COMMON.DATE')}</TableCell>
      <TableCell style={{ width: '50%' }}>{t('COMMON.REASON')}</TableCell>
      <TableCell style={{ width: '20%' }}>
        {t('DRIVER_DETAIL_VIEW.INCIDENTS.CAR_ASSIGNED')}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return history.map(h => <IncidentHistoryRow {...h} key={h.date} />);
    },
    [history],
  );

  return <Table tbody={tbody} theads={theads} className={className} />;
};

IncidentHistoryTable.propTypes = {
  className: PropTypes.string,
  history: PropTypes.array.isRequired,
};

export default IncidentHistoryTable;
