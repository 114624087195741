import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getRentFactor = serviceBase({
  request: params => axiosClient.get('/transactions/factor', { params }),
  retry: 0,
});

export const downloadeExportedFactor = serviceBase({
  request: () =>
    axiosClient.get('/transactions/factor/export', {
      responseType: 'blob',
    }),
  transformResponse: res => {
    try {
      const filename = 'indicadores.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});
