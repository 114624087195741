import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import StatusIcon from 'src/components/StatusIcon';
import { IconStatusAndColor, LicenceStatus } from 'src/utils/constants';

const LicencesRow = ({
  carniv,
  name,
  id,
  licenceExpirationAt,
  remainingDays,
  licenceStatus,
}) => {
  const getLicenceStatus = useMemo(() => {
    switch (licenceStatus) {
      case LicenceStatus.ACTIVE:
        return (
          <StatusIcon
            description="LICENCE_VIEW.UP_TO_DATE"
            type={IconStatusAndColor.OK.status}
          />
        );
      case LicenceStatus.EXPIRED:
        return (
          <StatusIcon
            description="LICENCE_VIEW.EXPIRED"
            type={IconStatusAndColor.ERROR.status}
          />
        );
      case LicenceStatus.EXPIRING:
        return (
          <StatusIcon
            description="LICENCE_VIEW.TO_EXPIRED"
            type={IconStatusAndColor.WARNING.status}
          />
        );
      case LicenceStatus.UNREGISTERED:
        return (
          <StatusIcon
            description="LICENCE_VIEW.NO_LICENCE"
            type={IconStatusAndColor.NONE.status}
          />
        );
      default:
        return (
          <StatusIcon
            description="LICENCE_VIEW.NO_LICENCE"
            type={IconStatusAndColor.NONE.status}
          />
        );
    }
  }, [licenceStatus]);
  return (
    <TableRow hover>
      <TableCell>
        {carniv ? <Link to={`/fleet/car/${carniv}`}>{carniv}</Link> : '--'}
      </TableCell>
      <TableCell>
        <Link to={`/drivers/detail/${id}`}>{name}</Link>
      </TableCell>
      <TableCell>{remainingDays <= 0 ? 0 : remainingDays}</TableCell>
      <TableCell>{moment(licenceExpirationAt).format('DD-MMM-YY')}</TableCell>
      <TableCell>{getLicenceStatus}</TableCell>
    </TableRow>
  );
};

LicencesRow.propTypes = {
  carniv: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  licenceExpirationAt: PropTypes.string,
  remainingDays: PropTypes.number,
  licenceStatus: PropTypes.string,
};

export default LicencesRow;
