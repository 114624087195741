/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import DriverCreationInfo from './DriverInfo';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`,
    },
  },
  root: {
    minWidth: theme.spacing(68),
  },
  closeIcon: {
    float: 'right',
    marginTop: '0px',
    width: '20px',
    height: '20px',
  },
}));

const DriverCreationForm = ({ open, handleClose, handleCreateDriver }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data] = useState({});

  const handleSubmit = useCallback(
    values => {
      handleCreateDriver(values);
    },
    [handleCreateDriver],
  );

  return (
    <div>
      <Dialog
        aria-labelledby="form-new-driver-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-new-driver-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('DRIVER_DETAIL_VIEW.ADD_NEW_DRIVER')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DriverCreationInfo
            data={data}
            handleClose={handleClose}
            submit={handleSubmit}
            handleCreateDriver={handleCreateDriver}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DriverCreationForm;
