import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Slide,
  Select,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  agentName: data ? data.agentName : '',
  regionId: data ? data.regionId : '',
});

// eslint-disable-next-line react/prop-types
const AgentForm = ({ open, data, handleClose, handleCreate }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const resetRef = useRef();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = useMemo(() => {
    return handleCreate;
  }, [data, handleCreate]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-test-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data ? 'Actualizar registro' : 'Ingresar nuevo registro'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.agentName && errors.agentName)}
                  >
                    <TextField
                      error={Boolean(touched.agentName && errors.agentName)}
                      fullWidth
                      helperText={touched.agentName && t(errors.agentName)}
                      label="Nombre"
                      margin="normal"
                      name="agentName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.agentName}
                      variant="outlined"
                    />
                  </FormControl>

                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.regionId && errors.regionId)}
                  >
                    <InputLabel id="regionId-select-label">Ciudad</InputLabel>
                    <Select
                      onBlur={handleBlur}
                      labelId="regionId-label"
                      label="Ciudad"
                      id="regionId"
                      value={values.regionId}
                      onChange={handleChange}
                      name="regionId"
                    >
                      <MenuItem value="1">Monterrey</MenuItem>
                      <MenuItem value="2">México</MenuItem>
                    </Select>
                    {Boolean(touched.regionId && errors.regionId) && (
                      <FormHelperText>{t(errors.regionId)}</FormHelperText>
                    )}
                  </FormControl>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? 'Actualizar' : 'Añadir'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AgentForm;
