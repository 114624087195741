import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import RentFactorList from './RentFactorList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

export default function RentFactorTabs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setTabValue(newValue);
    },
    [tabValue],
  );

  return (
    <div className={classes.root}>
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabValue}
          onChange={handleChange}
        >
          <Tab label={t('RENT_FACTOR.OPERATING_FACTOR')} />
          <Tab label={t('RENT_FACTOR.POTENTIAL_INCOME_LOSS')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <RentFactorList tabValue={tabValue} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <RentFactorList tabValue={tabValue} />
      </TabPanel>
    </div>
  );
}
