import * as Yup from 'yup';

export default Yup.object().shape({
  center: Yup.string().required('SCHEMA_ERRORS.ENTER_GEOFENCING'),
  radius: Yup.number()
    .test(
      'min-radius-required',
      'SCHEMA_ERRORS.ENTER_GEOFENCING',
      value => value > 0,
    )
    .required('SCHEMA_ERRORS.ENTER_GEOFENCING'),
});
