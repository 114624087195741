import React from 'react';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';

// eslint-disable-next-line react/prop-types
const BusinessRow = ({ id, commercialName, activity, carsAssigned }) => {
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/drivers/business/${id}`}>{id}</Link>
      </TableCell>
      <TableCell>{commercialName}</TableCell>
      <TableCell>{activity}</TableCell>
      <TableCell>{carsAssigned}</TableCell>
    </TableRow>
  );
};

export default BusinessRow;
