/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import NavBar from 'src/components/NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 50,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleNavbar = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <TopBar open={open} toggleNavbar={toggleNavbar} />
      <NavBar open={open} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
