import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/DatePicker';
import { loadDriversByPlate } from 'src/store/actions/expenseActions';
import useStyles from '../styles';
import makeInitialState from './initialState';
import validationSchema from './schema';

const StepOne = ({ handleBack, handleNext, data, resetRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const onBack = useCallback(
    resetForm => () => {
      resetForm();
      handleBack();
    },
    [handleBack],
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={handleNext}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        resetForm,
        setFieldValue,
      }) => {
        resetRef.current = () => resetForm(initialState);
        return (
          <form onSubmit={handleSubmit}>
            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.expenseConcept && errors.expenseConcept)}
            >
              <InputLabel id="expenseConcept-select-label">Gasto</InputLabel>
              <Select
                onBlur={handleBlur}
                labelId="expense-select-label"
                label={t('COMMON.EXPENSE')}
                id="expense-concept-select"
                value={values.expenseConcept}
                onChange={handleChange}
                name="expenseConcept"
              >
                <MenuItem value="" disabled />
                {data.expenses &&
                  data.expenses.map(expenseConcept => (
                    <MenuItem
                      key={expenseConcept.id}
                      value={expenseConcept.name}
                    >
                      {expenseConcept.name}
                    </MenuItem>
                  ))}
              </Select>
              {Boolean(touched.expenseConcept && errors.expenseConcept) && (
                <FormHelperText>{t(errors.expenseConcept)}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.plate && errors.plate)}
            >
              <Autocomplete
                value={values.plate}
                options={data.plates}
                includeInputInList
                onOpen={handleBlur}
                onChange={(_e, value) => {
                  if (value) {
                    setFieldValue('plate', value || '');
                    dispatch(loadDriversByPlate(value));
                  } else {
                    setFieldValue('plate', '');
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('COMMON.PLATE')}
                    name="plate"
                    fullWidth
                    variant="outlined"
                    error={Boolean(touched.plate && errors.plate)}
                  />
                )}
              />
              {Boolean(touched.plate && errors.plate) && (
                <FormHelperText>{t(errors.plate)}</FormHelperText>
              )}
            </FormControl>

            {values.plate && data.drivers && data.drivers.length > 0 && (
              <FormControl
                className={classes.field}
                variant="outlined"
                fullWidth
                error={Boolean(touched.driverId && errors.driverId)}
              >
                <Autocomplete
                  options={data.drivers}
                  getOptionLabel={option => option.driverName}
                  includeInputInList
                  onOpen={handleBlur}
                  onChange={(_e, value) => {
                    setFieldValue('driverId', value?.driverId || '', true);
                    setFieldValue('driverName', value?.driverName || '');
                    setFieldValue('region', value?.region || '');
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('COMMON.DRIVER')}
                      name="driverId"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
                {Boolean(touched.driverId && errors.driverId) && (
                  <FormHelperText>{t(errors.driverId)}</FormHelperText>
                )}
              </FormControl>
            )}

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.provider && errors.provider)}
            >
              <InputLabel id="provider-select-label">
                {t('COMMON.PROVIDER')}
              </InputLabel>
              <Select
                onBlur={handleBlur}
                labelId="expense-select-label"
                label={t('COMMON.PROVIDER')}
                id="expense-concept-select"
                value={values.provider}
                onChange={handleChange}
                name="provider"
              >
                <MenuItem value="" disabled />
                {data.providers &&
                  data.providers
                    .filter(provider => {
                      if (!values.expenseConcept) {
                        return true;
                      }

                      return provider.expenseType === values.expenseConcept;
                    })
                    .map(provider => (
                      <MenuItem key={provider.id} value={provider.name}>
                        {provider.name}
                      </MenuItem>
                    ))}
              </Select>
              {Boolean(touched.provider && errors.provider) && (
                <FormHelperText>{t(errors.provider)}</FormHelperText>
              )}
            </FormControl>

            <Field
              name="incidentDate"
              component={props => (
                <DatePicker
                  disablePast={false}
                  label={t('COMMON.INCIDENT_DATE')}
                  {...props}
                />
              )}
            />

            <TextField
              error={Boolean(touched.amount && errors.amount)}
              fullWidth
              helperText={touched.amount && t(errors.amount)}
              label={t('COMMON.AMOUNT')}
              margin="normal"
              name="amount"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.amount}
              variant="outlined"
            />
            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={onBack(resetForm)}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.CANCEL')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepOne.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  resetRef: PropTypes.object.isRequired,
};

export default StepOne;
