import { CarModeQuery, Periods } from 'src/utils/constants';
import {
  CLEAR_RENT_FACTOR,
  LOAD_RENT_FACTOR,
  SAVE_RENT_FACTOR_OPTIONS,
} from '../actions/rentFactorActions';

const initialState = {
  items: [],
  count: 0,
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    timePeriod: Periods.WEEKLY,
    carMode: CarModeQuery.ALL,
  },
};

const rentFactorReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RENT_FACTOR.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_RENT_FACTOR_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options },
      };
    }
    case CLEAR_RENT_FACTOR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default rentFactorReducer;
