import { Card } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  loadAmortizationBalanceList,
  LOAD_AMORTIZATION_BALANCE,
} from 'src/store/actions/accountingActions';
import {
  selectAmortizationBalanceList,
  selectAmortizationBalanceListCount,
  selectAmortizationBalanceQueryOptions,
} from 'src/store/selectors/accountingSelector';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import InsuranceBalanceTable from './InsuranceBalanceTable';

const InsuranceBalanceList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const amortizationBalanceList = useSelector(selectAmortizationBalanceList);
  const count = useSelector(selectAmortizationBalanceListCount);
  const { page } = useSelector(selectAmortizationBalanceQueryOptions);
  const queryOptions = useSelector(selectAmortizationBalanceQueryOptions);

  const loading = useSelector(
    isLoadingSelector([LOAD_AMORTIZATION_BALANCE.action]),
  );
  const success = useSelector(
    successSelector([LOAD_AMORTIZATION_BALANCE.action]),
  );

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadAmortizationBalanceList({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(
        loadAmortizationBalanceList({
          ...queryOptions,
          sort: { property, order },
        }),
      );
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.AMORTIZATION_BALANCE_TITLE')}
          description={t(
            'NOT_FOUND_RECORDS_MESSAGE.AMORTIZATION_BALANCE_DESCRIPTION',
          )}
        />
      </Card>
    );
  }

  return (
    <InsuranceBalanceTable
      balances={amortizationBalanceList}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

export default InsuranceBalanceList;
