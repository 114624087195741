/* eslint-disable react/prop-types */
import React from 'react';
import { TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TableCell from 'src/components/Table/TableCell';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SoldCarsRow = ({
  niv,
  plates,
  model,
  saleDate,
  saleReason,
  salePrice,
}) => {
  const { t } = useTranslation();
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/fleet/car/${niv}`}>{niv}</Link>
      </TableCell>
      <TableCell>{plates}</TableCell>
      <TableCell>{model}</TableCell>
      <TableCell>{moment(saleDate).format('DD-MMMM-YY')}</TableCell>
      <TableCell>{t(`FLEET_MOVEMENTS.SALE_REASON.${saleReason}`)}</TableCell>
      <TableCell>{formatPositiveAmount(salePrice)}</TableCell>
    </TableRow>
  );
};

SoldCarsRow.propTypes = {
  niv: PropTypes.string,
  model: PropTypes.string,
  saleDate: PropTypes.string,
  saleReason: PropTypes.string,
  salePrice: PropTypes.number,
};

export default SoldCarsRow;
