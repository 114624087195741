import React, { useMemo, useState, useEffect } from 'react';
import { Table, TableBody } from '@material-ui/core';
import DetailRow from 'src/components/DetailRow';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { AssignationStatus } from 'src/utils/constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { carIsLeaseMode, CAR_MODES } from '../utils';

const initialState = {
  insuranceCost: 0,
  rentCost: 0,
  costPerWeek: 0,
};

const ResumeInfo = ({
  carMode,
  carLease,
  rent,
  costPerWeek,
  assignationStatus,
  car,
}) => {
  const isLeaseMode = useMemo(() => carIsLeaseMode(carMode), [carMode]);
  const { t } = useTranslation();

  const setAssignationStatus = () => {
    switch (assignationStatus) {
      case AssignationStatus.PENDING:
        return 'COMMON.PENDING';
      case AssignationStatus.CANCELLED:
        return 'COMMON.CANCELLED';
      default:
        return 'COMMON.COMPLETED';
    }
  };

  const [
    { insuranceCost, rentCost, rentCosttPerWeek },
    setPricingData,
  ] = useState(initialState);

  useEffect(() => {
    if (car.contract) {
      return setPricingData({
        insuranceCost: Number(car.contract.insuranceCost),
        rentCost: Number(car.contract.rentCost),
        rentCosttPerWeek:
          Number(car.contract.rentCost) + Number(car.contract.insuranceCost),
      });
    }
    if (carLease) {
      return setPricingData({
        insuranceCost: carLease.costPerInsurance,
        rentCost: carLease.rentCost,
        rentCosttPerWeek: costPerWeek,
      });
    }
    return setPricingData(initialState);
  }, [car, carLease]);

  return (
    <Table>
      <TableBody>
        <DetailRow name={t('COMMON.MODE')} value={t(`FLEET_VIEW.${carMode}`)} />

        {assignationStatus === AssignationStatus.COMPLETED &&
        carMode !== CAR_MODES.ADMINISTRATIVE ? (
          <>
            <DetailRow
              name={t('COMMON.RENT')}
              value={formatPositiveAmount(isLeaseMode ? rentCost : rent)}
            />

            {isLeaseMode && (
              <DetailRow
                name={t('CAR_DETAIL.INSURANCE_COST')}
                value={formatPositiveAmount(insuranceCost)}
              />
            )}

            <DetailRow
              name={t('COMMON.TOTAL_COST')}
              value={formatPositiveAmount(rentCosttPerWeek)}
            />
          </>
        ) : null}

        <DetailRow
          name={t('CAR_DETAIL.ASSIGNATION_STATUS')}
          value={t(setAssignationStatus())}
        />
      </TableBody>
    </Table>
  );
};

ResumeInfo.propTypes = {
  carMode: PropTypes.string,
  carLease: PropTypes.string,
  rent: PropTypes.number,
  costPerWeek: PropTypes.number,
  assignationStatus: PropTypes.string,
  car: PropTypes.object,
};

export default ResumeInfo;
