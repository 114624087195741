/* eslint-disable react/prop-types */
import React from 'react';
import PrivateRoute from 'src/components/PrivateRoute';
import ExpenseListView from './ExpenseView';

const ExpenseView = ({ match }) => {
  return (
    <>
      <PrivateRoute path={`${match.url}`} component={ExpenseListView} />
    </>
  );
};

export default ExpenseView;
