import React, { useCallback, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import {
  createIncidentAction,
  deleteIncidentAction,
  editIncidentAction,
} from 'src/store/actions/catalogActions';
import { useDispatch } from 'react-redux';
import InicidentList from './InicidentList';
import IncidentForm from './IncidentForm';
import Toolbar from '../Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const IncidentsListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setData(null);
  }, [setOpen, setData]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleOpenEdit = useCallback(
    values => {
      setOpen(true);
      setData(values);
    },
    [setOpen, setData],
  );

  const handleCreate = useCallback(
    values => {
      dispatch(createIncidentAction(values));
      handleClose();
    },
    [handleClose, dispatch],
  );

  const handleEdit = useCallback(
    values => {
      dispatch(editIncidentAction(values));
      handleClose();
    },
    [handleClose, dispatch],
  );

  const handleDelete = useCallback(
    id => {
      dispatch(deleteIncidentAction(id));
      handleClose();
    },
    [handleClose, dispatch],
  );

  return (
    <Page className={classes.root} title="Incidente">
      <Container maxWidth={false}>
        <Toolbar catalogLabel="Incidente" handleOpen={handleOpen} />
        <Box mt={3}>
          <InicidentList
            handleOpenEdit={handleOpenEdit}
            handleDelete={handleDelete}
          />
        </Box>
      </Container>
      <IncidentForm
        open={open}
        data={data}
        handleClose={handleClose}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
      />
    </Page>
  );
};

export default IncidentsListView;
