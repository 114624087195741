import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAllCampaigns = serviceBase({
  request: options =>
    axiosClient.get('/campaigns', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 0,
});

export const updateCampaign = serviceBase({
  request: campaign => axiosClient.patch(`/campaigns`, campaign),
  retry: 0,
});

export const addNewCampaign = serviceBase({
  request: campaign => axiosClient.post(`/campaigns`, campaign),
  retry: 0,
});

export const removeCampaign = serviceBase({
  request: campaignId =>
    axiosClient.delete(`/campaigns`, { data: { campaignId } }),
  retry: 0,
});

export default getAllCampaigns;
