import { PlatformType, UberProCategory } from 'src/utils/constants';
import * as Yup from 'yup';

export default Yup.object().shape({
  platformRating: Yup.number().when('platform', {
    is: platform =>
      platform === PlatformType.DIDI || platform === PlatformType.UBER,
    then: Yup.number()
      .min(0, 'SCHEMA_ERRORS.ENTER_CERO_AND_FIVE_NUMBER')
      .max(5)
      .required('SCHEMA_ERRORS.ENTER_PLATFORM_RANKING'),
  }),
  tripsNumber: Yup.number()
    .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_TRIPS_NUMBER'),
  platform: Yup.string()
    .oneOf([...Object.values(PlatformType)], 'SCHEMA_ERRORS.ENTER_OPTION')
    .required('SCHEMA_ERRORS.ENTER_PLATFORM'),
  uberProStatus: Yup.string().when('platform', {
    is: PlatformType.UBER,
    then: Yup.string()
      .oneOf([...Object.values(UberProCategory)], 'SCHEMA_ERRORS.ENTER_OPTION')
      .required('SCHEMA_ERRORS.ENTER_OPTION'),
  }),
});
