import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadInsurers,
  LOAD_CATALOG,
  CLEAR_CATALOG,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import InsurersTable from './InsurersTable';

const InsurersList = () => {
  const dispatch = useDispatch();
  const insurers = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadInsurers());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency

  return <InsurersTable insurers={insurers} loading={loading} />;
};

export default InsurersList;
