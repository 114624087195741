import {
  CLEAR_TRANSACTIONS_TABLE,
  CLEAR_TRANSACTIONS_TABLE_OPTIONS,
  LOAD_TRANSACTIONS_TABLE,
  SAVE_TRANSACTIONS_TABLE_OPTIONS,
} from '../actions/transactionsActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  count: 0,
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSACTIONS_TABLE.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_TRANSACTIONS_TABLE_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_TRANSACTIONS_TABLE_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    case CLEAR_TRANSACTIONS_TABLE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default transactionsReducer;
