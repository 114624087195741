import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_DEVICE_GPS } from 'src/store/actions/gpsActions';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const CurrentStep = ({ activeStep, data, onNext, onBack, onSubmit }) => {
  const loading = useSelector(isLoadingSelector([CREATE_DEVICE_GPS.action]));

  if (activeStep === 0) {
    return <StepOne data={data} handleNext={onNext} handleBack={onBack} />;
  }

  if (activeStep === 1) {
    return <StepTwo data={data} handleNext={onNext} handleBack={onBack} />;
  }

  return (
    <StepThree
      data={data}
      loading={loading}
      handleBack={onBack}
      handleSubmit={onSubmit}
    />
  );
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CurrentStep;
