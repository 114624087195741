import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Z0-9]/, 'CATALOGS_VIEW.CAR_CATEGORY.ONLY_ALPHANUMERIC')
    .required('CATALOGS_VIEW.CAR_CATEGORY.NAME_REQUIRED'),
  description: Yup.string().required(
    'CATALOGS_VIEW.CAR_CATEGORY.DESCRIPTION_REQUIRED',
  ),
  price: Yup.number()
    .moreThan(0, 'CATALOGS_VIEW.CAR_CATEGORY.PRICE_ABOVE_ZERO')
    .required('CATALOGS_VIEW.CAR_CATEGORY.PRICE_REQUIRED'),
});
