import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Preloader from './Preloader';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
}));

const Loading = ({ label, childer }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box marginBottom={2}>
      <Preloader />
      <Typography align="center" className={classes.title}>
        {label || t('COMMON.LOADING')}
      </Typography>
      {childer}
    </Box>
  );
};

Loading.propTypes = {
  label: PropTypes.string,
  childer: PropTypes.node,
};

export default Loading;
