import * as Yup from 'yup';

export default Yup.object().shape({
  imei: Yup.string()
    .max(15, 'SCHEMA_ERRORS.ENTER_FIFTEEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_IMEI'),
  simNumber: Yup.string()
    .length(10, 'SCHEMA_ERRORS.ENTER_TEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PHONE_NUMBER'),
  gpsCatalog: Yup.object()
    .shape({
      brand: Yup.string().required('SCHEMA_ERRORS.ENTER_DEVICE_MODEL'),
      model: Yup.string(),
      type: Yup.string(),
    })
    .required('SCHEMA_ERRORS.ENTER_DEVICE_MODEL'),
});
