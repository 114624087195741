import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InsurersRow from './InsurersRow';

const InsurersTable = ({ className, insurers, ...rest }) => {
  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>Nombre de aseguradora</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return insurers.map(insurer => (
        <InsurersRow {...insurer} key={insurer.taxId} />
      ));
    },
    [insurers],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

InsurersTable.propTypes = {
  className: PropTypes.string,
  insurers: PropTypes.array.isRequired,
};

export default InsurersTable;
