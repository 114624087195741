/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { formatPositiveAmount } from 'src/utils/formatAmount';

const MovementsRow = ({
  t,
  amount,
  carniv,
  category,
  status,
  comment,
  createdAt,
  expiresAt,
}) => {
  return (
    <TableRow hover>
      <TableCell>{moment(createdAt).format('DD-MMM-YY')}</TableCell>
      <TableCell>{t(`MOVEMENT.STATUS.${status}`)}</TableCell>
      <TableCell>{moment(expiresAt).format('DD-MMM-YY')}</TableCell>
      <TableCell>{t(`MOVEMENT.CATEGORY.${category}`)}</TableCell>
      <TableCell>{carniv}</TableCell>
      <TableCell>{comment || '--'}</TableCell>
      <TableCell>{formatPositiveAmount(amount)}</TableCell>
    </TableRow>
  );
};

export default MovementsRow;
