import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getExpense = serviceBase({
  request: options =>
    axiosClient.get('/expense', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 0,
});

export const getPlates = serviceBase({
  request: () => axiosClient.get('/car/plates'),
  retry: 0,
});

export const getDriversByPlate = serviceBase({
  request: plate => axiosClient.get(`car/drivers/${plate}`),
  retry: 0,
});

export const createNewExpense = serviceBase({
  request: values => axiosClient.post('/expense', values),
  retry: 0,
});

export const downloadeExportedExpense = serviceBase({
  request: options =>
    axiosClient.get('/expense/export', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'expense.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});
