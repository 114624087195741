import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '70vh',
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    width: '100%',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  rootPage: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
