import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import {
  selectGpsList,
  selectGpsListCount,
  selectGpsQueryOptions,
} from 'src/store/selectors/gpsSelectors';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadGeofencingList, LOAD_GPS } from 'src/store/actions/gpsActions';
import { Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GeofencingTable from './GeofencingTable';

const GeofencingList = ({ handleOpenConfirmation }) => {
  const dispatch = useDispatch();
  const geofencing = useSelector(selectGpsList);
  const count = useSelector(selectGpsListCount);
  const { page } = useSelector(selectGpsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_GPS.action]));
  const success = useSelector(successSelector([LOAD_GPS.action]));
  const { t } = useTranslation();

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadGeofencingList({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadGeofencingList({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.GEOFENCING_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.GEOFENCING_DESCRIPTION')}
        />
      </Card>
    );
  }
  console.log(count);

  return (
    <GeofencingTable
      geofencing={geofencing}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
      handleOpenConfirmation={handleOpenConfirmation}
    />
  );
};

GeofencingList.propTypes = {
  handleOpenConfirmation: PropTypes.func,
};

export default GeofencingList;
