import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { DriverStatus } from 'src/utils/constants';
import GeneralDriver from './GeneralDriver';
import DriverDocuments from './DriverDocuments';
import DriverCarDetail from './DriverCarDetail';
import DriverDeactivationInfo from './DriverDeactivationInfo';
import DriverMinimumPayment from './DriverMinimumPayment';
import DriverCalification from './DriverCalification';

const GeneralView = ({ driver }) => {
  const spacing =
    driver.actualDriverCar || driver.status === DriverStatus.LEFT ? 3 : 4;
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={spacing}>
          <GeneralDriver driver={driver} />
        </Grid>
        <Grid item xs={12} md={6} lg={spacing}>
          <DriverDocuments driver={driver} />
        </Grid>
        <Grid item xs={12} md={6} lg={spacing} style={{ display: 'none' }}>
          <DriverCalification driver={driver} />
        </Grid>
        {driver.status === DriverStatus.LEFT && (
          <Grid item xs={12} md={6} lg={spacing}>
            <DriverDeactivationInfo driver={driver} />
          </Grid>
        )}
        {driver.actualDriverCar && (
          <Grid item xs={12} md={6} lg={spacing}>
            <DriverCarDetail
              driverId={driver.id}
              actualDriverCar={driver.actualDriverCar}
              actualDriverAssignation={driver.actualDriverAssignation}
              actualDriverAssignationId={driver.actualDriverAssignationId}
              actualDriverAssignationNiv={driver.actualDriverAssignationNiv}
              actualDriverIsLease={driver.actualDriverIsLease}
              actualDriverHasLease={driver.actualDriverHasLease}
              assignationStatus={driver.assignationStatus}
              contract={driver.actualContract}
            />
          </Grid>
        )}
        {driver.status === DriverStatus.ACTIVE && (
          <Grid item xs={12} md={6} lg={spacing}>
            <DriverMinimumPayment
              driverId={driver.id}
              minimumPaymentAdjustment={driver.minimumPaymentAdjustment}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

GeneralView.propTypes = {
  driver: PropTypes.object,
};

export default GeneralView;
