import React from 'react';
import clsx from 'clsx';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import Breadcrumb from 'src/components/Breadcrumb';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  icon: {
    fill: theme.palette.primary.main,
  },
}));

const Toolbar = ({ catalogLabel, handleOpen, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root} {...rest}>
      {catalogLabel ? (
        <Breadcrumb
          history={['NAVIGATION.ADMIN', 'NAVIGATION.CATALOGS']}
          current={catalogLabel}
        />
      ) : (
        <Breadcrumb
          history={['NAVIGATION.ADMIN']}
          current="NAVIGATION.CATALOGS"
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontWeight: '400', textTransform: 'capitalize' }}
          color="primary"
          variant="h1"
        >
          {catalogLabel || t('NAVIGATION.CATALOGS')}
        </Typography>
        <div className={classes.actionsContainer}>
          {handleOpen && (
            <Button
              className={clsx(classes.buttons, classes.horizontalPadding)}
              onClick={handleOpen}
              color="primary"
              variant="contained"
            >
              <PlusIcon style={{ marginRight: 8 }} fill="white" />
              {t('CATALOGS_VIEW.CREATE_REGISTER')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  catalogLabel: PropTypes.string,
  handleOpen: PropTypes.func,
};

export default Toolbar;
