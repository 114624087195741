/* eslint-disable no-unneeded-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Input,
  InputAdornment,
  Paper,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import moment from 'moment';
import { TimePeriod } from 'src/utils/constants';
import { Search } from 'react-feather';
import useDebounce from 'src/hooks/useDebounce';
import { selectAmortizationBalanceQueryOptions } from 'src/store/selectors/accountingSelector';
import {
  CLEAR_AMORTIZATION_BALANCE,
  exportExcelListAmortizationBalance,
  loadAmortizationBalanceList,
} from 'src/store/actions/accountingActions';
import useStyles from './styles';

const filters = [
  {
    title: 'COMMON.BY_DATE',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'timePeriod',
    items: [
      {
        label: 'COMMON.THIS_MONTH',
        value: TimePeriod.MONTH,
      },
      {
        label: 'COMMON.THIS_QUARTER',
        value: TimePeriod.QUARTER,
      },
      {
        label: 'COMMON.THIS_YEAR',
        value: TimePeriod.YEAR,
      },
    ],
  },
];

const getFilterOptions = options => {
  if (options.timePeriod === TimePeriod.MONTH) {
    return {
      startDate: moment()
        .startOf('month')
        .toDate(),
      endDate: moment().toDate(),
    };
  }

  if (options.timePeriod === TimePeriod.QUARTER) {
    return {
      startDate: moment()
        .quarter(moment().quarter())
        .startOf('quarter')
        .toDate(),
      endDate: moment()
        .quarter(moment().quarter())
        .toDate(),
    };
  }

  if (options.timePeriod === TimePeriod.YEAR) {
    return {
      startDate: moment()
        .startOf('year')
        .toDate(),
      endDate: moment().toDate(),
    };
  }

  return {};
};

const Toolbar = ({ className, filterView, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectAmortizationBalanceQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const [filterValues, setFilterValues] = React.useState({
    timePeriod: TimePeriod.MONTH,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleSubmitFilter = queryOpts => {
    dispatch(loadAmortizationBalanceList(queryOpts));
    handleCloseFilter();
  };

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      handleSubmitFilter({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [handleSubmitFilter, queryOptions],
  );

  const debounceSearch = useDebounce(
    (searchCriteria, queryOpts) =>
      dispatch(
        loadAmortizationBalanceList({ ...queryOpts, carniv: searchCriteria }),
      ),
    400,
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions, filterValues],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(exportExcelListAmortizationBalance());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadAmortizationBalanceList());
    return () => dispatch({ type: CLEAR_AMORTIZATION_BALANCE });
  }, []);

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <Box>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.ADMIN', 'NAVIGATION.ACCOUNTING']}
          current="NAVIGATION.POLICY_PROFILE"
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.POLICY_PROFILE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <Paper elevation={0} className={classes.search}>
              <Input
                className={classes.input}
                startAdornment={Addornment}
                placeholder={t('FLEET_VIEW.SEARCH_BY_NIV')}
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                type="string"
                onChange={handleSearch}
              />
            </Paper>
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.closingButtons,
              )}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleOpenFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>

            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  filterView: PropTypes.number,
};

export default Toolbar;
