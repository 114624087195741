import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import HistoryRow from './HistoryRow';

const HistoryTable = ({ history, className }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.DRIVER')}</TableCell>
      <TableCell>{t('COMMON.START_DATE')}</TableCell>
      <TableCell>{t('COMMON.END_DATE')}</TableCell>
      <TableCell>{t('COMMON.DAYS')}</TableCell>
      <TableCell>{t('COMMON.RENT')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return history.map(h => <HistoryRow {...h} key={h.startDate} />);
    },
    [history],
  );

  return <Table tbody={tbody} theads={theads} className={className} />;
};

HistoryTable.propTypes = {
  className: PropTypes.string,
  history: PropTypes.array.isRequired,
};

export default HistoryTable;
