import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TableCell from 'src/components/Table/TableCell';
import { Link } from 'react-router-dom';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { IconStatusAndColor } from 'src/utils/constants';
import StatusIcon from 'src/components/StatusIcon';

const RentLockoutGpsRow = ({ niv, plates, driver, rent, engineStatus }) => {
  const { t } = useTranslation();

  const getEngineStatus = status => {
    if (!status) {
      return {
        description: t('COMMON.BLOCKED'),
        type: IconStatusAndColor.ERROR.status,
      };
    }
    return {
      description: `${t('COMMON.ACTIVE')}`,
      type: IconStatusAndColor.OK.status,
    };
  };

  const client = useMemo(
    () =>
      driver
        ? `${driver.name} ${driver.paternalName} ${driver.maternalName}`
        : '',
    [driver],
  );
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/fleet/car/${niv}`}>{niv}</Link>
      </TableCell>
      <TableCell>{plates}</TableCell>
      <TableCell>
        <Link to={`/drivers/detail/${driver?.id}`}>{client}</Link>
      </TableCell>
      <TableCell>{formatPositiveAmount(rent)}</TableCell>
      <TableCell>
        <StatusIcon {...getEngineStatus(engineStatus)} />
      </TableCell>
    </TableRow>
  );
};
RentLockoutGpsRow.propTypes = {
  niv: propTypes.string.isRequired,
  plates: propTypes.string.isRequired,
  driver: propTypes.object,
  rent: propTypes.number.isRequired,
  engineStatus: propTypes.bool.isRequired,
};

export default RentLockoutGpsRow;
