import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadGeofencingList } from 'src/store/actions/gpsActions';
import GeofencingList from './GeofencingList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

const GeofencingListTabs = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(loadGeofencingList());
    },
    [setTabIndex],
  );

  return (
    <div className={classes.root}>
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('COMMON.GEOFENCING')} />
          <Tab label={t('COMMON.HISTORY')} disabled />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <GeofencingList />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <GeofencingList />
      </TabPanel>
    </div>
  );
};

export default GeofencingListTabs;
