import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MaintenanceRow from './MaintenanceRow';

const MaintenanceTable = ({ className, maintenances, ...rest }) => {
  const { t } = useTranslation();
  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.DRIVER')}</TableCell>
      <TableCell>
        {t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.DATE_OF_MAINTENANCE')}
      </TableCell>
      <TableCell>
        {t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.DAYS_BEETWEEN_MAINTENANCE')}
      </TableCell>
      <TableCell>
        {t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.KMS_OF_MAINTENANCE')}
      </TableCell>
      <TableCell>
        {t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.KMS_BEETWEEN_MAINTENANCE')}
      </TableCell>
    </TableRow>
  ));
  const tbody = useMemo(
    () => () => {
      return maintenances.map(h => <MaintenanceRow {...h} key={h.startDate} />);
    },
    [maintenances],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

MaintenanceTable.propTypes = {
  className: PropTypes.string,
  maintenances: PropTypes.array.isRequired,
};
export default MaintenanceTable;
