import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@material-ui/core';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCarAuditList,
  selectCarAuditListCount,
  selectCarAuditQueryOptions,
} from 'src/store/selectors/carSelectors';
import {
  loadCarAuditList,
  LOAD_CAR_AUDIT_LIST,
} from 'src/store/actions/carActions';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import AuditTable from './CarAuditTable';

const CarAudit = ({ car }) => {
  const { t } = useTranslation();
  const audits = useSelector(selectCarAuditList);
  const count = useSelector(selectCarAuditListCount);
  const { page } = useSelector(selectCarAuditQueryOptions);
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector([LOAD_CAR_AUDIT_LIST.action]));
  const success = useSelector(successSelector([LOAD_CAR_AUDIT_LIST.action]));
  const options = useSelector(selectCarAuditQueryOptions);

  useEffect(() => {
    dispatch(loadCarAuditList(options, car.niv));
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadCarAuditList({ page: value }, car.niv));
    },
    [dispatch],
  );

  if (!success && audits && audits.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.AUDIT_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.AUDIT_DESCRIPTION')}
        />
      </Card>
    );
  }
  return (
    <AuditTable
      audits={audits}
      count={count}
      page={page}
      loading={loading}
      handleChangePage={handleChangePage}
    />
  );
};

CarAudit.propTypes = {
  car: PropTypes.object.isRequired,
};

export default CarAudit;
