import { TRANSMISSION_TYPE } from 'src/views/fleet/CarDetail/utils';
import * as Yup from 'yup';

export default Yup.object().shape({
  purchaseDate: Yup.date().required(
    'SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_PURCHASE_DATE',
  ),
  rfc: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_OWNER_RFC'),
  motor: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_ENGINE_NUMBER'),
  brand: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.SELECT_BRAND'),
  year: Yup.string().required('SCHEMA_ERRORS.CAR_CREATION_FORM.SELECT_YEAR'),
  type: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_TYPE'),
  version: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_VERSION'),
  transmission: Yup.string()
    .oneOf(Object.values(TRANSMISSION_TYPE), 'SCHEMA_ERRORS.SELECT_ONE_OF_LIST')
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.SELECT_TRANSMISSION'),
  color: Yup.string()
    .max(255)
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_COLOR'),
  category: Yup.string().required(
    'SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_CATEGORY',
  ),
});
