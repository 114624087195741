/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateBusinessDocumentsAction } from 'src/store/actions/businessActions';
import DocumentsInfo from './DocumentsInfo';
import BusinessDocumentDialog from './BusinessDocumentDialog';
import useStyles from '../styles';

const BusinessDocuments = ({ business }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(updateBusinessDocumentsAction(business.id, values));
    },
    [business, dispatch],
  );

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('COMMON.DOCUMENTS')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            <DocumentsInfo business={business} />
          </TableBody>
        </Table>
        <BusinessDocumentDialog
          open={open}
          business={business}
          handleClose={handleClose}
          submit={handleSubmit}
        />
        <Box alignItems="center" display="flex" className={classes.buttons}>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            className={clsx(classes.label, classes.buttons)}
            startIcon={<EditIcon />}
          >
            {t('COMMON.EDIT')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BusinessDocuments;
