import React from 'react';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import GpsDeviceDetail from './GpsDetail/GpsDeviceDetail';

const GpsView = ({ car }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={4}>
        {car.gpsDevices.map((device, index) => {
          return (
            <Grid item xs={5} key={device.id}>
              <GpsDeviceDetail device={device} deviceNumber={index + 1} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

GpsView.propTypes = {
  car: PropTypes.object.isRequired,
};
export default GpsView;
