import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconButton, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Map } from '@material-ui/icons';
import TableCell from 'src/components/Table/TableCell';
import DeviceMapLocation from 'src/views/fleet/CarDetail/GpsDetail/DeviceMapLocation';
import PropTypes from 'prop-types';

const CarPositionRow = ({
  createdAt,
  latitude,
  longitude,
  driver,
  driverId,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <TableRow hover>
      <TableCell>{moment(createdAt).format('DD-MMM-YY')}</TableCell>
      <TableCell>
        <Link to={`/drivers/detail/${driverId}`}>
          {`${driver.name} ${driver.paternalName} ${driver.maternalName}`}
        </Link>
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        {longitude && latitude ? (
          <IconButton
            color="primary"
            arial-label={t('GPS_VIEW.VEHICLE_LOCATION')}
            onClick={() => handleClickOpen()}
          >
            <Map />
          </IconButton>
        ) : (
          t('COMMON.NO_INFORMATION')
        )}
      </TableCell>

      <DeviceMapLocation
        open={open}
        coor={[latitude || '', longitude || '']}
        handleClose={handleClose}
      />
    </TableRow>
  );
};
CarPositionRow.propTypes = {
  createdAt: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  driverId: PropTypes.string,
  driver: PropTypes.object,
};

export default CarPositionRow;
