import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
  },

  root: {
    minWidth: '35%',
    minHeight: '50%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  map: { height: '80vh', width: '100%' },
  divider: { marginBottom: '16px', width: '600px' },

  closeButton: {
    position: 'absolute !important',
    top: '25px',
    right: '25px',
    zIndex: 4001,
  },
}));
