/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import InputText from 'src/components/InputText/InputText';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const transformMobileIntoNumber = mobile => Number(mobile.split('.').join(''));
const addPointsMobile = mobile => {
  const r = typeof mobile === 'number' ? mobile.toString() : mobile;
  const t1 = r.slice(0, 2);
  const t2 = r.slice(2, 6);
  const t3 = r.slice(6);
  return `${t1}.${t2}.${t3}`;
};

const makeInitialState = driver => {
  return {
    age: driver.age || '',
    id: driver.id || '',
    civilStatus: driver.civilStatus || '',
    economicDependents: driver.economicDependents || '',
    homeTown: driver.homeTown || '',
    houseStatus: driver.houseStatus || '',
    zipCode: driver.zipCode || '',
    mobile: transformMobileIntoNumber(driver.mobile) || '',
    email: driver.email || '',
    platform: driver.platform || '',
    platformRating: driver.platformRating || '',
    tripsNumber: driver.tripsNumber || '',
    rfc: driver.rfc || '',
    uberProStatus: driver.uberProStatus || '',
  };
};

const DatePickerField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      disableFuture
      className={classes.field}
      inputVariant="outlined"
      margin="normal"
      fullWidth
      name={field.name}
      value={field.value}
      format="DD/MM/YYYY"
      variant="inline"
      label="Fecha de Nacimiento"
      helperText={currentError}
      error={Boolean(currentError)}
      onError={error => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={date => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...other}
    />
  );
};

const GeneralDriverDialog = ({ open, driver, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(driver), [driver]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit({ ...values, mobile: addPointsMobile(values.mobile) });
        resetForm(initialState);
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      scroll="body"
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-driver"
      >
        {!confirmationOpen && (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography className={classes.header} color="primary" variant="h2">
          Datos de conductor
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    {t('DRIVER_DETAIL_VIEW.DRIVER_UPDATE_MESSAGE')}
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.mobile && t(errors.mobile))}
                >
                  <InputText
                    error={Boolean(touched.mobile && t(errors.mobile))}
                    fullWidth
                    helperText={touched.mobile && t(errors.mobile)}
                    label="Celular"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.mobile}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                >
                  <InputText
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && t(errors.email)}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />
                </FormControl>

                <Field name="age" component={DatePickerField} />

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.civilStatus && errors.civilStatus)}
                >
                  <InputLabel id="civilStatus-select-label">
                    {t('COMMON.CIVIL_STATUS')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="civilStatus-label"
                    label={t('COMMON.CIVIL_STATUS')}
                    id="civilStatus"
                    value={values.civilStatus}
                    onChange={handleChange}
                    name="civilStatus"
                  >
                    <MenuItem value="Soltero">Soltero</MenuItem>
                    <MenuItem value="Casado">Casado</MenuItem>
                    <MenuItem value="Otro">Otro</MenuItem>
                  </Select>
                  {Boolean(touched.civilStatus && errors.civilStatus) && (
                    <FormHelperText>{t(errors.civilStatus)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(
                    touched.economicDependents && errors.economicDependents,
                  )}
                >
                  <InputText
                    error={Boolean(
                      touched.economicDependents && errors.economicDependents,
                    )}
                    fullWidth
                    helperText={
                      touched.economicDependents && t(errors.economicDependents)
                    }
                    label={t('COMMON.ECONOMIC_DEPENDENTS')}
                    name="economicDependents"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.economicDependents}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </FormControl>

                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.homeTown && errors.homeTown)}
                >
                  <InputLabel id="homeTown-select-label">
                    {t('COMMON.BIRTH_STATE')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="homeTown-label"
                    label="Estado de nacimiento"
                    id="homeTown"
                    margin="normal"
                    value={values.homeTown}
                    onChange={handleChange}
                    name="homeTown"
                  >
                    <MenuItem value="Ciudad de México">México</MenuItem>
                    <MenuItem value="Nuevo León">Nuevo León</MenuItem>
                    <MenuItem value="Aguascalientes">Aguascalientes</MenuItem>
                  </Select>
                  {Boolean(touched.homeTown && errors.homeTown) && (
                    <FormHelperText>{t(errors.homeTown)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.houseStatus && errors.houseStatus)}
                >
                  <InputLabel id="houseStatus-select-label">
                    {t('COMMON.HOME_STATUS')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="houseStatus-select-label"
                    label={t('COMMON.HOME_STATUS')}
                    id="houseStatus"
                    margin="normal"
                    value={values.houseStatus}
                    onChange={handleChange}
                    name="houseStatus"
                  >
                    <MenuItem value="Propia">Propia</MenuItem>
                    <MenuItem value="Financiada">Financiada</MenuItem>
                    <MenuItem value="Rentada">Rentada</MenuItem>
                    <MenuItem value="De Familiar">De Familiar</MenuItem>
                  </Select>
                  {Boolean(touched.houseStatus && errors.houseStatus) && (
                    <FormHelperText>{t(errors.houseStatus)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.zipCode && errors.zipCode)}
                >
                  <InputText
                    error={Boolean(touched.zipCode && errors.zipCode)}
                    fullWidth
                    helperText={touched.zipCode && t(errors.zipCode)}
                    label={t('COMMON.ZIP_CODE')}
                    name="zipCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.zipCode}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.platform && errors.platform)}
                >
                  <InputLabel id="platform-select-label">
                    {t('DRIVER_DETAIL_VIEW.CALIFICATION.JOB_PLATAFORM')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="platform-select-label"
                    label={t('DRIVER_DETAIL_VIEW.CALIFICATION.JOB_PLATAFORM')}
                    id="platform"
                    value={values.platform}
                    onChange={handleChange}
                    name="platform"
                  >
                    <MenuItem value="Uber">Uber</MenuItem>
                    <MenuItem value="Didi">Didi</MenuItem>
                    <MenuItem value="Privado">Privado</MenuItem>
                  </Select>
                  {Boolean(touched.platform && errors.platform) && (
                    <FormHelperText>{t(errors.platform)}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.tripsNumber && errors.tripsNumber)}
                >
                  <InputText
                    error={Boolean(touched.tripsNumber && errors.tripsNumber)}
                    fullWidth
                    helperText={touched.tripsNumber && t(errors.tripsNumber)}
                    label={t('DRIVER_DETAIL_VIEW.CALIFICATION.TRIPS_NUMBER')}
                    name="tripsNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.tripsNumber}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </FormControl>

                {values.platform === 'Uber' && (
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={Boolean(
                      touched.uberProStatus && errors.uberProStatus,
                    )}
                  >
                    <InputLabel id="uberProStatus-select-label">
                      {t('DRIVER_DETAIL_VIEW.CALIFICATION.UBER_PRO_STATUS')}
                    </InputLabel>
                    <Select
                      onBlur={handleBlur}
                      labelId="uberProStatus-select-label"
                      label={t(
                        'DRIVER_DETAIL_VIEW.CALIFICATION.UBER_PRO_STATUS',
                      )}
                      id="uberProStatus"
                      value={values.uberProStatus}
                      onChange={handleChange}
                      name="uberProStatus"
                    >
                      <MenuItem value="Plata">Plata</MenuItem>
                      <MenuItem value="Oro">Oro</MenuItem>
                      <MenuItem value="Platino">Platino</MenuItem>
                    </Select>
                    {Boolean(touched.uberProStatus && errors.uberProStatus) && (
                      <FormHelperText>{t(errors.uberProStatus)}</FormHelperText>
                    )}
                  </FormControl>
                )}

                {(values.platform === 'Uber' || values.platform === 'Didi') && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={Boolean(
                      touched.platformRating && errors.platformRating,
                    )}
                  >
                    <InputText
                      error={Boolean(
                        touched.platformRating && errors.platformRating,
                      )}
                      fullWidth
                      helperText={
                        touched.platformRating && t(errors.platformRating)
                      }
                      label={t(
                        'DRIVER_DETAIL_VIEW.CALIFICATION.PLATFORM_RATING',
                      )}
                      name="platformRating"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.platformRating}
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </FormControl>
                )}

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.rfc && errors.rfc)}
                >
                  <InputText
                    error={Boolean(touched.rfc && errors.rfc)}
                    fullWidth
                    helperText={touched.rfc && t(errors.rfc)}
                    label="RFC"
                    name="rfc"
                    onBlur={handleBlur}
                    onChange={e =>
                      setFieldValue(e.target.name, e.target.value.toUpperCase())
                    }
                    type="text"
                    value={values.rfc}
                    variant="outlined"
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

GeneralDriverDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  driver: PropTypes.object,
};

export default GeneralDriverDialog;
