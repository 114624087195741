import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { uploadCarXml } from 'src/store/actions/carActions';
import UploadFile from 'src/components/UploadFile';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { loadCarCategories } from 'src/store/actions/catalogActions';
import useStyles from '../styles';
import makeInitialState from './initialState';

import validationSchema from './schema';

const StepOne = ({ handleBack, handleNext, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const categories = useSelector(selectCatalogList);

  const initialState = useMemo(() => makeInitialState(data), [data]);

  const setFieldValueRef = useRef(() => console.log('Nothing'));

  const disablePaste = e => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(loadCarCategories());
  }, []);

  useEffect(() => {
    setFieldValueRef.current('carCategories', categories);
  }, [categories]);

  useEffect(() => {
    if (data.niv) {
      setFieldValueRef.current('niv', data.niv);
    }
  }, [data]);

  const handleUploadChange = useCallback(
    (setFieldValue, name) => e => {
      if (e.target.files[0]) {
        setFieldValue(name, e.target.files[0]);

        if (name === 'invoiceXML') {
          const fd = new FormData();
          fd.append('file', e.target.files[0]);
          dispatch(uploadCarXml(fd));
        }
      }
    },
    [dispatch],
  );

  const onBack = useCallback(
    resetForm => () => {
      resetForm();
      handleBack();
    },
    [handleBack],
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={handleNext}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm,
      }) => {
        setFieldValueRef.current = setFieldValue;
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.root}>
              <UploadFile
                values={values}
                errors={errors}
                touched={touched}
                handleUploadChange={handleUploadChange}
                setFieldValue={setFieldValue}
                accept=".pdf"
                valueLabel="invoicePDF"
                textLabel={t('CAR_DETAIL.UPLOAD_PDF_INVOICE')}
              />
              <UploadFile
                values={values}
                errors={errors}
                touched={touched}
                handleUploadChange={handleUploadChange}
                setFieldValue={setFieldValue}
                accept=".xml"
                valueLabel="invoiceXML"
                textLabel="Sube la factura en formato XML"
              />
            </div>

            <TextField
              error={Boolean(touched.serie && errors.serie)}
              fullWidth
              helperText={touched.serie && t(errors.serie)}
              label={t('CAR_DETAIL.SERIAL_NUMBER')}
              margin="normal"
              name="serie"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.serie}
              variant="outlined"
              onPaste={disablePaste}
            />

            <TextField
              error={Boolean(
                touched.serieConfirmation && errors.serieConfirmation,
              )}
              fullWidth
              helperText={
                touched.serieConfirmation && t(errors.serieConfirmation)
              }
              label={t('CAR_DETAIL.SERIAL_NUMBER_CONFIRMATION')}
              margin="normal"
              name="serieConfirmation"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.serieConfirmation}
              variant="outlined"
              onPaste={disablePaste}
            />

            <TextField
              error={Boolean(touched.total && errors.total)}
              fullWidth
              helperText={touched.total && t(errors.total)}
              label={t('COMMON.TOTAL_COST_WITH_IVA')}
              margin="normal"
              name="total"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.total}
              variant="outlined"
            />

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={onBack(resetForm)}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.CANCEL')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepOne.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default StepOne;
