/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import validationSchema from './schema';

const makeInitialState = () => {
  return {
    socialReason: '',
    commercialName: '',
    activity: '',
    address: '',
    contactName: '',
    contactType: '',
    contactPhonePersonal: '',
    contactMailAddress: '',
  };
};

const BusinessCreationInfo = ({ resetRef, handleClose, submit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialState = useMemo(() => makeInitialState(), []);

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        resetForm,
      }) => {
        resetRef.current = () => resetForm(initialState);
        return (
          <form onSubmit={handleSubmit}>
            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.socialReason && errors.socialReason)}
            >
              <TextField
                error={Boolean(touched.socialReason && errors.socialReason)}
                fullWidth
                helperText={touched.socialReason && t(errors.socialReason)}
                label={t('BUSINESS.SOCIAL_REASON')}
                margin="none"
                name="socialReason"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.socialReason}
                variant="outlined"
              />
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.commercialName && errors.commercialName)}
            >
              <TextField
                error={Boolean(touched.commercialName && errors.commercialName)}
                fullWidth
                helperText={touched.commercialName && t(errors.commercialName)}
                label={t('BUSINESS.COMMERCIAL_NAME')}
                margin="none"
                name="commercialName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.commercialName}
                variant="outlined"
              />
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.activity && errors.activity)}
            >
              <TextField
                error={Boolean(touched.activity && errors.activity)}
                fullWidth
                helperText={touched.activity && t(errors.activity)}
                label={t('BUSINESS.TWIST')}
                margin="none"
                name="activity"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.activity}
                variant="outlined"
              />
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.address && errors.address)}
            >
              <TextField
                error={Boolean(touched.address && errors.address)}
                fullWidth
                helperText={touched.address && t(errors.address)}
                label={t('COMMON.ADDRESS')}
                margin="none"
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.address}
                variant="outlined"
              />
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.contactName && errors.contactName)}
            >
              <TextField
                error={Boolean(touched.contactName && errors.contactName)}
                fullWidth
                helperText={touched.contactName && t(errors.contactName)}
                label={t('COMMON.NAME')}
                margin="none"
                name="contactName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.contactName}
                variant="outlined"
              />
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(touched.contactType && errors.contactType)}
            >
              <InputLabel id="contactType-select-label">
                {t('BUSINESS.CONTACT_TYPE')}
              </InputLabel>
              <Select
                onBlur={handleBlur}
                labelId="contactType-label"
                label={t('BUSINESS.CONTACT_TYPE')}
                id="contactType"
                value={values.contactType}
                onChange={handleChange}
                name="contactType"
              >
                <MenuItem value="COMMERCIAL">
                  {t('BUSINESS.COMMERCIAL')}
                </MenuItem>
                <MenuItem value="COLLECTION">
                  {t('BUSINESS.COLLECTION')}
                </MenuItem>
                <MenuItem value="LEGAL">{t('BUSINESS.LEGAL')}</MenuItem>
              </Select>
              {Boolean(touched.contactType && errors.contactType) && (
                <FormHelperText>{t(errors.contactType)}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(
                touched.contactPhoneOffice && errors.contactPhoneOffice,
              )}
            >
              <TextField
                error={Boolean(
                  touched.contactPhoneOffice && errors.contactPhoneOffice,
                )}
                fullWidth
                helperText={
                  touched.contactPhoneOffice && t(errors.contactPhoneOffice)
                }
                label={t('BUSINESS.COMMERCIAL_PHONE')}
                margin="none"
                name="contactPhoneOffice"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.contactPhoneOffice}
                variant="outlined"
              />
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(
                touched.contactPhonePersonal && errors.contactPhonePersonal,
              )}
            >
              <TextField
                error={Boolean(
                  touched.contactPhonePersonal && errors.contactPhonePersonal,
                )}
                fullWidth
                helperText={
                  touched.contactPhonePersonal && t(errors.contactPhonePersonal)
                }
                label={t('COMMON.MOBILE')}
                margin="none"
                name="contactPhonePersonal"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.contactPhonePersonal}
                variant="outlined"
              />
            </FormControl>

            <FormControl
              className={classes.field}
              variant="outlined"
              fullWidth
              error={Boolean(
                touched.contactMailAddress && errors.contactMailAddress,
              )}
            >
              <TextField
                error={Boolean(
                  touched.contactMailAddress && errors.contactMailAddress,
                )}
                fullWidth
                helperText={
                  touched.contactMailAddress && t(errors.contactMailAddress)
                }
                label={t('COMMON.EMAIL')}
                margin="none"
                name="contactMailAddress"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.contactMailAddress}
                variant="outlined"
              />
            </FormControl>

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Grid container direction="column" alignItems="stretch">
                <Grid item>
                  <Button
                    className={clsx(classes.label, classes.buttons)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('BUSINESS.ADD_BUSINESS')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={clsx(classes.outlined, classes.buttons)}
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.CANCEL')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default BusinessCreationInfo;
