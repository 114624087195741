import axios from 'axios';
import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAllCars = serviceBase({
  request: options =>
    axiosClient.get('/car', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 0,
});

export const getInactiveCars = serviceBase({
  request: options => axiosClient.get('/car/inactive', { params: options }),
  retry: 0,
});

export const getAvailableCars = serviceBase({
  request: () => axiosClient.get('/car/available'),
  retry: 0,
});

export const getCarByNiv = serviceBase({
  request: niv => axiosClient.get(`/car/detail/${niv}`),
  retry: 0,
});

export const updateCarBasicInfo = serviceBase({
  request: ({ niv, values }) =>
    axiosClient.put(`/car/basic/${niv}`, { ...values }),
  retry: 0,
});

export const updateCarInactivity = serviceBase({
  request: values =>
    axiosClient.put(`/car/inactivity/${values.niv}`, { ...values }),
  retry: 0,
});

export const updateCarMode = serviceBase({
  request: ({ niv, values }) =>
    axiosClient.put(`/car/mode/${niv}`, { ...values }),
  retry: 0,
});

export const updateCarCategory = serviceBase({
  request: ({ niv, values }) =>
    axiosClient.put(`/car/category/${niv}`, { ...values }),
  retry: 0,
});

export const updateCarDocuments = serviceBase({
  request: ({ niv, values }) =>
    axiosClient.put(`/car/documents/${niv}`, { ...values }),
  retry: 0,
});

export const downloadeExportedCars = serviceBase({
  request: options =>
    axiosClient.get('/car/export', { responseType: 'blob', params: options }),
  transformResponse: res => {
    try {
      const filename = 'cars.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const downloadeExportedInactiveCars = serviceBase({
  request: options =>
    axiosClient.get('/car/inactive/export', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'cars.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const uploadAndProcessXML = serviceBase({
  request: xml => axiosClient.post(`/car/readXML/`, xml),
  retry: 0,
});

export const getUploadLink = serviceBase({
  request: ({ format, contentType }) =>
    axiosClient.get(`/aws/backoffice-upload-link`, {
      params: { format, contentType },
    }),
  retry: 0,
});

export const uploadFile = serviceBase({
  request: ({ targetURL, file }) =>
    axios.put(targetURL, file, { timeout: 30000 }),
  retry: 0,
});

export const createNewCar = serviceBase({
  request: car => axiosClient.post(`/car`, car),
  retry: 0,
});

export const createLease = serviceBase({
  request: values => axiosClient.post(`/lease`, values),
  retry: 0,
});

export const revokeLease = serviceBase({
  request: id => axiosClient.delete(`/lease/${id}`),
  retry: 0,
});

export const unsubscribeCarFromSystem = serviceBase({
  request: car => axiosClient.post(`/car/unsubscription`, car),
  retry: 0,
});

export const getSoldCars = serviceBase({
  request: options => axiosClient.get('/car/sold', { params: options }),
  retry: 0,
});

export const getCarAudits = serviceBase({
  request: ({ niv, options }) => {
    return axiosClient.get(`/car/audit/${niv}`, { params: options });
  },
  retry: 0,
});

export const getCarLocations = serviceBase({
  request: options => {
    return axiosClient.get(`/car/location`, { params: options });
  },
  retry: 0,
});

export const updateCarStatusService = serviceBase({
  request: ({ status, niv }) => {
    return axiosClient.put(`/car/status/${niv}`, { status });
  },
  retry: 0,
});

export const editCarService = serviceBase({
  request: values => {
    return axiosClient.put(`/car/edit/${values.niv}`, values);
  },
  retry: 0,
});

export default getAllCars;
