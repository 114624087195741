import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import UnassignReasonRow from './UnassignReasonRow';

const UnassignReasonTable = ({
  className,
  reasons,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const { t } = useTranslation();
  const openEdit = useCallback(
    provider => () => {
      handleOpenEdit(provider);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell style={{width:"90%"}}>{t('COMMON.REASON')}</TableCell>
      <TableCell>{t('PARAMETERS_VIEW.ACTIONS')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return reasons.map(reason => (
        <UnassignReasonRow
          {...reason}
          key={reason.id}
          openEdit={openEdit(reason)}
          handleDelete={handleDelete}
        />
      ));
    },
    [reasons],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

UnassignReasonTable.propTypes = {
  className: PropTypes.string,
  reasons: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default UnassignReasonTable;
