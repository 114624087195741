/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles';

const EnableDriverDialog = ({ audit, open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      keepMounted
      onClose={handleClose}
      style={{ marginBottom: '16px' }}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-aval-driver"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DRIVER_DETAIL_VIEW.REACTIVATE_DRIVER')}
        </Typography>
      </DialogTitle>
      <DialogContentText
        className={classes.confirmation}
        id="form-enable-delete-dialog"
      >
        {t('DRIVER_DETAIL_VIEW.ENABLE_DRIVER_CONFIRMATION').replace(
          '{value}',
          audit,
        )}
        <Button
          className={clsx(classes.label, classes.buttons)}
          style={{ fontSize: 13 }}
          onClick={handleSubmit}
          color="primary"
          variant="outlined"
        >
          {t('COMMON.ACCEPT')}
        </Button>
      </DialogContentText>
    </Dialog>
  );
};

EnableDriverDialog.propTypes = {
  audit: PropTypes.number,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EnableDriverDialog;
