import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { CLEAR_CARS } from 'src/store/actions/carActions';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import {
  addMaintenanceInCarAction,
  loadMaintenanceLockoutList,
} from 'src/store/actions/gpsActions';
import { selectGpsQueryOptions } from 'src/store/selectors/gpsSelectors';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import AddMaintenanceDialog from './AddMaintenanceDialog/AddMaintenanceDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '50vh',
    border: '1px solid #EEEEEE',
    margin: '0 8px',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  buttonPadding: { marginRight: 6 },
}));

const MmtoLockoutGpsToolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const queryOptions = useSelector(selectGpsQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria] = useState('niv');

  const debounceSearch = useDebounce(
    (criteria = searchCriteria, value, queryOpts) =>
      dispatch(loadMaintenanceLockoutList({ ...queryOpts, [criteria]: value })),
    400,
  );
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleSubmit = useCallback(
    values => {
      dispatch(addMaintenanceInCarAction(values));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(loadMaintenanceLockoutList());
    return () => dispatch({ type: CLEAR_CARS });
  }, []);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(searchCriteria, value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'GPS']}
          current="GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.TITLE"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.TITLE')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Paper elevation={0} className={classes.search}>
              <Input
                classes={{ input: classes.input }}
                startAdornment={Addornment}
                placeholder="Buscar"
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                type="number"
                onChange={handleSearch}
              />
            </Paper>

            <Button
              className={clsx(classes.buttons, classes.horizontalPadding)}
              color="primary"
              variant="contained"
              onClick={handleOpen}
            >
              <PlusIcon className={clsx(classes.buttonPadding)} fill="white" />
              {t('GPS_VIEW.MAINTENANCE_LOCKOUT_VIEW.ADD_NEW_MAINTENANCE')}
            </Button>
          </div>
        </div>
        <AddMaintenanceDialog
          open={open}
          handleClose={handleClose}
          submit={handleSubmit}
        />
      </div>
    </>
  );
};

MmtoLockoutGpsToolbar.propTypes = {
  className: PropTypes.string,
};

export default MmtoLockoutGpsToolbar;
