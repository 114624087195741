import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: theme.spacing(68),
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 3,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
  },
  filename: {
    flex: 1,
    paddingLeft: theme.spacing(),
  },
  uploadButton: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.26)',
  },
  box: {
    padding: `${theme.spacing(2)}px 0`,
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    minWidth: '100%',
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableRoot: {
    display: 'flex',
  },
  tableBody: {
    width: '100%',
    display: 'table',
  },
  tableBox: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`,
  },
  outlined: {
    borderColor: '#FFFFFF',
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
  },
}));
