import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CAR_MODES } from '../utils';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const modes = [
  { value: CAR_MODES.PLATFORM, label: 'Plataforma' },
  { value: CAR_MODES.LEASE, label: 'Arrendamiento' },
  /* 
  TODO: the code is commented to be implemented again in the future
  { value: CAR_MODES.COMPANIES, label: 'Empresas' } */
  { value: CAR_MODES.ADMINISTRATIVE, label: 'Administrativo' },
];

const makeModes = mode => {
  return modes.reduce((xs, x) => {
    if (x.value !== mode) {
      xs.push(x);
    }
    return xs;
  }, []);
};

const initialState = {
  mode: '',
};

const validationSchema = Yup.object().shape({
  mode: Yup.string()
    .oneOf(
      [CAR_MODES.PLATFORM, CAR_MODES.LEASE, CAR_MODES.COMPANIES],
      'SCHEMA_ERRORS.ENTER_VALUE_LIST',
    )
    .required('SCHEMA_ERRORS.ENTER_VALUE_LIST'),
});

const CarModeDialog = ({ open, car, handleClose, submit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const filteredModes = useMemo(() => makeModes(car.mode), [car]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      submit(values);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-car-mode"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CAR_DETAIL.VEHICLE_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.mode && errors.mode)}
                >
                  <InputLabel id="mode-select-label">
                    {t('COMMON.MODE')}
                  </InputLabel>
                  <Select
                    onBlur={handleBlur}
                    labelId="mode-select-label"
                    label={t('COMMON.MODE')}
                    id="mode-select"
                    value={values.mode}
                    onChange={handleChange}
                    name="mode"
                  >
                    {filteredModes.map(mode => (
                      <MenuItem
                        key={mode.value}
                        value={mode.value}
                        disabled={mode.value === CAR_MODES.LEASE}
                      >
                        {mode.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(touched.mode && errors.mode) && (
                    <FormHelperText>{t(errors.mode)}</FormHelperText>
                  )}
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CarModeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  car: PropTypes.object,
};

export default CarModeDialog;
