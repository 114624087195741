/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import {
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  getPaymentCategoryOptions,
  getPaymentCommentlist,
} from 'src/utils/payment';
import DropdownMenu from 'src/components/DropdownMenu';
import { TransactionType } from 'src/utils/constants';
import { TransactionCategory } from 'src/utils/transactions';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_TRANSACTION_MOVEMENTS,
  loadTransactionMovements,
} from 'src/store/actions/driverActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { selectTransactionMovements } from 'src/store/selectors/driverSelectors';
import { formatAmountWithDecimals } from 'src/utils/formatAmount';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Autocomplete } from '@material-ui/lab';
import useStyles from '../styles';
import {
  paymentSchema,
  chargeSchema,
  pendingPaymentSchema,
} from './transactionSchema';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManualTransactionDialog = ({
  type,
  debts,
  open,
  handleClose,
  submit,
  category,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const resetRef = useRef(() => console.log('Not implemented yet'));
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const movementsData = useSelector(selectTransactionMovements);
  const { id } = useParams();

  const categoryPaymentOptions = getPaymentCategoryOptions(t);

  const isLoadingMovements = useSelector(
    isLoadingSelector([LOAD_TRANSACTION_MOVEMENTS.action]),
  );

  const initialValues = () => {
    if (
      type === TransactionType.PAYMENT &&
      category === TransactionCategory.PENDING_PAYMENT
    ) {
      return {
        movement: '',
        category: TransactionCategory.PENDING_PAYMENT,
      };
    }
    return {
      amount: 0,
      comment: '',
    };
  };

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit({ ...values, category: values.category || category });
        resetForm(initialValues);
        handleClose();
        handleConfirmationClose();
      }
    },
    [submit, handleClose, handleConfirmationOpen, confirmationOpen],
  );

  useEffect(() => {
    if (category === TransactionCategory.PENDING_PAYMENT) {
      dispatch(loadTransactionMovements(id));
    }
  }, [category]);

  const schema = () => {
    if (type === TransactionType.PAYMENT) {
      if (category === TransactionCategory.PENDING_PAYMENT) {
        return pendingPaymentSchema;
      }
      return paymentSchema;
    }
    return chargeSchema;
  };

  const handleOuterClose = useCallback(() => {
    resetRef.current();
    handleClose();
  }, [resetRef.current, handleClose]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleOuterClose}
      scroll="body"
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-add-payment"
      >
        <Typography className={classes.header} color="primary" variant="h2">
          {type === TransactionType.PAYMENT
            ? t('DRIVER_DETAIL_VIEW.ADD_MANUAL_PAYMENT')
            : t('DRIVER_DETAIL_VIEW.ADD_MANUAL_CHARGE')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialValues);
            if (
              type === TransactionType.PAYMENT &&
              values.amount > debts &&
              confirmationOpen
            ) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    Por favor, ingrese un monto menor o igual a&nbsp;
                    <b>{debts}</b>
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    {type === TransactionType.PAYMENT
                      ? t('DRIVER_DETAIL_VIEW.CONFIRMATION_PAYMENT_MESSAGE')
                      : t('DRIVER_DETAIL_VIEW.CONFIRMATION_CHARGE_MESSAGE')}
                    &nbsp;
                    <b>
                      {category === TransactionCategory.PENDING_PAYMENT
                        ? values.movement.amount
                        : values.amount}
                    </b>
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                {type === TransactionType.PAYMENT &&
                  (category === TransactionCategory.PENDING_PAYMENT ? (
                    <>
                      <DropdownMenu
                        list={movementsData.map(data => ({
                          key: data,
                          name: ` $${formatAmountWithDecimals(data.amount)} - ${
                            data.reference
                          }`,
                        }))}
                        value={values.movement}
                        setValue={value => setFieldValue('movement', value)}
                        label={t('COMMON.REFERENCE')}
                        defaultValue={values.movement}
                        error={Boolean(touched.movement && errors.movement)}
                        errorText={touched.movement && t(errors.movement)}
                        disabled={!movementsData.length || isLoadingMovements}
                        tooltipDescription={t(
                          'DRIVER_DETAIL_VIEW.NO_MOVEMENTS_DATA',
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <DropdownMenu
                        list={categoryPaymentOptions}
                        value={values.category}
                        setValue={value => setFieldValue('category', value)}
                        label={t('COMMON.CATEGORY')}
                        error={Boolean(touched.category && errors.category)}
                        errorText={touched.category && t(errors.category)}
                      />
                      <FormControl
                        className={classes.field}
                        variant="outlined"
                        fullWidth
                        error={Boolean(touched.comment && errors.comment)}
                      >
                        <Autocomplete
                          value={values.comment}
                          options={getPaymentCommentlist(t)}
                          includeInputInList
                          onOpen={handleBlur}
                          freeSolo
                          closeIcon={false}
                          autoSelect
                          onChange={(_e, value) => {
                            setFieldValue('comment', value || '');
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={t('COMMON.COMMENT')}
                              name="comment"
                              fullWidth
                              variant="outlined"
                              error={Boolean(touched.comment && errors.comment)}
                            />
                          )}
                        />
                        {Boolean(touched.comment && errors.comment) && (
                          <FormHelperText>{t(errors.comment)}</FormHelperText>
                        )}
                      </FormControl>
                    </>
                  ))}
                {category !== TransactionCategory.PENDING_PAYMENT && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    error={Boolean(touched.amount && errors.amount)}
                  >
                    <TextField
                      error={Boolean(touched.amount && errors.amount)}
                      fullWidth
                      helperText={touched.amount && t(errors.amount)}
                      label="Monto"
                      name="amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.amount}
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </FormControl>
                )}

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CREATE')}
                      {type === TransactionType.PAYMENT
                        ? ` ${t('COMMON.PAYMENT')}`
                        : ` ${t('COMMON.CHARGE')}`}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleOuterClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ManualTransactionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  category: PropTypes.string,
};

export default ManualTransactionDialog;
