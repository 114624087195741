import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CLEAR_CATALOG,
  LOAD_CATALOG,
  loadUnassignReasons,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import UnassignReasonTable from './UnassignReasonTable';

const UnassignReasonList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const reasonList = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadUnassignReasons());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); 
  return (
    <UnassignReasonTable
      reasons={reasonList}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
    />
  );
};
UnassignReasonList.propTypes = {
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default UnassignReasonList;
