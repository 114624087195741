import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  getRentResume,
  downloadExportedRent,
} from 'src/services/rentResumeService';
import { selectRentResumeOptions } from '../selectors/rentResumeSelectors';
import { showMessage } from './snackBarActions';

export const SAVE_RENT_OPTIONS = 'SAVE_RENT_OPTIONS';
export const CLEAR_RENT_OPTIONS = 'CLEAR_RENT_OPTIONS';
export const LOAD_RENT_RESUME = asyncActionCreator('LOAD_RENT_RESUME');
export const CLEAR_RENT_RESUME = 'CLEAR_RENT_RESUME';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectRentResumeOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.searchCriteria) {
    reqOptions.searchCriteria = queryOpts.searchCriteria;
  }

  if (queryOpts.balance) {
    reqOptions.balance = queryOpts.balance;
  }

  if (queryOpts.filterByDebt) {
    reqOptions.filterByDebt = queryOpts.filterByDebt;
  }

  if (queryOpts.isAssigned === 'true') {
    reqOptions.isAssigned = true;
  }

  if (queryOpts.isAssigned === null) {
    reqOptions.isAssigned = null;
  }

  if (queryOpts.isAssigned === 'false') {
    reqOptions.isAssigned = false;
  }

  if (queryOpts.timePeriod) {
    reqOptions.rentPeriod = queryOpts.timePeriod;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadRentResume = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_RENT_RESUME.start });

    dispatch({
      type: SAVE_RENT_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getRentResume(reqOptions);

    return dispatch({
      type: LOAD_RENT_RESUME.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_RENT_RESUME.failure });
  }
};

export const clearRentResume = () => dispatch => {
  dispatch({ type: CLEAR_RENT_RESUME });
};

export const clearRentResumeOptions = () => dispatch => {
  dispatch({ type: CLEAR_RENT_RESUME });
};

export const downloadExcelRent = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    return downloadExportedRent(options);
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};
