/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  makeStyles,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DropdownMenu from './DropdownMenu';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 48,
    width: 200,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    minWidth: 'unset',
    paddingBottom: theme.spacing(2),
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  typography: {
    padding: theme.spacing(2),
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

export const FilterControlGroups = {
  CHECKBOX: 'checkbox',
  RADIO_BUTTON: 'radio-button',
  DROPDOWN: 'dropdown',
};

const makeInitialState = currentFilterValues => {
  const filterValuesProps = Object.keys(currentFilterValues);
  const initialState = {};

  filterValuesProps.forEach(value => {
    initialState[value] = currentFilterValues[value] || false;
  });

  return initialState;
};

const Filter = ({
  anchorEl,
  handleClose,
  filterValues,
  filters,
  handleChange,
  style,
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const [state, setState] = useState(makeInitialState(filterValues));
  const { t } = useTranslation();

  const handleChangeFilter = event => {
    if (event.target.type === 'radio') {
      setState({ ...state, [event.target.name]: event.target.value });
      handleChange({ ...state, [event.target.name]: event.target.value });
    } else if (event.target.type === 'checkbox') {
      setState({ ...state, [event.target.name]: event.target.checked });
      handleChange({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
      handleChange({ ...state, [event.target.name]: event.target.value });
    }
  };

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, anchorEl]);

  return (
    <Box className={classes.root}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Container className={classes.horizontalPadding}>
          {filters.map((filter, index) => (
            <Box key={index} display="flex" flexDirection="column">
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typography}
              >
                {t(filter.title).toUpperCase()}
              </Typography>
              {filter.controlGroup === FilterControlGroups.CHECKBOX &&
                filter.items.map(item => (
                  <FormControlLabel
                    key={item.value}
                    control={
                      <Checkbox
                        name={item.value}
                        color="primary"
                        value={filterValues[item.value]}
                        checked={filterValues[item.value]}
                        onChange={handleChangeFilter}
                      />
                    }
                    label={t(item.label)}
                  />
                ))}

              {filter.controlGroup === FilterControlGroups.RADIO_BUTTON && (
                <RadioGroup
                  display="flex"
                  flexDirection="column"
                  value={filterValues[filter.value]}
                  onChange={handleChangeFilter}
                  name={filterValues[filter.value]}
                >
                  {filter.items.map(item => (
                    <FormControlLabel
                      key={item.value}
                      control={
                        <Radio
                          name={filter.value}
                          color="primary"
                          checked={filterValues[filter.value] === item.value}
                        />
                      }
                      value={item.value}
                      label={t(item.label)}
                    />
                  ))}
                </RadioGroup>
              )}

              {filter.controlGroup === FilterControlGroups.DROPDOWN && (
                <DropdownMenu
                  list={filter.items}
                  value={filterValues[filter.value]}
                  label={filter.fieldLabel}
                  setValue={handleChange}
                  style={style}
                  name={filter.value}
                />
              )}
            </Box>
          ))}
        </Container>
      </Popover>
    </Box>
  );
};

Filter.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  filterValues: PropTypes.any,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      controlGroup: PropTypes.string,
      value: PropTypes.string, // For radio buttons and dropdown
      fieldLabel: PropTypes.string, // Only for dropdowns
      items: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
          }),
        ),
        // Type for dropdowns
        PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
          }),
        ),
      ]),
    }),
  ),
  handleChange: PropTypes.func,
  style: PropTypes.object,
};

export default Filter;
