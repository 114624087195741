/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import DetailRow from 'src/components/DetailRow';

const GeneralBusinessInfo = ({ business }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('BUSINESS.SOCIAL_REASON')}
        value={business.socialReason}
      />
      <DetailRow
        name={t('BUSINESS.COMMERCIAL_NAME')}
        value={business.commercialName}
      />
      <DetailRow name={t('BUSINESS.TWIST')} value={business.activity} />
      <DetailRow name={t('COMMON.ADDRESS')} value={business.address} />
    </>
  );
};

export default GeneralBusinessInfo;
