import React, { useCallback } from 'react';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in';
import {
  selectInsuranceList,
  selectInsuranceListCount,
  selectInsuranceQueryOptions,
} from 'src/store/selectors/insuranceSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import {
  loadInsurances,
  LOAD_INSURANCES,
} from 'src/store/actions/insuranceActions';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';
import InsurancesTable from './InsurancesTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const InsuranceList = () => {
  const dispatch = useDispatch();
  const insurances = useSelector(selectInsuranceList);
  const count = useSelector(selectInsuranceListCount);
  const { page } = useSelector(selectInsuranceQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_INSURANCES.action]));
  const success = useSelector(successSelector([LOAD_INSURANCES.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadInsurances({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadInsurances({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado seguros"
          description="Ningún seguro coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <InsurancesTable
      insurances={insurances}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

const InsurancesListView = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Page className={classes.root} title={t('NAVIGATION.INSURANCE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <InsuranceList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default InsurancesListView;
