import { push } from 'connected-react-router';
import {
  createNewDeviceGps,
  downloadListDevice,
  getAllGps,
  getTransmissionList,
  updateDeviceGps,
  getRentLockoutList,
  addOdometerForDevice,
  getMaintenanceLockoutList,
  AddMaintenanceInCar,
  getGeofencingList,
  createNewGeofencing,
  removeGeofencingById,
  switchCarEngineStatus,
  getMileageExcedentList,
  getDevicesLocationList,
  unassignGpsDevice,
  deleteGpsDevice,
} from 'src/services/gpsService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectGpsQueryOptions } from '../selectors/gpsSelectors';
import { loadCarDetail } from './carActions';
import { showMessage } from './snackBarActions';

export const SAVE_OPTIONS = 'SAVE_OPTIONS';
export const CLEAR_OPTIONS = 'CLEAR_OPTIONS';
export const GET_ALL_GPS = 'GET_ALL_GPS';
export const CLEAR_FORMS = 'CLEAR_FORMS';
export const LOAD_GPS = asyncActionCreator('LOAD_GPS');
export const LOAD_ALL_GPS = asyncActionCreator('LOAD_ALL_GPS');
export const CLEAR_DEVICE = 'CLEAR_DEVICE';
export const CREATE_DEVICE_GPS = asyncActionCreator('CREATE_DEVICE_GPS');
export const UPDATE_DEVICE_GPS = asyncActionCreator('UPDATE_DEVICE_GPS');
export const ENGINE_DEVICE_GPS = asyncActionCreator('UPDATE_DEVICE_GPS');
export const DOWNLOAD_DEVICES_LIST = asyncActionCreator(
  'DOWNLOAD_DEVICES_LIST',
);
export const INSTALL_ODOMETER_FOR_DEVICE = asyncActionCreator(
  'INSTALL_ODOMETER_FOR_DEVICE',
);
export const ADD_MAINTENANCE_IN_CAR = asyncActionCreator(
  'ADD_MAINTENANCE_IN_CAR',
);
export const CREATE_GEOFENCE = asyncActionCreator('CREATE_GEOFENCE');
export const LOAD_GPS_DEVICES_LOCATION = asyncActionCreator(
  'LOAD_GPS_DEVICES_LOCATION',
);
export const UNASSIGN_GPS_DEVICE = asyncActionCreator('UNASSIGN_GPS_DEVICE');
export const DELETE_GPS_DEVICE = asyncActionCreator('DELETE_GPS_DEVICE');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectGpsQueryOptions(getState());
  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.carniv) {
    reqOptions.carniv = queryOpts.carniv;
  }
  if (queryOpts.plateOrCarniv) {
    reqOptions.plateOrCarniv = queryOpts.plateOrCarniv;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }
  if (queryOpts.deviceCatalogId) {
    reqOptions.deviceCatalogId = queryOpts.deviceCatalogId;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadGpsList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_GPS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);
    const data = await getAllGps(reqOptions);

    return dispatch({
      type: LOAD_GPS.success,
      ...data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_GPS.failure });
  }
};
export const loadAllDevices = () => async dispatch => {
  try {
    dispatch({ type: LOAD_ALL_GPS.start });
    const data = await getAllGps();

    return dispatch({
      type: LOAD_ALL_GPS.success,
      ...data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_ALL_GPS.failure });
  }
};

export const createDeviceGps = (values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: CREATE_DEVICE_GPS.start });

    const gps = {
      ...values,
      gpsCatalogId: values.gpsCatalog.id,
      simNumber: values.simNumber.toString(),
    };

    console.log(gps);

    await createNewDeviceGps(gps);

    closeCallback();

    dispatch(
      showMessage({
        message: 'Dispositivo Agregado correctamente',
        variant: 'success',
      }),
    );
    dispatch(push(`/fleet/car/${gps.niv}`));

    return dispatch({ type: CREATE_DEVICE_GPS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_DEVICE_GPS.failure });
  }
};

export const removeGpsDevice = id => async dispatch => {
  try {
    dispatch({ type: DELETE_GPS_DEVICE.start });

    await deleteGpsDevice(id);

    dispatch(
      showMessage({
        message: 'DELETE_GPS_DEVICE',
        variant: 'success',
      }),
    );

    dispatch({
      type: DELETE_GPS_DEVICE.success,
    });

    return dispatch(loadGpsList({ catalogId: null, plateOrCarniv: null }));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_GPS_DEVICE.failure });
  }
};

export const updateDeviceGPS = (values, id) => async dispatch => {
  try {
    dispatch({ type: UPDATE_DEVICE_GPS.start });

    const gps = {
      ...values,
      id,
    };

    await updateDeviceGps(gps);
    dispatch(
      showMessage({
        message: 'Dispositivo actualizado correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadGpsList());
    return dispatch({ type: UPDATE_DEVICE_GPS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_DEVICE_GPS.failure });
  }
};

export const switchEngineStatus = values => async dispatch => {
  try {
    dispatch({ type: ENGINE_DEVICE_GPS.start });
    await switchCarEngineStatus(values);
    dispatch(
      showMessage({
        message: 'UPDATE_ENGINE_STATUS',
        variant: 'success',
      }),
    );
    await dispatch(loadCarDetail(values.niv));
    return dispatch({ type: ENGINE_DEVICE_GPS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: ENGINE_DEVICE_GPS.failure });
  }
};

export const loadTransmissionList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_GPS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);
    const data = await getTransmissionList(reqOptions);

    return dispatch({
      type: LOAD_GPS.success,
      ...data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_GPS.failure });
  }
};

export const downloadExcelListDevice = () => async dispatch => {
  try {
    dispatch({ type: DOWNLOAD_DEVICES_LIST.start });
    await downloadListDevice({ type: null });
    return dispatch({ type: DOWNLOAD_DEVICES_LIST.success });
  } catch (error) {
    console.error(error);
    return dispatch({ type: DOWNLOAD_DEVICES_LIST.failure });
  }
};

export const loadRentLockoutList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_GPS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);
    const data = await getRentLockoutList(reqOptions);

    return dispatch({
      type: LOAD_GPS.success,
      ...data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_GPS.failure });
  }
};

export const addOdometerForDeviceAction = id => async dispatch => {
  try {
    dispatch({ type: INSTALL_ODOMETER_FOR_DEVICE.start });
    await addOdometerForDevice(id);
    dispatch(
      showMessage({
        message: `El Odómetro fue instalado con éxito`,
        variant: 'success',
      }),
    );
    return dispatch({ type: INSTALL_ODOMETER_FOR_DEVICE.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: INSTALL_ODOMETER_FOR_DEVICE.failure });
  }
};

export const loadMaintenanceLockoutList = options => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_GPS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);
    const data = await getMaintenanceLockoutList(reqOptions);

    return dispatch({
      type: LOAD_GPS.success,
      ...data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_GPS.failure });
  }
};

export const addMaintenanceInCarAction = maintenanceData => async dispatch => {
  try {
    dispatch({ type: ADD_MAINTENANCE_IN_CAR.start });
    await AddMaintenanceInCar(maintenanceData);
    dispatch(
      showMessage({
        message: 'Se agrego correctamente la información de mantenimiento',
        variant: 'success',
      }),
    );
    dispatch(loadMaintenanceLockoutList());
    return dispatch({ type: ADD_MAINTENANCE_IN_CAR.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: ADD_MAINTENANCE_IN_CAR.failure });
  }
};

export const loadGeofencingList = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_GPS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);
    const data = await getGeofencingList(reqOptions);

    return dispatch({
      type: LOAD_GPS.success,
      ...data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_GPS.failure });
  }
};

export const createGeofencing = (values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: CREATE_GEOFENCE.start });

    await createNewGeofencing(values);
    dispatch({ type: CLEAR_FORMS });
    closeCallback();
    dispatch(
      showMessage({
        message: 'CREATE_GEOFENCING',
        variant: 'success',
      }),
    );
    dispatch(loadGeofencingList());
    return dispatch({ type: CREATE_GEOFENCE.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_GEOFENCE.failure });
  }
};

export const removeGeofencing = id => async dispatch => {
  try {
    await removeGeofencingById(id);
    dispatch(
      showMessage({
        message: 'DELETE_GEOFENCING',
        variant: 'success',
      }),
    );
    return dispatch(loadGeofencingList());
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadMileageExcedentList = options => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_GPS.start });

    dispatch({
      type: SAVE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);
    const data = await getMileageExcedentList(reqOptions);

    return dispatch({
      type: LOAD_GPS.success,
      ...data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_GPS.failure });
  }
};

export const loadDevicesLocationList = () => async dispatch => {
  try {
    dispatch({ type: LOAD_GPS_DEVICES_LOCATION.start });

    const data = await getDevicesLocationList();

    return dispatch({
      type: LOAD_GPS_DEVICES_LOCATION.success,
      payload: data,
    });
  } catch (error) {
    return dispatch({ type: LOAD_GPS_DEVICES_LOCATION.failure });
  }
};

export const unassignGpsDeviceFromCar = carniv => async dispatch => {
  try {
    dispatch({ type: UNASSIGN_GPS_DEVICE.start });

    await unassignGpsDevice(carniv);

    return dispatch({
      type: UNASSIGN_GPS_DEVICE.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UNASSIGN_GPS_DEVICE.failure });
  }
};
