import {
  getBusiness,
  downloadeExportedBusiness,
  createNewBusiness,
  getBusinessById,
  getBusinessTransactionsById,
  downloadeExportedBusinessTransactions,
  deleteBusinessPayment,
  createBusinessTransaction,
  updateBusinessBasicInfo,
  updateBusinessDocuments,
  updateBusinessContactInfo,
} from 'src/services/businessService';
import { getUploadLink, uploadFile } from 'src/services/serviceBase';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  selectBusinesssQueryOptions,
  selectTransactionsQueryOptions,
} from '../selectors/businessSelectors';
import { showMessage } from './snackBarActions';

export const SAVE_BUSINESS_OPTIONS = 'SAVE_BUSINESS_OPTIONS';
export const LOAD_BUSINESS = asyncActionCreator('LOAD_BUSINESS');
export const LOAD_DETAIL_BUSINESS = asyncActionCreator('LOAD_DETAIL_BUSINESS');
export const CLEAR_BUSINESS = 'CLEAR_BUSINESS';
export const CLEAR_BUSINESS_OPTIONS = 'CLEAR_BUSINESS_OPTIONS';
export const CREATE_BUSINESS = asyncActionCreator('CREATE_BUSINESS');
export const CLEAR_DETAIL_BUSINESS = 'CLEAR_DETAIL_BUSINESS';
export const UPDATE_BUSINESS = asyncActionCreator('UPDATE_BUSINESS');

export const LOAD_TRANSACTIONS_BUSINESS = asyncActionCreator(
  'LOAD_TRANSACTIONS_BUSINESS',
);
export const CLEAR_TRANSACTIONS_BUSINESS = 'CLEAR_TRANSACTIONS_BUSINESS';
export const SAVE_TRANSACTION_BUSINESS_OPTIONS =
  'SAVE_TRANSACTION_BUSINESS_OPTIONS';
export const CLEAR_TRANSACTION_BUSINESS_OPTIONS =
  'CLEAR_TRANSACTION_BUSINESS_OPTIONS';

export const CREATE_TRANSACTION = asyncActionCreator('CREATE_TRANSACTION');
export const DELETE_TRANSACTION = asyncActionCreator('DELETE_TRANSACTION');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectBusinesssQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

function getTransactionOptions(getState, sendLimit = true) {
  const queryOpts = selectTransactionsQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

const uploadSingleFile = async file => {
  if (!file) return Promise.reject(new Error('No file'));
  const { type } = file;
  const extension = file.name.split('.').pop();
  if (!extension) {
    throw new Error('No extension :(');
  }
  const link = await getUploadLink({ format: extension, contentType: type });
  const url = new URL(link);
  const attachmentId = url.pathname.slice(1);
  await uploadFile({ targetURL: link, file });
  return `https://meteoros-files.s3.us-east-2.amazonaws.com/${attachmentId}`;
};

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_BUSINESS_OPTIONS });
};

export const clearTransactionOptions = () => dispatch => {
  dispatch({ type: CLEAR_TRANSACTION_BUSINESS_OPTIONS });
};

export const loadBusiness = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_BUSINESS.start });

    dispatch({
      type: SAVE_BUSINESS_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getBusiness(reqOptions);

    return dispatch({
      type: LOAD_BUSINESS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_BUSINESS.failure });
  }
};

export const clearBusiness = () => async dispatch => {
  try {
    return dispatch({ type: CLEAR_BUSINESS });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const downloadExcelBusiness = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    await downloadeExportedBusiness(options);
  } catch (error) {
    console.log(dispatch);
    console.error(error);
  }
};

export const createBusiness = values => async dispatch => {
  try {
    dispatch({ type: CREATE_BUSINESS.start });

    await createNewBusiness(values);
    dispatch(
      showMessage({
        message: 'Empresa creada correctamente',
        variant: 'success',
      }),
    );

    dispatch(loadBusiness());

    return dispatch({ type: CREATE_BUSINESS.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_BUSINESS.failure });
  }
};

export const loadBusinessDetail = id => async dispatch => {
  try {
    dispatch({ type: LOAD_DETAIL_BUSINESS.start });

    const data = await getBusinessById(id);

    return dispatch({
      type: LOAD_DETAIL_BUSINESS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DETAIL_BUSINESS.failure });
  }
};

const updateBusinessAction = serviceCallback => (
  id,
  values,
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_BUSINESS.start });
    const data = await serviceCallback({ id, values });

    dispatch(
      showMessage({
        message: 'Empresa actualizada correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadBusinessDetail(id));
    return dispatch({
      type: UPDATE_BUSINESS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_BUSINESS.failure });
  }
};

export const updateBasicBusinessInfoAction = updateBusinessAction(
  updateBusinessBasicInfo,
);

export const updateContactBusinessInfoAction = updateBusinessAction(
  updateBusinessContactInfo,
);

export const updateBusinessDocumentsAction = updateBusinessAction(
  async data => {
    let {
      constanceRfc,
      constitutiveFile,
      attorneyIfe,
      businessAddressInvoice,
      avalAddressInvoice,
      taxCompliance,
      frameworkContract,
    } = data.values;
    if (constanceRfc && constanceRfc.inDb) {
      constanceRfc = '';
    }

    if (constitutiveFile && constitutiveFile.inDb) {
      constitutiveFile = '';
    }

    if (attorneyIfe && attorneyIfe.inDb) {
      attorneyIfe = '';
    }

    if (businessAddressInvoice && businessAddressInvoice.inDb) {
      businessAddressInvoice = '';
    }

    if (avalAddressInvoice && avalAddressInvoice.inDb) {
      avalAddressInvoice = '';
    }

    if (taxCompliance && taxCompliance.inDb) {
      taxCompliance = '';
    }

    if (frameworkContract && frameworkContract.inDb) {
      frameworkContract = '';
    }

    if (constanceRfc) {
      constanceRfc = await uploadSingleFile(constanceRfc);
    }
    if (constitutiveFile) {
      constitutiveFile = await uploadSingleFile(constitutiveFile);
    }
    if (attorneyIfe) {
      attorneyIfe = await uploadSingleFile(attorneyIfe);
    }
    if (businessAddressInvoice) {
      businessAddressInvoice = await uploadSingleFile(businessAddressInvoice);
    }
    if (avalAddressInvoice) {
      avalAddressInvoice = await uploadSingleFile(avalAddressInvoice);
    }
    if (taxCompliance) {
      taxCompliance = await uploadSingleFile(taxCompliance);
    }
    if (frameworkContract) {
      frameworkContract = await uploadSingleFile(frameworkContract);
    }

    return updateBusinessDocuments({
      id: data.id,
      values: {
        constanceRfc,
        constitutiveFile,
        attorneyIfe,
        businessAddressInvoice,
        avalAddressInvoice,
        taxCompliance,
        frameworkContract,
      },
    });
  },
);

export const loadBusinessTransactions = (id, options) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_TRANSACTIONS_BUSINESS.start });

    dispatch({
      type: SAVE_TRANSACTION_BUSINESS_OPTIONS,
      options,
    });
    const reqOptions = getTransactionOptions(getState);

    const data = await getBusinessTransactionsById({ id, reqOptions });

    return dispatch({
      type: LOAD_TRANSACTIONS_BUSINESS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_TRANSACTIONS_BUSINESS.failure });
  }
};

export const createTransaction = (id, values) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_TRANSACTION.start });
    await createBusinessTransaction({ id, values });

    dispatch(
      showMessage({
        message: 'Transacción creada correctamente',
        variant: 'success',
      }),
    );
    const reqOptions = getOptions(getState);

    dispatch(loadBusinessTransactions(id, reqOptions));
    return dispatch({
      type: CREATE_TRANSACTION.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_TRANSACTION.failure });
  }
};

export const downloadExcelBusinessTransactions = id => async (
  dispatch,
  getState,
) => {
  try {
    const options = getOptions(getState, false);
    downloadeExportedBusinessTransactions({ id, options });
  } catch (error) {
    console.log(dispatch);
    console.error(error);
  }
};

export const deletePayment = (transactionId, businessId) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: DELETE_TRANSACTION.start });
    await deleteBusinessPayment({ id: transactionId });

    dispatch(
      showMessage({
        message: 'Transacción eliminada correctamente',
        variant: 'success',
      }),
    );
    const reqOptions = getOptions(getState);

    dispatch(loadBusinessTransactions(businessId, reqOptions));
    return dispatch({
      type: DELETE_TRANSACTION.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_TRANSACTION.failure });
  }
};
