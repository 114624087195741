import React, { useEffect } from 'react';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import Preloader from 'src/components/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import { selectDriverDetail } from 'src/store/selectors/driverSelectors';
import * as Actions from 'src/store/actions/driverActions';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import { useParams } from 'react-router';
import DriverTabs from './DriverTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  preloaderCard: {
    height: '82vh',
  },
}));

const DriverDetailView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const driver = useSelector(selectDriverDetail);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_DETAIL_DRIVER.action]),
  );
  const success = useSelector(
    successSelector([Actions.LOAD_DETAIL_DRIVER.action]),
  );

  useEffect(() => {
    dispatch(Actions.loadDriverDetail(id));
  }, [dispatch, id]);

  return (
    <Page
      className={classes.root}
      title={`Conductor ${driver ? driver.id : ''}`}
    >
      <FadeIn transitionDuration={300}>
        <Container maxWidth={false}>
          <Box>
            {success && !loading && driver ? (
              <DriverTabs driver={driver} />
            ) : (
              <Card className={classes.preloaderCard}>
                <Preloader />
              </Card>
            )}
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default DriverDetailView;
