import moment from 'moment';
import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DetailRow from 'src/components/DetailRow';
import { AssignationStatus } from 'src/utils/constants';
import { useTranslation } from 'react-i18next';

const DriverCarInfo = ({
  actualDriverCar,
  actualDriverAssignation,
  actualDriverAssignationNiv,
  assignationStatus,
}) => {
  const { t } = useTranslation();
  const setAssignationStatus = () => {
    switch (assignationStatus) {
      case AssignationStatus.PENDING:
        return 'COMMON.PENDING';
      case AssignationStatus.CANCELLED:
        return 'COMMON.CANCELLED';
      default:
        return 'COMMON.COMPLETED';
    }
  };

  return (
    <>
      <DetailRow name="Auto" value={actualDriverCar} />
      <DetailRow
        name="NIV"
        value={
          <Link
            style={{ color: 'blue' }}
            to={`/fleet/car/${actualDriverAssignationNiv}`}
          >
            {actualDriverAssignationNiv}
          </Link>
        }
      />
      <DetailRow
        name="Fecha de inicio"
        value={moment(actualDriverAssignation).format('DD-MMM-YY')}
      />
      <DetailRow name="Estado" value={t(setAssignationStatus())} />
    </>
  );
};

DriverCarInfo.propTypes = {
  actualDriverCar: propTypes.string,
  actualDriverAssignation: propTypes.string,
  actualDriverAssignationNiv: propTypes.string,
  assignationStatus: propTypes.string,
};

export default DriverCarInfo;
