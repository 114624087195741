import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import DriverBalance from './DriverBalance';

const BalanceView = ({ driver }) => {
  return (
    <Box mt={3}>
      <DriverBalance driver={driver} />
    </Box>
  );
};

BalanceView.propTypes = {
  driver: PropTypes.object,
};

export default BalanceView;
