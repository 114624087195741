import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadAmortizationBalanceList } from 'src/store/actions/accountingActions';
import { AmortizationBalanceType } from '../../../utils/constants';
import useStyles from './styles';
import InsuranceBalanceList from './InsuranceBalanceList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function selectAmortizationBalanceType(value) {
  switch (Number(value)) {
    case 0:
      return AmortizationBalanceType.ASSETS;
    case 1:
      return AmortizationBalanceType.AMORTIZATION;
    default:
      return '';
  }
}

export default function InsuranceBalanceTabs() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadAmortizationBalanceList({
          insuranceBalanceType: selectAmortizationBalanceType(newValue),
        }),
      );
    },
    [setTabIndex],
  );

  return (
    <div className={classes.root}>
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('INSURANCE_BALANCE_VIEW.POLICIES')} />
          <Tab label={t('INSURANCE_BALANCE_VIEW.AMORTIZATION')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <InsuranceBalanceList />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <InsuranceBalanceList />
      </TabPanel>
    </div>
  );
}
