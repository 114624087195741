import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, TableRow } from '@material-ui/core';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import MovementsRow from './MovementsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const Tbody = ({ movements, t }) =>
  movements.map(movement => (
    <MovementsRow key={movement.id} t={t} {...movement} />
  ));

const MovementsTable = ({
  t,
  className,
  movements,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('TRANSACTION.DATE')}</TableCell>
      <TableCell>{t(`MOVEMENT.STATUS_HEADER`)}</TableCell>
      <TableCell>{t('MOVEMENT.EXPIRES_AT')}</TableCell>
      <TableCell>{t('TRANSACTION.CATEGORY_TITLE')}</TableCell>
      <TableCell>{t('COMMON.CARNIV')}</TableCell>
      <TableCell style={{ width: '20%' }}>{t('TRANSACTION.COMMENT')}</TableCell>
      <TableCell>{t('MOVEMENT.AMOUNT')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody movements={movements} t={t} />, [
    movements,
    t,
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, movements],
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

MovementsTable.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  movements: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
};

MovementsTable.defaultProps = {
  count: 0,
};

export default MovementsTable;
