import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  downloadExcelDepreciationBalanceList,
  downloadExcelListAmorizationBalance,
  downloadExcelListFleetMovements,
  getAmortizationBalance,
  getAmortizationResume,
  getDepreciationBalance,
  getDepreciationResume,
  getFleetMovements,
} from 'src/services/accountingService';
import { showMessage } from './snackBarActions';
import {
  selectAmortizationBalanceQueryOptions,
  selectDepreciationBalanceQueryOptions,
} from '../selectors/accountingSelector';

export const SAVE_DEPRECIATION_BALANCE_OPTIONS =
  'SAVE_DEPRECIATION_BALANCE_OPTIONS';
export const CLEAR_DEPRECIATION_BALANCE_OPTIONS =
  'CLEAR_DEPRECIATION_BALANCE_OPTIONS';
export const LOAD_DEPRECIATION_BALANCE = asyncActionCreator(
  'LOAD_DEPRECIATION_BALANCE',
);
export const CLEAR_DEPRECIATION_BALANCE = 'CLEAR_DEPRECIATION_BALANCE';

export const LOAD_DEPRECIATION_RESUME = asyncActionCreator(
  'LOAD_DEPRECIATION_RESUME',
);
export const LOAD_AMORTIZATION_BALANCE = asyncActionCreator(
  'LOAD_AMORTIZATION_BALANCE',
);
export const LOAD_AMORTIZATION_RESUME = asyncActionCreator(
  'LOAD_AMORTIZATION_RESUME',
);
export const SAVE_AMORTIZATION_BALANCE_OPTIONS =
  'SAVE_AMORTIZATION_BALANCE_OPTIONS';
export const CLEAR_AMORTIZATION_BALANCE_OPTIONS =
  'CLEAR_AMORTIZATION_BALANCE_OPTIONS';
export const CLEAR_AMORTIZATION_BALANCE = 'CLEAR_AMORTIZATION_BALANCE';

export const LOAD_FLEET_MOVEMENTS = asyncActionCreator('LOAD_FLEET_MOVEMENTS');

function getDepreciationOptions(getState, sendLimit = true) {
  const queryOpts = selectDepreciationBalanceQueryOptions(getState());
  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.startDateBalance && queryOpts.endDateBalance) {
    reqOptions.startDateBalance = queryOpts.startDateBalance;
    reqOptions.endDateBalance = queryOpts.endDateBalance;
  }
  if (queryOpts.soldAt) {
    reqOptions.soldStartDate = queryOpts.soldAt.soldStartDate;
    reqOptions.soldEndDate = queryOpts.soldAt.soldEndDate;
  }
  if (queryOpts.balanceType) {
    reqOptions.balanceType = queryOpts.balanceType;
  }
  if (queryOpts.niv) {
    reqOptions.niv = queryOpts.niv;
  }
  if (queryOpts.includeSoldCars) {
    reqOptions.includeSoldCars = queryOpts.includeSoldCars;
  }
  if (queryOpts.onlySoldCars) {
    reqOptions.onlySoldCars = queryOpts.onlySoldCars;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

function getAmortizationOptions(getState, sendLimit = true) {
  const queryOpts = selectAmortizationBalanceQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.startDate && queryOpts.endDate) {
    reqOptions.startDate = queryOpts.startDate;
    reqOptions.endDate = queryOpts.endDate;
  }
  if (queryOpts.insuranceBalanceType) {
    reqOptions.insuranceBalanceType = queryOpts.insuranceBalanceType;
  }
  if (queryOpts.carniv) {
    reqOptions.carniv = queryOpts.carniv;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadDepreciationBalance = options => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_DEPRECIATION_BALANCE.start });

    dispatch({
      type: SAVE_DEPRECIATION_BALANCE_OPTIONS,
      options,
    });

    const reqOptions = getDepreciationOptions(getState);

    const data = await getDepreciationBalance(reqOptions);

    return dispatch({
      type: LOAD_DEPRECIATION_BALANCE.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DEPRECIATION_BALANCE.failure });
  }
};

export const loadFleetMovements = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_FLEET_MOVEMENTS.start });
    dispatch({
      type: SAVE_DEPRECIATION_BALANCE_OPTIONS,
      options,
    });

    const reqOptions = getDepreciationOptions(getState);

    const data = await getFleetMovements(reqOptions);

    return dispatch({
      type: LOAD_FLEET_MOVEMENTS.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_FLEET_MOVEMENTS.failure });
  }
};

export const loadDepreciationResume = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_DEPRECIATION_RESUME.start });

    dispatch({
      type: SAVE_DEPRECIATION_BALANCE_OPTIONS,
      options,
    });

    const reqOptions = getDepreciationOptions(getState);

    const data = await getDepreciationResume(reqOptions);

    return dispatch({
      type: LOAD_DEPRECIATION_RESUME.success,
      payload: data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DEPRECIATION_RESUME.failure });
  }
};

export const exportExcelDepreciationBalanceList = () => async (_, getState) => {
  try {
    const options = getDepreciationOptions(getState, false);

    await downloadExcelDepreciationBalanceList(options);
  } catch (error) {
    console.error(error);
  }
};

export const loadAmortizationBalanceList = options => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_AMORTIZATION_BALANCE.start });

    dispatch({
      type: SAVE_AMORTIZATION_BALANCE_OPTIONS,
      options,
    });
    const reqOptions = getAmortizationOptions(getState);

    const data = await getAmortizationBalance(reqOptions);

    return dispatch({
      type: LOAD_AMORTIZATION_BALANCE.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_AMORTIZATION_BALANCE.failure });
  }
};

export const loadAmortizationResume = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_AMORTIZATION_RESUME.start });

    dispatch({
      type: SAVE_AMORTIZATION_BALANCE_OPTIONS,
      options,
    });
    const reqOptions = getAmortizationOptions(getState);

    const data = await getAmortizationResume(reqOptions);

    return dispatch({
      type: LOAD_AMORTIZATION_RESUME.success,
      payload: data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_AMORTIZATION_RESUME.failure });
  }
};

export const exportExcelListAmortizationBalance = () => async (
  dispatch,
  getState,
) => {
  try {
    const options = getAmortizationOptions(getState, false);

    downloadExcelListAmorizationBalance(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const exportExcelListFleetMovements = () => async (
  dispatch,
  getState,
) => {
  try {
    const options = getDepreciationOptions(getState, false);

    downloadExcelListFleetMovements(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};
