/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'white',
    color: 'white',
  },
}));
const initialState = {
  insuranceCost: 0,
  rentCost: 0,
  costPerWeek: 0,
};

const PricingLease = ({ driver }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [{ insuranceCost, rentCost, costPerWeek }, setPricingData] = useState(
    initialState,
  );

  useEffect(() => {
    if (driver.actualContract) {
      return setPricingData({
        insuranceCost: Number(driver.actualContract.insuranceCost),
        rentCost: Number(driver.actualContract.rentCost),
        costPerWeek:
          Number(driver.actualContract?.rentCost) +
          Number(driver.actualContract?.insuranceCost),
      });
    }
    if (driver.actualDriverLease) {
      return setPricingData({
        insuranceCost: driver.actualDriverLease.costPerInsurance,
        rentCost: driver.actualDriverLease.rentCost,
        costPerWeek: driver.actualDriverLease.costPerWeek,
      });
    }
    return setPricingData(initialState);
  }, [driver]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography component="h5" variant="h5" color="primary">
            {t('DRIVER_DETAIL_VIEW.LEASE.CONTRACT_COSTS')}
          </Typography>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            {driver.actualDriverLease || driver.actualContract ? (
              <>
                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.RENT_COSTS')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {formatPositiveAmount(rentCost)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.INSURANCE_COSTS')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {formatPositiveAmount(insuranceCost)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.TOTAL_COST_PER_WEEK')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {formatPositiveAmount(costPerWeek)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default PricingLease;
