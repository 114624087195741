import getAllLicence, {
  downloadExportedLicences,
} from 'src/services/licenceService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectLicenceQueryOptions } from '../selectors/licenceSelectors';
import { showMessage } from './snackBarActions';

export const SAVE_LICENCE_OPTIONS = 'SAVE_LICENCE_OPTIONS';
export const CLEAR_LICENCE_OPTIONS = 'CLEAR_LICENCE_OPTIONS';
export const LOAD_DRIVERS_WITH_LICENCE = asyncActionCreator(
  'LOAD_DRIVERS_WITH_LICENCE',
);
export const CLEAR_DRIVERS_WITH_LICENCE = 'CLEAR_DRIVERS_WITH_LICENCE';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectLicenceQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.hasLicence) {
    reqOptions.hasLicence = queryOpts.hasLicence;
  }
  if (queryOpts.licenceStatus) {
    reqOptions.licenceStatus = queryOpts.licenceStatus;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const loadLicences = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_DRIVERS_WITH_LICENCE.start });

    dispatch({
      type: SAVE_LICENCE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);
    const data = await getAllLicence(reqOptions);

    return dispatch({
      type: LOAD_DRIVERS_WITH_LICENCE.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DRIVERS_WITH_LICENCE.failure });
  }
};

export const downloadExcelExportedLicences = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadExportedLicences(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};
