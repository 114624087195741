/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import DepreciationBalanceListView from './DepreciationBalanceListView';
import DepreciationResumeListView from './DepreciationResumeListView';
import FleetMovementsListView from './FleetMovementsListView';
import InsuranceBalanceListView from './InsuranceBalanceListView';
import InsuranceResumeListView from './InsuranceResumeListView';

const AcccountingView = ({ match }) => {
  return (
    <>
      <Route
        path={`${match.url}/depreciation/balance`}
        component={DepreciationBalanceListView}
      />
      <Route
        path={`${match.url}/depreciation/resume`}
        component={DepreciationResumeListView}
      />
      <Route
        path={`${match.url}/fleet-movements`}
        component={FleetMovementsListView}
      />
      <Route
        path={`${match.url}/amortization/balance`}
        component={InsuranceBalanceListView}
      />
      <Route
        path={`${match.url}/amortization/resume`}
        component={InsuranceResumeListView}
      />
    </>
  );
};

export default AcccountingView;
