/* eslint-disable no-unneeded-ternary */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import moment from 'moment';
import { TimePeriod } from 'src/utils/constants';
import {
  CLEAR_DEPRECIATION_BALANCE_OPTIONS,
  loadDepreciationResume,
} from 'src/store/actions/accountingActions';
import { selectDepreciationBalanceQueryOptions } from 'src/store/selectors/accountingSelector';
import useStyles from './styles';

const filters = [
  {
    title: 'COMMON.BY_DATE',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'timePeriod',
    items: [
      {
        label: 'COMMON.THIS_MONTH',
        value: TimePeriod.MONTH,
      },
      {
        label: 'COMMON.THIS_QUARTER',
        value: TimePeriod.QUARTER,
      },
      {
        label: 'COMMON.THIS_YEAR',
        value: TimePeriod.YEAR,
      },
    ],
  },
];

const getFilterOptions = options => {
  if (options.timePeriod === TimePeriod.MONTH) {
    return {
      startDateBalance: moment()
        .startOf('month')
        .toDate(),
      endDateBalance: moment().toDate(),
    };
  }

  if (options.timePeriod === TimePeriod.QUARTER) {
    return {
      startDateBalance: moment()
        .quarter(moment().quarter())
        .startOf('quarter')
        .toDate(),
      endDateBalance: moment()
        .quarter(moment().quarter())
        .toDate(),
    };
  }

  if (options.timePeriod === TimePeriod.YEAR) {
    return {
      startDateBalance: moment()
        .startOf('year')
        .toDate(),
      endDateBalance: moment().toDate(),
    };
  }

  return {};
};

const Toolbar = ({ className, filterView, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectDepreciationBalanceQueryOptions);

  const [filterValues, setFilterValues] = React.useState({
    timePeriod: TimePeriod.MONTH,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleSubmitFilter = queryOpts => {
    dispatch(loadDepreciationResume(queryOpts));
    handleCloseFilter();
  };

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      handleSubmitFilter({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [handleSubmitFilter, queryOptions],
  );

  useEffect(() => {
    dispatch({ type: CLEAR_DEPRECIATION_BALANCE_OPTIONS });
    dispatch(loadDepreciationResume());
  }, []);

  return (
    <Box>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.ADMIN', 'NAVIGATION.ACCOUNTING']}
          current="NAVIGATION.FLEET_PROFILE"
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.FLEET_PROFILE')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleOpenFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>

            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  filterView: PropTypes.number,
};

export default Toolbar;
