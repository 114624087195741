import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Input,
  InputAdornment,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import { loadMileageExcedentList } from 'src/store/actions/gpsActions';
import { selectGpsQueryOptions } from 'src/store/selectors/gpsSelectors';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { loadDeviceList } from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import useStyles from './styles';

const ExcedentMileageToolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectGpsQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria] = useState('plateOrCarniv');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const devicesCatalogList = useSelector(selectCatalogList);
  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState({
    deviceCatalogId: '',
  });

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const debounceSearch = useDebounce(
    (criteria = searchCriteria, value, queryOpts) =>
      dispatch(
        loadMileageExcedentList({ ...queryOpts, [criteria]: value, page: 0 }),
      ),
    400,
  );

  useEffect(() => {
    dispatch(loadDeviceList());
  }, []);

  const handleFilterChange = useCallback(
    (value, name) => {
      setFilterValues({ ...filterValues, [name]: value });
      dispatch(
        loadMileageExcedentList({
          ...filterValues,
          [name]: value,
        }),
      );
    },
    [setFilterValues],
  );

  useEffect(() => {
    setFilters([
      {
        title: 'GPS_VIEW.FILTER_BY_MODEL',
        controlGroup: FilterControlGroups.DROPDOWN,
        value: 'deviceCatalogId',
        fieldLabel: 'GPS_VIEW.SELECT_A_MODEL',
        setValue: handleFilterChange,
        items: devicesCatalogList.map(device => {
          return {
            key: device.id,
            name: `${device.brand} ${device.model} - ${device.type}`,
          };
        }),
      },
    ]);
  }, [devicesCatalogList]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(searchCriteria, value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.INCOME']}
          current="NAVIGATION.EXCEDENT_MILEAGES"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.EXCEDENT_MILEAGES')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Paper elevation={0} className={classes.search}>
              <Input
                classes={{ input: classes.input }}
                startAdornment={Addornment}
                placeholder={t('GPS_VIEW.SEARCH_BY_PLATE_OR_NIV')}
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                onChange={handleSearch}
              />
            </Paper>
            <Box className={classes.actionsContainer}>
              <Button
                className={clsx(
                  classes.buttons,
                  classes.outlined,
                  classes.horizontalPadding,
                )}
                color="primary"
                onClick={handleOpenFilter}
              >
                <FilterIcon className={classes.icon} />
                {t('COMMON.FILTER')}
              </Button>
              <Filter
                filters={filters}
                anchorEl={anchorEl}
                filterValues={filterValues}
                handleClose={handleCloseFilter}
                handleChange={handleFilterChange}
                style={{ minWidth: '200px', width: '100%' }}
              />
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

ExcedentMileageToolbar.propTypes = {
  className: PropTypes.string,
};

export default ExcedentMileageToolbar;
