/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Box, Grid } from '@material-ui/core';
import BasicCar from './BasicCar';
import CarDocuments from './CarDocuments';
import CarDriver from './CarDriver';
import CarInsurance from './CarInsurance';
import { CAR_STATUS } from './utils';

moment.updateLocale('es', moment.localeData('es'));

const GeneralView = ({ car, t }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <BasicCar car={car} t={t} />
        </Grid>

        <Grid item xs={3}>
          <CarDocuments car={car} />
        </Grid>

        <Grid item xs={3}>
          {car.status !== CAR_STATUS.SOLD && <CarInsurance car={car} />}
        </Grid>

        <Grid item xs={3}>
          {car.status !== CAR_STATUS.SOLD &&
            car.status !== CAR_STATUS.ON_SALE && (
              <CarDriver
                t={t}
                actualDriverName={car.actualDriverName}
                actualDriverId={car.actualDriverId}
                actualDriverAssignation={car.actualDriverAssignation}
                actualDriverAssignationId={car.actualDriverAssignationId}
                actualDriverAssignationStatus={car.assignationStatus}
                niv={car.niv}
                carMode={car.mode}
                carLease={car.lease}
                rent={car.rent}
                costPerWeek={car.costPerWeek}
                car={car}
                contract={car.contract}
              />
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralView;
