import React, { useCallback, useEffect } from 'react';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import {
  selectGpsList,
  selectGpsListCount,
  selectGpsQueryOptions,
} from 'src/store/selectors/gpsSelectors';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { loadDeviceList } from 'src/store/actions/catalogActions';
import { loadGpsList, LOAD_GPS } from 'src/store/actions/gpsActions';
import GpsTable from './gpsTable';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const GpsList = () => {
  const dispatch = useDispatch();
  const gps = useSelector(selectGpsList);
  const count = useSelector(selectGpsListCount);
  const { page } = useSelector(selectGpsQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_GPS.action]));
  const success = useSelector(successSelector([LOAD_GPS.action]));

  useEffect(() => {
    dispatch(loadDeviceList());
    dispatch(
      loadGpsList({ deviceCatalogId: null, plateOrCarniv: null, page: 0 }),
    );
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(loadGpsList({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(loadGpsList({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado GPS"
          description="Ningún GPS coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <GpsTable
      cars={gps}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

const GpsListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('NAVIGATION.GPS_DEVICES')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <GpsList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default GpsListView;
