/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { TableRow } from '@material-ui/core';
/* import { Link } from 'react-router-dom'; */
import TableCell from 'src/components/Table/TableCell';
import { CancelOutlined, CheckCircle } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const MmtoLockoutGpsRow = ({
  niv,
  plates,
  driver,
  sensor,
  nextMaintenance,
  lastMaintenance,
  engineStatus,
}) => {
  const client = useMemo(
    () =>
      driver
        ? `${driver.name} ${driver.paternalName} ${driver.maternalName}`
        : '',
    [driver],
  );
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/fleet/car/${niv}`}>{niv}</Link>
      </TableCell>
      <TableCell>{plates}</TableCell>
      <TableCell>
        <Link to={`/drivers/detail/${driver?.id}`}>{client}</Link>
      </TableCell>
      <TableCell>{sensor || 'Sin datos'}</TableCell>
      <TableCell>{sensor ? sensor - lastMaintenance : 'Sin datos'}</TableCell>
      <TableCell>{sensor ? nextMaintenance : 'Sin datos'}</TableCell>
      <TableCell>
        {engineStatus ? (
          <CheckCircle style={{ color: ' green' }} />
        ) : (
          <CancelOutlined style={{ color: ' red ' }} />
        )}
      </TableCell>
    </TableRow>
  );
};

export default MmtoLockoutGpsRow;
