import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination } from '@material-ui/core';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import CarPositionRow from './CarPositionRow';

const CarPositionTable = ({
  className,
  locations,
  count,
  page,
  handleChangePage,
  loading,
}) => {
  const { t } = useTranslation();
  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.DATE')}</TableCell>
      <TableCell>{t('COMMON.DRIVER')}</TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        {t('COMMON.POSITION')}
      </TableCell>
    </TableRow>
  ));
  const tbody = useMemo(
    () => () => {
      return locations.map(car => (
        <CarPositionRow {...car} key={car.createdAt} />
      ));
    },
    [locations],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, locations],
  );

  return (
    <Table
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
      className={className}
    />
  );
};

CarPositionTable.propTypes = {
  className: PropTypes.string,
  driverId: PropTypes.string,
  driverName: PropTypes.string,
  locations: PropTypes.array.isRequired,
  handleChangePage: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  loading: PropTypes.bool,
};
export default CarPositionTable;
