import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AgentsRow from './AgentsRow';

const AgentsTable = ({ className, agents, ...rest }) => {
  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>Nombre de agencia</TableCell>
      <TableCell>Ciudad</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return agents.map(agent => <AgentsRow {...agent} key={agent.taxId} />);
    },
    [agents],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

AgentsTable.propTypes = {
  className: PropTypes.string,
  agents: PropTypes.array.isRequired,
};

export default AgentsTable;
