import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import * as Actions from 'src/store/actions/fineActions';
import {
  selectFineList,
  selectFineListCount,
  selectFineQueryOptions,
} from 'src/store/selectors/fineSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { useTranslation } from 'react-i18next';
import FineTable from './FineTable';

const FineList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fines = useSelector(selectFineList);
  const count = useSelector(selectFineListCount);
  const { page } = useSelector(selectFineQueryOptions);
  const loading = useSelector(isLoadingSelector([Actions.LOAD_FINES.action]));
  const success = useSelector(successSelector([Actions.LOAD_FINES.action]));

  const handleChangePage = useCallback(
    (_event, value) => {
      dispatch(Actions.loadFines({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(
        Actions.loadFines({
          page,
          sortOrder: order,
          sortProperty: property,
        }),
      );
    },
    [dispatch],
  );

  if (success && !loading && fines.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.FINE_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.FINE_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <FineTable
      fines={fines}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
      handleSortChange={handleSortChange}
    />
  );
};

export default FineList;
