const distanceInMeters = (lat1, lon1, lat2, lon2) => {
  const radius = x => {
    return (x * Math.PI) / 180;
  };

  const earthRadius = 6378.137;
  const dLat = radius(lat2 - lat1);
  const dLong = radius(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(radius(lat1)) *
      Math.cos(radius(lat2)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const resultInMeters = earthRadius * c * 1000;

  return resultInMeters;
};
export default distanceInMeters;
