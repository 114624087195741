import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDriversList,
  selectDriversListCount,
  selectDriversQueryOptions,
} from 'src/store/selectors/driverSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import * as Actions from 'src/store/actions/driverActions';
import NotFoundRecords from 'src/components/NotFoundRecords';
import { Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DriverTable from './DriverTable';

const DriverList = ({ driverStatus, handleOpenConfirmation }) => {
  const dispatch = useDispatch();
  const drivers = useSelector(selectDriversList);
  const count = useSelector(selectDriversListCount);
  const { page } = useSelector(selectDriversQueryOptions);
  const loading = useSelector(isLoadingSelector([Actions.LOAD_DRIVERS.action]));
  const success = useSelector(successSelector([Actions.LOAD_DRIVERS.action]));
  const { t } = useTranslation();

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(Actions.loadDrivers({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(Actions.loadDrivers({ sort: { property, order } }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.DRIVERS_LIST_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.DRIVERS_LIST_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <DriverTable
      drivers={drivers}
      count={count}
      page={page}
      driverStatus={driverStatus}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
      handleOpenConfirmation={handleOpenConfirmation}
    />
  );
};

DriverList.propTypes = {
  driverStatus: PropTypes.string,
  handleOpenConfirmation: PropTypes.func,
};

export default DriverList;
