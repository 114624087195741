import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    borderRadius: '4px',
    padding: theme.spacing(1),
    minWidth: '40%',
    margin: '0 auto',
    marginBottom: theme.spacing(1.25),
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: 11,
    },
  },
  root: {
    minWidth: '95vw',
    minHeight: '95vh',
    backgroundColor: '#00000061',
  },
  outlined: {
    borderColor: '#FFFFFF',
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: '#F1F1F4',
      opacity: 0.8,
    },
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
    '&:focus': {
      borderColor: '#FFFFFF',
      background: theme.palette.primary.dark,
      opacity: 0.8,
    },
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    '& h2': {
      fontWeight: 400,
    },
  },

  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  icon: {
    width: '75%',
    height: '75%',
  },
  closeIcon: {
    float: 'right',
    marginTop: '0px',
    width: '20px',
    height: '20px',
    color: '#FFFFFF',
  },
  dialogContent: {
    marginBottom: '16px',
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: {
    display: 'flex',
  },
}));
