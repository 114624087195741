import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import DetailRow from 'src/components/DetailRow';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import PropTypes from 'prop-types';
import useStyles from './styles';
import DriverResidualSubMenu from './DriverResidualSubMenu';

const DriverResidual = ({ residualAmount }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card variant="elevation" className={classes.cardContainer}>
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('DRIVER_DETAIL_VIEW.RESIDUAL_PAYMENT')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent>
        <Table>
          <TableBody>
            <DetailRow
              name="COMMON.AMOUNT"
              value={formatPositiveAmount(residualAmount)}
            >
              <DriverResidualSubMenu
                hasResidualAmount={Boolean(!residualAmount)}
              />
            </DetailRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

DriverResidual.propTypes = {
  residualAmount: PropTypes.number,
};

export default DriverResidual;
