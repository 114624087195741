import * as Yup from 'yup';

export default Yup.object().shape({
  estado: Yup.string()
    .oneOf(
      ['Monterrey', 'Saltillo', 'Torreón'],
      'SCHEMA_ERRORS.SELECT_VALUE_LIST',
    )
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  plates: Yup.string().length(7, 'SCHEMA_ERRORS.ENTER_SEVEN_NUMBER'),
  tarjeta: Yup.mixed(),
});
