/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = business => {
  return {
    id: business.id,
    socialReason: business.socialReason,
    commercialName: business.commercialName,
    activity: business.activity,
    address: business.address,
  };
};

const GeneralBusinessDialog = ({ open, business, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(business), [business]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-driver"
      >
        {!confirmationOpen && (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography className={classes.header} color="primary" variant="h2">
          {t('BUSINESS.BUSINESS_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-business-dialog"
                  >
                    {t('BUSINESS.UPDATE_BUSINESS_DATA')}
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.socialReason && errors.socialReason)}
                >
                  <TextField
                    error={Boolean(touched.socialReason && errors.socialReason)}
                    fullWidth
                    helperText={touched.socialReason && t(errors.socialReason)}
                    label={t('BUSINESS.SOCIAL_REASON')}
                    margin="none"
                    name="socialReason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.socialReason}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(
                    touched.commercialName && errors.commercialName,
                  )}
                >
                  <TextField
                    error={Boolean(
                      touched.commercialName && errors.commercialName,
                    )}
                    fullWidth
                    helperText={
                      touched.commercialName && t(errors.commercialName)
                    }
                    label={t('BUSINESS.COMERCIAL_NAME')}
                    margin="none"
                    name="commercialName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.commercialName}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.activity && errors.activity)}
                >
                  <TextField
                    error={Boolean(touched.activity && errors.activity)}
                    fullWidth
                    helperText={touched.activity && t(errors.activity)}
                    label={t('BUSINESS.TWIST')}
                    margin="none"
                    name="activity"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.activity}
                    variant="outlined"
                  />
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.address && errors.address)}
                >
                  <TextField
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && t(errors.address)}
                    label={t('COMMON.ADDRESS')}
                    margin="none"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.address}
                    variant="outlined"
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.UPDATE')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

GeneralBusinessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  business: PropTypes.object,
};

export default GeneralBusinessDialog;
