import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import BillingParametersRow from './BillingParametersRow';

const BillingParametersTable = ({ className, parameters, ...rest }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>{t('COMMON.DESCRIPTION')}</TableCell>
      <TableCell>{t('COMMON.VALUE')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return parameters.map(parameter => (
        <BillingParametersRow {...parameter} key={parameter.id} />
      ));
    },
    [parameters],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

BillingParametersTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.array.isRequired,
};

export default BillingParametersTable;
