import {
  downloadExportedInsurances,
  getAllInsurances,
  editInsurance,
  assignInsurance,
  deleteInsurance,
  getUploadLink,
  uploadFile,
} from 'src/services/insuranceService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectInsuranceQueryOptions } from '../selectors/insuranceSelectors';
import { showMessage } from './snackBarActions';
import { loadCarDetail } from './carActions';

export const SAVE_INSURANCE_OPTIONS = 'SAVE_INSURANCE_OPTIONS';
export const CLEAR_INSURANCE_OPTIONS = 'CLEAR_INSURANCE_OPTIONS';
export const LOAD_INSURANCES = asyncActionCreator('LOAD_INSURANCES');
export const CLEAR_INSURANCES = 'CLEAR_INSURANCES';
export const UPDATE_INSURANCE = asyncActionCreator('UPDATE_INSURANCE');
export const DELETE_INSURANCE = asyncActionCreator('DELETE_INSURANCE');

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectInsuranceQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.carniv) {
    reqOptions.carniv = queryOpts.carniv;
  }
  if (queryOpts.policyType) {
    reqOptions.policyType = queryOpts.policyType;
  }
  if (queryOpts.remainingDays) {
    reqOptions.remainingDays = queryOpts.remainingDays;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_INSURANCE_OPTIONS });
};

const uploadSingleFile = async file => {
  if (!file) return Promise.reject(new Error('No file'));
  const { type } = file;
  const extension = file.name.split('.').pop();
  if (!extension) {
    throw new Error('No extension :(');
  }
  const link = await getUploadLink({ format: extension, contentType: type });
  const url = new URL(link);
  const attachmentId = url.pathname.slice(1);
  await uploadFile({ targetURL: link, file });
  return `https://meteoros-files.s3.us-east-2.amazonaws.com/${attachmentId}`;
};

export const loadInsurances = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_INSURANCES.start });

    dispatch({
      type: SAVE_INSURANCE_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getAllInsurances(reqOptions);

    return dispatch({
      type: LOAD_INSURANCES.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INSURANCES.failure });
  }
};

export const updateInsurance = (niv, id, values) => async dispatch => {
  try {
    dispatch({ type: UPDATE_INSURANCE.start });
    let { policyPDF } = values;

    if (policyPDF && policyPDF.inDb) {
      policyPDF = '';
    }

    if (policyPDF) {
      policyPDF = await uploadSingleFile(policyPDF);
    }

    const data = await editInsurance({
      id,
      values: { ...values, policyPDF },
    });

    dispatch(
      showMessage({
        message: 'Seguro actualizado correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadCarDetail(niv));
    return dispatch({
      type: UPDATE_INSURANCE.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_INSURANCE.failure });
  }
};

export const unassignInsurance = (niv, policyId) => async dispatch => {
  try {
    dispatch({ type: DELETE_INSURANCE.start });
    const data = await deleteInsurance(policyId);

    dispatch(
      showMessage({
        message: 'Seguro desasignado correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadCarDetail(niv));
    return dispatch({
      type: DELETE_INSURANCE.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_INSURANCE.failure });
  }
};

export const createInsurance = (niv, values) => async dispatch => {
  try {
    dispatch({ type: UPDATE_INSURANCE.start });
    let { policyPDF } = values;

    if (policyPDF && policyPDF.inDb) {
      policyPDF = '';
    }

    if (policyPDF) {
      policyPDF = await uploadSingleFile(policyPDF);
    }

    const data = await assignInsurance({
      niv,
      values: { ...values, policyPDF },
    });

    dispatch(
      showMessage({
        message: 'Seguro asignado correctamente',
        variant: 'success',
      }),
    );
    dispatch(loadCarDetail(niv));
    return dispatch({
      type: UPDATE_INSURANCE.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_INSURANCE.failure });
  }
};

export const downloadExcelInsurances = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    downloadExportedInsurances(options);
  } catch (error) {
    console.log(dispatch);
    console.error(error);
  }
};
