import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import GeofencingMap from 'src/components/GeofencingMap/GeofencingMap';
import Preloader from 'src/components/Preloader';
import { FormHelperText } from '@material-ui/core';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepTwo = ({ data, handleBack, onSubmit, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOutherSubmit = useCallback((values, event) => {
    onSubmit(values, event);
  });
  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={handleOutherSubmit}
    >
      {({ errors, setFieldValue, touched, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            {!loading ? (
              <Box
                className={classes.box}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box style={{ width: '100%', height: '50vh' }}>
                  <GeofencingMap
                    data={initialState}
                    handleRadiusChange={value => setFieldValue('radius', value)}
                    handleCenterChange={value => setFieldValue('center', value)}
                  />
                </Box>
                {Boolean(touched.radius && errors.radius) && (
                  <FormHelperText style={{ textAlign: 'center' }} error>
                    {t(errors.radius)}
                  </FormHelperText>
                )}

                <Box display="flex" justifyContent="space-between">
                  <Button
                    className={classes.buttons}
                    onClick={handleBack}
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.BACK')}
                  </Button>
                  <Button
                    className={clsx(classes.buttons, classes.buttonMain)}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    {t('COMMON.CONFIRMATION')}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Preloader />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};
export default StepTwo;
