import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ExternalLink } from 'react-feather';
import PropTypes from 'prop-types';
import InvoiceSubMenu from './InvoiceSubMenu';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'white',
    color: 'white',
  },
}));

const initialState = {
  physicalSignedPromNote: null,
  digitalSignedPromNote: null,
  contract: null,
};

const InvoiceLease = ({ driver }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [
    { physicalSignedPromNote, contract, digitalSignedPromNote },
    setInvoiceData,
  ] = useState(initialState);

  useEffect(() => {
    if (driver.actualContract) {
      if (driver.actualContract.promissoryNote) {
        const {
          physicalSignedPdf,
          virtualSignedPdf,
        } = driver.actualContract.promissoryNote;
        return setInvoiceData({
          ...initialState,
          physicalSignedPromNote: physicalSignedPdf,
          digitalSignedPromNote: virtualSignedPdf,
          contract: driver.actualContract.contractPdf,
        });
      }
    }
    if (driver.actualDriverLease) {
      return setInvoiceData({
        physicalSignedPdf: driver.actualDriverLease.promissoryNote,
        contract: driver.actualDriverLease.contract,
      });
    }
    return setInvoiceData(initialState);
  }, [driver]);

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography component="h5" variant="h5" color="primary">
            {t('DRIVER_DETAIL_VIEW.LEASE.INVOICES')}
          </Typography>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableBody>
            {driver.actualDriverLease || driver.actualContract ? (
              <>
                {driver.actualDriverLease && (
                  <>
                    <TableRow>
                      <TableCell style={{ fontWeight: 500, width: '40%' }}>
                        {t('DRIVER_DETAIL_VIEW.LEASE.INVOICES_DOWNLOAD_XML')}
                      </TableCell>
                      <TableCell>
                        <Typography
                          component="div"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          variant="body2"
                          color="textSecondary"
                        >
                          {t('DRIVER_DETAIL_VIEW.LEASE.VIEW_INVOICE')}

                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={driver.actualDriverLease.hitchInvoiceXML}
                          >
                            <ExternalLink
                              size={20}
                              style={{
                                marginLeft: '8px',
                                cursor: 'pointer',
                                color: '#7cb342',
                              }}
                            />
                          </a>
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: 500, width: '40%' }}>
                        {t('DRIVER_DETAIL_VIEW.LEASE.INVOICES_DOWNLOAD_PDF')}
                      </TableCell>
                      <TableCell style={{ width: '50%' }}>
                        <Typography
                          component="div"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          variant="body2"
                          color="textSecondary"
                        >
                          {t('DRIVER_DETAIL_VIEW.LEASE.VIEW_INVOICE')}
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={driver.actualDriverLease.hitchInvoicePDF}
                          >
                            <ExternalLink
                              size={20}
                              style={{
                                marginLeft: '8px',
                                cursor: 'pointer',
                                color: '#7cb342',
                              }}
                            />
                          </a>
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </>
                )}

                <TableRow>
                  <TableCell style={{ fontWeight: 500, width: '40%' }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.CONTRACT')}
                  </TableCell>
                  <TableCell style={{ width: '50%' }}>
                    <Typography
                      component="div"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {t('DRIVER_DETAIL_VIEW.LEASE.VIEW_CONTRACT')}
                      <a rel="noreferrer" target="_blank" href={contract}>
                        <ExternalLink
                          size={20}
                          style={{
                            marginLeft: '8px',
                            cursor: 'pointer',
                            color: '#7cb342',
                          }}
                        />
                      </a>
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>

                {driver.actualContract && (
                  <TableRow>
                    <TableCell style={{ fontWeight: 500, width: '40%' }}>
                      {t('DRIVER_DETAIL_VIEW.LEASE.PROMISSORY_NOTE_VIRTUAL')}
                    </TableCell>
                    <TableCell style={{ width: '50%' }}>
                      <Typography
                        component="div"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        {t(
                          'DRIVER_DETAIL_VIEW.LEASE.VIEW_PROMISSORY_NOTE_VIRTUAL',
                        )}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={digitalSignedPromNote}
                        >
                          <ExternalLink
                            size={20}
                            style={{
                              marginLeft: '8px',
                              cursor: 'pointer',
                              color: '#7cb342',
                            }}
                          />
                        </a>
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}

                <TableRow>
                  <TableCell style={{ fontWeight: 500, width: '40%' }}>
                    {t('DRIVER_DETAIL_VIEW.LEASE.PROMISSORY_NOTE')}
                  </TableCell>
                  <TableCell style={{ width: '50%' }}>
                    {physicalSignedPromNote ? (
                      <Typography
                        component="div"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        {t('DRIVER_DETAIL_VIEW.LEASE.VIEW_PROMISSORY_NOTE')}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={physicalSignedPromNote}
                        >
                          <ExternalLink
                            size={20}
                            style={{
                              marginLeft: '8px',
                              cursor: 'pointer',
                              color: '#7cb342',
                            }}
                          />
                        </a>
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {t('COMMON.NO_DATA')}
                      </Typography>
                    )}
                  </TableCell>
                  {driver.actualContract && (
                    <TableCell style={{ width: '50px' }}>
                      <InvoiceSubMenu driver={driver} />
                    </TableCell>
                  )}
                </TableRow>
              </>
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

InvoiceLease.propTypes = {
  driver: PropTypes.object,
};

export default InvoiceLease;
