import { asyncActionCreator } from 'src/utils/loadingUtils';
import { TransactionCategory } from 'src/utils/constants';
import getTransactions, {
  downloadExportedTransactions,
} from '../../services/transactionsService';
import { selectTransactionsQueryOptions } from '../selectors/transactionsSelectors';
import { showMessage } from './snackBarActions';

export const SAVE_TRANSACTIONS_TABLE_OPTIONS =
  'SAVE_TRANSACTIONS_TABLE_OPTIONS';
export const CLEAR_TRANSACTIONS_TABLE_OPTIONS =
  'CLEAR_TRANSACTIONS_TABLE_OPTIONS';
export const LOAD_TRANSACTIONS_TABLE = asyncActionCreator(
  'LOAD_TRANSACTIONS_TABLE',
);
export const CLEAR_TRANSACTIONS_TABLE = 'CLEAR_TRANSACTIONS_TABLE';
export const LOAD_RESIDUAL_TRANSACTIONS = asyncActionCreator(
  'LOAD_RESIDUAL_TRANSACTIONS',
);

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectTransactionsQueryOptions(getState());

  console.log(queryOpts);
  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.startDate) {
    reqOptions.startDate = new Date(queryOpts.startDate);
  }
  if (queryOpts.endDate) {
    reqOptions.endDate = new Date(queryOpts.endDate);
  }
  if (queryOpts.carniv) {
    reqOptions.carniv = queryOpts.carniv;
  }
  if (queryOpts.driverId) {
    reqOptions.driverId = queryOpts.driverId;
  }
  if (queryOpts.category) {
    reqOptions.category = queryOpts.category;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_TRANSACTIONS_TABLE_OPTIONS });
};

export const loadTransactions = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_TRANSACTIONS_TABLE.start });
    dispatch({
      type: SAVE_TRANSACTIONS_TABLE_OPTIONS,
      options,
    });

    const reqOptions = getOptions(getState);

    const data = await getTransactions(reqOptions);

    return dispatch({
      type: LOAD_TRANSACTIONS_TABLE.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_TRANSACTIONS_TABLE.failure });
  }
};

export const downloadExcelTransactions = () => async (dispatch, getState) => {
  try {
    const options = getOptions(getState, false);
    await downloadExportedTransactions(options);
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};

export const loadResidualTransactions = (data, setState = () => {}) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOAD_RESIDUAL_TRANSACTIONS.start });
    dispatch({
      type: SAVE_TRANSACTIONS_TABLE_OPTIONS,
      options: { ...data, category: TransactionCategory.RESIDUAL },
    });

    const reqOptions = getOptions(getState, false);

    const { items: residualTransactionList } = await getTransactions(
      reqOptions,
    );
    setState(residualTransactionList);

    dispatch(clearOptions());

    return dispatch({
      type: LOAD_RESIDUAL_TRANSACTIONS.success,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_RESIDUAL_TRANSACTIONS.failure });
  }
};
