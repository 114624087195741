import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloudUpload from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 3,
    marginBottom: theme.spacing(),
  },
  uploadContainerLabel: {
    marginTop: theme.spacing(),
    fontSize: '14px',
  },
  label: {
    borderColor: '#bdbdbd',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
  filename: {
    flex: 1,
    paddingLeft: theme.spacing(),
  },
  uploadButton: {
    color: theme.palette.primary.main,
  },
  deleteButton: {
    color: '#EE3552',
  },
}));

const UploadFile = ({
  handleUploadChange,
  setFieldValue,
  values,
  touched,
  errors,
  textLabel,
  valueLabel,
  accept,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleOpenCurrentCard = useCallback(
    value => {
      if (value && value.inDb) {
        window.open(value.link, '_blank');
      }
    },
    [values],
  );

  return (
    <>
      <Typography
        className={classes.uploadContainerLabel}
        variant="body2"
        style={{ marginBottom: 10 }}
      >
        {textLabel}
      </Typography>
      <div className={classes.uploadContainer}>
        <div>
          <Typography
            className={classes.filename}
            component="label"
            variant="body1"
            htmlFor={`icon-button-file-${valueLabel}`}
            onClick={() => handleOpenCurrentCard(values[valueLabel])}
          >
            {values[valueLabel] && t(values[valueLabel].name)}
          </Typography>

          <label
            className={classes.uploadButton}
            htmlFor={`icon-button-file-${valueLabel}`}
          >
            <input
              style={{ width: 0, height: 0 }}
              name={valueLabel}
              type="file"
              accept={accept}
              onChange={handleUploadChange(setFieldValue, valueLabel)}
              id={`icon-button-file-${valueLabel}`}
            />
            <IconButton aria-label={`upload ${valueLabel}`} component="span">
              <CloudUpload className={classes.uploadButton} />
              {!values[valueLabel] && (
                <Typography
                  className={classes.filename}
                  component="label"
                  variant="body1"
                  htmlFor={`icon-button-file-${valueLabel}`}
                >
                  {t('COMMON.SELECT_FILE')}
                </Typography>
              )}
            </IconButton>
          </label>
        </div>
        {values[valueLabel] && (
          <IconButton
            onClick={() => setFieldValue(valueLabel, null)}
            aria-label="upload pdf"
            component="span"
          >
            <DeleteIcon className={classes.deleteButton} />
          </IconButton>
        )}
      </div>
      {Boolean(touched[valueLabel] && errors[valueLabel]) && (
        <FormHelperText error>{t(errors[valueLabel])}</FormHelperText>
      )}
    </>
  );
};

UploadFile.propTypes = {
  accept: PropTypes.string,
  valueLabel: PropTypes.string,
  textLabel: PropTypes.string,
  handleUploadChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.shape({ name: PropTypes.string })),
  touched: PropTypes.objectOf(PropTypes.bool),
  errors: PropTypes.objectOf(PropTypes.string),
};

export default UploadFile;
