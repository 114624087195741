import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Proptype from 'prop-types';
import DetailRow from 'src/components/DetailRow';

const AvalInfo = ({ aval }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('COMMON.FULL_NAME')}
        value={aval.fullName || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.PHONE')}
        value={aval.mobile || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.EMAIL')}
        value={aval.email || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.RELANTIONSHIP')}
        value={aval.relationShip || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.DATE_OF_BIRTH')}
        value={aval.age ? moment(aval.age).format('DD-MMM-YY') : ''}
      />

      <DetailRow
        name={t('COMMON.AGE')}
        value={moment().diff(aval.age, 'years') || ''}
      />

      <DetailRow
        name={t('COMMON.BIRTH_STATE')}
        value={aval.homeTown || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.HOME_STATUS')}
        value={aval.houseStatus || t('COMMON.NO_DATA')}
      />

      <DetailRow
        name={t('COMMON.REGISTERED_ADDRESSES')}
        value={aval.ifeLicence || t('COMMON.NO_DATA')}
      />
    </>
  );
};

AvalInfo.propTypes = {
  aval: Proptype.shape({
    fullName: Proptype.string,
    mobile: Proptype.string,
    email: Proptype.string,
    relationShip: Proptype.string,
    age: Proptype.string,
    homeTown: Proptype.string,
    houseStatus: Proptype.string,
    ifeLicence: Proptype.string,
  }).isRequired,
};

export default AvalInfo;
