import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Popover,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/DatePicker';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { CLEAR_FINES_OPTIONS } from 'src/store/actions/fineActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'red',
  },
  buttons: {
    height: 48,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '500',
    minWidth: 'unset',
    paddingBottom: theme.spacing(2),
  },
  horizontalPadding: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  typography: {
    padding: theme.spacing(2),
    fontSize: 14,
    fontWeight: 'bold',
  },
  formLabel: {
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

const makeInitialState = filters => {
  return {
    last30Days: filters.last30Days || false,
    thisMonth: filters.thisMonth || false,
    thisYear: filters.thisYear || false,
    customDateSelector: filters.customDateSelector || false,
    startDate: filters.startDate || new Date(),
    endDate: filters.endDate || new Date(),
  };
};

const validationSchema = Yup.object().shape({
  customDateSelector: Yup.boolean(),
  startDate: Yup.date()
    .nullable()
    .when('customDateSelector', {
      is: true,
      then: Yup.date().required(
        'TRANSACTION.FILTER_SCHEMA_ERROR.DATE_SELECTOR_REQUIRE',
      ),
    })
    .max(
      Yup.ref('endDate'),
      'TRANSACTION.FILTER_SCHEMA_ERROR.START_DATE_SELECTOR_ERROR',
    ),
  endDate: Yup.date()
    .nullable()
    .when('customDateSelector', {
      is: true,
      then: Yup.date().required(
        'TRANSACTION.FILTER_SCHEMA_ERROR.DATE_SELECTOR_REQUIRE',
      ),
    })
    .min(
      Yup.ref('startDate'),
      'TRANSACTION.FILTER_SCHEMA_ERROR.END_DATE_SELECTOR_ERROR',
    ),
});

const TransactionsFilter = ({
  anchorEl,
  handleClose,
  filterValues,
  handleFilterSubmit,
}) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialState = useMemo(() => makeInitialState(filterValues), [
    filterValues,
    anchorEl,
  ]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, anchorEl]);

  const customHandleChange = useCallback(
    (setFieldError, values) => event => {
      const notSelectedValues = Object.keys(values).filter(
        field =>
          field !== event.target.name &&
          field !== 'startDate' &&
          field !== 'endDate',
      );
      setFieldError(event.target.name, true);
      notSelectedValues.forEach(field => {
        setFieldError(field, false);
      });
    },
    [],
  );

  const clearFilters = (values, setFieldValue) => {
    const fields = Object.keys(values);
    fields.forEach(field => {
      if (field === 'startDate' || field === 'endDate') {
        setFieldValue(field, new Date());
      } else {
        setFieldValue(field, false);
      }
    });
    dispatch({ type: CLEAR_FINES_OPTIONS });
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Formik
        initialValues={initialState}
        onSubmit={handleFilterSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, resetForm, errors, setFieldValue }) => {
          resetRef.current = () => resetForm(initialState);

          return (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormGroup className={classes.horizontalPadding}>
                  <FormLabel
                    id="radio-buttons-group-label"
                    className={classes.formLabel}
                  >
                    {t('FINE_VIEW.BY_DATE')}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue="last30Days"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      control={<Radio checked={values.last30Days} />}
                      name="last30Days"
                      value={values.last30Days}
                      onChange={customHandleChange(setFieldValue, values)}
                      label={t('FINE_VIEW.LAST_30_DAYS')}
                    />
                    <FormControlLabel
                      control={<Radio checked={values.thisMonth} />}
                      name="thisMonth"
                      value={values.thisMonth}
                      onChange={customHandleChange(setFieldValue, values)}
                      label={t('FINE_VIEW.THIS_MONTH')}
                    />
                    <FormControlLabel
                      control={<Radio checked={values.thisYear} />}
                      name="thisYear"
                      value={values.thisYear}
                      onChange={customHandleChange(setFieldValue, values)}
                      label={t('FINE_VIEW.THIS_YEAR')}
                    />
                    <FormControlLabel
                      control={<Radio checked={values.customDateSelector} />}
                      name="customDateSelector"
                      value={values.customDateSelector}
                      onChange={customHandleChange(setFieldValue, values)}
                      label={t('FINE_VIEW.CUSTOM_DATE')}
                    />
                    {values.customDateSelector && (
                      <>
                        <DatePicker
                          field={{
                            name: 'startDate',
                            value: values.startDate,
                          }}
                          form={{
                            errors,
                            setFieldValue,
                          }}
                          label={t('FINE_VIEW.START_DATE')}
                          disablePast={false}
                        />
                        <DatePicker
                          field={{
                            name: 'endDate',
                            value: values.endDate,
                          }}
                          form={{
                            errors,
                            setFieldValue,
                          }}
                          label={t('FINE_VIEW.END_DATE')}
                          disablePast={false}
                        />
                      </>
                    )}
                    <br />
                    <Button
                      className={clsx(
                        classes.buttons,
                        classes.typography,
                        classes.horizontalPadding,
                      )}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {t('FINE_VIEW.APPLY')}
                    </Button>
                    <br />
                    <Button
                      className={clsx(
                        classes.buttons,
                        classes.typography,
                        classes.horizontalPadding,
                      )}
                      color="primary"
                      variant="contained"
                      onClick={() => clearFilters(values, setFieldValue)}
                    >
                      {t('FINE_VIEW.CLEAR')}
                    </Button>
                  </RadioGroup>
                </FormGroup>
              </FormControl>
            </form>
          );
        }}
      </Formik>
    </Popover>
  );
};

TransactionsFilter.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  filterValues: PropTypes.any,
};

export default TransactionsFilter;
