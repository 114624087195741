import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LocationRow from './LocationRow';

const LocationTable = ({
  className,
  locations,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Nombre</TableCell>
      <TableCell>Región</TableCell>
      <TableCell>Acciones</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return locations.map(location => (
        <LocationRow
          {...location}
          key={location.id}
          openEdit={openEdit(location)}
          handleDelete={handleDelete}
        />
      ));
    },
    [locations],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

LocationTable.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default LocationTable;
