import React, { useMemo } from 'react';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from 'src/components/Table/TableCell';
import { Link } from 'react-router-dom';
import { formatPositiveAmount } from 'src/utils/formatAmount';

const ExcedentMileageRow = ({
  niv,
  plates,
  driver,
  kilometersExcedents,
  distanceTraveled,
  remainingKilometers,
  excedentMileageCharge,
  device,
  mileage,
}) => {
  const client = useMemo(
    () =>
      driver
        ? `${driver.name} ${driver.paternalName} ${driver.maternalName}`
        : '',
    [driver],
  );
  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/fleet/car/${niv}`}>{niv}</Link>
      </TableCell>
      <TableCell>{plates || '-'}</TableCell>
      <TableCell>
        <Link to={`/drivers/detail/${driver?.id}`}>{client}</Link>
      </TableCell>
      <TableCell>{device}</TableCell>
      <TableCell>{mileage || 0}</TableCell>
      <TableCell>{distanceTraveled}</TableCell>
      <TableCell>{remainingKilometers}</TableCell>
      <TableCell>{kilometersExcedents}</TableCell>
      <TableCell>{`$${formatPositiveAmount(excedentMileageCharge)}`}</TableCell>
    </TableRow>
  );
};

ExcedentMileageRow.propTypes = {
  niv: PropTypes.string.isRequired,
  plates: PropTypes.string.isRequired,
  driver: PropTypes.object.isRequired,
  kilometersExcedents: PropTypes.number.isRequired,
  distanceTraveled: PropTypes.number.isRequired,
  remainingKilometers: PropTypes.number.isRequired,
  excedentMileageCharge: PropTypes.number.isRequired,
  device: PropTypes.string,
  mileage: PropTypes.number,
};

export default ExcedentMileageRow;
