import React from 'react';
import DetailRow from 'src/components/DetailRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DocumentLink } from '../DriverDocuments/DocumentsInfo';

const DocumentsInfo = ({ aval }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('COMMON.IFE_AVAL')}
        value={<DocumentLink name={t('COMMON.IFE_AVAL')} link={aval.ifeFile} />}
      />

      <DetailRow
        name={t('COMMON.WATER_BILL')}
        value={
          <DocumentLink name={t('COMMON.WATER_BILL')} link={aval.waterFile} />
        }
      />
    </>
  );
};

DocumentsInfo.propTypes = {
  aval: PropTypes.shape({
    ifeFile: PropTypes.string,
    waterFile: PropTypes.string,
  }).isRequired,
};

export default DocumentsInfo;
