import React, { useCallback, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectDriversQueryOptions } from 'src/store/selectors/driverSelectors';
import { DriverStatus, DriverStatusAction } from 'src/utils/constants';
import { updateDriverStatusAction } from 'src/store/actions/driverActions';
import Toolbar from './Toolbar';
import DriverListTabs from './DriverListTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const selectDriverStatus = action => {
  switch (action) {
    case DriverStatusAction.ACTIVATE_DRIVER:
      return DriverStatus.ACTIVE;
    case DriverStatusAction.REJECT_DRIVER:
      return DriverStatus.REJECTED;
    default:
      return '';
  }
};

const DriversListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = useSelector(selectDriversQueryOptions);
  const [openConfirmation, setOpenConfirmation] = useState('');
  const [driverId, setDriverId] = useState('');
  const dispatch = useDispatch();

  const handleOpenConfirmation = useCallback(
    (action, id) => {
      setOpenConfirmation(action);
      setDriverId(id);
    },
    [setOpenConfirmation],
  );

  const handleCloseConfirmation = useCallback(() => {
    setOpenConfirmation('');
  }, [setOpenConfirmation]);

  const handleSubmitAction = useCallback(() => {
    dispatch(
      updateDriverStatusAction(driverId, {
        status: selectDriverStatus(openConfirmation),
      }),
    );
    handleCloseConfirmation();
  }, [openConfirmation]);

  return (
    <Page className={classes.root} title={t('DRIVERS_VIEW.TITLE')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar
            driverStatus={status || DriverStatus.ACTIVE}
            handleCloseConfirmation={handleCloseConfirmation}
            openConfirmation={openConfirmation}
            handleSubmitAction={handleSubmitAction}
          />
          <Box mt={3}>
            <DriverListTabs handleOpenConfirmation={handleOpenConfirmation} />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default DriversListView;
