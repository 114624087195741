const formatAmount = value =>
  (value || 0).toLocaleString('DE').replaceAll('.', ',');

export const formatAmountWithDecimals = value =>
  (value || 0).toLocaleString('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });

export const formatNegativeAmount = value => {
  return value > 0
    ? `(${formatAmount(value)})`
    : formatAmount(Math.round(value));
};

export const formatPositiveAmount = value => {
  return value < 0
    ? `(${formatAmount(Math.round(value))})`
    : formatAmount(value);
};
