import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import {
  selectFineListCount,
  selectFineQueryOptions,
  selectFinesReport,
} from 'src/store/selectors/fineSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  CLEAR_FINES,
  LOAD_FINES_REPORT,
  loadFinesReport,
} from 'src/store/actions/fineActions';
import { useTranslation } from 'react-i18next';
import FinesReportTable from './FinesReportTable';

const FinesReportList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const reports = useSelector(selectFinesReport);
  const count = useSelector(selectFineListCount);
  const { page } = useSelector(selectFineQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_FINES_REPORT.action]));
  const success = useSelector(successSelector([LOAD_FINES_REPORT.action]));

  useEffect(() => {
    dispatch(loadFinesReport());
    return () => dispatch({ type: CLEAR_FINES });
  }, []);

  const handleChangePage = useCallback(
    (_event, value) => {
      dispatch(loadFinesReport({ page: value }));
    },
    [dispatch],
  );

  if (success && !loading && reports.length === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.FINE_REPORT_TITLE')}
          description={t('NOT_FOUND_RECORDS_MESSAGE.FINE_REPORT_DESCRIPTION')}
        />
      </Card>
    );
  }

  return (
    <FinesReportTable
      reports={reports}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default FinesReportList;
