/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import CampaignsView from './CampaignsListView';

const CampaignsViews = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}`} component={CampaignsView} />
    </>
  );
};

export default CampaignsViews;
