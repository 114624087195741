import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  recurrent: Yup.boolean().required(
    'CAMPAIGNS_VIEW.FORM_SCHEMA.RECURRENT_REQUIRED',
  ),
  discountDays: Yup.number()
    .positive('CAMPAIGNS_VIEW.FORM_SCHEMA.DAYS_POSITIVE')
    .required('CAMPAIGNS_VIEW.FORM_SCHEMA.DAYS_REQUIRED'),
  minDriverLevel: Yup.string().required(
    'CAMPAIGNS_VIEW.FORM_SCHEMA.DRIVER_LEVEL_REQUIRED',
  ),
  description: Yup.string().required(
    'CAMPAIGNS_VIEW.FORM_SCHEMA.DESCRIPTION_REQUIRED',
  ),
  discountDate: Yup.date().required('CAMPAIGNS_VIEW.FORM_SCHEMA.DISCOUNT_DATE'),
  active: Yup.boolean().nullable(),
  type: Yup.string().nullable(),
  id: Yup.number().nullable(),
});

export default validationSchema;
