/* eslint-disable react/prop-types */
import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { File } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(20),
  },
  icon: {
    color: theme.palette.text.secondary,
    opacity: 0.4,
  },
  emptyTitle: {
    margin: `${theme.spacing()}px 0`,
  },
  hintText: {
    width: theme.spacing(36),
    textAlign: 'center',
    margin: `${theme.spacing()}px 0px ${theme.spacing(3)}px 0px`,
  },
}));

const WithoutLease = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.root}>
        <File height={160} width={160} className={classes.icon} />
        <Typography
          className={classes.emptyTitle}
          variant="h3"
          color="textSecondary"
        >
          {t('DRIVER_DETAIL_VIEW.LEASE.NO_CONTRACT')}
        </Typography>
        <Typography
          className={classes.hintText}
          variant="body1"
          color="textSecondary"
        >
          {t('DRIVER_DETAIL_VIEW.LEASE.NO_CONTRACT_TEXT')}
        </Typography>
      </Box>
    </>
  );
};

export default WithoutLease;
