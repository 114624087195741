import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectRentResumeOptions } from 'src/store/selectors/rentResumeSelectors';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { useTranslation } from 'react-i18next';
import {
  clearExpense,
  createExpense,
  downloadExcelExpense,
  loadExpense,
} from 'src/store/actions/expenseActions';
import ExpenseForm from './ExpenseForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 600,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const Toolbar = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectRentResumeOptions);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const debounceSearch = useDebounce(
    (value, queryOpts) =>
      dispatch(loadExpense({ ...queryOpts, driverName: value })),
    400,
  );

  useEffect(() => {
    dispatch(loadExpense());
    return () => dispatch(clearExpense());
  }, []); // not add dependency

  const handleCreateExpense = useCallback(
    values => {
      dispatch(createExpense(values, () => handleClose()));
    },
    [dispatch, handleClose],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelExpense());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <>
      <div className={classes.root} {...props}>
        <Breadcrumb
          history={['NAVIGATION.ADMIN']}
          current="NAVIGATION.EXPENSE"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.EXPENSE')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Paper
              elevation={0}
              className={classes.search}
              style={{ marginLeft: 0 }}
            >
              <Input
                classes={{ input: classes.input }}
                startAdornment={Addornment}
                placeholder={t('DRIVERS_VIEW.SEARCH_BY_NAME')}
                className="flex mx-8"
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                type="text"
                onChange={handleSearch}
              />
            </Paper>

            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>

            <Button
              className={clsx(classes.buttons, classes.horizontalPadding)}
              color="primary"
              variant="contained"
              onClick={handleOpen}
            >
              <PlusIcon style={{ marginRight: 8 }} fill="white" />
              Crear gasto
            </Button>
          </div>
        </div>
      </div>

      <ExpenseForm
        open={open}
        handleSubmit={handleCreateExpense}
        handleClose={handleClose}
      />
    </>
  );
};

export default Toolbar;
