/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Field, Formik } from 'formik';
import { DialogContentText, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UploadFile from 'src/components/UploadFile';
import { DatePicker } from '@material-ui/pickers';
import { InsertInvitation } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DatePickerField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];
  const { t } = useTranslation();

  return (
    <DatePicker
      className={classes.datePicker}
      fullWidth
      name={field.name}
      value={field.value}
      format="DD-MMM-YY"
      placeholder={t('COMMON.SELECT_DATE')}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={error => {
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      onChange={date => form.setFieldValue(field.name, date, true)}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <IconButton aria-label="calendar">
            <InsertInvitation />
          </IconButton>
        ),
      }}
      {...other}
    />
  );
};

const makeInitialState = driver => {
  let aval;
  if (driver.aval) {
    aval = {
      ifeAval: driver.aval.ifeFile
        ? { name: 'Ver actual IFE', link: driver.aval.ifeFile, inDb: true }
        : null,
      waterAval: driver.aval.waterFile
        ? {
            name: 'Ver actual boleta de agua',
            link: driver.aval.waterFile,
            inDb: true,
          }
        : null,
    };
  }
  return {
    ifeFile: driver.ifeFile
      ? { name: 'Ver actual IFE', link: driver.ifeFile, inDb: true }
      : null,
    licenceFile: driver.licenceFile
      ? { name: 'Ver actual licencia', link: driver.licenceFile, inDb: true }
      : null,
    waterFile: driver.waterFile
      ? {
          name: 'Ver actual boleta de agua',
          link: driver.waterFile,
          inDb: true,
        }
      : null,
    licenceExpirationAt: driver.licenceExpirationAt || null,
    pictureFile: driver.pictureFile
      ? { name: 'Ver actual foto', link: driver.pictureFile, inDb: true }
      : null,
    ...aval,
  };
};

const DriverDocumentDialog = ({ open, driver, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(driver), [driver]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  const handleUploadChange = useCallback(
    setFieldValue => e => {
      if (e.target.files[0]) {
        setFieldValue(e.target.name, e.target.files[0]);
      }
    },
    [],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-driver"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          Documentos de conductor
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    Al hacer click en&nbsp;
                    <b>Aceptar</b>, acepta que los datos actuales del conductor
                    serán&nbsp;
                    <b>reemplazados.</b>
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        Aceptar
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept="image/*"
                  valueLabel="ifeFile"
                  textLabel="Sube el IFE"
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept="image/*"
                  valueLabel="licenceFile"
                  textLabel="Sube la Licencia"
                />

                <Typography className={classes.datePickerLabel} variant="body1">
                  {t('DRIVERS_VIEW.LICENCE_EXPIRATION_DATE')}
                </Typography>
                <Field name="licenceExpirationAt" component={DatePickerField} />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept="image/*"
                  valueLabel="waterFile"
                  textLabel="Sube la Boleta de Agua"
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept="image/*"
                  valueLabel="pictureFile"
                  textLabel="Sube la Foto de Plataforma"
                />

                {driver.aval && (
                  <>
                    <UploadFile
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleUploadChange={handleUploadChange}
                      setFieldValue={setFieldValue}
                      accept="image/*"
                      valueLabel="ifeAval"
                      textLabel="Sube el IFE del Aval"
                    />
                    <UploadFile
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleUploadChange={handleUploadChange}
                      setFieldValue={setFieldValue}
                      accept="image/*"
                      valueLabel="waterAval"
                      textLabel="Sube el IFE del Aval"
                    />
                  </>
                )}

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      Actualizar Documentos
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DriverDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  driver: PropTypes.object,
};

export default DriverDocumentDialog;
