import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Button } from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import useDebounce from 'src/hooks/useDebounce';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';

import { selectTransactionsQueryOptions } from 'src/store/selectors/transactionsSelectors';
import {
  CLEAR_TRANSACTIONS_TABLE,
  downloadExcelTransactions,
  loadTransactions,
} from 'src/store/actions/transactionsActions';
import moment from 'moment';
import TransactionsFilter from './TransactionsFilter';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: 430,
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

function getFilterOptions(options) {
  const filterOptions = {};
  if (options.last30Days) {
    filterOptions.startDate = moment()
      .subtract(30, 'days')
      .toDate();
    filterOptions.endDate = moment().toDate();
  }

  if (options.thisMonth) {
    filterOptions.startDate = moment()
      .startOf('month')
      .toDate();
    filterOptions.endDate = moment().toDate();
  }

  if (options.thisYear) {
    filterOptions.startDate = moment()
      .startOf('year')
      .toDate();
    filterOptions.endDate = moment().toDate();
  }

  if (options.customDateSelector) {
    filterOptions.startDate = options.startDate;
    filterOptions.endDate = options.endDate;
  }

  return filterOptions;
}

const TransactionsToolbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryOptions = useSelector(selectTransactionsQueryOptions);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({});

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const debounceSearch = useDebounce(queryOpts => {
    dispatch(loadTransactions({ ...queryOpts }));
    handleCloseFilter();
  }, 400);

  useEffect(() => {
    dispatch(loadTransactions());
    return () => dispatch({ type: CLEAR_TRANSACTIONS_TABLE });
  }, []);

  const handleExcelExport = useCallback(() => {
    const options = getFilterOptions(filterValues);
    dispatch(
      downloadExcelTransactions({ ...queryOptions, page: 0, ...options }),
    );
  }, [dispatch]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [debounceSearch, queryOptions, getFilterOptions],
  );

  return (
    <div className={classes.root}>
      <Breadcrumb
        history={['NAVIGATION.ADMIN']}
        current="COMMON.TRANSACTIONS"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontWeight: '400', textTransform: 'capitalize' }}
          color="primary"
          variant="h1"
        >
          {t('COMMON.TRANSACTIONS')}
        </Typography>

        <div className={classes.actionsContainer}>
          <Button
            className={clsx(
              classes.buttons,
              classes.outlined,
              classes.horizontalPadding,
            )}
            color="primary"
            onClick={handleOpenFilter}
          >
            <FilterIcon className={classes.icon} style={{ marginRight: 8 }} />
            {t('COMMON.FILTER')}
          </Button>
          <TransactionsFilter
            filters={filterValues}
            anchorEl={anchorEl}
            filterValues={filterValues}
            handleClose={handleCloseFilter}
            handleFilterSubmit={handleFilterChange}
          />
          <Button
            className={clsx(classes.buttons, classes.outlined)}
            onClick={handleExcelExport}
            color="primary"
          >
            <ExportIcon className={classes.icon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TransactionsToolbar;
