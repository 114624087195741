import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import BusinessBalance from './BusinessBalance';

const BalanceView = ({ business }) => {
  return (
    <Box mt={3}>
      <BusinessBalance business={business} />
    </Box>
  );
};

BalanceView.propTypes = {
  business: PropTypes.object,
};

export default BalanceView;
