import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, makeStyles } from '@material-ui/core';
import navigation from 'src/routes/navigation';
import Logo from 'src/components/Logo';
import IconLogo from 'src/components/IconLogo';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import Menu from './Menu';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: `${theme.spacing(36)}px`,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: `${theme.spacing(36)}px`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(13),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.default,
  },
  container: {
    flexGrow: 1,
    backgroundColor: 'inherit',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginTop: theme.spacing(5),
  },
  logo: {
    marginTop: theme.spacing(2),
    marginLeft: 30,
    marginRight: 60,
  },
}));

const NavBar = ({ open }) => {
  const classes = useStyles();

  const content = (
    <Box className={classes.root}>
      <RouterLink className={classes.logo} to="/">
        {open ? <Logo /> : <IconLogo />}
      </RouterLink>

      <Box className={classes.container}>
        {navigation.map(item => (
          <Menu item={item} key={item.title} openDrawer={open} />
        ))}
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open
      variant="permanent"
    >
      {content}
    </Drawer>
  );
};

NavBar.propTypes = {
  open: PropTypes.bool,
};

export default NavBar;
