import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  CLEAR_CARS,
  downloadExcelInactiveCars,
  loadInactiveCars,
} from 'src/store/actions/carActions';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectCarQueryOptions } from 'src/store/selectors/carSelectors';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { CAR_STATUS } from '../CarDetail/utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '70vh',
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    width: '100%',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCarQueryOptions);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (searchCriteria, queryOpts) =>
      dispatch(loadInactiveCars({ ...queryOpts, searchCriteria, page: 0 })),
    400,
  );

  useEffect(() => {
    dispatch(
      loadInactiveCars({
        sort: {
          order: 'asc',
          property: 'last_assignation_end',
        },
        status: CAR_STATUS.AVAILABLE,
      }),
    );
    return () => dispatch({ type: CLEAR_CARS });
  }, []);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelInactiveCars());
  }, [dispatch]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.FLEET']}
        current="NAVIGATION.INACTIVE_CARS"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontWeight: '400' }} color="primary" variant="h1">
          {t('NAVIGATION.INACTIVE_CARS')}
        </Typography>
        <div className={classes.actionsContainer}>
          <Paper className={classes.search}>
            <Input
              className={classes.input}
              startAdornment={
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              }
              placeholder={t('FLEET_VIEW.SEARCH_CAR')}
              disableUnderline
              value={searchValue}
              inputProps={{
                'aria-label': 'Search',
              }}
              type="string"
              onChange={handleSearch}
            />
          </Paper>
          <Button
            className={clsx(classes.buttons, classes.outlined)}
            onClick={handleExcelExport}
            color="primary"
          >
            <ExportIcon className={classes.icon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
