import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Page from 'src/components/Page';
import {
  changePassword,
  sendRecoverPassEmail,
  validateToken,
} from 'src/store/actions/authActions';
import { useDispatch } from 'react-redux';
import MainLayout from 'src/layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import I18nMenu from 'src/layouts/DashboardLayout/I18nMenu';
import { useLocation } from 'react-router';
import Footer from 'src/components/Footer';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: `${theme.spacing(4)}px ${theme.spacing(10)}px`,
  },
  login: {
    maxWidth: '75%',
    padding: 0,
    margin: 'auto',
    marginLeft: '20%',
  },
  button: {
    letterSpacing: 1.25,
    fontSize: theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(10)}px`,
    maxWidth: '85%',
  },
  text: {
    width: '85%',
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(7.5),
    fontWeight: '300',
    letterSpacing: -0.5,
    textAlign: 'left',
    marginBottom: theme.spacing(),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(2.3),
    fontWeight: '500',
    letterSpacing: 0.15,
    textAlign: 'left',
  },
}));

const makeIntiailValues = () => ({
  email: '',
  password: '',
  confirmPass: '',
});

const validationSchemaSendEmail = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('RECOVER_PASS_VIEW.EMAIL_REQUIRED'),
});

const validationSchemaChangePass = Yup.object().shape({
  password: Yup.string()
    .min(8, 'RECOVER_PASS_VIEW.MIN_PASS_LONG')
    .max(255)
    .required('RECOVER_PASS_VIEW.PASS_REQUIRED'),
  confirmPass: Yup.string()
    .oneOf([Yup.ref('password'), null], 'RECOVER_PASS_VIEW.MUST_BE_SAME_PASS')
    .required('RECOVER_PASS_VIEW.MUST_BE_SAME_PASS'),
});

const RecoverPassView = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValues = makeIntiailValues();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const userName = query.get('userName');

  useEffect(() => {
    if (token && userName) {
      dispatch(validateToken(token, userName));
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const timeoutRef = useRef(null);

  const handleChangePass = useCallback(
    ({ password }, { resetForm, setSubmitting }) => {
      dispatch(
        changePassword(
          { userName, newPassword: password },
          resetForm,
          setSubmitting,
        ),
      );
    },
    [dispatch],
  );

  const handleSendRecoverPassEmail = useCallback(
    ({ email }, { resetForm, setSubmitting }) => {
      dispatch(sendRecoverPassEmail(email, resetForm, setSubmitting));
    },
    [dispatch],
  );

  useEffect(() => () => clearTimeout(timeoutRef.current), [timeoutRef]);

  if (token && userName) {
    return (
      <MainLayout>
        <Page className={classes.root} title={t('RECOVER_PASS_VIEW.TITLE')}>
          <Box display="flex" flexDirection="column" height="100%">
            <div style={{ marginLeft: 'auto' }}>
              <I18nMenu />
            </div>
            <Container maxWidth="sm" className={classes.login}>
              <FadeIn transitionDuration={200}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchemaChangePass}
                  onSubmit={handleChangePass}
                >
                  {({
                    errors,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    handleChange,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={3}>
                        <Typography className={classes.title}>
                          {t('RECOVER_PASS_VIEW.RECOVER_PASS')}
                        </Typography>
                        <Typography className={classes.subtitle}>
                          {t('RECOVER_PASS_VIEW.ENTER_PASS')}
                        </Typography>
                      </Box>
                      <TextField
                        className={classes.text}
                        error={Boolean(touched.password && errors.password)}
                        helperText={
                          touched.password ? t(errors.password) : null
                        }
                        label={t('LOGIN_VIEW.PASSWORD')}
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.text}
                        error={Boolean(
                          touched.confirmPass && errors.confirmPass,
                        )}
                        helperText={
                          touched.confirmPass ? t(errors.confirmPass) : null
                        }
                        label={t('RECOVER_PASS_VIEW.REPEAT_PASS')}
                        margin="normal"
                        name="confirmPass"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        type={showPassword ? 'text' : 'password'}
                        value={values.confirmPass}
                        variant="outlined"
                      />
                      <Box my={3}>
                        <Button
                          className={classes.button}
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {t('RECOVER_PASS_VIEW.CHANGE_PASS')}
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </FadeIn>
            </Container>
            <Footer />
          </Box>
        </Page>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Page className={classes.root} title={t('RECOVER_PASS_VIEW.TITLE')}>
        <Box display="flex" flexDirection="column" height="100%">
          <div style={{ marginLeft: 'auto' }}>
            <I18nMenu />
          </div>
          <Container maxWidth="sm" className={classes.login}>
            <FadeIn transitionDuration={200}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaSendEmail}
                onSubmit={handleSendRecoverPassEmail}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  handleChange,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                      <Typography className={classes.title}>
                        {t('RECOVER_PASS_VIEW.TITLE')}
                      </Typography>
                      <Typography className={classes.subtitle}>
                        {t('RECOVER_PASS_VIEW.TEXT')}
                      </Typography>
                    </Box>
                    <TextField
                      className={classes.text}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email ? t(errors.email) : null}
                      label={t('RECOVER_PASS_VIEW.EMAIL')}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.email}
                      variant="outlined"
                    />
                    <Box my={3}>
                      <Button
                        className={classes.button}
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {t('RECOVER_PASS_VIEW.SEND')}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </FadeIn>
          </Container>
          <Footer />
        </Box>
      </Page>
    </MainLayout>
  );
};

export default RecoverPassView;
