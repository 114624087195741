import * as Yup from 'yup';

export default Yup.object().shape({
  invoicePDF: Yup.mixed().required(
    'SCHEMA_ERRORS.CAR_CREATION_FORM.UPLOAD_PDF',
  ),
  invoiceXML: Yup.mixed().required(
    'SCHEMA_ERRORS.CAR_CREATION_FORM.UPLOAD_XML',
  ),
  serie: Yup.string()
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.INVALID_SERIAL_NUMBER')
    .length(17, 'SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_SERIAL_NUMBER'),
  serieConfirmation: Yup.string()
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.CONFIRM_SERIAL_NUMBER')
    .oneOf(
      [Yup.ref('serie'), null],
      'SCHEMA_ERRORS.CAR_CREATION_FORM.SAME_SERIAL_NUMBER',
    ),
  total: Yup.number()
    .required('SCHEMA_ERRORS.CAR_CREATION_FORM.ENTER_TOTAL_COST')
    .min(0, 'SCHEMA_ERRORS.ENTER_POSITIVE_NUMBER'),
});
