import moment from 'moment';
import { AmortizationBalanceType } from 'src/utils/constants';
import {
  CLEAR_AMORTIZATION_BALANCE_OPTIONS,
  CLEAR_DEPRECIATION_BALANCE,
  CLEAR_DEPRECIATION_BALANCE_OPTIONS,
  LOAD_AMORTIZATION_BALANCE,
  LOAD_AMORTIZATION_RESUME,
  LOAD_DEPRECIATION_BALANCE,
  LOAD_DEPRECIATION_RESUME,
  SAVE_AMORTIZATION_BALANCE_OPTIONS,
  SAVE_DEPRECIATION_BALANCE_OPTIONS,
  CLEAR_AMORTIZATION_BALANCE,
  LOAD_FLEET_MOVEMENTS,
} from '../actions/accountingActions';

const initialState = {
  depreciationBalance: {
    options: {
      limit: +process.env.REACT_APP_TABLE_SIZE,
      page: 0,
      sort: { property: 'id', order: 'desc' },
      startDateBalance: moment()
        .startOf('month')
        .toDate(),
      endDateBalance: moment().toDate(),
    },
    items: [],
    count: 0,
    resume: {},
    movementsItems: [],
    movementsCount: 0,
  },
  amortizationBalance: {
    options: {
      limit: +process.env.REACT_APP_TABLE_SIZE,
      page: 0,
      sortOrder: 'desc',
      startDate: moment()
        .startOf('month')
        .toDate(),
      endDate: moment().toDate(),
      insuranceBalanceType: AmortizationBalanceType.ASSETS,
    },
    items: [],
    count: 0,
    resume: {},
  },
};

const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DEPRECIATION_BALANCE_OPTIONS: {
      return {
        ...state,
        depreciationBalance: {
          ...state.depreciationBalance,
          options: {
            ...state.depreciationBalance.options,
            ...action.options,
          },
        },
      };
    }
    case CLEAR_DEPRECIATION_BALANCE_OPTIONS: {
      return {
        ...state,
        depreciationBalance: {
          ...state.depreciationBalance,
          options: initialState.depreciationBalance.options,
        },
      };
    }
    case LOAD_DEPRECIATION_BALANCE.success: {
      return {
        ...state,
        depreciationBalance: {
          ...state.depreciationBalance,
          items: action.items,
          count: action.count,
        },
      };
    }
    case CLEAR_DEPRECIATION_BALANCE: {
      return {
        ...state,
        depreciationBalance: initialState.depreciationBalance,
      };
    }
    case LOAD_DEPRECIATION_RESUME.success: {
      return {
        ...state,
        depreciationBalance: {
          ...state.depreciationBalance,
          resume: action.payload,
        },
      };
    }
    case LOAD_FLEET_MOVEMENTS.success: {
      return {
        ...state,
        depreciationBalance: {
          ...state.depreciationBalance,
          movementsItems: action.items,
          movementsCount: action.count,
        },
      };
    }
    case LOAD_AMORTIZATION_BALANCE.success: {
      return {
        ...state,
        amortizationBalance: {
          ...state.amortizationBalance,
          items: action.items,
          count: action.count,
        },
      };
    }
    case LOAD_AMORTIZATION_RESUME.success: {
      return {
        ...state,
        amortizationBalance: {
          ...state.amortizationBalance,
          resume: action.payload,
        },
      };
    }
    case CLEAR_AMORTIZATION_BALANCE: {
      return {
        ...state,
        amortizationBalance: initialState.amortizationBalance,
      };
    }
    case CLEAR_AMORTIZATION_BALANCE_OPTIONS: {
      return {
        ...state,
        amortizationBalance: {
          ...state.amortizationBalance,
          options: initialState.amortizationBalance.options,
        },
      };
    }
    case SAVE_AMORTIZATION_BALANCE_OPTIONS: {
      return {
        ...state,
        amortizationBalance: {
          ...state.amortizationBalance,
          options: {
            ...state.amortizationBalance.options,
            ...action.options,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default accountingReducer;
