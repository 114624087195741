import React from 'react';
import { Link } from 'react-router-dom';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from 'src/components/Table/TableCell';
import {
  formatPositiveAmount,
  formatNegativeAmount,
} from 'src/utils/formatAmount';
import { NumberPositionRow } from 'src/utils/constants';

const RentResumeRow = ({
  driverId,
  driverName,
  initialBalance,
  rent,
  surcharge,
  interests,
  others,
  fines,
  sinisters,
  advances,
  discounts,
  payments,
  finalBalance,
  /*   semaphore, */
}) => {
  /*   const getSemaphoreStatus = semaphoreStatus => {
    if (semaphoreStatus === DriverSemaphoreStatus.GREEN) {
      return {
        description: 'COLORS.GREEN',
        type: IconStatusAndColor.OK.status,
      };
    }

    if (semaphoreStatus === DriverSemaphoreStatus.YELLOW) {
      return {
        description: 'COLORS.YELLOW',
        type: IconStatusAndColor.WARNING.status,
      };
    }

    if (semaphoreStatus === DriverSemaphoreStatus.RED) {
      return {
        description: 'COLORS.RED',
        type: IconStatusAndColor.ERROR.status,
      };
    }
    return {
      description: 'COMMON.NONE',
      type: IconStatusAndColor.NONE.status,
    };
  }; */

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/drivers/detail/${driverId}`}>{driverId}</Link>
      </TableCell>
      <TableCell>
        <Link to={`/drivers/detail/${driverId}`}>{driverName}</Link>
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatPositiveAmount(initialBalance)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatPositiveAmount(rent)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatPositiveAmount(surcharge)}
      </TableCell>
      <TableCell style={{ display: 'none' }} align={NumberPositionRow}>
        {formatPositiveAmount(interests)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatPositiveAmount(fines)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatPositiveAmount(sinisters)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatPositiveAmount(others)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatNegativeAmount(advances)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatNegativeAmount(discounts)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatNegativeAmount(payments)}
      </TableCell>
      <TableCell align={NumberPositionRow}>
        {formatPositiveAmount(finalBalance)}
      </TableCell>
      {/*       <TableCell align="center">
        <StatusIcon {...getSemaphoreStatus(semaphore)} />
      </TableCell> */}
    </TableRow>
  );
};

RentResumeRow.propTypes = {
  driverId: PropTypes.string,
  driverName: PropTypes.string,
  initialBalance: PropTypes.number,
  rent: PropTypes.number,
  surcharge: PropTypes.number,
  interests: PropTypes.number,
  others: PropTypes.number,
  fines: PropTypes.number,
  sinisters: PropTypes.number,
  advances: PropTypes.number,
  discounts: PropTypes.number,
  payments: PropTypes.number,
  finalBalance: PropTypes.number,
  /*   semaphore: PropTypes.string,
   */
};

export default RentResumeRow;
