import { Box, Container, TableRow } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Table from 'src/components/Table';
import { CATALOG_ROUTES } from 'src/routes/navigation';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import TableCell from 'src/components/Table/TableCell';
import useStyles from './styles';
import Toolbar from './Toolbar';

const CatalogListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell className={classes.row}>{t('COMMON.NAME')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return CATALOG_ROUTES.map(catalog => (
        <CatalogRow {...catalog} key={catalog.title} />
      ));
    },
    [CATALOG_ROUTES],
  );

  return (
    <Page className={classes.rootCatalog} title={t('NAVIGATION.CATALOGS')}>
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Table tbody={tbody} theads={theads} />
        </Box>
      </Container>
    </Page>
  );
};

const CatalogRow = ({ href, title }) => {
  const { t } = useTranslation();

  return (
    <TableRow hover>
      <TableCell>
        <Link to={href}>{t(title)}</Link>
      </TableCell>
    </TableRow>
  );
};

CatalogRow.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
};

export default CatalogListView;
