import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import RentResumeRow from './RentResumeRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const useStyles = makeStyles(() => ({
  tableRoot: {
    border: 'none',
  },
}));

const Tbody = ({ incomes }) =>
  incomes.map(income => <RentResumeRow key={income.driverId} {...income} />);

const RentResumeTable = ({
  className,
  incomes,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell align="left" style={{ width: '5%' }}>
          {t('INCOME_VIEW.ID')}
        </TableCell>
        <TableCell align="left" style={{ width: '20%' }}>
          {t('INCOME_VIEW.CLIENT')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.INITIAL_BALANCE')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.RENT')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.SURCHARGES')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%', display: 'none' }}>
          {t('INCOME_VIEW.INTERESTS')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('NAVIGATION.FINES')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.SINISTERS')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.OTHERS')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.ADVANCES')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.DISCOUNTS')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.PAYMENTS')}
        </TableCell>
        <TableCell align="center" style={{ width: '7.5%' }}>
          {t('INCOME_VIEW.FINAL_BALANCE')}
        </TableCell>
        {/*         <TableCell align="left" style={{ width: '5%' }}>
          {t('COMMON.SEMAPHORE')}
        </TableCell> */}
      </TableRow>
    ),
    [],
  );

  const tbody = useMemo(() => () => <Tbody incomes={incomes} />, [incomes]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, count, handleChangePage],
  );

  return (
    <Table
      {...rest}
      className={classes.tableRoot}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

RentResumeTable.propTypes = {
  className: PropTypes.string,
  incomes: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool,
};

RentResumeTable.defaultProps = {
  count: 0,
};

export default RentResumeTable;
