/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DialogContentText, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UploadFile from 'src/components/UploadFile';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = business => {
  return {
    constanceRfc: business.constanceRfc
      ? {
          name: 'BUSINESS.BUSINESS_DOCUMENT.VIEW_CONSTANCE_RFC',
          link: business.constanceRfc,
          inDb: true,
        }
      : '',
    constitutiveFile: business.constitutiveFile
      ? {
          name: 'BUSINESS.BUSINESS_DOCUMENT.VIEW_CERTIFICATE_CONSTITUTIVE',
          link: business.constitutiveFile,
          inDb: true,
        }
      : '',
    attorneyIfe: business.attorneyIfe
      ? {
          name: 'BUSINESS.BUSINESS_DOCUMENT.VIEW_ATTORNEY_IFE',
          link: business.attorneyIfe,
          inDb: true,
        }
      : '',
    businessAddressInvoice: business.businessAddressInvoice
      ? {
          name: 'BUSINESS.BUSINESS_DOCUMENT.VIEW_BUSINESS_ADDRESS_INVOICE',
          link: business.businessAddressInvoice,
          inDb: true,
        }
      : '',
    avalAddressInvoice: business.avalAddressInvoice
      ? {
          name: 'BUSINESS.BUSINESS_DOCUMENT.VIEW_AVAL_ADDRESS_INVOICE',
          link: business.avalAddressInvoice,
          inDb: true,
        }
      : '',
    taxCompliance: business.taxCompliance
      ? {
          name: 'BUSINESS.BUSINESS_DOCUMENT.VIEW_TAX_COMPLIANCE',
          link: business.taxCompliance,
          inDb: true,
        }
      : '',
    frameworkContract: business.frameworkContract
      ? {
          name: 'BUSINESS.BUSINESS_DOCUMENT.VIEW_FRAMEWORK_CONTRACT',
          link: business.frameworkContract,
          inDb: true,
        }
      : '',
  };
};

const BusinessDocumentDialog = ({ open, business, handleClose, submit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(business), [business]);
  const resetRef = useRef();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  const handleUploadChange = useCallback(
    setFieldValue => e => {
      if (e.target.files[0]) {
        setFieldValue(e.target.name, e.target.files[0]);
      }
    },
    [],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-doc-business"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('BUSINESS.BUSINESS_DOCUMENT.TITLE')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    {t('BUSINESS.UPDATE_BUSINESS_DATA')}
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="constanceRfc"
                  textLabel={t(
                    'BUSINESS.BUSINESS_DOCUMENT.UPLOAD_CONSTANCE_RFC',
                  )}
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="constitutiveFile"
                  textLabel={t(
                    'BUSINESS.BUSINESS_DOCUMENT.UPLOAD_CERTIFICATE_CONSTITUTIVE',
                  )}
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="attorneyIfe"
                  textLabel={t(
                    'BUSINESS.BUSINESS_DOCUMENT.UPLOAD_ATTORNEY_IFE',
                  )}
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="businessAddressInvoice"
                  textLabel={t(
                    'BUSINESS.BUSINESS_DOCUMENT.UPLOAD_BUSINESS_ADDRESS_INVOICE',
                  )}
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="avalAddressInvoice"
                  textLabel={t(
                    'BUSINESS.BUSINESS_DOCUMENT.UPLOAD_AVAL_ADDRESS_INVOICE',
                  )}
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="taxCompliance"
                  textLabel={t(
                    'BUSINESS.BUSINESS_DOCUMENT.UPLOAD_TAX_COMPLIANCE',
                  )}
                />

                <UploadFile
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleUploadChange={handleUploadChange}
                  setFieldValue={setFieldValue}
                  accept=".pdf"
                  valueLabel="frameworkContract"
                  textLabel={t(
                    'BUSINESS.BUSINESS_DOCUMENT.UPLOAD_FRAMEWORK_CONTRACT',
                  )}
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.UPDATE')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

BusinessDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  business: PropTypes.object,
};

export default BusinessDocumentDialog;
