import { DriverStatus } from 'src/utils/constants';
import {
  CLEAR_AVAILABLE_DRIVERS,
  LOAD_AVAILABLE_DRIVERS,
  CLEAR_DRIVERS,
  LOAD_DRIVERS,
  LOAD_DETAIL_DRIVER,
  CLEAR_DETAIL_DRIVER,
  SAVE_DRIVER_OPTIONS,
  CLEAR_DRIVER_OPTIONS,
  SAVE_TRANSACTION_OPTIONS,
  CLEAR_TRANSACTION_OPTIONS,
  LOAD_TRANSACTIONS,
  LOAD_MOVEMENTS,
  SAVE_MOVEMENT_OPTIONS,
  CLEAR_MOVEMENT_OPTIONS,
  LOAD_INCIDENTS,
  LOAD_METRICS,
  SAVE_METRICS_OPTIONS,
  CLEAR_METRICS_OPTIONS,
  LOAD_TRANSACTION_MOVEMENTS,
} from '../actions/driverActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    sort: { property: 'id', order: 'asc' },
    status: DriverStatus.ACTIVE,
  },
  items: [],
  count: 0,
  detail: null,
  transactionItems: [],
  balance: {
    debts: 0,
    asset: 0,
  },
  transactionsCount: 0,
  transactionOptions: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  transactionMovements: [],
  movementItems: [],
  movementsCount: 0,
  movementsOptions: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  incidents: {
    items: [],
    amount: 0,
    years: 0,
    averageIncidentsYear: 0,
    incidentsNinety: 0,
  },
  metricsCount: 0,
  metricsOptions: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  metrics: {
    items: [],
    count: 0,
    mileageTraveled: 0,
  },
};

const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AVAILABLE_DRIVERS.success: {
      return {
        ...state,
        items: action.data,
      };
    }
    case CLEAR_AVAILABLE_DRIVERS: {
      return initialState;
    }
    case LOAD_DRIVERS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count,
      };
    }
    case CLEAR_DRIVERS: {
      return initialState;
    }
    case LOAD_DETAIL_DRIVER.success: {
      return {
        ...state,
        detail: action.data,
      };
    }
    case CLEAR_DETAIL_DRIVER: {
      return {
        ...state,
        detail: null,
      };
    }
    case SAVE_DRIVER_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options },
      };
    }
    case CLEAR_DRIVER_OPTIONS: {
      return {
        ...state,
        options: initialState.options,
      };
    }
    case LOAD_TRANSACTIONS.success: {
      return {
        ...state,
        transactionItems: action.transactions,
        transactionsCount: action.count,
        balance: action.balance,
      };
    }
    case SAVE_TRANSACTION_OPTIONS: {
      return {
        ...state,
        transactionOptions: {
          ...state.transactionOptions,
          ...(action.options || {}),
        },
      };
    }
    case CLEAR_TRANSACTION_OPTIONS: {
      return {
        ...state,
        transactionOptions: initialState.transactionOptions,
      };
    }
    case LOAD_MOVEMENTS.success: {
      return {
        ...state,
        movementItems: action.movements,
        movementsCount: action.count,
      };
    }
    case SAVE_MOVEMENT_OPTIONS: {
      return {
        ...state,
        movementsOptions: {
          ...state.movementsOptions,
          ...action.options,
        },
      };
    }
    case CLEAR_MOVEMENT_OPTIONS: {
      return {
        ...state,
        movementsOptions: initialState.movementsOptions,
      };
    }
    case LOAD_INCIDENTS.success: {
      return {
        ...state,
        incidents: {
          items: action.data.items,
          amount: action.data.amount,
          years: action.data.years,
          averageIncidentsYear: action.data.averageIncidentsYear,
          incidentsNinety: action.data.incidentsNinety,
        },
      };
    }
    case LOAD_METRICS.success: {
      return {
        ...state,
        metricsCount: action.data.count,
        metrics: {
          items: action.data.items,
          mileageTraveled: action.data.mileageTraveledThisWeek,
        },
      };
    }
    case SAVE_METRICS_OPTIONS: {
      return {
        ...state,
        metricsOptions: {
          ...state.metricsOptions,
          ...action.options,
        },
      };
    }
    case CLEAR_METRICS_OPTIONS: {
      return {
        ...state,
        metricsOptions: initialState.metricsOptions,
      };
    }
    case LOAD_TRANSACTION_MOVEMENTS.success: {
      return {
        ...state,
        transactionMovements: action.data,
      };
    }

    default: {
      return state;
    }
  }
};

export default driverReducer;
