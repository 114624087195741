import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import validationSchema from './schema';
import useStyles from '../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = data => ({
  id: data ? data.id : '',
  brand: data ? data.brand : '',
  type: data ? data.type : '',
  version: data ? data.version : '',
});

// eslint-disable-next-line react/prop-types
const BrandForm = ({ open, data, handleClose, handleCreate, handleEdit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const resetRef = useRef();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = useMemo(() => {
    if (data) {
      return handleEdit;
    }
    return handleCreate;
  }, [data, handleEdit, handleCreate]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <div>
      <Dialog
        aria-labelledby="form-test"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-test-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {data ? 'Actualizar registro' : 'Ingresar nuevo registro'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginBottom: '16px' }}>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm,
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.brand && errors.brand)}
                    fullWidth
                    helperText={touched.brand && t(errors.brand)}
                    label="Marca"
                    margin="normal"
                    name="brand"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.brand}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.type && errors.type)}
                    fullWidth
                    helperText={touched.type && t(errors.type)}
                    label="Tipo"
                    margin="normal"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.type}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.version && errors.version)}
                    fullWidth
                    helperText={touched.version && t(errors.version)}
                    label="Versión"
                    margin="normal"
                    name="version"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.version}
                    variant="outlined"
                  />

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.label)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {data ? 'Actualizar' : 'Añadir'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx(classes.buttons, classes.outlined)}
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BrandForm;
