export const selectGps = state => state.gps;
export const selectGpsList = state => selectGps(state).items;
export const selectAllGpsList = state => selectGps(state).allDevices;
export const selectGpsListCount = state => selectGps(state).count;
export const selectGpsQueryOptions = state => {
  const { options } = selectGps(state);
  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });
  return options;
};
export const selectGpsForm = state => selectGps(state).form;
export const selectGpsDevicesLocation = state => selectGps(state).locations;
