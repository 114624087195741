import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import TransactionsRow from './TransactionsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const Tbody = ({ transactions, handlePaymentDelete, t }) =>
  transactions.map(transaction => (
    <TransactionsRow
      handlePaymentDelete={handlePaymentDelete}
      key={transaction.transactionId}
      t={t}
      {...transaction}
    />
  ));

const TransactionsTable = ({
  t,
  className,
  transactions,
  count,
  page,
  handleChangePage,
  handlePaymentDelete,
  loading,
  ...rest
}) => {
  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell style={{ width: '7%' }}>{t('TRANSACTION.DATE')}</TableCell>
      <TableCell style={{ width: '7%' }}>{t('TRANSACTION.TIME')}</TableCell>
      <TableCell style={{ width: '7%' }}>{t('TRANSACTION.EXT_TIME')}</TableCell>
      <TableCell style={{ width: '12%' }}>{t('TRANSACTION.REFERENCE')}</TableCell>
      <TableCell style={{ width: '10%' }}>{t('TRANSACTION.CATEGORY_TITLE')}</TableCell>
      <TableCell style={{ width: '10%' }}>{t('TRANSACTION.ORIGIN_TITLE')}</TableCell>
      <TableCell style={{ width: '19%' }}>{t('TRANSACTION.COMMENT')}</TableCell>
      <TableCell style={{ width: '7%' }}>{t('TRANSACTION.CHARGE')}</TableCell>
      <TableCell style={{ width: '7%' }}>{t('TRANSACTION.PAYMENT')}</TableCell>
      <TableCell style={{ width: '7%' }}>{t('TRANSACTION.BALANCE')}</TableCell>
      <TableCell style={{ width: '7%' }}>{t('TRANSACTION.ACTION')}</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => (
      <Tbody
        transactions={transactions}
        handlePaymentDelete={handlePaymentDelete}
        t={t}
      />
    ),
    [transactions, handlePaymentDelete, t],
  );

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, transactions],
  );

  return (
    <Table
      {...rest}
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

TransactionsTable.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handlePaymentDelete: PropTypes.func,
  loading: PropTypes.bool,
};

TransactionsTable.defaultProps = {
  count: 0,
};

export default TransactionsTable;
