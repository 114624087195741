import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  title: {
    color: 'black',
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 700,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`,
      padding: '0px 10px',
    },
  },
  root: {
    minWidth: theme.spacing(68),
    borderRadius: '10px',
    padding: '20px',
  },
  closeIcon: {
    float: 'right',
    marginTop: '-15px',
    width: '20px',
    height: '20px',
  },
  button: {
    letterSpacing: 1.25,
    fontSize: theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(10)}px`,
    maxWidth: '50%',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  cancelButton: {
    backgroundColor: theme.palette.text.disabled,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
