import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, IconButton } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from '../styles';

const GeofencingCategoryRow = ({ category, id, openEdit, handleDelete }) => {
  const classes = useStyles();

  return (
    <TableRow hover>
      <TableCell>{category}</TableCell>

      <TableCell style={{ width: 130 }}>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={openEdit}
          key="edit"
          aria-label="Edit"
          color="inherit"
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
        <IconButton
          style={{ padding: '0 12px' }}
          onClick={() => handleDelete(id)}
          key="delete"
          aria-label="Delete"
          color="inherit"
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

GeofencingCategoryRow.propTypes = {
  id: PropTypes.string,
  category: PropTypes.string,
  openEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default GeofencingCategoryRow;
