import {
  CLEAR_COLLECTIONS,
  CLEAR_COLLECTIONS_OPTIONS,
  LOAD_COLLECTIONS,
  SAVE_COLLECTIONS_OPTIONS,
} from '../actions/collectionsActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
  },
  items: [],
  count: 0,
};

const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COLLECTIONS.success: {
      return {
        options: state.options,
        items: action.items,
        count: action.count,
      };
    }
    case SAVE_COLLECTIONS_OPTIONS: {
      return {
        items: state.items,
        count: state.count,
        options: { ...state.options, ...(action.options || {}) },
      };
    }
    case CLEAR_COLLECTIONS_OPTIONS: {
      return {
        options: initialState.options,
        items: state.items,
        count: state.count,
      };
    }
    case CLEAR_COLLECTIONS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default collectionsReducer;
