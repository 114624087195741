import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import * as Actions from 'src/store/actions/businessActions';
import {
  selectBusinesssList,
  selectBusinesssListCount,
  selectBusinesssQueryOptions,
} from 'src/store/selectors/businessSelectors';
import BusinessTable from './BusinessTable';

const BusinessList = () => {
  const dispatch = useDispatch();
  const business = useSelector(selectBusinesssList);
  const count = useSelector(selectBusinesssListCount);
  const { page } = useSelector(selectBusinesssQueryOptions);
  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_BUSINESS.action]),
  );
  const success = useSelector(successSelector([Actions.LOAD_BUSINESS.action]));

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadBusiness({ page: value }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado empresas"
          description="Ningún empresa coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <BusinessTable
      business={business}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default BusinessList;
