/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSelections,
  loadSelections,
} from 'src/store/actions/expenseActions';
import { selectExpenseAutcompletes } from 'src/store/selectors/expenseSelectors';
import CurrentStep from './CurrentStep';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`,
    },
  },
  root: {
    minWidth: theme.spacing(68),
  },
  closeIcon: {
    float: 'right',
    marginTop: '-25px',
    width: '20px',
    height: '20px',
  },
}));

const ExpenseForm = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const { providers, expenses, plates, drivers } = useSelector(
    selectExpenseAutcompletes,
  );
  const resetRef = useRef();

  useEffect(() => {
    if (open) {
      dispatch(loadSelections());
    }

    return () => {
      dispatch(clearSelections());
    };
  }, [open]);

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data],
  );

  const handleBackOrClose = useCallback(() => {
    if (step === 0) {
      handleClose();
    } else {
      setStep(step - 1);
    }
  }, [step, setStep, handleClose]);

  const handleOuterSubmit = useCallback(
    event => {
      event.preventDefault();
      handleSubmit(data);
    },
    [data],
  );

  return (
    <div>
      <Dialog
        aria-labelledby="form-new-expense-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          disableTypography
          className={classes.title}
          id="form-new-expense-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
              setData({});
              setStep(0);
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            Ingresar gasto
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <DialogContent>
          <CurrentStep
            activeStep={step}
            data={{ ...data, providers, expenses, plates, drivers }}
            onNext={handleNext}
            onBack={handleBackOrClose}
            resetRef={resetRef}
            onSubmit={handleOuterSubmit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ExpenseForm;
