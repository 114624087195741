/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import {
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  paymentSchema,
  paymentComment,
  chargeSchema,
} from './transactionSchema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = { comment: '', amount: 0 };

const ManualTransactionDialog = ({
  type,
  debts,
  open,
  handleClose,
  submit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const resetRef = useRef(() => console.log('Not implemented yet'));
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleClose();
        handleConfirmationClose();
      }
    },
    [submit, handleClose, handleConfirmationOpen, confirmationOpen],
  );

  const handleOuterClose = useCallback(() => {
    resetRef.current();
    handleClose();
  }, [resetRef.current, handleClose]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleOuterClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-add-payment"
      >
        <Typography className={classes.header} color="primary" variant="h2">
          Agregar&nbsp;
          {type === 'PAYMENT' ? 'Pago' : 'Cargo'}
          &nbsp;Manual
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={type === 'PAYMENT' ? paymentSchema : chargeSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (
              type === 'PAYMENT' &&
              values.amount > debts &&
              confirmationOpen
            ) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    Por favor, ingrese un monto menor o igual a&nbsp;
                    <b>{debts}</b>
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        Aceptar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    Al hacer click en&nbsp;
                    <b>Aceptar</b>
                    , se creará un&nbsp;
                    {type === 'PAYMENT' ? 'pago' : 'cargo'}
                    &nbsp;por un monto de&nbsp;
                    <b>{values.amount}</b>
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                {type === 'PAYMENT' && (
                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.comment && errors.comment)}
                  >
                    <InputLabel id="comment-select-label">Categoría</InputLabel>
                    <Select
                      onBlur={handleBlur}
                      labelId="comment-label"
                      label="Categoría"
                      id="comment"
                      value={values.comment}
                      onChange={handleChange}
                      name="comment"
                    >
                      {paymentComment.map(value => (
                        <MenuItem value={value}>{value}</MenuItem>
                      ))}
                    </Select>
                    {Boolean(touched.comment && errors.comment) && (
                      <FormHelperText>{t(errors.comment)}</FormHelperText>
                    )}
                  </FormControl>
                )}
                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.amount && errors.amount)}
                >
                  <TextField
                    error={Boolean(touched.amount && errors.amount)}
                    fullWidth
                    helperText={touched.amount && t(errors.amount)}
                    label="Monto"
                    margin="normal"
                    name="amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.amount}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </FormControl>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {type === 'PAYMENT' ? ' Pago' : ' Cargo'}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleOuterClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ManualTransactionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default ManualTransactionDialog;
