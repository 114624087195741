import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import useStyles from './styles';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const InsuranceResumeTable = ({
  className,
  resume,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell key="initialBalance">{t('COMMON.PERIOD')}</TableCell>
        <TableCell key="initialBalance">
          {t('COMMON.INITIAL_BALANCE')}
        </TableCell>
        <TableCell key="amortization-platform">
          {t('INSURANCE_BALANCE_VIEW.AMORTIZATION_PLATFORM')}
        </TableCell>
        <TableCell key="amortization-administrative">
          {t('INSURANCE_BALANCE_VIEW.AMORTIZATION_ADMINISTRATIVE')}
        </TableCell>
        <TableCell key="amortization-lease">
          {t('INSURANCE_BALANCE_VIEW.AMORTIZATION_LEASE')}
        </TableCell>
        <TableCell key="finalBalance">{t('COMMON.FINAL_BALANCE')}</TableCell>
      </TableRow>
    ),
    [classes, t],
  );

  const tbody = useMemo(
    () => () => (
      <TableRow hover>
        <TableCell className={classes.center}>{resume?.period}</TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(resume?.initialBalance)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(resume?.amortizationPlatform)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(resume?.amortizationAdministrative)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(resume?.amortizationLease)}
        </TableCell>
        <TableCell className={classes.center}>
          {formatPositiveAmount(resume?.finalBalance)}
        </TableCell>
      </TableRow>
    ),
    [resume, t],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

InsuranceResumeTable.propTypes = {
  className: PropTypes.string,
  resume: PropTypes.shape({
    period: PropTypes.string,
    initialBalance: PropTypes.number,
    amortizationPlatform: PropTypes.number,
    amortizationAdministrative: PropTypes.number,
    amortizationLease: PropTypes.number,
    finalBalance: PropTypes.number,
  }),
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
  campaignType: PropTypes.string,
};

InsuranceResumeTable.defaultProps = {
  count: 0,
};

export default InsuranceResumeTable;
