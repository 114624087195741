/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadDrivers } from 'src/store/actions/driverActions';
import { selectDriversQueryOptions } from 'src/store/selectors/driverSelectors';
import { DriverStatus } from 'src/utils/constants';
import DriverList from './DriverList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

function selectDriverStatus(value) {
  switch (Number(value)) {
    case 0:
      return DriverStatus.ACTIVE;
    case 1:
      return DriverStatus.PIPELINE;
    case 2:
      return DriverStatus.REVIEW;
    case 3:
      return DriverStatus.REJECTED;
    case 4:
      return DriverStatus.LEFT;
    default:
      return DriverStatus.ACTIVE;
  }
}

const DriverListTabs = ({ handleOpenConfirmation }) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const queryOpts = useSelector(selectDriversQueryOptions);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, newValue) => {
      setTabIndex(newValue);
      dispatch(
        loadDrivers({
          ...queryOpts,
          status: selectDriverStatus(newValue),
          isAssigned: null,
        }),
      );
    },
    [setTabIndex],
  );

  return (
    <div className={classes.root}>
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={t('DRIVERS_VIEW.ACTIVE')} />
          <Tab label={t('DRIVERS_VIEW.PIPELINE')} />
          <Tab label={t('DRIVERS_VIEW.REVIEW')} />
          <Tab label={t('DRIVERS_VIEW.REJECTED')} />
          <Tab label={t('DRIVERS_VIEW.LEFT')} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <DriverList driverStatus={DriverStatus.ACTIVE} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <DriverList driverStatus={DriverStatus.PIPELINE} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <DriverList
          driverStatus={DriverStatus.REVIEW}
          handleOpenConfirmation={handleOpenConfirmation}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <DriverList driverStatus={DriverStatus.REJECTED} />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <DriverList driverStatus={DriverStatus.LEFT} />
      </TabPanel>
    </div>
  );
};

DriverListTabs.propTypes = {
  handleOpenConfirmation: PropTypes.func,
};

export default DriverListTabs;
