import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('SCHEMA_ERRORS.ENTER_NAME'),
  paternalName: Yup.string()
    .max(50, 'SCHEMA_ERRORS.ENTER_FIFTY_CHARACTER')
    .required('SCHEMA_ERRORS.ENTER_PATERNAL_NAME'),
  maternalName: Yup.string()
    .max(50, 'SCHEMA_ERRORS.ENTER_FIFTY_CHARACTER')
    .required('SCHEMA_ERRORS.ENTER_MATERNAL_NAME'),
  relationShip: Yup.string()
    .oneOf(
      ['Esposa', 'Hijo', 'Padre', 'Madre'],
      'SCHEMA_ERRORS.SELECT_VALUE_LIST',
    )
    .required('SCHEMA_ERRORS.SELECT_VALUE_LIST'),
  mobile: Yup.string()
    .max(15, 'SCHEMA_ERRORS.ENTER_FIFTEEN_NUMBER')
    .required('SCHEMA_ERRORS.ENTER_PHONE_NUMBER'),
  email: Yup.string()
    .email()
    .required('SCHEMA_ERRORS.ENTER_EMAIL_VALID'),
});
