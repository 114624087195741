import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  step: {
    color: theme.palette.primary.main,
  },
}));

const QontoConnector = withStyles(theme => ({
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderTopWidth: '1px',
    borderBottomWidth: '0px',
    height: '1px',
    borderStyle: 'dashed',
  },
}))(StepConnector);

function getSteps() {
  return [
    'COMMON.DOCUMENTS',
    'FLEET_VIEW.CAR_FORM.CAR_DETAIL',
    'FLEET_VIEW.CAR_FORM.CIRCULATION_CARD',
    'COMMON.CONFIRMATION',
  ];
}

const CarCreationStepper = ({ step }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={<QontoConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel className={step.toString()}>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

CarCreationStepper.propTypes = {
  step: PropTypes.number.isRequired,
};

export default CarCreationStepper;
