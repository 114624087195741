/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultDocumentIcon } from 'src/assets/icons/default_doc.svg';
import DetailRow from 'src/components/DetailRow';
import useStyles from '../styles';

const DocumentLink = ({ link }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return link ? (
    <>
      <a href={link} className={classes.documentLink}>
        <Typography
          variant="body2"
          component="div"
          className={classes.documentText}
        >
          <DefaultDocumentIcon className={classes.documentIcon} />
          {t('COMMON.DOCUMENT_VIEW')}
          <ExternalLink style={{ marginLeft: '8px' }} size={20} />
        </Typography>
      </a>
    </>
  ) : (
    <>{t('COMMON.NOT_AVAILABLE')}</>
  );
};

const DocumentsInfo = ({ business }) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('BUSINESS.BUSINESS_DOCUMENT.CONSTANCE_RFC')}
        value={<DocumentLink link={business.constanceRfc} />}
      />

      <DetailRow
        name={t('BUSINESS.BUSINESS_DOCUMENT.CERTIFICATE_CONSTITUTIVE')}
        value={<DocumentLink link={business.constitutiveFile} />}
      />

      <DetailRow
        name={t('BUSINESS.BUSINESS_DOCUMENT.ATTOERNY_IFE')}
        value={<DocumentLink link={business.attorneyIfe} />}
      />

      <DetailRow
        name={t('BUSINESS.BUSINESS_DOCUMENT.BUSINESS_ADDRESS_INVOICE')}
        value={<DocumentLink link={business.businessAddressInvoice} />}
      />

      <DetailRow
        name={t('BUSINESS.BUSINESS_DOCUMENT.AVAL_ADDRESS_INVOICE')}
        value={<DocumentLink link={business.avalAddressInvoice} />}
      />

      <DetailRow
        name={t('BUSINESS.BUSINESS_DOCUMENT.TAX_COMPLIANCE')}
        value={<DocumentLink link={business.taxCompliance} />}
      />

      <DetailRow
        name={t('BUSINESS.BUSINESS_DOCUMENT.FRAMEWORK_CONTRACT')}
        value={<DocumentLink link={business.frameworkContract} />}
      />
    </>
  );
};

export default DocumentsInfo;
