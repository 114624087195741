import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.contrastText,
    letterSpacing: 0.1,
    justifyContent: 'flex-start',
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: theme.palette.primary.contrastText,
  },
  title: {
    marginRight: 'auto',
    fontSize: theme.spacing(1.8),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.1,
    textTransform: 'none',
  },
  extraMargin: {
    marginRight: theme.spacing(2.2),
  },
  active: {
    color: theme.palette.secondary.main,
    '& $icon': {
      fill: theme.palette.secondary.main,
    },
  },
}));

const NavItem = ({
  className,
  isRootItem,
  href,
  icon: Icon,
  title,
  toggle,
  openDrawer,
  disabled,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      {toggle ? (
        <Tooltip condition={disabled} text="En mantenimiento">
          <Button
            activeClassName={classes.active}
            className={classes.button}
            disabled={disabled}
          >
            {Icon && (
              <Icon
                className={clsx(classes.icon, {
                  [classes.extraMargin]: isRootItem,
                })}
                size="20"
                style={{ fill: disabled && '#bdbdaf' }}
              />
            )}
            <span
              className={classes.title}
              style={{ color: disabled && '#bdbdaf' }}
            >
              {t(title)}
            </span>
          </Button>
        </Tooltip>
      ) : (
        <Tooltip condition={disabled} text="En mantenimiento">
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to={href}
            disabled={disabled}
          >
            {Icon && (
              <ListItemIcon>
                <Icon
                  className={clsx(classes.icon, {
                    [classes.extraMargin]: isRootItem,
                  })}
                  size="20"
                  style={{ fill: disabled && '#bdbdaf' }}
                />
              </ListItemIcon>
            )}
            <ListItemText>
              <span
                className={classes.title}
                style={{ color: disabled && '#bdbdaf' }}
              >
                {openDrawer ? t(title) : ''}
              </span>
            </ListItemText>
          </Button>
        </Tooltip>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  toggle: PropTypes.bool,
  isRootItem: PropTypes.bool,
  openDrawer: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NavItem;
