import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CLEAR_DRIVERS_WITH_LICENCE,
  downloadExcelExportedLicences,
  loadLicences,
} from 'src/store/actions/licenceActions';
import { selectLicenceQueryOptions } from 'src/store/selectors/licenceSelectors';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { LicenceStatus } from 'src/utils/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  icon: {
    fill: theme.palette.primary.main,
    marginRight: '8px',
  },
  downloadIcon: {
    fill: theme.palette.primary.main,
  },
}));

const filters = [
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'hasLicence',
    items: [
      {
        label: 'LICENCE_VIEW.WITH_LICENCE',
        value: 'true',
      },
      {
        label: 'LICENCE_VIEW.NO_LICENCE',
        value: 'false',
      },
      {
        label: 'COMMON.ALL',
        value: '',
      },
    ],
  },
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.RADIO_BUTTON,
    value: 'licenceStatus',
    items: [
      {
        label: 'LICENCE_VIEW.EXPIRED_LICENCES',
        value: LicenceStatus.EXPIRED,
      },
      {
        label: 'LICENCE_VIEW.CURRENT_LICENCES',
        value: LicenceStatus.ACTIVE,
      },
      {
        label: 'LICENCE_VIEW.EXPIRING_LICENCES',
        value: LicenceStatus.EXPIRING,
      },
      {
        label: 'COMMON.ALL',
        value: null,
      },
    ],
  },
];

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryOptions = useSelector(selectLicenceQueryOptions);

  const [filterValues, setFilterValues] = React.useState({
    hasLicence: 'true',
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleSubmitFilter = queryOpts => {
    dispatch(loadLicences(queryOpts));
    handleCloseFilter();
  };

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      handleSubmitFilter({
        ...queryOptions,
        page: 0,
        ...event,
      });
    },
    [handleSubmitFilter, queryOptions],
  );

  const handleDownloadExcelLicenceList = useCallback(() => {
    dispatch(downloadExcelExportedLicences());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadLicences());
    return () => dispatch({ type: CLEAR_DRIVERS_WITH_LICENCE });
  }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Breadcrumb
        history={['NAVIGATION.GENERAL', 'NAVIGATION.DRIVERS']}
        current="LICENCE_VIEW.LICENCES"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontWeight: '400' }} color="primary" variant="h1">
          {t('LICENCE_VIEW.LICENCES')}
        </Typography>
        <div className={classes.actionsContainer}>
          <Button
            className={clsx(classes.buttons, classes.outlined)}
            onClick={handleDownloadExcelLicenceList}
            color="primary"
          >
            <ExportIcon className={classes.downloadIcon} />
          </Button>

          <Button
            className={clsx(
              classes.buttons,
              classes.outlined,
              classes.horizontalPadding,
            )}
            color="primary"
            onClick={handleOpenFilter}
          >
            <FilterIcon className={classes.icon} />
            {t('COMMON.FILTER')}
          </Button>
          <Filter
            anchorEl={anchorEl}
            filterValues={filterValues}
            handleClose={handleCloseFilter}
            handleChange={handleFilterChange}
            filters={filters}
          />
        </div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
