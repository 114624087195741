import qs from 'qs';
import axios from 'axios';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAllInsurances = serviceBase({
  request: options =>
    axiosClient.get('/insurance', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 0,
});

export const getUploadLink = serviceBase({
  request: ({ format, contentType }) =>
    axiosClient.get(`/aws/backoffice-upload-link`, {
      params: { format, contentType },
    }),
  retry: 0,
});

export const uploadFile = serviceBase({
  request: ({ targetURL, file }) =>
    axios.put(targetURL, file, { timeout: 30000 }),
  retry: 0,
});

export const editInsurance = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/insurance/${id}`, { ...values }),
  retry: 0,
});

export const assignInsurance = serviceBase({
  request: ({ niv, values }) =>
    axiosClient.post(`/insurance/assign/${niv}`, { ...values }),
  retry: 0,
});

export const deleteInsurance = serviceBase({
  request: id => axiosClient.post(`/insurance/unassign/${id}`),
  retry: 0,
});

export const downloadExportedInsurances = serviceBase({
  request: options =>
    axiosClient.get('/insurance/export', {
      responseType: 'blob',
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  transformResponse: res => {
    try {
      const filename = 'insurances.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});
