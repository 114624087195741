import { Card } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  loadDepreciationBalance,
  LOAD_DEPRECIATION_BALANCE,
} from 'src/store/actions/accountingActions';
import {
  selectDepreciationBalanceList,
  selectDepreciationBalanceListCount,
  selectDepreciationBalanceQueryOptions,
} from 'src/store/selectors/accountingSelector';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import DepreciationBalanceTable from './DepreciationBalanceTable';

const DepreciationBalanceList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const depreciationBalanceList = useSelector(selectDepreciationBalanceList);
  const count = useSelector(selectDepreciationBalanceListCount);
  const { page } = useSelector(selectDepreciationBalanceQueryOptions);
  const queryOptions = useSelector(selectDepreciationBalanceQueryOptions);

  const loading = useSelector(
    isLoadingSelector([LOAD_DEPRECIATION_BALANCE.action]),
  );
  const success = useSelector(
    successSelector([LOAD_DEPRECIATION_BALANCE.action]),
  );

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadDepreciationBalance({ page: value }));
    },
    [dispatch],
  );

  const handleSortChange = useCallback(
    (property, order) => {
      dispatch(
        loadDepreciationBalance({
          ...queryOptions,
          sort: { property, order },
        }),
      );
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title={t('NOT_FOUND_RECORDS_MESSAGE.AMORTIZATION_BALANCE_TITLE')}
          description={t(
            'NOT_FOUND_RECORDS_MESSAGE.AMORTIZATION_BALANCE_DESCRIPTION',
          )}
        />
      </Card>
    );
  }

  return (
    <DepreciationBalanceTable
      balances={depreciationBalanceList}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      handleSortChange={handleSortChange}
      loading={loading}
    />
  );
};

export default DepreciationBalanceList;
