import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import Table from 'src/components/Table';
import RentLockoutGpsRow from './RentLockoutGpsRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!',
  );
}

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Tbody = ({ cars }) =>
  cars.map(car => <RentLockoutGpsRow key={car.imei} {...car} />);

const RentLockoutGpsTable = ({
  className,
  cars,
  count,
  page,
  handleChangePage,
  handleSortChange,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="id" style={{ maxWidth: '50px' }}>
          Niv
        </TableCell>

        <TableCell variant="head" key="status" style={{ maxWidth: '80px' }}>
          {t('GPS_VIEW.PLATES')}
        </TableCell>

        <TableCell variant="head" key="mode" style={{ maxWidth: '95px' }}>
          {t('GPS_VIEW.CLIENT')}
        </TableCell>

        <TableCell variant="head" key="plates" style={{ maxWidth: '125px' }}>
          {t('GPS_VIEW.RENT_LOCKOUT_VIEW.RENT_BALANCE')}
        </TableCell>

        <TableCell variant="head" key="plates" style={{ maxWidth: '95px' }}>
          {t('GPS_VIEW.RENT_LOCKOUT_VIEW.ENGINE')}
        </TableCell>
      </TableRow>
    ),
    [classes, t],
  );

  const tbody = useMemo(() => () => <Tbody t={t} cars={cars} />, [cars, t]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationCustom}
      />
    ),
    [page, cars],
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

RentLockoutGpsTable.propTypes = {
  className: PropTypes.string,
  cars: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleSortChange: PropTypes.func,
  loading: PropTypes.bool,
};

RentLockoutGpsTable.defaultProps = {
  count: 0,
};

export default RentLockoutGpsTable;
