import React from 'react';
import {  useSelector } from 'react-redux';
import {

  LOAD_BILLING_PARAMETERS,
} from 'src/store/actions/billingActions';
import { selectBillingParameters } from 'src/store/selectors/billingSelector';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import BillingParametersTable from './BillingParametersTable';

const BillingParametersList = () => {
  
  const parameters = useSelector(selectBillingParameters);
  const loading = useSelector(
    isLoadingSelector([LOAD_BILLING_PARAMETERS.action]),
  );

  return <BillingParametersTable parameters={parameters} loading={loading} />;
};

export default BillingParametersList;
