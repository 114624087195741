import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { useTranslation } from 'react-i18next';
import { loadTransmissionList } from 'src/store/actions/gpsActions';
import useDebounce from 'src/hooks/useDebounce';
import { selectGpsQueryOptions } from 'src/store/selectors/gpsSelectors';
import { Search } from '@material-ui/icons';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { GpsTransmissionStatus } from 'src/utils/gps';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '70vh',
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    '&:placeholder': {
      color: 'red',
    },
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const filters = [
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'COMMON.ONLINE',
        value: 'online',
      },
      {
        label: 'COMMON.OFFLINE',
        value: 'offline',
      },
      {
        label: 'COMMON.NO_DATA',
        value: 'noData',
      },
    ],
  },
];

function getFilterOptions(options) {
  const status = [];

  if (options.online) {
    status.push(GpsTransmissionStatus.ONLINE);
  }
  if (options.offline) {
    status.push(GpsTransmissionStatus.OFFLINE);
  }
  if (options.noData) {
    status.push(GpsTransmissionStatus.NO_DATA);
  }
  return { status };
}

const TransmissionGpsToolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    online: false,
    offline: false,
    noData: false,
  });
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria] = useState('plateOrCarniv');
  const queryOptions = useSelector(selectGpsQueryOptions);
  const dispatch = useDispatch();

  const debounceSearch = useDebounce(
    (criteria = searchCriteria, value, queryOpts) =>
      dispatch(
        loadTransmissionList({ ...queryOpts, [criteria]: value, page: 0 }),
      ),
    400,
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(searchCriteria, value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions, filterValues],
  );

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchCriteria, searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions,
    ],
  );

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'GPS']}
          current="NAVIGATION.TRANSMISSION"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.TRANSMISSION')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Paper
              elevation={0}
              className={classes.search}
              style={{ marginLeft: 0 }}
            >
              <Input
                classes={{ input: classes.input }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                }
                placeholder={t('GPS_VIEW.SEARCH_BY_PLATE_OR_NIV')}
                className="flex mx-8"
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                onChange={handleSearch}
              />
            </Paper>
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

TransmissionGpsToolbar.propTypes = {
  className: PropTypes.string,
};

export default TransmissionGpsToolbar;
