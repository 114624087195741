export const selectDriver = state => state.drivers;
export const selectDriverDetail = state => selectDriver(state).detail;
export const selectAvailableDrivers = state => selectDriver(state).items;
export const selectDriversList = state => selectDriver(state).items;
export const selectDriversListCount = state => selectDriver(state).count;
export const selectDriversQueryOptions = state => {
  const { options } = selectDriver(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
export const selectTransactionBalance = state => selectDriver(state).balance;
export const selectTransactionsList = state =>
  selectDriver(state).transactionItems;

export const selectTransactionsListCount = state =>
  selectDriver(state).transactionsCount;

export const selectTransactionsQueryOptions = state => {
  const { transactionOptions } = selectDriver(state);
  Object.keys(transactionOptions).forEach(key => {
    if (transactionOptions[key] === '') {
      delete transactionOptions[key];
    }
  });

  return transactionOptions;
};

export const selectMovementsList = state => selectDriver(state).movementItems;

export const selectMovementsListCount = state =>
  selectDriver(state).movementsCount;

export const selectMovementsQueryOptions = state => {
  const { movementsOptions } = selectDriver(state);
  Object.keys(movementsOptions).forEach(key => {
    if (movementsOptions[key] === '') {
      delete movementsOptions[key];
    }
  });

  return movementsOptions;
};
export const selectDriverIncidents = state => selectDriver(state).incidents;

export const selectMetricsQueryOptions = state => {
  const { metricsOptions } = selectDriver(state);
  Object.keys(metricsOptions).forEach(key => {
    if (metricsOptions[key] === '') {
      delete metricsOptions[key];
    }
  });

  return metricsOptions;
};
export const selectMetricsListCount = state => selectDriver(state).metricsCount;
export const selectDriverMetrics = state => selectDriver(state).metrics;

export const selectTransactionMovements = state =>
  selectDriver(state).transactionMovements;
