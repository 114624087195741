import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getBusiness = serviceBase({
  request: options =>
    axiosClient.get('/business', {
      params: options,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    }),
  retry: 0,
});

export const getBusinessById = serviceBase({
  request: id => axiosClient.get(`/business/detail/${id}`),
  retry: 0,
});

export const createNewBusiness = serviceBase({
  request: values => axiosClient.post('/business', values),
  retry: 0,
});

export const updateBusinessBasicInfo = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/business/basic/${id}`, { ...values }),
  retry: 0,
});

export const updateBusinessContactInfo = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/business/contact/${id}`, { ...values }),
  retry: 0,
});

export const updateBusinessDocuments = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/business/documents/${id}`, { ...values }),
  retry: 0,
});

export const downloadeExportedBusiness = serviceBase({
  request: options =>
    axiosClient.get('/business/export', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'business.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

// TODO:

export const getBusinessTransactionsById = serviceBase({
  request: ({ id, reqOptions }) =>
    axiosClient.get(`/business/transactions/${id}`, {
      params: reqOptions,
    }),
  retry: 0,
});

export const createBusinessTransaction = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/business/transactions/${id}`, { ...values }),
  retry: 0,
});

export const deleteBusinessPayment = serviceBase({
  request: ({ id }) => axiosClient.delete(`/business/transactions/${id}`),
  retry: 0,
});

export const downloadeExportedBusinessTransactions = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/business/export/${id}`, {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'transactions.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});