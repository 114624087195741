import React from 'react';
import { Box, Container } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';
import useStyles from './styles';
import SoldCarsList from './SoldCarsList';

const SoldCarsListView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t('NAVIGATION.SOLD_CARS')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <SoldCarsList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default SoldCarsListView;
