import React from 'react';
import propTypes from 'prop-types';
import DetailRow from 'src/components/DetailRow';
import { useTranslation } from 'react-i18next';
import { formatPositiveAmount } from 'src/utils/formatAmount';
import { PlatformType } from 'src/utils/constants';

const DriverCalification = ({
  creditRiskScore,
  fraudScore,
  phoneScore,
  contactabilityScore,
  driver,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('DRIVER_DETAIL_VIEW.CALIFICATION.CREDIT_RISK_SCORE')}
        value={creditRiskScore || t('COMMON.NO_DATA')}
      />
      <DetailRow
        name={t('DRIVER_DETAIL_VIEW.CALIFICATION.FRAUD_SCORE')}
        value={fraudScore || t('COMMON.NO_DATA')}
      />
      <DetailRow
        name={t('DRIVER_DETAIL_VIEW.CALIFICATION.PHONE_SCORE')}
        value={phoneScore || t('COMMON.NO_DATA')}
      />
      <DetailRow
        name={t('DRIVER_DETAIL_VIEW.CALIFICATION.CONTACTABILITY_SCORE')}
        value={contactabilityScore || t('COMMON.NO_DATA')}
      />

      <DetailRow name={t('COMMON.PLATFORM')} value={driver.platform} />

      {driver.platform === PlatformType.UBER && (
        <DetailRow
          name={t('DRIVER_DETAIL_VIEW.CALIFICATION.UBER_PRO_STATUS')}
          value={driver.uberProStatus}
        />
      )}

      <DetailRow
        name={t('COMMON.NUMBER_OF_TRIPS')}
        value={formatPositiveAmount(driver.tripsNumber)}
      />

      {(driver.platform === PlatformType.UBER ||
        driver.platform === PlatformType.DIDI) && (
        <DetailRow
          name={t('COMMON.RATE_IN_PLATFORM')}
          value={driver.platformRating}
        />
      )}
    </>
  );
};

DriverCalification.propTypes = {
  creditRiskScore: propTypes.number,
  fraudScore: propTypes.number,
  phoneScore: propTypes.number,
  contactabilityScore: propTypes.number,
  driver: propTypes.object,
};

export default DriverCalification;
