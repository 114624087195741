/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumb from 'src/components/Breadcrumb';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import GeneralView from './General';
import BalanceView from './Balance';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `driver-detail-tab-${index}`,
    'aria-controls': `driver-detail-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
  },
}));

export default function BusinessTabs({ business }) {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <div className={classes.root}>
      <Breadcrumb
        history={[
          'NAVIGATION.GENERAL',
          'NAVIGATION.DRIVERS',
          'NAVIGATION.BUSINESS',
        ]}
        current={business.commercialName}
      />
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <IconButton onClick={goBack} color="primary" component="span">
          <ArrowLeft />
        </IconButton>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('NAVIGATION.GENERAL')} {...a11yProps(0)} />
          <Tab label={t('COMMON.BALANCE_VIEW')} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <GeneralView business={business} t={t} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BalanceView business={business} t={t} />
      </TabPanel>
    </div>
  );
}
