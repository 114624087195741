import { Divider, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  footer: {
    marginLeft: '20%',
    marginTop: 'auto',
    width: '65%',
    textAlign: 'center',
  },
  footerText: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(3),
    fontSize: theme.spacing(1.6),
    fontWeight: '400',
    letterSpacing: 0.25,
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.footer}>
      <Divider />
      <Typography className={classes.footerText}>
        {`Meteoros © ${moment().format('YYYY')}.
        ${t('LOGIN_VIEW.COPYRIGHT')}`}
      </Typography>
    </div>
  );
}
