import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import TableCell from 'src/components/Table/TableCell';
import { EXTERNAL_PAYMENT_METHODS } from 'src/utils/constants';
import {
  formatNegativeAmount,
  formatPositiveAmount,
} from 'src/utils/formatAmount';
import { Link } from 'react-router-dom';

const TransactionsRow = ({
  t,
  completedAt,
  type,
  origin,
  driverId,
  category,
  amount,
  driverName,
  balance,
  comment,
  reference,
  createdAt,
  carniv,
}) => {
  return (
    <TableRow hover>
      <TableCell align="center">
        {moment(createdAt).format('DD-MMM-YY HH:mm')}
      </TableCell>
      <TableCell>
        <Link style={{ color: 'blue' }} to={`/drivers/detail/${driverId}`}>
          {driverId}
        </Link>
      </TableCell>
      <TableCell>{driverName}</TableCell>
      <TableCell>
        <Link style={{ color: 'blue' }} to={`/fleet/car/${carniv}`}>
          {carniv}
        </Link>
      </TableCell>
      <TableCell>{t(`TRANSACTION.CATEGORY.${category}`)}</TableCell>
      <TableCell>{t(`TRANSACTION.ORIGIN.${origin}`)}</TableCell>
      <TableCell align="right">
        {type === 'PAYMENT' ? formatNegativeAmount(amount) : '--'}
      </TableCell>
      <TableCell align="right">
        {type === 'PAYMENT' ? '--' : formatPositiveAmount(amount)}
      </TableCell>
      <TableCell align="right">
        {formatPositiveAmount(balance) || '--'}
      </TableCell>
      <TableCell align="center">
        {EXTERNAL_PAYMENT_METHODS.includes(origin) && !!reference
          ? reference
          : '--'}
      </TableCell>
      <TableCell align="center">
        {EXTERNAL_PAYMENT_METHODS.includes(origin) && !!completedAt
          ? moment(completedAt).format('HH:mm')
          : '--'}
      </TableCell>
      <TableCell>{comment || '--'}</TableCell>
    </TableRow>
  );
};

TransactionsRow.propTypes = {
  t: PropTypes.func,
  createdAt: PropTypes.string,
  completedAt: PropTypes.string,
  type: PropTypes.string,
  origin: PropTypes.string,
  category: PropTypes.string,
  amount: PropTypes.string,
  balance: PropTypes.string,
  comment: PropTypes.string,
  reference: PropTypes.string,
  driverId: PropTypes.string,
  driverName: PropTypes.string,
  carniv: PropTypes.string,
};

export default TransactionsRow;
