import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  getIncomeResume,
  downloadExportedIncomeResume,
} from 'src/services/incomeResumeService';
import { selectIncomeResumeOptions } from '../selectors/incomeResumeSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_INCOME_RESUME = asyncActionCreator('LOAD_INCOME_RESUME');
export const CLEAR_INCOME_RESUME = 'CLEAR_INCOME_RESUME';
export const SAVE_INCOME_RESUME_OPTIONS = 'SAVE_INCOME_RESUME_OPTIONS';
export const CLEAR_INCOME_RESUME_OPTIONS = 'CLEAR_INCOME_RESUME_OPTIONS';
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';

const getOptions = (getState, sendLimit = true) => {
  const queryOpts = selectIncomeResumeOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.skip = queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }

  if (queryOpts.rangeType) {
    reqOptions.rangeType = queryOpts.rangeType;
  }

  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }

  if (queryOpts.balanceType) {
    reqOptions.balanceType = queryOpts.balanceType;
  }
  if (queryOpts.carMode) {
    reqOptions.carMode = queryOpts.carMode;
  }

  return reqOptions;
};

export const loadIncomeResume = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_INCOME_RESUME.start });

    dispatch({
      type: SAVE_INCOME_RESUME_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getIncomeResume(reqOptions);

    return dispatch({
      type: LOAD_INCOME_RESUME.success,
      ...data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_INCOME_RESUME.failure });
  }
};

export const clearIncomeResume = () => dispatch => {
  dispatch({ type: CLEAR_INCOME_RESUME });
};

export const clearIncomeResumeOptions = () => dispatch => {
  dispatch({ type: CLEAR_INCOME_RESUME_OPTIONS });
};

export const downloadIncomeResume = () => async (dispatch, getState) => {
  try {
    const reqOptions = getOptions(getState);
    return downloadExportedIncomeResume({ rangeType: reqOptions.rangeType });
  } catch (error) {
    return dispatch(showMessage({ message: error.message, variant: 'error' }));
  }
};
