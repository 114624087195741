/* eslint-disable react/prop-types */
import React from 'react';
import DriverDetailView from 'src/views/driver/DriverDetail';
import { Route } from 'react-router-dom';
import BusinessListView from './Business';
import BusinessDetailView from './BusinessDetail';
import DriversListView from './Drivers';

const DriversView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/businesses`} component={BusinessListView} />
      <Route path={`${match.url}/business/:id`} component={BusinessDetailView} />
      <Route path={`${match.url}/list`} component={DriversListView} />
      <Route path={`${match.url}/detail/:id`} component={DriverDetailView} />
    </>
  );
};

export default DriversView;
