export const selectCar = state => state.cars;
export const selectCarList = state => selectCar(state).items;
export const selectCarListCount = state => selectCar(state).count;
export const selectCarQueryOptions = state => {
  const { options } = selectCar(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
export const selectCarDetail = state => selectCar(state).detail;
export const selectCarForm = state => selectCar(state).form;
export const selectAvailableCarList = state => selectCar(state).availableCars;
export const selectCarAuditList = state => selectCar(state).carAudit.items;
export const selectCarAuditListCount = state => selectCar(state).carAudit.count;
export const selectCarAuditQueryOptions = state => {
  const { options } = selectCar(state).carAudit;

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
export const selectCarLocationList = state =>
  selectCar(state).carLocation.items;
export const selectCarLocationListCount = state =>
  selectCar(state).carLocation.count;
export const selectCarLocationQueryOptions = state => {
  const { options } = selectCar(state).carLocation;

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
