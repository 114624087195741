import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { Img } from 'react-image';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RotateIcon from '@material-ui/icons/RotateRight';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditImageDialog = ({ open, onClose, imageUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [rotation, setRotation] = useState(0);
  const [zoomed, setZoomed] = useState(false);

  const zoomImage = () => {
    setZoomed(!zoomed);
  };

  const handleRotate = () => {
    setRotation(rotation + 90);
  };

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      classes={{ paper: classes.root }}
      keepMounted
    >
      <DialogTitle>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Img
          src={imageUrl}
          alt="Imagen"
          id="myImage"
          style={{
            transform: `rotate(${rotation}deg) scale(${zoomed ? 2 : 1})`,
            transition: 'transform 0.3s ease',
            borderRadius: '.5rem',
            display: 'block',
            maxHeight: '70%',
            maxWidth: '40%',
            objectFit: 'cover',
            margin: 'auto',
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.buttons}
          onClick={handleRotate}
          variant="contained"
          color="primary"
          startIcon={<RotateIcon />}
        >
          {t('COMMON.ROTATE')}
        </Button>
        <Button
          className={classes.buttons}
          onClick={zoomImage}
          variant="contained"
          color="primary"
          startIcon={zoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
        >
          {zoomed ? t('COMMON.ZOOM_OUT') : t('COMMON.ZOOM_IN')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditImageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imageUrl: PropTypes.object.isRequired,
};

export default EditImageDialog;
