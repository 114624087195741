import axios from 'axios';
import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getAvailableDrivers = serviceBase({
  request: isBusiness =>
    axiosClient.get('/driver/available', {
      params: { isBusiness },
    }),
  retry: 0,
});

export const createNewDriver = serviceBase({
  request: driver => axiosClient.post(`/driver`, driver),
  retry: 0,
});

export const getDriverById = serviceBase({
  request: id => axiosClient.get(`/driver/detail/${id}`),
  retry: 0,
});

export const getDrivers = serviceBase({
  request: options =>
    axiosClient.get('/driver', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    }),
  retry: 0,
});

export const assignDriver = serviceBase({
  request: values => axiosClient.post('/driver/assign', values),
  retry: 0,
});

export const unassignDriver = serviceBase({
  request: values => axiosClient.put('/driver/unassign', values),
  retry: 0,
});

export const cancelAssing = serviceBase({
  request: values => axiosClient.post('/driver/cancel-assgination', values),
  retry: 0,
});

export const changeDriverCar = serviceBase({
  request: values => axiosClient.post('/driver/changeCar', values),
  retry: 0,
});

export const updateDriverStatusService = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/driver/status/${id}`, { ...values }),
  retry: 0,
});

export const enableDriverService = serviceBase({
  request: ({ id }) => axiosClient.post(`/driver/enable/${id}`),
  retry: 0,
});

export const activeDriverService = serviceBase({
  request: ({ id }) => axiosClient.put(`/driver/active/${id}`),
  retry: 0,
});

export const unactiveDriverService = serviceBase({
  request: ({ id }) => axiosClient.put(`/driver/unactive/${id}`),
  retry: 0,
});

export const updateDriverBasicInfo = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/driver/basic/${id}`, { ...values }),
  retry: 0,
});

export const updateDriverDocuments = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/driver/documents/${id}`, { ...values }),
  retry: 0,
});

export const createAndAssignAval = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/driver/aval/assign/${id}`, { ...values }),
  retry: 0,
});

export const editAval = serviceBase({
  request: ({ id, values }) =>
    axiosClient.put(`/driver/aval/${id}`, { ...values }),
  retry: 0,
});

export const deleteAval = serviceBase({
  request: ({ id }) => axiosClient.post(`/driver/aval/unassign/${id}`),
  retry: 0,
});

export const getUploadLink = serviceBase({
  request: ({ format, contentType }) =>
    axiosClient.get(`/aws/backoffice-upload-link`, {
      params: { format, contentType },
    }),
  retry: 0,
});

export const uploadFile = serviceBase({
  request: ({ targetURL, file }) =>
    axios.put(targetURL, file, { timeout: 30000 }),
  retry: 0,
});

export const updateSigleFile = serviceBase({
  request: file => axiosClient.put(`/aws`, file),
  retry: 0,
});

export const getDriverTransactionsById = serviceBase({
  request: ({ id, reqOptions }) =>
    axiosClient.get(`/driver/transactions/${id}`, {
      params: reqOptions,
    }),
  retry: 0,
});

export const createTransaction = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/driver/transactions/${id}`, { ...values }),
  retry: 0,
});

export const deletePayment = serviceBase({
  request: ({ id }) => axiosClient.delete(`/driver/transactions/${id}`),
  retry: 0,
});

export const downloadeExportedTransactions = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/driver/export/${id}`, {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'transactions.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const downloadExportedDrivers = serviceBase({
  request: options =>
    axiosClient.get('/driver/list/export', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'drivers.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const getDriverIncidentsById = serviceBase({
  request: id => axiosClient.get(`/driver/incidents/${id}`),
  retry: 0,
});

export const getKilometersTraveledById = serviceBase({
  request: ({ id, reqOptions }) =>
    axiosClient.get(`/driver/mileages-traveled/${id}`, {
      params: reqOptions,
    }),
  retry: 0,
});

export const downloadExportedMovements = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/deferred-charge/pending/download/${id}`, {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'pending-transactions.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
});

export const createLease = serviceBase({
  request: values => axiosClient.post(`/lease`, values),
  retry: 0,
});

export const revokeLease = serviceBase({
  request: id => axiosClient.delete(`/lease/${id}`),
  retry: 0,
});

export const updatePromissoryDocument = serviceBase({
  request: ({ values, id }) =>
    axiosClient.put(`/driver/update-promissory/${id}`, values),
  retry: 0,
});

export const resendSignNotification = serviceBase({
  request: contractId =>
    axiosClient.post(`/driver/contract/resend-sign-link/${contractId}`),
  retry: 0,
});

export default getAvailableDrivers;
