import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'src/store/actions/businessActions';
import { ReactComponent as MoneyIcon } from 'src/assets/icons/attach_money.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { useTranslation } from 'react-i18next';
import { selectTransactionBalance } from 'src/store/selectors/businessSelectors';
import { formatPositiveAmount } from 'src/utils/formatAmount';

import ManualTransactionDialog from './ManualTransactionDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    minWidth: theme.spacing(55),
    justifyContent: 'space-between',
  },
  balanceContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  balance: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc((100% - 24px) / 2)',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(),
    padding: theme.spacing(4),
  },
  balanceIcon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    background: 'rgba(40,167,69,0.1)',
    borderRadius: theme.spacing(),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
}));

const Toolbar = ({ business }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const balance = useSelector(selectTransactionBalance);
  const [openTransactionMenu, setOpenTransactionMenu] = useState(false);
  const [transactionType, setTransactionType] = useState(null);

  const handleOpenPayment = useCallback(() => {
    setTransactionType('PAYMENT');
    setOpenTransactionMenu(true);
  }, [setOpenTransactionMenu]);

  const handleOpenCharge = useCallback(() => {
    setTransactionType('CHARGE');
    setOpenTransactionMenu(true);
  }, [setOpenTransactionMenu]);

  const handleCloseTransaction = useCallback(() => {
    setOpenTransactionMenu(false);
  }, [setOpenTransactionMenu]);

  const handleSubmitTransaction = useCallback(
    values => {
      handleCloseTransaction();
      dispatch(
        Actions.createTransaction(business.id, {
          type: transactionType,
          ...values,
        }),
      );
    },
    [handleCloseTransaction, dispatch, transactionType],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(Actions.downloadExcelBusinessTransactions(business.id));
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.actionsContainer}>
        <Typography color="primary" component="h4" variant="h4">
          {business.fullName}
        </Typography>
        <div className={classes.buttonsContainer}>
          <Button
            style={{ marginRight: 0 }}
            className={clsx(classes.buttons, classes.horizontalPadding)}
            color="primary"
            variant="contained"
            onClick={handleOpenCharge}
          >
            <PlusIcon style={{ marginRight: 8 }} fill="white" />
            {t('COMMON.MANUAL_CHARGE')}
          </Button>

          <Button
            style={{ marginRight: 0 }}
            className={clsx(classes.buttons, classes.horizontalPadding)}
            color="primary"
            variant="contained"
            onClick={handleOpenPayment}
          >
            <PlusIcon style={{ marginRight: 8 }} fill="white" />
            {t('COMMON.MANUAL_PAYMENT')}
          </Button>
          <Button
            className={clsx(classes.buttons, classes.outlined)}
            onClick={handleExcelExport}
            color="primary"
          >
            <ExportIcon className={classes.icon} />
          </Button>
        </div>
      </div>

      <div className={classes.balanceContainer}>
        <div className={classes.balance}>
          <div className={classes.balanceIcon}>
            <MoneyIcon fill="#28A745" />
          </div>
          <div>
            <Typography
              style={{ marginBottom: 8 }}
              color="textPrimary"
              component="p"
              variant="body2"
            >
              {t('COMMON.BALANCE')}
            </Typography>
            <Typography color="primary" component="h2" variant="h2">
              {`$${formatPositiveAmount(balance.debts)}`}
            </Typography>
          </div>
        </div>

        <div />
      </div>

      <ManualTransactionDialog
        debts={balance.debts}
        type={transactionType}
        open={openTransactionMenu}
        handleClose={handleCloseTransaction}
        submit={handleSubmitTransaction}
      />
    </div>
  );
};

Toolbar.propTypes = {
  business: PropTypes.object,
};

export default Toolbar;
