/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import TableCell from 'src/components/Table/TableCell';
import {
  formatPositiveAmount,
  formatNegativeAmount,
} from 'src/utils/formatAmount';
import { EXTERNAL_PAYMENT_METHODS } from 'src/utils/constants';

const TransactionsRow = ({
  t,
  type,
  origin,
  category,
  amount,
  balance,
  comment,
  createdAt,
  transactionId,
  reference,
  completedAt,
  handlePaymentDelete,
}) => {
  return (
    <TableRow hover>
      <TableCell>{moment(createdAt).format('DD-MMM-YY')}</TableCell>
      <TableCell>{moment(createdAt).format('HH:mm')}</TableCell>
      <TableCell>
        {EXTERNAL_PAYMENT_METHODS.includes(origin) && !!completedAt
          ? moment(completedAt).format('HH:mm')
          : '--'}
      </TableCell>
      <TableCell>
        {EXTERNAL_PAYMENT_METHODS.includes(origin) && !!reference
          ? reference
          : '--'}
      </TableCell>
      <TableCell>{t(`TRANSACTION.CATEGORY.${category}`)}</TableCell>
      <TableCell>{t(`TRANSACTION.ORIGIN.${origin}`)}</TableCell>
      <TableCell>{comment || '--'}</TableCell>
      <TableCell>
        {type === 'PAYMENT' ? '--' : formatPositiveAmount(amount)}
      </TableCell>
      <TableCell>
        {type === 'PAYMENT' ? formatNegativeAmount(amount) : '--'}
      </TableCell>
      <TableCell>{formatPositiveAmount(balance) || '--'}</TableCell>
      <TableCell>
        {origin === 'MANUAL' && type === 'PAYMENT' ? (
          <IconButton
            style={{ padding: 0 }}
            onClick={() => handlePaymentDelete(transactionId)}
            aria-label="delete payment"
            component="span"
          >
            <DeleteIcon style={{ color: '#EE3552' }} />
          </IconButton>
        ) : (
          '--'
        )}
      </TableCell>
    </TableRow>
  );
};

export default TransactionsRow;
