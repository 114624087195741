/* eslint-disable react/prop-types */
import React from 'react';
import {
  Grid,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  tableCell: {
    content: '',
    display: 'block',
    margin: '0 auto',
    width: '90%',
  },
}));

const TwoColumnsRow = ({ name, value, extra, extraStyles = {} }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        <Grid container alignItems="flex-start" columns={2}>
          <Grid container item direction="column" xs={8}>
            <Grid item className={classes.title}>
              {name}
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {value}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} {...extraStyles}>
            {extra}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default TwoColumnsRow;
