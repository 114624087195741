import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getDepreciationBalance = serviceBase({
  request: options =>
    axiosClient.get('/accounting/depreciation/balance', { params: options }),
  retry: 0,
});

export const getDepreciationResume = serviceBase({
  request: options =>
    axiosClient.get('/accounting/depreciation/resume', { params: options }),
  retry: 0,
});

export const downloadExcelDepreciationBalanceList = serviceBase({
  request: options =>
    axiosClient.get('accounting/depreciation/download', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'Depreciacion.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const getAmortizationBalance = serviceBase({
  request: options =>
    axiosClient.get('/accounting/amortization/balance', { params: options }),
  retry: 0,
});

export const getAmortizationResume = serviceBase({
  request: options =>
    axiosClient.get('/accounting/amortization/resume', { params: options }),
  retry: 0,
});

export const downloadExcelListAmorizationBalance = serviceBase({
  request: options =>
    axiosClient.get('accounting/amortization/download', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    try {
      const filename = 'Amortizacion.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});

export const getFleetMovements = serviceBase({
  request: options =>
    axiosClient.get('/accounting/fleet-movements', { params: options }),
  retry: 0,
});

export const downloadExcelListFleetMovements = serviceBase({
  request: options =>
    axiosClient.get('accounting/fleet-movements/download', {
      responseType: 'blob',
      params: options,
    }),
  transformResponse: res => {
    const filename = 'Movimientos de flota.xlsx';
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(res.data);
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  },
  retry: 0,
});
