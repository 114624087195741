import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from 'src/components/Preloader';
import { selectDriverMetrics } from 'src/store/selectors/driverSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import * as Actions from 'src/store/actions/driverActions';
import DriverMetrics from './DriverMetrics';

const useStyles = makeStyles(() => ({
  preloaderCard: {
    height: '75vh',
  },
}));

const Metrics = ({ driver }) => {
  const classes = useStyles();
  const { id } = driver;
  const dispatch = useDispatch();

  const mileages = useSelector(selectDriverMetrics);

  const loading = useSelector(isLoadingSelector([Actions.LOAD_METRICS.action]));
  const success = useSelector(successSelector([Actions.LOAD_METRICS.action]));

  useEffect(() => {
    dispatch(Actions.loadMileagesTraveled(id));
  }, []);

  return (
    <Box mt={3}>
      {success && !loading && mileages ? (
        <DriverMetrics mileageList={mileages} id={id} />
      ) : (
        <Card className={classes.preloaderCard}>
          <Preloader />
        </Card>
      )}
    </Box>
  );
};

Metrics.propTypes = {
  driver: PropTypes.object,
};

export default Metrics;
