/* eslint-disable no-unneeded-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import {
  CLEAR_CAMPAIGNS,
  loadCampaignListAction,
} from 'src/store/actions/campaignsActions';
import Breadcrumb from 'src/components/Breadcrumb';
import { selectCampaignsQueryOptions } from 'src/store/selectors/campaignsSelector';
import { CAMPAIGN_TYPE } from 'src/utils/constants';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import FadeIn from 'react-fade-in';
import CreateOrEditCampaignDialog from './CreateOrEditCampaignDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsToolbar: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  buttonPadding: { marginRight: 6 },
  icon: {
    fill: theme.palette.primary.main,
  },
}));

const filters = [
  {
    title: 'CAMPAIGNS_VIEW.RECURRENCE',
    controlGroup: FilterControlGroups.CHECKBOX,
    value: 'recurrent',
    items: [
      {
        label: 'CAMPAIGNS_VIEW.RECURRENT',
        value: 'recurrent',
      },
      {
        label: 'CAMPAIGNS_VIEW.NOT_RECURRENT',
        value: 'notRecurrent',
      },
    ],
  },
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'COMMON.ACTIVE',
        value: 'active',
      },
      {
        label: 'COMMON.INACTIVE',
        value: 'inactive',
      },
    ],
  },
];

const CampaignsToolbar = ({ className, filterView, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCampaignsQueryOptions);

  const [open, setOpen] = useState(false);

  const [filterValues, setFilterValues] = React.useState({
    type: '',
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleOpenFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleSubmitFilter = queryOpts => {
    dispatch(
      loadCampaignListAction({ ...queryOpts, type: CAMPAIGN_TYPE.GENERAL }),
    );
    handleCloseFilter();
  };

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      handleSubmitFilter({
        ...queryOptions,
        page: 0,
        ...event,
      });
    },
    [handleSubmitFilter, queryOptions],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    dispatch(
      loadCampaignListAction({
        ...queryOptions,
        type: CAMPAIGN_TYPE.GENERAL,
      }),
    );
    return () => dispatch({ type: CLEAR_CAMPAIGNS });
  }, []);

  return (
    <Box>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.ADMIN']}
          current="NAVIGATION.CAMPAIGNS"
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.CAMPAIGNS')}
          </Typography>
          {filterView === 0 && (
            <Box className={classes.actionsContainer}>
              <FadeIn
                transitionDuration={300}
                className={classes.actionsContainer}
              >
                <Button
                  className={clsx(
                    classes.buttons,
                    classes.outlined,
                    classes.horizontalPadding,
                  )}
                  color="primary"
                  onClick={handleOpenFilter}
                >
                  <FilterIcon className={classes.icon} />
                  {t('COMMON.FILTER')}
                </Button>

                <Button
                  className={clsx(
                    classes.buttonsToolbar,
                    classes.horizontalPadding,
                  )}
                  color="primary"
                  variant="contained"
                  onClick={handleOpen}
                >
                  <PlusIcon
                    className={clsx(classes.buttonPadding)}
                    fill="white"
                  />
                  {t('CAMPAIGNS_VIEW.ADD_CAMPAIGN')}
                </Button>

                <Filter
                  anchorEl={anchorEl}
                  filterValues={filterValues}
                  handleClose={handleCloseFilter}
                  handleChange={handleFilterChange}
                  filters={filters}
                />

                <CreateOrEditCampaignDialog
                  open={open}
                  handleClose={handleClose}
                />
              </FadeIn>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CampaignsToolbar.propTypes = {
  className: PropTypes.string,
  filterView: PropTypes.number,
};

export default CampaignsToolbar;
