/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCarXml } from 'src/store/actions/carActions';
import { selectCarForm } from 'src/store/selectors/carSelectors';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CarCreationStepper from './CarCreationStepper';
import CurrentStep from './CurrentStep';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '& h2': {
      fontWeight: 400,
      fontSize: theme.spacing(4.25),
      lineHeight: `${theme.spacing(6.5)}px`,
    },
  },
  root: {
    minWidth: theme.spacing(68),
  },
  closeIcon: {
    float: 'right',
    marginTop: '-25px',
    width: '20px',
    height: '20px',
  },
}));

const CardForm = ({ open, handleClose, handleCreateCar }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { niv, serie, purchaseDate, rfc, catalog, vat, total } = useSelector(
    selectCarForm,
  );
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, [open]); // DO NOT ADD DEPENDENCY - the intended behavior is achieved like this

  useEffect(() => {
    setData(state => ({
      ...state,
      purchaseDate,
      rfc,
      catalog,
      niv,
      serie,
      vat,
      total,
    }));
  }, [setData, niv, serie, purchaseDate, rfc, catalog, vat, total]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data],
  );

  const handleBackOrClose = useCallback(() => {
    if (step === 0) {
      handleClose();
      dispatch(clearCarXml());
    } else {
      setStep(step - 1);
    }
  }, [dispatch, step, setStep, handleClose]);

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      handleCreateCar(data);
    },
    [data],
  );

  return (
    <div>
      <Dialog
        aria-labelledby="form-new-car-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        onClose={handleClose}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography className={classes.title} id="form-new-car-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
              setData({});
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            Ingresar auto nuevo
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <DialogContent>
          <CarCreationStepper step={step} />
          <CurrentStep
            activeStep={step}
            data={data}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CardForm;
