/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as LoginLogo } from 'src/assets/images/login_logo.svg';
import { ReactComponent as Car } from 'src/assets/images/login_car.svg';
import RaceTrack from 'src/assets/images/race_track.png';
import useWindowSize from 'src/hooks/useWindowSize';

function wp(percentage, viewportWidth) {
  const value = (percentage * viewportWidth) / 100;
  return Math.round(value);
}

function wh(percentage, viewportHeigth) {
  const value = (percentage * viewportHeigth) / 100;
  return Math.round(value);
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  banner: {
    width: '35.76%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  content: {
    width: '64.24%',
    backgroundColor: theme.palette.common.white,
  },
  raceTrack: {
    width: '100%',
    height: '48%',
    filter: 'opacity(0.3)',
    backgroundImage: `url(${RaceTrack})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundBlendMode: 'lighten',
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: 125,
    boxShadow: `0 0 ${theme.spacing(2)}px ${theme.spacing()}px ${
      theme.palette.secondary.main
    } inset`,
    backgroundSize: 'cover',
  },
  logo: {
    margin: `${theme.spacing(10)}px 0`,
    zIndex: 1,
  },
  car: {
    marginLeft: theme.spacing(3),
    zIndex: 1,
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [width, height] = useWindowSize();

  const carWidth = wp(width, 37);
  const carHeight = wh(height, 37);

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <LoginLogo className={classes.logo} />
        <div className={classes.raceTrack} />
        <Car width={carWidth} height={carHeight} className={classes.car} />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default MainLayout;
