/* eslint-disable react/prop-types */
import React from 'react';
import CarsListView from 'src/views/fleet/Cars';
import InsurancesListView from 'src/views/fleet/Insurances';
import InactiveCarsListView from 'src/views/fleet/InactiveCars';
import CarDetailView from 'src/views/fleet/CarDetail';
import { Redirect, Route } from 'react-router-dom';
import LicencesListView from './Licences';
import SoldCarsListView from './SoldCars';

const FleetView = ({ match }) => {
  return (
    <>
      <Route path={`${match.url}/cars`} component={CarsListView} />
      <Route path={`${match.url}/car/:niv`} component={CarDetailView} />
      <Route
        path={`${match.url}/inactive-cars`}
        component={InactiveCarsListView}
      />
      <Route path={`${match.url}/insurance`} component={InsurancesListView} />
      <Route path={`${match.url}/licences`} component={LicencesListView} />
      <Route path={`${match.url}/sold`} component={SoldCarsListView} />
      <Route
        exact
        path={`${match.url}`}
        component={() => <Redirect to={`${match.url}/cars`} />}
      />
    </>
  );
};

export default FleetView;
