import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from 'src/components/Preloader';
import { selectDriverIncidents } from 'src/store/selectors/driverSelectors';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import * as Actions from 'src/store/actions/driverActions';
import DriverIncidents from './DriverIncidents';

const useStyles = makeStyles(() => ({
  preloaderCard: {
    height: '75vh',
  },
}));

const IncidentsHistory = ({ driver }) => {
  const classes = useStyles();
  const { id, fullName: driverName } = driver;
  const dispatch = useDispatch();

  const incidents = useSelector(selectDriverIncidents);

  const loading = useSelector(
    isLoadingSelector([Actions.LOAD_INCIDENTS.action]),
  );
  const success = useSelector(successSelector([Actions.LOAD_INCIDENTS.action]));

  useEffect(() => {
    dispatch(Actions.loadDriverIncidents(id));
  }, []);

  return (
    <Box mt={3}>
      {success && !loading && incidents ? (
        <DriverIncidents driverName={driverName} incidents={incidents} />
      ) : (
        <Card className={classes.preloaderCard}>
          <Preloader />
        </Card>
      )}
    </Box>
  );
};

IncidentsHistory.propTypes = {
  driver: PropTypes.object,
};

export default IncidentsHistory;
