import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AssignDriverDialog from 'src/views/fleet/CarDetail/CarDriver/AssignDriverDialog';
import Loading from 'src/components/Loading';
import {
  cancelAssignDriver,
  CANCEL_ASSIGNATION,
  CHANGE_DRIVER_ASSIGNATION,
  loadDriverDetail,
  resendSignNotificationAction,
  RESEND_SIGN_NOTIFICATION,
  UNASSIGN_DRIVER,
} from 'src/store/actions/driverActions';
import { AssignationStatus } from 'src/utils/constants';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { useTranslation } from 'react-i18next';
import SendIcon from '@material-ui/icons/Send';
import Preloader from 'src/components/Preloader';
import DriverCarInfo from './DriverCarInfo';
import useStyles from '../styles';

const DriverCarDetail = ({
  driverId,
  actualDriverCar,
  actualDriverAssignation,
  actualDriverAssignationId,
  actualDriverAssignationNiv,
  actualDriverIsLease,
  actualDriverHasLease,
  assignationStatus,
  contract,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const cancelAssignationLoading = useSelector(
    isLoadingSelector([CANCEL_ASSIGNATION.action]),
  );
  const changeAssignDriverLoading = useSelector(
    isLoadingSelector([CHANGE_DRIVER_ASSIGNATION.action]),
  );

  const unassingDriverLoading = useSelector(
    isLoadingSelector([UNASSIGN_DRIVER.action]),
  );
  const resendSignNotificationLoading = useSelector(
    isLoadingSelector([RESEND_SIGN_NOTIFICATION.action]),
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCancelAsignation = useCallback(() => {
    dispatch(cancelAssignDriver({ driverId }));
  }, []);

  const handleSubmit = useCallback(
    action => values => {
      dispatch(
        action({
          ...values,
          niv: actualDriverAssignationNiv,
          id: actualDriverAssignationId,
        }),
      );
    },
    [actualDriverAssignationNiv, actualDriverAssignationId, dispatch],
  );

  const hanldeResendSignNotification = () => {
    dispatch(resendSignNotificationAction(contract.id));
  };

  const driverUpdate = useCallback(() => {
    dispatch(loadDriverDetail(driverId));
  }, [driverId, dispatch]);

  const setLoadingMessage = useCallback(() => {
    switch (true) {
      case cancelAssignationLoading:
        return 'LOADING_MESSAGE.CANCEL_ASSIGNATION';
      case changeAssignDriverLoading:
        return 'LOADING_MESSAGE.CHANGE_DRIVER_ASSIGNATION';
      case unassingDriverLoading:
        return 'LOADING_MESSAGE.UNASSIGN_DRIVER';
      default:
        return '';
    }
  });

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.tableCell} component="h4" variant="h4">
            {t('FLEET_VIEW.CAR_ASSIGNED')}
          </Typography>
        }
      />
      <Divider className={classes.tableCell} />
      <CardContent style={{ padding: 0 }}>
        {cancelAssignationLoading ||
        changeAssignDriverLoading ||
        unassingDriverLoading ? (
          <Loading label={t(setLoadingMessage())} />
        ) : (
          <>
            <Table>
              <TableBody>
                <DriverCarInfo
                  actualDriverCar={actualDriverCar}
                  actualDriverAssignation={actualDriverAssignation}
                  actualDriverAssignationNiv={actualDriverAssignationNiv}
                  assignationStatus={assignationStatus}
                />
              </TableBody>
            </Table>

            {assignationStatus === AssignationStatus.PENDING && (
              <Box
                style={{ padding: '16px' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex"
              >
                {!resendSignNotificationLoading ? (
                  <Box>
                    <Button
                      onClick={hanldeResendSignNotification}
                      variant="contained"
                      color="primary"
                      className={clsx(classes.label, classes.buttons)}
                      startIcon={<SendIcon />}
                    >
                      {t('COMMON.RESEND_TO_SIGN')}
                    </Button>
                    <Button
                      onClick={handleCancelAsignation}
                      variant="contained"
                      color="primary"
                      className={clsx(classes.label, classes.buttons)}
                      startIcon={<EditIcon />}
                      disabled={actualDriverIsLease && actualDriverHasLease}
                    >
                      {t('DRIVER_DETAIL_VIEW.LEASE.CANCEL_ASSIGNATION')}
                    </Button>
                  </Box>
                ) : (
                  <Preloader />
                )}
              </Box>
            )}

            {assignationStatus === AssignationStatus.COMPLETED && (
              <Box
                alignItems="center"
                display="flex"
                className={classes.buttons}
              >
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  color="primary"
                  className={clsx(classes.label, classes.buttons)}
                  startIcon={<EditIcon />}
                >
                  {t('FLEET_VIEW.UNASSIGN_DRIVER')}
                </Button>
              </Box>
            )}
          </>
        )}

        <AssignDriverDialog
          assigned
          open={open}
          niv={actualDriverAssignationNiv}
          handleClose={handleClose}
          submit={handleSubmit}
          actionUpdate={driverUpdate}
        />
      </CardContent>
    </Card>
  );
};

DriverCarDetail.propTypes = {
  driverId: propTypes.number,
  actualDriverCar: propTypes.object,
  actualDriverAssignation: propTypes.object,
  actualDriverAssignationId: propTypes.number,
  actualDriverAssignationNiv: propTypes.string,
  actualDriverIsLease: propTypes.bool,
  actualDriverHasLease: propTypes.bool,
  assignationStatus: propTypes.string,
  contract: propTypes.object,
};

export default DriverCarDetail;
