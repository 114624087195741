export const selectRentResume = state => state.rentResume;
export const selectRentResumeList = state => selectRentResume(state).items;
export const selectRentResumeCount = state => selectRentResume(state).count;
export const selectRentResumeOptions = state => {
  const { options } = selectRentResume(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
