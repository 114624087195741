import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DetailRow from 'src/components/DetailRow';

const useStyles = makeStyles(theme => ({
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    width: '80%',
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

const InfoCard = ({ title, fields }) => {
  const classes = useStyles();

  return (
    <Card variant="elevation">
      <CardHeader
        className={classes.header}
        title={
          <Typography className={classes.title} component="h4" variant="h4">
            {title}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Box className={classes.infoContainer}>
          {fields.map(field => (
            <DetailRow key={field.name} name={field.name} value={field.value} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
};

export default InfoCard;
