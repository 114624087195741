import * as Yup from 'yup';

export default Yup.object().shape({
  ifeFile: Yup.mixed(),
  licenceFile: Yup.mixed(),
  waterFile: Yup.mixed(),
  pictureFile: Yup.mixed(),
  licenceExpiredAt: Yup.date(),
  ifeAval: Yup.mixed(),
  licenceAval: Yup.mixed(),
  waterAval: Yup.mixed(),
});
