import moment from 'moment';

export default values => ({
  expenseConcept: values.expenseConcept || '',
  plate: values.plate || '',
  driverId: values.driverId || '',
  driverName: values.driverName || '',
  provider: values.provider || '',
  amount: values.amount || '',
  incidentDate: values.incidentDate || moment(),
});
