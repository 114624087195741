import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import TableCell from 'src/components/Table/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ServiceRow from './ServiceRow';

const ServiceTable = ({
  className,
  services,
  handleOpenEdit,
  handleDelete,
  ...rest
}) => {
  const openEdit = useCallback(
    location => () => {
      handleOpenEdit(location);
    },
    [handleOpenEdit],
  );

  const theads = useMemo(() => () => (
    <TableRow>
      <TableCell>Nombre</TableCell>
      <TableCell>Precio</TableCell>
      <TableCell>Prioridad</TableCell>
      <TableCell>Acciones</TableCell>
    </TableRow>
  ));

  const tbody = useMemo(
    () => () => {
      return services.map(service => (
        <ServiceRow
          {...service}
          key={service.serviceId}
          openEdit={openEdit(service)}
          handleDelete={handleDelete}
        />
      ));
    },
    [services],
  );

  return <Table tbody={tbody} theads={theads} {...rest} />;
};

ServiceTable.propTypes = {
  className: PropTypes.string,
  services: PropTypes.array.isRequired,
  handleOpenEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ServiceTable;
