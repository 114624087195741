/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import useStyles from '../styles';

const DeletePaymentConfirmation = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      keepMounted
      onClose={handleClose}
      style={{ marginBottom: '16px' }}
    >
      <DialogTitle disableTypography className={classes.header} id="form-aval-driver">
        <Typography className={classes.header} color="primary" variant="h2">
          Borrar pago
        </Typography>
      </DialogTitle>
      <DialogContentText
        style={{ marginBottom: '16px' }}
        className={classes.confirmation}
        id="form-enable-delete-dialog"
      >
        Al hacer click en&nbsp;
        <b>Aceptar</b>
        &nbsp;borrará el pago seleccionado
      </DialogContentText>
      <Grid
        container
        style={{ marginBottom: '16px' }}
        direction="column"
        alignItems="stretch"
      >
        <Grid item className={classes.buttons}>
          <Button
            className={clsx(classes.label, classes.buttons)}
            onClick={handleSubmit}
            color="primary"
            variant="outlined"
          >
            Aceptar
          </Button>
        </Grid>
        <Grid item className={classes.buttons}>
          <Button
            className={clsx(classes.outlined, classes.buttons)}
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

DeletePaymentConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default DeletePaymentConfirmation;
