import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb';
import {
  downloadExcelCars,
  loadCars,
  CLEAR_CARS,
  createCar,
} from 'src/store/actions/carActions';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { selectCarQueryOptions } from 'src/store/selectors/carSelectors';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/add.svg';
import { ReactComponent as ExportIcon } from 'src/assets/icons/download_file.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter_alt.svg';
import { useTranslation } from 'react-i18next';
import Filter, { FilterControlGroups } from 'src/components/Filter';
import CardForm from './CarForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 44,
    letterSpacing: 1.25,
    fontSize: 16,
    fontWeight: '400',
    minWidth: 'unset',
  },
  outlined: {
    background: '#FFFFFF',
    fontWeight: '500',
    '&:hover': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
    '&:focus': {
      background: '#FFFFFF',
      opacity: 0.8,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    width: '70vh',
    border: '1px solid #EEEEEE',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  input: {
    width: '100%',
  },
  horizontalPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      marginRight: 8,
      marginLeft: 8,
    },
  },
}));

const filters = [
  {
    title: 'COMMON.BY_STATUS',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'FLEET_VIEW.ASSIGNED',
        value: 'assigned',
      },
      {
        label: 'FLEET_VIEW.AVAILABLE',
        value: 'available',
      },
      {
        label: 'FLEET_VIEW.PENDING_ASSIGNATION',
        value: 'pendingAssignation',
      },
    ],
  },
  {
    title: 'FLEET_VIEW.BY_MODE',
    controlGroup: FilterControlGroups.CHECKBOX,
    items: [
      {
        label: 'FLEET_VIEW.PLATFORM',
        value: 'platform',
      },
      {
        label: 'FLEET_VIEW.LEASE',
        value: 'lease',
      },
      /*   
      TODO: Uncomment this block when the feature is implemented
      {
        label: 'FLEET_VIEW.COMPANIES',
        value: 'companies',
      }, */
      {
        label: 'FLEET_VIEW.ADMINISTRATIVE',
        value: 'administrative',
      },
    ],
  },
];

function getFilterOptions(options) {
  const status = [];
  const mode = [];

  if (options.assigned) {
    status.push('ASSIGNED');
  }
  if (options.available) {
    status.push('AVAILABLE');
  }
  if (options.pendingAssignation) {
    status.push('PENDING_ASSIGNATION');
  }

  if (options.lease) {
    mode.push('LEASE');
  }
  if (options.platform) {
    mode.push('PLATFORM');
  }
  if (options.administrative) {
    mode.push('ADMINISTRATIVE');
  }

  /*
  TODO: Uncomment this block when the feature is implemented
if (options.companies) {
    mode.push('COMPANIES');
  } */

  return { status, mode };
}

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectCarQueryOptions);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({});
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce(
    (searchCriteria, queryOpts) =>
      dispatch(loadCars({ ...queryOpts, searchCriteria })),
    400,
  );

  useEffect(() => {
    dispatch(loadCars());
    return () => dispatch({ type: CLEAR_CARS });
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceSearch(searchValue, {
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event),
      });
    },
    [
      setFilterValues,
      searchValue,
      debounceSearch,
      queryOptions,
      getFilterOptions,
    ],
  );

  const handleCreateCar = useCallback(
    values => {
      dispatch(createCar(values, () => handleClose()));
    },
    [dispatch, handleClose],
  );

  const handleExcelExport = useCallback(() => {
    dispatch(downloadExcelCars());
  }, [dispatch]);

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      debounceSearch(value, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions, filterValues],
  );

  const Addornment = (
    <InputAdornment position="start">
      <Search size={20} />
    </InputAdornment>
  );

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb
          history={['NAVIGATION.GENERAL', 'NAVIGATION.FLEET']}
          current="FLEET_VIEW.TITLE"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontWeight: '400' }}
            color="primary"
            variant="h1"
          >
            {t('FLEET_VIEW.TITLE')}
          </Typography>
          <div className={classes.actionsContainer}>
            <Paper
              elevation={0}
              className={classes.search}
              style={{ marginLeft: 0, marginRight: '-8px' }}
            >
              <Input
                className={classes.input}
                startAdornment={Addornment}
                placeholder={t('FLEET_VIEW.SEARCH_CAR')}
                disableUnderline
                value={searchValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
                type="string"
                onChange={handleSearch}
              />
            </Paper>
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />
            <Button
              className={clsx(
                classes.buttons,
                classes.outlined,
                classes.horizontalPadding,
              )}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterIcon className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <ExportIcon className={classes.icon} />
            </Button>
            <Button
              className={clsx(classes.buttons, classes.horizontalPadding)}
              color="primary"
              variant="contained"
              onClick={handleOpen}
            >
              <PlusIcon style={{ marginRight: 8 }} fill="white" />
              {t('FLEET_VIEW.NEW_CAR')}
            </Button>
          </div>
        </div>
      </div>

      <CardForm
        open={open}
        handleCreateCar={handleCreateCar}
        handleClose={handleClose}
      />
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
