import { getBillingParameters } from 'src/services/billingService';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { selectBillingOptions } from '../selectors/billingSelector';
import { showMessage } from './snackBarActions';

export const SAVE_BILLING_OPTIONS = 'SAVE_BILLING_OPTIONS';
export const CLEAR_BILLING_OPTIONS = 'CLEAR_BILLING_OPTIONS';
export const LOAD_BILLING_PARAMETERS = asyncActionCreator(
  'LOAD_BILLING_PARAMETERS',
);
export const CLEAR_BILLING = 'CLEAR_BILLING';

function getOptions(getState) {
  const queryOpts = selectBillingOptions(getState());

  const reqOptions = {};
  if (queryOpts.type) {
    reqOptions.type = queryOpts.type;
  }

  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_BILLING });
};

export const loadBillingParameters = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_BILLING_PARAMETERS.start });

    dispatch({
      type: SAVE_BILLING_OPTIONS,
      options,
    });
    const reqOptions = getOptions(getState);

    const data = await getBillingParameters(reqOptions);

    return dispatch({
      type: LOAD_BILLING_PARAMETERS.success,
      data,
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_BILLING_PARAMETERS.failure });
  }
};
