export const selectRentFactor = state => state.rentFactor;
export const selectRentFactorList = state => selectRentFactor(state).items;
export const selectRentFactorListCount = state => selectRentFactor(state).count;
export const selectRentFactorQueryList = state => {
  const { options } = selectRentFactor(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
