/* eslint-disable react/prop-types */
import React from 'react';
import PrivateRoute from 'src/components/PrivateRoute';
import AgentsListView from 'src/views/catalog/AgentsListView';
import BrandsListView from './BrandsListView';
import CompaniesListView from './CompaniesListView';
import IncidentsListView from './IncidentsListView';
import ExpenseTypesListView from './ExpenseTypesListView';
import LocationsListView from './LocationsListView';
import ServicesListView from './ServicesListView';
import InsurersListView from './InsurersListView';
import ProvidersListView from './ProvidersListView';
import DeviceListView from './DeviceListView';
import CarCategoryListView from './CarCategoriesListView';
import OperationalEmailsListView from './OperationalEmailsListView';
import GeofencingCategoryView from './GeofencingCategory';
import GeofencingZonesView from './GeofencingZones';
import CatalogListView from './CatalogListView';
import UnassignReasonView from './UnassignReasonListView';

const CatalogView = ({ match }) => {
  return (
    <>
      <PrivateRoute exact path={`${match.url}`} component={CatalogListView} />
      <PrivateRoute path={`${match.url}/agents`} component={AgentsListView} />
      <PrivateRoute
        path={`${match.url}/companies`}
        component={CompaniesListView}
      />
      <PrivateRoute path={`${match.url}/brands`} component={BrandsListView} />
      <PrivateRoute
        path={`${match.url}/services`}
        component={ServicesListView}
      />
      <PrivateRoute
        path={`${match.url}/incidents`}
        component={IncidentsListView}
      />
      <PrivateRoute
        path={`${match.url}/location`}
        component={LocationsListView}
      />
      <PrivateRoute
        path={`${match.url}/insurer`}
        component={InsurersListView}
      />
      <PrivateRoute
        path={`${match.url}/expenses`}
        component={ExpenseTypesListView}
      />
      <PrivateRoute
        path={`${match.url}/providers`}
        component={ProvidersListView}
      />
      <PrivateRoute path={`${match.url}/devices`} component={DeviceListView} />
      <PrivateRoute
        path={`${match.url}/car-categories`}
        component={CarCategoryListView}
      />
      <PrivateRoute
        path={`${match.url}/categorias-coches`}
        component={CarCategoryListView}
      />
      <PrivateRoute
        path={`${match.url}/operational-emails`}
        component={OperationalEmailsListView}
      />
      <PrivateRoute
        path={`${match.url}/geofencing-category`}
        component={GeofencingCategoryView}
      />
      <PrivateRoute
        path={`${match.url}/geofencing-zones`}
        component={GeofencingZonesView}
      />
      <PrivateRoute
        path={`${match.url}/unassign-reasons`}
        component={UnassignReasonView}
      />
    </>
  );
};

export default CatalogView;
