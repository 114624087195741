import React, { useCallback } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import FadeIn from 'react-fade-in';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import CampaignsToolbar from './CampaignsToolbar';
import CampaignTabs from './CampaignsTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    width: '100%',
  },
}));

const CampaignsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = React.useState(0);

  const filterView = useCallback(value => {
    setTabIndex(value);
  });

  return (
    <Page className={classes.root} title={t('NAVIGATION.CAMPAIGNS')}>
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <CampaignsToolbar filterView={tabIndex} />
          <Box mt={3}>
            <CampaignTabs filterView={filterView} />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default CampaignsView;
