export const selectTransactions = state => state.transactions;
export const selectTransactionsList = state => selectTransactions(state).items;
export const selectTransactionsListCount = state => selectTransactions(state).count;
export const selectTransactionsQueryOptions = state => {
  const { options } = selectTransactions(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
