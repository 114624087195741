/* eslint-disable import/prefer-default-export */
export const TransactionCategory = {
  RENT: 'RENT',
  SURCHARGE: 'SURCHARGE',
  INTERESTS: 'INTERESTS',
  GAS_RENT: 'GAS_RENT',
  TERMINATION: 'TERMINATION',
  OTHERS: 'OTHERS',
  DISCOUNTS: 'DISCOUNTS',
  FINES: 'FINES',
  SINISTER: 'SINISTER',
  ADVANCE: 'ADVANCE',
  RESIDUAL: 'RESIDUAL',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
};
