import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './authReducer';
import carReducer from './carReducer';
import snackBarReducer from './snackBarReducer';
import statusReducer from './statusReducer';
import driverReducer from './driverReducer';
import catalogReducer from './catalogReducer';
import insuranceReducer from './insuranceReducer';
import parameterReducer from './parameterReducer';
import rentResumeReducer from './rentResumeReducer';
import rentFactorReducer from './rentFactorReducer';
import incomeResumeReducer from './incomeResumeReducer';
import expenseReducer from './expenseReducer';
import fineReducer from './fineReducer';
import businessReducer from './businessReducer';
import GpsReducer from './gpsReducer';
import collectionsReducer from './collectionsReducer';
import licenceReducer from './licenceReducer';
import transactionsReducer from './transactionsReducer';
import campaignsReducer from './campaignsReducer';
import accountingReducer from './accountingReducer';
import billingReducer from './billingReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    status: statusReducer,
    auth: authReducer,
    cars: carReducer,
    drivers: driverReducer,
    business: businessReducer,
    catalog: catalogReducer,
    parameter: parameterReducer,
    insurance: insuranceReducer,
    rentResume: rentResumeReducer,
    expense: expenseReducer,
    fine: fineReducer,
    collections: collectionsReducer,
    rentFactor: rentFactorReducer,
    incomeResume: incomeResumeReducer,
    snackbar: snackBarReducer,
    gps: GpsReducer,
    licence: licenceReducer,
    transactions: transactionsReducer,
    campaigns: campaignsReducer,
    accounting: accountingReducer,
    billing: billingReducer,
  });
