import React, { useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CLEAR_BILLING,
  loadBillingParameters,
} from 'src/store/actions/billingActions';
import BillingParametersTabs from './BillingParametersTabs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const BillingParametersListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadBillingParameters());
    return () => dispatch({ type: CLEAR_BILLING });
  }, []);

  return (
    <Page className={classes.root} title={t('NAVIGATION.BILLING')}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <BillingParametersTabs />
        </Box>
      </Container>
    </Page>
  );
};

export default BillingParametersListView;
