/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Box, Grid } from '@material-ui/core';
import GeneralLease from './Lease/GeneralLease';
import PricingLease from './Lease/PricingLease';
import InvoiceLease from './Lease/InvoicesLease';
import WithoutLease from './Lease/WithoutLease';
import DriverResidual from './DriverResidual/DriverResidual';

moment.updateLocale('es', moment.localeData('es'));

const LeaseView = ({ driver }) => {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        {driver.actualDriverLease || driver.actualContract ? (
          <>
            <Grid item xs={3}>
              <GeneralLease driver={driver} />
            </Grid>

            <Grid item xs={3}>
              <PricingLease driver={driver} />
            </Grid>

            <Grid item xs={3}>
              <InvoiceLease driver={driver} />
            </Grid>
            <Grid item xs={3}>
              <DriverResidual residualAmount={driver.residualPayment} />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <WithoutLease driver={driver} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LeaseView;
