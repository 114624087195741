import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_EXPENSE } from 'src/store/actions/expenseActions';

import StepOne from './StepOne';
import StepTwo from './StepTwo';

const CurrentStep = ({
  activeStep,
  data,
  onNext,
  onBack,
  onSubmit,
  resetRef,
}) => {
  const loading = useSelector(isLoadingSelector([CREATE_EXPENSE.action]));

  if (activeStep === 0) {
    return (
      <StepOne
        data={data}
        handleNext={onNext}
        handleBack={onBack}
        resetRef={resetRef}
      />
    );
  }

  return (
    <StepTwo
      data={data}
      loading={loading}
      handleBack={onBack}
      handleSubmit={onSubmit}
    />
  );
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resetRef: PropTypes.object.isRequired,
};

export default CurrentStep;
