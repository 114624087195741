import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  LOAD_CATALOG,
  CLEAR_CATALOG,
  loadDevices,
} from 'src/store/actions/catalogActions';
import { selectCatalogList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import DeviceTable from './DeviceTable';

const DispositiveList = ({ handleOpenEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const devices = useSelector(selectCatalogList);
  const loading = useSelector(isLoadingSelector([LOAD_CATALOG.action]));

  useEffect(() => {
    dispatch(loadDevices());
    return () => dispatch({ type: CLEAR_CATALOG });
  }, []); // not add dependency
  return (
    <DeviceTable
      devices={devices}
      loading={loading}
      handleOpenEdit={handleOpenEdit}
      handleDelete={handleDelete}
    />
  );
};

DispositiveList.propTypes = {
  handleOpenEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DispositiveList;
