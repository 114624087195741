import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getIncomeResume = serviceBase({
  request: options =>
    axiosClient.get('/transactions/resume', { params: options }),
  retry: 0,
});

export const downloadExportedIncomeResume = serviceBase({
  request: options =>
    axiosClient.get('/transactions/resume/export', {
      params: options,
      responseType: 'blob',
    }),
  transformResponse: res => {
    try {
      const filename = 'incomeResume.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0,
});
