import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { CREATE_GEOFENCE } from 'src/store/actions/gpsActions';

import StepOne from './StepOne';
import StepTwo from './StepTwo';

const CurrentStep = ({ activeStep, data, onNext, onBack, onSubmit }) => {
  const loading = useSelector(isLoadingSelector([CREATE_GEOFENCE.action]));

  if (activeStep === 0) {
    return <StepOne data={data} handleNext={onNext} handleBack={onBack} />;
  }

  return (
    <StepTwo
      data={data}
      onSubmit={onSubmit}
      handleBack={onBack}
      loading={loading}
    />
  );
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CurrentStep;
