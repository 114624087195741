/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core';
import DropdownMenu from 'src/components/DropdownMenu';
import CloseIcon from '@material-ui/icons/Close';
import { PlatformType } from 'src/utils/constants';
import { useTranslation } from 'react-i18next';
import validationSchema from './schema';
import useStyles from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makeInitialState = driver => {
  return {
    platform: driver.platform || '',
    platformRating: driver.platformRating || '',
    tripsNumber: driver.tripsNumber || '',
    uberProStatus: driver.uberProStatus || '',
  };
};

const uberProStatusList = [
  {
    key: 'Plata',
    name: 'Plata',
  },
  {
    key: 'Oro',
    name: 'Oro',
  },
  {
    key: 'Platino',
    name: 'Platino',
  },
];

const plataformList = [
  {
    key: 'Uber',
    name: 'Uber',
  },
  {
    key: 'Didi',
    name: 'Didi',
  },
  {
    key: 'Privado',
    name: 'Privado',
  },
];

const DriverCalificationDialog = ({ open, driver, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(driver), [driver]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit({ ...values });
        resetForm(initialState);
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-driver"
      >
        {!confirmationOpen && (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              handleClose();
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DRIVER_DETAIL_VIEW.CALIFICATION.TITLE')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    {t('DRIVER_DETAIL_VIEW.CALIFICATION.CONFIRM_TEXT')}
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.platform && errors.platform)}
                >
                  <DropdownMenu
                    list={plataformList}
                    value={values.platform}
                    setValue={value => setFieldValue('platform', value)}
                    label={t('DRIVER_DETAIL_VIEW.CALIFICATION.JOB_PLATAFORM')}
                    error={Boolean(touched.platform && errors.platform)}
                    errorText={touched.platform && t(errors.platform)}
                  />
                </FormControl>

                <FormControl
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(touched.tripsNumber && errors.tripsNumber)}
                >
                  <TextField
                    error={Boolean(touched.tripsNumber && errors.tripsNumber)}
                    fullWidth
                    helperText={touched.tripsNumber && t(errors.tripsNumber)}
                    label={t('DRIVER_DETAIL_VIEW.CALIFICATION.TRIPS_NUMBER')}
                    margin="none"
                    name="tripsNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.tripsNumber}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </FormControl>

                {values.platform === PlatformType.UBER && (
                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(
                      touched.uberProStatus && errors.uberProStatus,
                    )}
                  >
                    <DropdownMenu
                      list={uberProStatusList}
                      value={values.uberProStatus}
                      setValue={value => setFieldValue('uberProStatus', value)}
                      label={t(
                        'DRIVER_DETAIL_VIEW.CALIFICATION.UBER_PRO_STATUS',
                      )}
                      error={Boolean(touched.uberProStatus && errors.category)}
                      errorText={
                        touched.uberProStatus && t(errors.uberProStatus)
                      }
                    />
                  </FormControl>
                )}

                {(values.platform === PlatformType.UBER ||
                  values.platform === PlatformType.DIDI) && (
                  <FormControl
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    error={Boolean(
                      touched.platformRating && errors.platformRating,
                    )}
                  >
                    <TextField
                      className={classes.field}
                      error={Boolean(
                        touched.platformRating && errors.platformRating,
                      )}
                      fullWidth
                      helperText={
                        touched.platformRating && t(errors.platformRating)
                      }
                      label={t(
                        'DRIVER_DETAIL_VIEW.CALIFICATION.PLATFORM_RATING',
                      )}
                      margin="none"
                      name="platformRating"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.platformRating}
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </FormControl>
                )}

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.UPDATE')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

DriverCalificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  driver: PropTypes.object,
};

export default DriverCalificationDialog;
