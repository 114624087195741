import axios from 'axios';
import { removeEmptyStringFieldsRecursive } from 'src/utils/removeEmptyStringFieldsRecursive';
import axiosClient from './axiosClient';

const defaultResponseTransformation = res => res.data.data;

const selectErrorMessage = err => {
  try {
    const statusCode = err.response.status;
    const serverError = err.response.data;

    if (statusCode === 401) {
      return 'Unauthorized';
    }

    if (serverError.message) {
      if (typeof serverError.message === 'string') {
        return serverError.message;
      }

      if (typeof serverError.message === 'object') {
        if (typeof serverError.message.message === 'string') {
          return serverError.message.message;
        }

        const actualError = serverError.message;
        const [error] = actualError;

        return error;
      }
    }
  } catch (err1) {
    return 'Something was wrong...';
  }

  return 'Something was wrong...';
};

/**
 * Generates a service according to the passed config object
 * @param {{request: (data: any) => Promise<any>; transformResponse?: (response: AxiosResponse<any>) => any; retry?: number; debug?: boolean}} config configuration object to define the service
 * @returns {(data: any) => Promise<any>} service you can call from your thunks
 */
export const serviceBase = ({
  request,
  transformResponse = defaultResponseTransformation,
  retry = 0,
  debug = false,
}) => {
  if (!request) {
    throw Error('Please provide a valid request function to serviceBase!');
  }

  return async data => {
    if (data && typeof data === 'object') {
      removeEmptyStringFieldsRecursive(data);
    }

    try {
      if (debug) {
        console.log('Sending request...');
      }

      const response = await request(data);

      if (!response) {
        throw Error('Somehting was wrong...');
      }

      return transformResponse(response);
    } catch (err) {
      if (retry > 0) {
        if (debug) {
          console.log('retrying');
        }

        return serviceBase({
          request,
          transformResponse,
          retry: retry - 1,
          debug,
        })(data);
      }

      throw Error(selectErrorMessage(err));
    }
  };
};

export const getUploadLink = serviceBase({
  request: ({ format, contentType }) =>
    axiosClient.get(`/aws/backoffice-upload-link`, {
      params: { format, contentType },
    }),
  retry: 0,
});

export const uploadFile = serviceBase({
  request: ({ targetURL, file }) =>
    axios.put(targetURL, file, { timeout: 30000 }),
  retry: 0,
});

export default serviceBase;
