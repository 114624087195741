/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Formik } from 'formik';
import { DialogContentText, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UploadFile from 'src/components/UploadFile';
import { useTranslation } from 'react-i18next';
import validationSchema from './schema';
import useStyles from '../styles';
import makeInitialState from './initialState';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AvalDocumentDialog = ({ open, aval, handleClose, submit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(aval), [aval]);
  const resetRef = useRef();
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, [setConfirmationOpen]);

  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, [setConfirmationOpen]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      if (!confirmationOpen) {
        handleConfirmationOpen();
      } else {
        submit(values);
        resetForm(initialState);
        handleClose();
      }
    },
    [
      submit,
      handleClose,
      initialState,
      handleConfirmationOpen,
      confirmationOpen,
    ],
  );

  const handleUploadChange = useCallback(
    setFieldValue => e => {
      if (e.target.files[0]) {
        setFieldValue(e.target.name, e.target.files[0]);
      }
    },
    [],
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        className={classes.header}
        id="form-general-driver"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('COMMON.DOCUMENTS')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            resetForm,
            setFieldValue,
          }) => {
            resetRef.current = () => resetForm(initialState);
            if (confirmationOpen) {
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContentText
                    className={classes.confirmation}
                    id="form-general-driver-dialog"
                  >
                    {t('DRIVER_DETAIL_VIEW.DRIVER_UPDATE_MESSAGE')}
                  </DialogContentText>

                  <Grid container direction="column" alignItems="stretch">
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.label, classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.ACCEPT')}
                      </Button>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      <Button
                        className={clsx(classes.outlined, classes.buttons)}
                        onClick={handleConfirmationClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <>
                  <UploadFile
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleUploadChange={handleUploadChange}
                    setFieldValue={setFieldValue}
                    accept="image/*"
                    valueLabel="ifeAval"
                    textLabel={t('UPLOAD_MESSAGE.UPLOAD_AVAL_IFE')}
                  />
                  <UploadFile
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleUploadChange={handleUploadChange}
                    setFieldValue={setFieldValue}
                    accept="image/*"
                    valueLabel="waterAval"
                    textLabel={t('UPLOAD_MESSAGE.UPLOAD_AVAL_WATER_FILE')}
                  />
                </>

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.ACCEPT')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.outlined, classes.buttons)}
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AvalDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  aval: PropTypes.object,
};

export default AvalDocumentDialog;
