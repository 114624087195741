import * as Yup from 'yup';

export default Yup.object().shape({
  constanceRfc: Yup.mixed(),
  constitutiveFile: Yup.mixed(),
  attorneyIfe: Yup.mixed(),
  businessAddressInvoice: Yup.mixed(),
  avalAddressInvoice: Yup.mixed(),
  taxCompliance: Yup.mixed(),
  frameworkContract: Yup.mixed(),
});
