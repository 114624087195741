import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingSelector,
  successSelector,
} from 'src/store/selectors/statusSelector';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  selectExpenseCount,
  selectExpenseList,
  selectExpenseOptions,
} from 'src/store/selectors/expenseSelectors';
import * as Actions from 'src/store/actions/expenseActions';
import ExpenseTable from './ExpenseTable';

const ExpenseList = () => {
  const dispatch = useDispatch();
  const expenses = useSelector(selectExpenseList);
  const count = useSelector(selectExpenseCount);
  const { page } = useSelector(selectExpenseOptions);
  const loading = useSelector(isLoadingSelector([Actions.LOAD_EXPENSE.action]));
  const success = useSelector(successSelector([Actions.LOAD_EXPENSE.action]));

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(Actions.loadExpense({ page: value }));
    },
    [dispatch],
  );

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="No se han encontrado gastos"
          description="Ningún gasto coincide con la búsqueda realizada"
        />
      </Card>
    );
  }

  return (
    <ExpenseTable
      expenses={expenses}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default ExpenseList;
